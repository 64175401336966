import React, { useEffect, useRef, useState } from "react";
import { Field, Form } from "react-final-form";

import calculateAge from "../../../../extensions/date.calculateAge";
import {
    renderDropdownList,
    renderDatePicker,
    renderInput,
    toIntOptions,
    toSortedIntOptions,
} from "../../../form-inputs/final-form";
import { successNotification } from "../../../common";

import Aliases from "../aliases";
import Documents from "../documents";
import NoteWidget from "../../notes/widget";
import QueriesWidget from "../../../queries/widget";
import RtpfLookupModal from "../../../rtpf-lookup/modal"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFlagCheckered,
    faCheck,
    faSearch,
} from "@fortawesome/free-solid-svg-icons";

const defaultOptions = {
    genders: [],
    nationalities: [],
    languages: [],
    ethnicities: [],
    impairmentGroups: [],
    isCaseKnownToHomeOffice: [],
};

const AddForm = ({
    onSubmit,
    initialValues,
    isClosed,
    aliases,
    documents,
    localAuthorityNote,
    homeOfficeNote,
    queries,
    successMessage,
    highlights,
    onEditAliasModalRequest,
    onDeleteAliasModalRequest,
    onEditDocumentModalRequest,
    onDeleteDocumentModalRequest,
    onAddLocalAuthorityNoteModalRequest,
    onLocalAuthorityNoteHistoryModalRequest,
    onAddHomeOfficeNoteModalRequest,
    onHomeOfficeNoteHistoryModalRequest,
    onAddQueryModalRequest,
    onViewQueryModalRequest,
}) => {

    const formSubmitButton = useRef();

    useEffect(() => {
        var { unsubscribe } = window.dispatcher.subscribe(
            "change-tracker-submit-form",
            () => formSubmitButton.current.click()
        );

        return () => {
            unsubscribe();
        };
    }, []);


    const {
        genders,
        nationalities,
        languages,
        ethnicities,
        impairmentGroups,
        isCaseKnownToHomeOffice,
    } = initialValues.options || defaultOptions;

    const gendersOptions = genders;
    const nationalitiesOptions = nationalities;
    const languagesOptions = toSortedIntOptions(languages);
    const ethnicitiesOptions = toSortedIntOptions(ethnicities);
    const isCaseKnownToHomeOfficeOptions = toIntOptions(isCaseKnownToHomeOffice);


    const [showRtpfModal, setShowRtpfModal] = useState(false);
    const onRtpfLookUpClick = () => setShowRtpfModal(true);
    const onRtpfLookUpClose = () => setShowRtpfModal(false);


    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            render={({ handleSubmit, submitting, pristine, values }) => {

                const {
                    caseId,
                    selectedHealthConditionIds,
                    dateOfBirth,
                    nationalityCode,
                    title,
                    forename,
                    surname,
                    brpNumber,
                    brcNumber,
                    euIdentityCardNumber,
                    ninoNumber,
                    passportNumber
                } = values;

                const disabled = submitting || isClosed;

                const age = calculateAge(dateOfBirth);
                const name = `${title || ''} ${forename || ''} ${surname || ''}`.trim();

                const impairmentGroupsList = (selectedHealthConditionIds?.length || 0) === 0
                    ? "N/A"
                    : impairmentGroups.filter(ig => selectedHealthConditionIds.includes(parseInt(ig.value)))
                        .map(ig => ig.label)
                        .join(', ');

                const success = successNotification(successMessage);


                const modalValues = {
                    caseId,
                    forename,
                    surname,
                    brpNumber,
                    brcNumber,
                    euIdentityCardNumber,
                    ninoNumber,
                    passportNumber,
                    dateOfBirth,
                    nationalityCode,
                    autoUpdate: true
                }
                const rtpfLookupModal = showRtpfModal 
                    ? <RtpfLookupModal onClose={onRtpfLookUpClose} values={modalValues} /> 
                    : null


                const saveButtonContents = highlights.areActive ? (
                    pristine ? (
                        <>
                            <FontAwesomeIcon icon={faCheck} />
                            <span>Accept</span>
                        </>
                    ) : (
                        <>
                            <FontAwesomeIcon icon={faCheck} />
                            <span>Accept and Save</span>
                        </>
                    )
                ) : (
                    <>
                        <FontAwesomeIcon icon={faFlagCheckered} />
                        <span>Save</span>
                    </>
                );

                return (
                    <>
                        <form onSubmit={handleSubmit} rel="change-tracked">
                            <div className="dependant-edit-wrapper">
                                <div className="stripedbox">
                                    <div className="mandatory">
                                        <span>*</span> Denotes a mandatory field{" "}
                                    </div>
                                    {success}
                                </div>
                                <div className="dependant-grid-wrapper">
                                    <div className="stripedbox left-column">
                                        <div className="dependant-edit-form">
                                            <h2>Personal Details</h2>

                                            <div className="input-wrapper">
                                                <label>Name</label>
                                                <span
                                                    className={`fake-input ${highlights.name ? "is-highlighted" : ""
                                                        }`}
                                                >
                                                    {name}
                                                </span>
                                            </div>
                                            <div className="input-wrapper">
                                                <label>Gender</label>
                                                <Field
                                                    name="gender"
                                                    type="text"
                                                    render={renderDropdownList}
                                                    options={gendersOptions}
                                                    isHighlighted={highlights.gender}
                                                    disabled={true}
                                                />
                                            </div>
                                            <div className="input-wrapper">
                                                <label>Nationality</label>
                                                <Field
                                                    name="nationalityCode"
                                                    type="text"
                                                    render={renderDropdownList}
                                                    options={nationalitiesOptions}
                                                    isHighlighted={highlights.nationalityCode}
                                                    disabled={true}
                                                />
                                            </div>
                                            <div className="input-wrapper">
                                                <label>Language</label>
                                                <Field
                                                    name="languageId"
                                                    type="text"
                                                    render={renderDropdownList}
                                                    options={languagesOptions}
                                                    isHighlighted={highlights.languageId}
                                                    disabled={true}
                                                />
                                            </div>
                                            <div className="input-wrapper">
                                                <label>Ethnicity</label>
                                                <Field
                                                    name="ethnicityId"
                                                    type="text"
                                                    render={renderDropdownList}
                                                    options={ethnicitiesOptions}
                                                    isHighlighted={highlights.ethnicityId}
                                                    disabled={true}
                                                />
                                            </div>

                                            <hr />

                                            <h2>Date of Birth & Age</h2>

                                            <div className="input-wrapper">
                                                <Field
                                                    name="dateOfBirth"
                                                    type="text"
                                                    render={renderDatePicker}
                                                    label="Date of Birth"
                                                    placeholder=""
                                                    isHighlighted={highlights.dateOfBirth}
                                                    disabled={true}
                                                />
                                            </div>
                                            <div className="input-wrapper">
                                                <label>Age</label>
                                                <div className="fake-input">{age}</div>
                                            </div>
                                            <div className="input-wrapper">
                                                <Field
                                                    name="dateOfDeath"
                                                    type="text"
                                                    render={renderDatePicker}
                                                    label="Date of Death"
                                                    placeholder=""
                                                    isHighlighted={highlights.dateOfDeath}
                                                    disabled={true}
                                                />
                                            </div>

                                            <hr />

                                            <h2>
                                                Which impairment groups have been recorded by the LA?
                                            </h2>

                                            <div className="fake-input">{impairmentGroupsList}</div>


                                            <hr />

                                            <h2>Local Authority Notes</h2>

                                            <NoteWidget
                                                note={localAuthorityNote}
                                                disabled={disabled}
                                                onAddModalRequest={onAddLocalAuthorityNoteModalRequest}
                                                onHistoryModalRequest={
                                                    onLocalAuthorityNoteHistoryModalRequest
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="right-column">
                                        <div className="stripedbox">
                                            <div className="dependant-edit-form">
                                                <h2>Reference Numbers</h2>
                                                <div className="input-wrapper">
                                                    <Field
                                                        name="referenceNumber"
                                                        type="text"
                                                        render={renderInput}
                                                        label="LA Reference Number"
                                                        placeholder=""
                                                        isHighlighted={highlights.referenceNumber}
                                                        disabled={true}
                                                    />
                                                </div>
                                                <div className="input-wrapper">
                                                    <Field
                                                        name="homeOfficeReferenceNumber"
                                                        type="text"
                                                        render={renderInput}
                                                        label="Home Office Reference"
                                                        placeholder=""
                                                        isHighlighted={highlights.homeOfficeReferenceNumber}
                                                        disabled={disabled}
                                                    />
                                                </div>
                                                <div className="input-wrapper">
                                                    <Field
                                                        name="visaNumber"
                                                        type="text"
                                                        render={renderInput}
                                                        label="Home Office Person Id"
                                                        placeholder=""
                                                        isHighlighted={highlights.visaNumber}
                                                        disabled={disabled}
                                                    />
                                                </div>
                                                <div className="input-wrapper">
                                                    <Field
                                                        name="nassReference"
                                                        type="text"
                                                        render={renderInput}
                                                        label="NASS Reference Number"
                                                        placeholder=""
                                                        isHighlighted={highlights.nassReference}
                                                        disabled={disabled}
                                                    />
                                                </div>
                                                <div className="input-wrapper">
                                                    <Field
                                                        name="uniqueAppNumber"
                                                        type="text"
                                                        render={renderInput}
                                                        label="Unique Application Number"
                                                        placeholder=""
                                                        disabled={disabled}
                                                    />
                                                </div>

                                                <h2>
                                                    Complete one field below for digital status check
                                                    purposes
                                                </h2>

                                                <div className="input-wrapper">
                                                    <Field
                                                        name="brpNumber"
                                                        type="text"
                                                        render={renderInput}
                                                        label="BRP Number"
                                                        placeholder=""
                                                        disabled={disabled}
                                                    />
                                                </div>
                                                <div className="input-wrapper">
                                                    <Field
                                                        name="brcNumber"
                                                        type="text"
                                                        render={renderInput}
                                                        label="BRC Number"
                                                        placeholder=""
                                                        disabled={disabled}
                                                    />
                                                </div>
                                                <div className="input-wrapper">
                                                    <Field
                                                        name="passportNumber"
                                                        type="text"
                                                        render={renderInput}
                                                        label="Passport Number"
                                                        placeholder=""
                                                        disabled={disabled}
                                                    />
                                                </div>
                                                <div className="input-wrapper">
                                                    <Field
                                                        name="euIdentityCardNumber"
                                                        type="text"
                                                        render={renderInput}
                                                        label="European National Identity Card Number"
                                                        placeholder=""
                                                        disabled={disabled}
                                                    />
                                                </div>
                                                <div className="input-wrapper">
                                                    <Field
                                                        name="ninoNumber"
                                                        type="text"
                                                        render={renderInput}
                                                        label="NINO Number"
                                                        placeholder=""
                                                        disabled={disabled}
                                                    />
                                                </div>
                                                <div className="form-buttons">
                                                    <button
                                                        type="button"
                                                        onClick={onRtpfLookUpClick}
                                                        className="rbtn green hover-tooltip"
                                                        disabled={disabled}
                                                        data-tooltip="The ‘Recourse to Public Funds checker’ accesses a person’s UK Visa and Immigration account to confirm any digital immigration status held by the applicant."
                                                    >
                                                        <FontAwesomeIcon icon={faSearch} />
                                                        <span>RTPF Look-up</span>
                                                    </button>
                                                </div>

                                                <hr />

                                                <Aliases
                                                    aliases={aliases}
                                                    disabled={disabled}
                                                    onEditAliasModalRequest={onEditAliasModalRequest}
                                                    onDeleteAliasModalRequest={onDeleteAliasModalRequest}
                                                    applicantName={name}
                                                    applicantDateOfBirth={dateOfBirth}
                                                />

                                                <hr />

                                                <Documents
                                                    applicantId={values.applicantId}
                                                    documents={documents}
                                                    disabled={disabled}
                                                    onEditDocumentModalRequest={onEditDocumentModalRequest}
                                                    onDeleteDocumentModalRequest={
                                                        onDeleteDocumentModalRequest
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div className="stripedbox dark">
                                            <div className="dependant-edit-form">
                                                <h2>Home Office Actions</h2>

                                                <div className="input-wrapper">
                                                    <label>
                                                        Case known to Home Office?{" "}
                                                        <span className="mandatory-stars">*</span>
                                                    </label>
                                                    <Field
                                                        name="homeOfficeActionId"
                                                        type="text"
                                                        render={renderDropdownList}
                                                        options={isCaseKnownToHomeOfficeOptions}
                                                        disabled={disabled}
                                                    />
                                                </div>

                                                <NoteWidget
                                                    note={homeOfficeNote}
                                                    disabled={disabled}
                                                    onAddModalRequest={onAddHomeOfficeNoteModalRequest}
                                                    onHistoryModalRequest={
                                                        onHomeOfficeNoteHistoryModalRequest
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div className="stripedbox">
                                            <div className="dependant-edit-form">
                                                <QueriesWidget
                                                    queries={queries}
                                                    disabled={disabled}
                                                    onAddQueryModalRequest={onAddQueryModalRequest}
                                                    onViewQueryModalRequest={onViewQueryModalRequest}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="stripedbox">
                                    <div className="form-buttons">
                                        <button
                                            type="submit"
                                            className="rbtn primary"
                                            disabled={submitting}
                                            ref={formSubmitButton}
                                        >
                                            {saveButtonContents}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        {rtpfLookupModal}
                    </>
                );
            }}
        />
    );
};

export default AddForm;
