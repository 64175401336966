import React, { useEffect } from "react";

import { caseArea, caseId, userIsCaseOwner, isClosed } from '../../server-values';

import useListState from '../../use-list-state';
import emptyGuid from '../../../../extensions/guid.empty';
import DeleteModal from './delete';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

const DependantRow = ({dependant, onEditClick, onDeleteModalRequest}) => {

    const rowClass = `${dependant.isHighlightedForAlert ? "is-highlighted" : ""}`;

    return (
        <div className={`body-row ${rowClass}`}>
            <span>{dependant.name}</span>
            <span>{dependant.dateOfBirth}</span>
            <span>{dependant.gender}</span>
            <span>{dependant.relationship}</span>
            <span>{dependant.ukbaAction}</span>
            <span>
                <div className="list-buttons">
                    <button type="button" onClick={() => onEditClick(dependant.id)} className="rbtn light-grey">
                        <span><FontAwesomeIcon icon={faEdit} /></span>
                    </button>
                    {
                        userIsCaseOwner && !isClosed
                            ? (
                                <button type="button" onClick={() => onDeleteModalRequest(dependant.id)} className="rbtn light-grey">
                                    <span><FontAwesomeIcon icon={faTrash} /></span>
                                </button>
                            )
                            : null
                    }                   
                </div>                    
            </span>
        </div>
    )
}

export default ({dependants, isClosed}) => {
    
    const {
        items,
        setItems,
        onDeleteModalRequest,
        deleteModal
    } = useListState(
        emptyGuid,
        (id, onClose) => null,
        (id, onClose) => <DeleteModal onClose={onClose} caseId={caseId} applicantId={id} disabled={isClosed} />
    )

    useEffect(() => {
        setItems(dependants)
    }, [dependants, setItems]);
    

    const dependantCount = items?.records?.length || 0;
    const hasDependants = (dependantCount > 0)


    const onEditClick = (id) => {
        window.location = `/${caseArea}/Dependant?CaseId=${caseId}#/Edit/${id}`;
    }

    return (
        <>
            <div className="dependants-list">
                <div className={`dependants-summary-rows ${hasDependants === false ? 'no-records' : ''}`}>
                    <div className="header">
                        <span>Name</span>
                        <span>DoB</span>
                        <span>Gender</span>
                        <span>Relationship</span>
                        <span>Known to HO?</span>
                        <span></span>
                    </div>
                    {
                        hasDependants === false
                        ? (
                            <div className={`body-row`}>
                                <span>No Dependants</span>
                            </div>
                        )
                        : (
                            <>
                                { items.records.map(dependant => <DependantRow key={dependant.id} dependant={dependant} onEditClick={onEditClick} onDeleteModalRequest={onDeleteModalRequest} />) }
                            </>
                        )
                    }
                </div>
            </div>
            { deleteModal }
        </>
    )
}