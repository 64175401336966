import React from 'react';

import ActionButtons from './action-buttons';

export const localAuthorityColumns = [
  {
    Header: 'Move In',
    accessor: 'dateMovedIn',
    Cell: ({row, value}) => <span className={`${row.original.isCurrentAddress ? 'current-address' : ''}`}>{value}</span>,
    width: 100,
    disableFilters: true
  },
  {
    Header: 'Move Out',
    accessor: 'dateMovedOut',
    Cell: ({row, value}) => <span className={`${row.original.isCurrentAddress ? 'current-address' : ''}`}>{value}</span>,
    width: 100,
    disableFilters: true
  },
  {
    Header: 'Address',
    accessor: 'address',
    Cell: ({row, value}) => <span className={`${row.original.isCurrentAddress ? 'current-address' : ''}`}>{value}</span>,
    width: 400,
    disableFilters: true
  },
  {
    Header: ' ',
    disableSortBy: true,
    Cell: ({row}) => <ActionButtons row={row} />,
    width: 200,
    disableFilters: true
  }
];

export const homeOfficeColumns = [
  {
    Header: 'Move In',
    accessor: 'dateMovedIn',
    Cell: ({row, value}) => <span className={`${row.original.isCurrentAddress ? 'current-address' : ''}`}>{value}</span>,
    width: 100,
    disableFilters: true
  },
  {
    Header: 'Move Out',
    accessor: 'dateMovedOut',
    Cell: ({row, value}) => <span className={`${row.original.isCurrentAddress ? 'current-address' : ''}`}>{value}</span>,
    width: 100,
    disableFilters: true
  },
  {
    Header: 'Address',
    accessor: 'address',
    Cell: ({row, value}) => <span className={`${row.original.isCurrentAddress ? 'current-address' : ''}`}>{value}</span>,
    width: 400,
    disableFilters: true
  },
  {
    Header: ' ',
    disableSortBy: true,
    Cell: ({row}) => <ActionButtons row={row} />,
    width: 200,
    disableFilters: true
  }
];

export default localAuthorityColumns;