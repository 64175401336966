import React from 'react';
import { Field, Form } from 'react-final-form';
import { renderInput } from '../../../../../form-inputs/final-form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faKey } from '@fortawesome/free-solid-svg-icons';


const ResetPasswordForm = ({onSubmit, onClose, initialValues, isCurrentUser}) => (
    
    <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({handleSubmit}) => {

   
            const currentPasswordField = isCurrentUser
            ? (
                <div>
                    <span className="w-100 p-15 option-label">Current Password <span className="mandatory-stars">*</span></span>
                    <div className="w-100 p-15">
                        <Field name="currentPassword" render={renderInput} type="password" tabIndex={3} />
                    </div>
                </div>
            ) : null;

            return (
                <form autoComplete="off" onSubmit={handleSubmit}>

                    <div>
                        <span className="w-100 p-15 option-label">New Password <span className="mandatory-stars">*</span></span>
                        <div className="w-100 p-15">
                            <Field name="password" render={renderInput} type="password" tabIndex={1} className="input-block-level" />
                        </div>
                    </div>

                    <div>
                        <span className="w-100 p-15 option-label">Confirm Password <span className="mandatory-stars">*</span></span>
                        <div className="w-100 p-15">
                            <Field name="confirmPassword" render={renderInput} type="password" tabIndex={2} />
                        </div>
                    </div>

                    { currentPasswordField }

                    <div className="advise-box">
                        <p>Passwords must meet the following minimum requirements:</p>
                        <ul>
                            <li>Be at least twelve characters in length</li>
                            <li>Not be the same as the last ten passwords used</li>
                            <li>
                                Contain characters from three of the following four categories:
                                <ul>
                                    <li>English uppercase characters (A through Z)</li>
                                    <li>English lowercase characters (a through z)</li>
                                    <li>Base 10 digits (0 through 9)</li>
                                    <li>Non-alphabetic characters (for example, !, $, #, %)</li>
                                </ul>
                            </li>
                        </ul>
                    </div>

                    <div className="button-row p-15">
                        <button onClick={onClose} className="rbtn secondary"><FontAwesomeIcon icon={faTimesCircle} /><span>Cancel</span></button>
                        <button type="submit" className="rbtn primary"><FontAwesomeIcon icon={faKey} /><span>Change Password</span></button>
                    </div>
                </form>
            );
        }}
    />
);

export default ResetPasswordForm;
