import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import useRedirectIfNotLoggedIn from '../common/useRedirectIfNotLoggedIn';

import Routing from './routing';

export default () => {

    useRedirectIfNotLoggedIn();

    return (
        <Router>
            <Routing />
        </Router>
        
    );
}