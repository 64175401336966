import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { caseId, isClosed, caseArea, addressId } from '../../server-values';
import { onLoad, onUpdateSubmit } from '../../../../api-endpoint-calls/case/address';

import Form from './form';

import { LoadingV1 } from '../../../common/loading';

const CaseAddressAdd = () => {

    const history = useHistory();

    useEffect(() => {
        if (isClosed) {
            history.push(`/${caseArea}/Address/History?CaseId=${caseId}`);
        }
    }, [history]);


    const [loaded, setLoaded] = useState(false);
    const [successful, setSuccessful] = useState(false);
    const [initialValues, setInitialValues] = useState({});

    useEffect(() => {
        const onPageLoad = async () => {
            const result = await onLoad(caseId, addressId);
            
            setLoaded(result.isLoaded);
            setSuccessful(result.isSuccessful);

            if (result.initialValues) {
                setInitialValues(result.initialValues);
            }
        }

        onPageLoad();
        
    }, [setInitialValues, setLoaded, setSuccessful]);

    
    const showSpinner = (!loaded);
    const showFailure = (loaded && !successful);

    if (showSpinner) {
        return <LoadingV1 />;
    } else if (showFailure) {
        return <h2>An error has occured!</h2>;
    }


    const onFormSubmit = async (formValues) => {

        const result = await onUpdateSubmit(formValues);

        if (result.isSuccess) {
            window.dispatcher.dispatchEvent('change-tracker-reset');

            if (formValues.isAddingFinanceAfterAddingAddress) {
                //history.push(`/${caseArea}/Finance/Display?iCaseID=${caseId}&addressId=${result.addressId}`);
                window.location = `/${caseArea}/Finance/Display?iCaseID=${caseId}&addressId=${result.addressId}`;
            } else {
                //history.push(`/${caseArea}/Address/History?CaseId=${caseId}`);
                window.location = `/${caseArea}/Address/History?CaseId=${caseId}`;
            }
        }

        return result.errors;
    }


    return (
        <div className="case-address-edit-page">
            <Form
                onSubmit={onFormSubmit}
                initialValues={initialValues}
            />
        </div>
    );
}

export default CaseAddressAdd;
