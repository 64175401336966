import React, { useCallback, useMemo } from 'react';
import * as actions from '../../../state/actions/case/summary-actions';

import RtfpModal from '../../rtpf-lookup/modal';
import dateParseForReactWidget from '../../../extensions/date.parseForReactWidget';

export default (showRtpfModal, rtpfLookupDetails, caseId, dispatch) => {

    const onRtpfLookupClose = useCallback(() => {
        actions.toggleRtpfModal(dispatch);
    }, [dispatch]);

    const {
        firstName,
        surname,
        biometricResidencyPermit,
        biometricResidencyCard,
        europeanNationalIdentityCardNumber,
        nationalInsuranceNumber,
        passportNumber,
        dateOfBirth,
        nationalityCode
    } = rtpfLookupDetails;

    const modalValues = useMemo(() => ({
        caseId,
        forename: firstName,
        surname: surname,
        brpNumber: biometricResidencyPermit,
        brcNumber: biometricResidencyCard,
        euIdentityCardNumber: europeanNationalIdentityCardNumber,
        ninoNumber: nationalInsuranceNumber,
        passportNumber: passportNumber,
        dateOfBirth: dateParseForReactWidget(dateOfBirth),
        nationalityCode: nationalityCode,
        autoUpdate: true
    }),
        [
            caseId,
            firstName,
            surname,
            biometricResidencyPermit,
            biometricResidencyCard,
            europeanNationalIdentityCardNumber,
            nationalInsuranceNumber,
            passportNumber,
            dateOfBirth,
            nationalityCode
        ]);

    const modal = useMemo(() =>
        showRtpfModal 
            ? <RtfpModal onClose={onRtpfLookupClose} values={modalValues} /> 
            : null
    , [showRtpfModal, onRtpfLookupClose, modalValues]);

    return modal;
}