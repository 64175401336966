import React from 'react';

export default ({ rows }) => (
    <div className="case-closure-history">
        <div className="summary-rows">
            <div className="header">
                <span style={{width: "70px"}}>Closed By</span>
                <span style={{width: "70px"}}>Reason For Closure</span>
                <span style={{width: "70px"}}>Referral Made</span>
                <span style={{width: "70px"}}>Client Followed Refferal Route</span>
                <span style={{width: "70px"}}>Previous referral date</span>
                <span style={{width: "70px"}}>Previous start date</span>
                <span style={{width: "70px"}}>Closure date</span>
                <span style={{width: "70px"}}>Date of restart</span>
            </div>
            {
                rows.map((values, index) =>
                    <div key={`history_${values.closureDate}_${index}`} className="body-row">
                        <span>{values.user}</span>
                        <span>{values.closureReason}</span>
                        <span>{values.referralMade}</span>
                        <span>{values.clientFollowedReferralRoute}</span>
                        <span>{values.previousReferralDate}</span>
                        <span>{values.previousStartDate}</span>
                        <span>{values.closureDate}</span>
                        <span>{values.reOpenDate}</span>
                    </div>
                )
            }
        </div>
    </div>
)