import React from "react";

import DatePicker from 'react-datepicker';
import Select from 'react-select'

import customDatePickerHeader from './react-datepicker-custom-header';
import parseBool from '../../extensions/bool.parse';


export const renderErrorMessage = ({meta, children}) => {

    const { touched, error, submitError } = meta;
    const hasError = (touched && (error || submitError));

    const errorMessage = hasError
        ? (
            <span className="field-validation-error">
                {(error || submitError)}
            </span>
        )
        : null;

    return (
        <div className="input-with-error-message">
            {children}    
            {errorMessage}
        </div>
    );
}

export const renderInput = ({ input, meta, type, label, placeholder, tabIndex, disabled, style, isMandatory = false, hasErrorMessage = true, isHighlighted = false, maxlength = 100 }) => {

    const { touched, error, submitError } = meta;
    const hasError = (touched && (error || submitError));

    const errorMessage = hasError
        ? (
            <span className="field-validation-error">
                {(error || submitError)}
            </span>
        )
        : null;

    const labelElement = label ? (<label>{label} {isMandatory ? <span className="mandatory-stars">*</span> : null}</label>) : null;

    const className = `${isHighlighted ? 'is-highlighted' : ''} ${hasError ? " input-validation-error" : ""}`;
    const inputElement = (
        <input
            {...input}
            type={type || input.type}
            placeholder={placeholder ?? ""}
            tabIndex={tabIndex}
            disabled={disabled}
            className={className}
            style={style}
            maxLength={maxlength}
        />
    );

    const finalInputElement = hasErrorMessage
    ? (
        <div className="input-with-error-message">
            {inputElement}    
            {errorMessage}
        </div>
    )
    : inputElement;

    return (
        <>
            {labelElement}
            {finalInputElement}
        </>
    );
}

export const renderDatePicker = ({ input, meta, label, placeholder = "dd/mm/yyyy", tabIndex, disabled, minDate, maxDate, isMandatory = false, isHighlighted = false }) => {
    
    const { touched, error, submitError } = meta;
    const hasError = (touched && (error || submitError));
    
    // this is required to convert the existing input.value to null from empty string
    const { name, onChange, onBlur, onFocus, value } = input;

    const errorMessage = hasError
    ? (
        <span className="field-validation-error">
            {(error || submitError)}
        </span>
    )
    : null;

    let limits = {};
    if (minDate)
        limits.minDate = minDate;
    if (maxDate)
        limits.maxDate = maxDate;

    const labelElement = label ? (<label>{label} {isMandatory ? <span className="mandatory-stars">*</span> : null}</label>) : null;
    const className = `search-field ${isHighlighted ? 'is-highlighted' : ''} ${hasError ? " input-validation-error" : ""}`;

    return (
        <>
            {labelElement}
            <div className="input-with-error-message">
                <DatePicker
                    className={className}
                    autoComplete="off"
                    dateFormat="dd/MM/yyyy"
                    closeOnScroll={true}
                    selected={value === "" ? null : value}
                    placeholder={placeholder}
                    tabIndex={tabIndex}
                    disabled={disabled}
                    
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    renderCustomHeader={customDatePickerHeader}
                    { ...limits }
                />
                {errorMessage}
            </div>
        </>
    );
}

export const renderSelectList = ({ input, meta, tabIndex, disabled, title, children }) => {

    const { touched, error, submitError } = meta;

    const errorMessage = (touched && (error || submitError))
        ? (
            <span className="field-validation-error">
                {(error || submitError)}
            </span>
        )
        : null;


    return (
        <div className="input-with-error-message">
            <select
                {...input}
                tabIndex={tabIndex}
                disabled={disabled}
                title={title}
                className={((error || submitError) ? "keep-original input-validation-error" : "keep-original")}
            >
                {children}
            </select>
            {errorMessage}
        </div>
    );
}

const defaultOptions = (defaultText = ' -- Please Select -- ') => [{ value: null, label: defaultText }]
export const toBoolOptions = (values) => values ? values.map(v => ({ ...v, value: parseBool(v.value) })) : [];
export const toIntOptions = (values) => values ? values.map(v => ({ ...v, value: parseInt(v.value) })) : [];
export const toOptionsWithDefault = (values, defaultText) => values ? defaultOptions(defaultText).concat(values) : defaultOptions();

export const toSortedIntOptions = (values) => values ? toIntOptions(values).sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1) : [];
export const toSortedOptions = (values) => values ? values.sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1) : [];
export const toSortedOptionsWithDefault = (values, defaultText) => values ? defaultOptions(defaultText).concat(toSortedOptions(values)) : defaultOptions();

const defaultGroupedOptions = () => [{label: "", options: []}];
export const toGroupedIntOptions = (groups) => groups.length > 1 
    ? [ ...groups ].map(grp => ({
        ...grp,
        options: grp.options.map(opt => ({
            ...opt,
            value: parseInt(opt.value)
        }))
    })) 
    : defaultGroupedOptions();


export const renderDropdownList = ({ input, meta, tabIndex, disabled, title, placeholder, options, hasErrorMessage = true, isHighlighted = false, isClearable = true }) => {

    const { touched, error, submitError } = meta;
    const hasError = (touched && (error || submitError));

    const errorMessage = hasError
        ? (
            <span className="field-validation-error">
                {(error || submitError)}
            </span>
        )
        : null;

    var currentValue = options.find(item => item.value === input.value);

    const additionalFlags = {};
    if (isClearable) {
        additionalFlags.isClearable = true;
    }

    const className = `react-select keep-original ${isHighlighted ? 'is-highlighted-dropdown' : ''} ${hasError ? " input-validation-error" : ""}`;

    const inputElement = (
        <Select
            {...input}
            value={currentValue}
            tabIndex={tabIndex}
            isDisabled={disabled}
            title={title}
            placeholder={placeholder || " -- Please Select -- "}
            options={options}
            onChange={(selectedOption) => input.onChange(selectedOption?.value)}
            className={className}
            classNamePrefix="react-select"
            { ...additionalFlags }
        />
    );

    return hasErrorMessage
    ? (
        <div className="input-with-error-message">
            {inputElement}    
            {errorMessage}
        </div>
    )
    : inputElement;
}

export const renderGroupedDropdownList = ({ input, meta, tabIndex, disabled, title, placeholder, options, hasErrorMessage = true, isHighlighted = false, isClearable = true }) => {

    const { touched, error, submitError } = meta;
    const hasError = (touched && (error || submitError));

    const errorMessage = hasError
        ? (
            <span className="field-validation-error">
                {(error || submitError)}
            </span>
        )
        : null;

    const filterableOptions = options.reduce((acc, val) => acc.concat(val.options), []);
    var currentValue = filterableOptions.find(item => input.value === item.value);

    const additionalFlags = {};
    if (isClearable) {
        additionalFlags.isClearable = true;
    }

    const className = `react-select has-groups keep-original ${isHighlighted ? 'is-highlighted-dropdown' : ''} ${hasError ? " input-validation-error" : ""}`;

    const inputElement = (
        <Select
            {...input}
            value={currentValue}
            tabIndex={tabIndex}
            isDisabled={disabled}
            title={title}
            placeholder={placeholder || " -- Please Select -- "}
            options={options}
            onChange={(selectedOption) => input.onChange(selectedOption?.value)}
            className={className}
            classNamePrefix="react-select"
            { ...additionalFlags }
        />
    );

    return hasErrorMessage
    ? (
        <div className="input-with-error-message">
            {inputElement}    
            {errorMessage}
        </div>
    )
    : inputElement;
}

export const renderMultiSelectList = ({ input, meta, tabIndex, disabled, title, options, label, placeholder }) => {

    const { touched, error, submitError } = meta;
    const values = Array.isArray(input.value) ? input.value : [];

    const errorMessage = (touched && (error || submitError))
        ? (
            <span className="field-validation-error">
                {(error || submitError)}
            </span>
        )
        : null;

    var selectedOptions = options.filter(item => values.includes(parseInt(item.Value)));

    return (
        <>
            <div>
                <label>{label}</label>
                {selectedOptions.length > 0 ? <button className="rbtn link right" onClick={() => input.onChange([])}>Clear all</button> : null}
            </div>
            <Select
                onBlur={() => input.onBlur()}
                tabIndex={tabIndex}
                disabled={disabled}
                title={title}
                className={((error || submitError) ? "keep-original input-validation-error" : "keep-original")}
                classNamePrefix="react-multiselect"
                rel="changeTrackerIgnoreControl"
                value={selectedOptions}
                placeholder={placeholder}
                onChange={((selectedOptions) => input.onChange(selectedOptions.map(o => parseInt(o.Value))))}
                options={options}
                isMulti />
            {errorMessage}
        </>
    );
}

export const renderCheckboxWithLabelLeft = ({ input, id, label, tabIndex, disabled, isMandatory = false }) => 
    renderCheckbox({ input, id, label, tabIndex, disabled, isMandatory, labelOnLeft: true });

export const renderCheckboxWithLabelRight = ({ input, id, label, tabIndex, disabled, isMandatory = false }) => 
    renderCheckbox({ input, id, label, tabIndex, disabled, isMandatory, labelOnLeft: false });

const renderCheckbox = ({ input, id, label, tabIndex, disabled, labelOnLeft, isMandatory }) => {
    
    const textLabel = <label htmlFor={id} className="regular-checkbox-label">{label} {isMandatory ? <span className="mandatory-stars">*</span> : null}</label>;

    return (
        <>
            { labelOnLeft ? textLabel : null }            
            <input
                {...input}
                id={id}
                type="checkbox"
                tabIndex={tabIndex}
                checked={input.value}
                disabled={disabled}
                className="regular-checkbox"
            />
            <label htmlFor={id} className="regular-checkbox-box"></label>
            { labelOnLeft ? null : textLabel }
        </>
    )
};

export const renderBigCheckbox = ({ input, id, label, tabIndex, disabled }) => (
    <>
        <label htmlFor={id} className="big-checkbox-label">{label}</label>
        <input
            {...input}
            id={id}
            type="checkbox"
            tabIndex={tabIndex}
            checked={input.value}
            disabled={disabled}
            className="regular-checkbox big-checkbox"
        />
        <label htmlFor={id}></label>
    </>
);


export const renderRadioOption = ({ input, label, tabIndex, disabled }) => {

    var id = `${input.name}_${input.value}`;
    return (
        <>
            <input
                {...input}
                id={id}
                tabIndex={tabIndex}
                disabled={disabled}
            />
            <label htmlFor={id}>{label}</label>
        </>
    );
}

export const renderBigRadioOption = ({ input, label, tabIndex, disabled, radiovalue }) => {

    var id = `${input.name}_${radiovalue}`;
    return (
        <>
            <label htmlFor={id} className="big-checkbox-label">{label}</label>
            <input
                {...input}
                id={id}
                type="radio"
                value={radiovalue}
                tabIndex={tabIndex}
                checked={radiovalue === input.value.toString()}
                disabled={disabled}
                className="regular-radio big-radio"
            />
            <label htmlFor={id}></label>
        </>
    );
}

export const renderHidden = ({ input }) => (
    <input type="hidden" {...input} />
);

export const renderTextArea = ({ input, meta, label, tabIndex, disabled, style, isMandatory = false, hasErrorMessage = true, isFullWidth = false }) => {

    const { touched, error, submitError } = meta;
    const hasError = (touched && (error || submitError));

    const errorMessage = hasError
        ? (
            <span className="field-validation-error">
                {(error || submitError)}
            </span>
        )
        : null;

    const labelElement = label ? (<label>{label} {isMandatory ? <span className="mandatory-stars">*</span> : null}</label>) : null;

    const inputElement = (
        <textarea
            {...input}
            tabIndex={tabIndex}
            disabled={disabled}
            className={hasError ? " input-validation-error" : ""}
            style={style}
            rows={6}
        />
    );

    const finalInputElement = hasErrorMessage
    ? (
        <div className={`input-with-error-message ${isFullWidth ? "full-width" : null}`}>
            {inputElement}    
            {errorMessage}
        </div>
    )
    : inputElement;

    return (
        <>
            {labelElement}
            {finalInputElement}
        </>
    );
}