import React from "react";
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '../../../state/actions/reports/performance-report-actions';
import { selector } from '../../../state/reducers/reports/performance-report-reducer';

import { BigCheckbox, BigRadioOption } from './form-inputs';
import { Table1, Table2, Table3, Table4 } from "./result-tables";

const Results = () => {

    const dispatch = useDispatch();

    const { 
        interestedNewReferrals, interestedFinanciallySupported, interestedCasesClosedReferrals, interestedCasesClosedFinanciallySupported,
        withinFrequency, endFrequency
    } = useSelector(state => selector.formValues(state));

    const onCheckboxChange = (event) => {
        dispatch(actions.updateFormValue(event.target.id, event.target.checked));
    }

    const onRadioChange = (event) => {
        dispatch(actions.updateFormValue(event.target.name, event.target.value));
    }

    return (
        <div className="stripedbox">

            <div className="reports-performance-monitoring-form">

                <h2 className="full-width">How many cases were opened or closed?</h2>

                <div className="input-wrapper big-checkbox-wrapper">
                    <BigCheckbox
                        id="interestedNewReferrals"
                        label="New referrals (by referral date)"
                        tabIndex={11}
                        checked={interestedNewReferrals}
                        onChange={onCheckboxChange}
                    />
                </div>
                <div className="input-wrapper big-checkbox-wrapper">
                    <BigCheckbox
                        id="interestedCasesClosedReferrals"
                        label="Cases closed - referral cases"
                        tabIndex={11}
                        checked={interestedCasesClosedReferrals}
                        onChange={onCheckboxChange}
                    />
                </div>

                <div className="input-wrapper big-checkbox-wrapper">
                    <BigCheckbox
                        id="interestedFinanciallySupported"
                        label="New financially supported case (by start date)"
                        tabIndex={11}
                        checked={interestedFinanciallySupported}
                        onChange={onCheckboxChange}
                    />
                </div>
                <div className="input-wrapper big-checkbox-wrapper">
                    <BigCheckbox
                        id="interestedCasesClosedFinanciallySupported"
                        label="Cases closed - financially supported"
                        tabIndex={11}
                        checked={interestedCasesClosedFinanciallySupported}
                        onChange={onCheckboxChange}
                    />
                </div>

                <Table1 className="full-width" />


                <h2 className="full-width">How many cases were open within the frequency set?</h2>

                <div className="full-width flex-row">
                    <div className="input-wrapper big-checkbox-wrapper">
                        <BigRadioOption
                            name="withinFrequency"
                            radiovalue="financiallysupported" 
                            label="Total of financially supported"
                            tabIndex={11}
                            disabled={false}
                            value={withinFrequency}
                            onChange={onRadioChange}
                        />
                    </div>
                    <div className="input-wrapper big-checkbox-wrapper">
                        <BigRadioOption
                            name="withinFrequency"
                            radiovalue="referrals" 
                            label="Total referrals open"
                            tabIndex={11}
                            disabled={false}
                            value={withinFrequency}
                            onChange={onRadioChange}
                        />
                    </div>
                    <div className="input-wrapper big-checkbox-wrapper">
                        <BigRadioOption
                            name="withinFrequency"
                            radiovalue="both" 
                            label="Total for both together"
                            tabIndex={11}
                            disabled={false}
                            value={withinFrequency}
                            onChange={onRadioChange}
                        />
                    </div>
                </div>

                <Table2 className="full-width" />

                <h2 className="full-width">How many cases were open at the end of the frequency set?</h2>

                <div className="full-width flex-row">
                    <div className="input-wrapper big-checkbox-wrapper">
                        <BigRadioOption
                            name="endFrequency"
                            radiovalue="financiallysupported" 
                            label="Total of financially supported"
                            tabIndex={11}
                            disabled={false}
                            value={endFrequency}
                            onChange={onRadioChange}
                        />
                    </div>
                    <div className="input-wrapper big-checkbox-wrapper">
                        <BigRadioOption
                            name="endFrequency"
                            radiovalue="referrals" 
                            label="Total referrals open"
                            tabIndex={11}
                            disabled={false}
                            value={endFrequency}
                            onChange={onRadioChange}
                        />
                    </div>
                    <div className="input-wrapper big-checkbox-wrapper">
                        <BigRadioOption
                            name="endFrequency"
                            radiovalue="both" 
                            label="Total for both together"
                            tabIndex={11}
                            disabled={false}
                            value={endFrequency}
                            onChange={onRadioChange}
                        />
                    </div>
                </div>

                <Table3 className="full-width" />

                <span className="full-width">Based on legislation and caseworker, what is the projected spend for the current financial year?</span>

                <Table4 className="full-width" />
            </div>

        </div>
    );
}


export default Results;