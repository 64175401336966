import axios from 'axios';


export const onGetAll = async () => {

    try {
        const response = await axios.post(`/api/case/housing-provider`);
        const results = response.data || {};

        return results;
    }
    catch (err) {
        return {};
    }
}


export const onLoad = async (housingProviderId, setInitialValues, setLoaded, setSuccessful) => {
    try {
        const response = await axios.get(`/api/case/housing-provider/${housingProviderId}`);
        const values = response.data || {};

        setInitialValues(mappers.toReduxForm(values));
        setLoaded(true);
        setSuccessful(true);
    }
    catch (err) {
        setLoaded(true);
        setSuccessful(false);
    }
}


export const onSubmit = async (formValues, onClose) => {

    var serverModel = mappers.toServerModel(formValues);

    var url = formValues.housingProviderId > 0
        ? '/api/case/housing-provider/edit'
        : '/api/case/housing-provider/add';

    const validateResponse = await axios.post(`${url}/validate`, serverModel);

    if (Object.keys(validateResponse.data).length)
        return validateResponse.data;

    try {
        await axios.post(url, serverModel);
        
        onClose();
    }
    catch (err) {
        
    }
}


const mappers = {
    toReduxForm: (values) => ({
        ...values
    }),
    toServerModel: (values) => ({
        ...values
    })
}


export default {
    onHousingProvidersReload: onGetAll,
    onLoad,
    onSubmit
}