import React from 'react';
import { HashRouter as Router, Switch, Route } from "react-router-dom";

import useRedirectIfNotLoggedIn from '../../common/useRedirectIfNotLoggedIn';

import DependantsPage from './dependants-page';
import DependantEdit from './dependant-edit';

export default () => {

    useRedirectIfNotLoggedIn();    

    return (
        <Router>
            <Switch>
                <Route exact path="/Add">
                    <div className="case-dependant-edit-page">
                        <DependantEdit />
                    </div>
                </Route>
                <Route exact path="/Edit/:dependantId">
                    <div className="case-dependant-edit-page">
                        <DependantEdit />
                    </div>
                </Route>
                <Route exact path="/">
                    <div className="react-case-dependants-page">
                        <DependantsPage />
                    </div>
                </Route>
                <Route>
                    <h4>404</h4>
                </Route>
            </Switch>
        </Router>
    );
}
