import React from 'react';
import { Form } from 'react-final-form';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const ClosedMessage = ({date}) => (
	<div className="rounded-box complete">
		<span className="prompt">This query was completed on {moment(date).format('DD/MM/YYYY HH:mm')}</span>
	</div>
)

const CloseButton = ({onSubmit, initialValues}) => (
	<Form
		onSubmit={onSubmit}
		initialValues={initialValues}
		render={({ handleSubmit, submitting }) => (
			<form onSubmit={handleSubmit} className="rounded-box complete">
				<span className="prompt">Has this query been completed?</span>
				<button type="submit" className="rbtn primary" disabled={submitting}>
					<FontAwesomeIcon icon={faCheck} />
					<span>Complete</span>
				</button>
			</form>
		)}
	/>
)
	

export default ({onSubmit, initialValues}) => {
	const { isClosed, dateClosed, userCanCompleteQuery } = initialValues;
	return isClosed
		? <ClosedMessage date={dateClosed} />
		: userCanCompleteQuery
			? <CloseButton onSubmit={onSubmit} initialValues={initialValues} />
			: null;
}