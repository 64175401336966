import React, { useState, useEffect, useRef } from "react";
import { Form, Field } from 'react-final-form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlagCheckered } from '@fortawesome/free-solid-svg-icons';


const UpdateForm = ({onSubmit, initialValues}) => {
    
    const formSubmitButton = useRef();

    const [selectedFile, setSelectedFile] = useState(null);

    useEffect(() => {
        var { unsubscribe } = window.dispatcher.subscribe("change-tracker-modal-submit-form", () => formSubmitButton.current.click());

        return () => {
            unsubscribe();
        }
    }, []);

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            render={({ handleSubmit, submitting, values }) => {

                const whenSubmittingTheForm = async (event) => {
                    values.file = selectedFile;
                    await handleSubmit(event);
                }

                return (
                    <form onSubmit={whenSubmittingTheForm} rel="change-tracked-modal">
                        <div className="update-photo-app">
                            <div className="modal-edit-form">

                                <Field name="file" type="file">
                                    {({meta}) => {
                                        
                                        const { touched, error, submitError } = meta;
                                        const hasError = (touched && (error || submitError));
                                        const errorMessage = hasError
                                            ? (
                                                <span className="field-validation-error">
                                                    {(error || submitError)}
                                                </span>
                                            )
                                            : null;
                                        
                                        return (
                                            <div className="input-with-error-message">
                                                <input type="file" accept="image/*" className="form-control-file" onChange={(e) => setSelectedFile(e.target.files[0])} />
                                                {errorMessage}
                                            </div>
                                        )
                                    }}
                                </Field>

                                <hr />

                                <div className="form-buttons">
                                    <button type="submit" className="rbtn primary" disabled={submitting} ref={formSubmitButton}>
                                        <FontAwesomeIcon icon={faFlagCheckered} />
                                        <span>Save</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                )
            }}
        />
    )
}


export default UpdateForm;