import React from 'react';
import {withClassNameSwitch} from '../../../common'

const BaseRow = ({children, className='', row}) => {

  return (
    <div {...row.getRowProps()} className={`tr ${className} ${row.original.isHighlighted ? 'highlightChange' : ''}`} style={{flexDirection: "column"}}>
      <div className="result-row">
        {
          row.cells.map((cell) => (
            <span {...cell.getCellProps()} title={cell.value ? cell.value.toString(): ''}>
              {cell.render('Cell')}
            </span>
          ))
        }
      </div>
      { children }
    </div>
  );
}

const RowWithCaseBeingViewAndOddEven = withClassNameSwitch(props => props.rowIndex % 2 === 0 ? 'odd' : 'even')(BaseRow);
const Row = RowWithCaseBeingViewAndOddEven;

export default Row;