import { useState, useCallback, useMemo } from 'react';

export default (initialId, editModalFunc, deleteModalFunc) => {

    const [items, setItems] = useState([]);
    const [id, setId] = useState(initialId);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const onEditModalRequest = useCallback((id) => {
        setId(id);
        setShowEditModal(true);
    }, [setId, setShowEditModal]);

    const onEditModalClose = useCallback((items) => {
        setShowEditModal(false);
        console.log('items:', items);
        if (items) {
            setItems(items);
        }
    }, [setItems, setShowEditModal]);

    const onDeleteModalRequest = useCallback((id) => {
        setId(id);
        setShowDeleteModal(true);
    }, [setId, setShowDeleteModal]);

    const onDeleteModalClose = useCallback((items) => {
        setShowDeleteModal(false);
        if (items) {
            setShowEditModal(false);
            setItems(items);
        }
    }, [setItems, setShowDeleteModal]);

    const editModal = useMemo(() => 
        showEditModal ? editModalFunc(id, onEditModalClose, onDeleteModalRequest) : null
    , [id, showEditModal, editModalFunc, onEditModalClose, onDeleteModalRequest]);

    const deleteModal = useMemo(() => 
        showDeleteModal ? deleteModalFunc(id, onDeleteModalClose) : null
    , [id, showDeleteModal, deleteModalFunc, onDeleteModalClose]);


    return {
        items,
        setItems,
        onEditModalRequest,
        onDeleteModalRequest,
        editModal,
        deleteModal
    }
}