import axios from 'axios';

import { onFormSubmitAsync } from './form-submit';

const baseUrl = '/api/queries';
export const urls = {
    getByFilter: `${baseUrl}/by-filter`,
    addReplySubmit: `${baseUrl}/reply`,
    completeSubmit: `${baseUrl}/complete`
}

export const onGetAllByFilterAsync = async (filters) => {
    try {
        var data = mappers.toFilterServerModel(filters);
        const response = await axios.post(urls.getByFilter, data);
        const results = response.data || [];

        return results;
    }
    catch (err) {
        return {};
    }
}

export const onGetAllForCaseAsync = async (caseId) => {
    try {
        const response = await axios.post(`${baseUrl}/${caseId}`);
        const results = response.data || [];

        return results;
    }
    catch (err) {
        return {};
    }
}


export const onLoadAsync = async (caseId, caseQueryId) => {
    try {
        const response = await axios.get(`${baseUrl}/${caseId}/${caseQueryId}`);
        const values = response.data || {};

        return {
            isLoaded: true,
            isSuccessful: true,
            initialValues: mappers.toReduxForm(values)
        };
    }
    catch (err) {
        return {
            isLoaded: true,
            isSuccessful: false
        }
    }
}


export const onAddSubmitAsync = async (formValues) => {
    const serverModel = await mappers.toServerAddModel(formValues);
    return await onFormSubmitAsync(baseUrl, serverModel);
}

export const onAddReplySubmitAsync = async (formValues) => {
    const serverModel = await mappers.toServerAddReplyModel(formValues);
    return await onFormSubmitAsync(urls.addReplySubmit, serverModel);
}

export const onCompleteSubmitAsync = async (formValues) => {
    const serverModel = mappers.toServerCompleteModel(formValues);
    return await onFormSubmitAsync(urls.completeSubmit, serverModel);
}


const mappers = {
    toReduxForm: (values) => ({
        ...values
    }),
    toFilterServerModel: (values) => ({
        legislationIds: values.legislationIds,
        caseWorkerIds: values.caseWorkerIds,
        localAuthorityIds: values.localAuthorityIds,
        hoCaseWorkerIds: values.hoCaseWorkerIds,
        tab: values.queriesTab,
        show: values.queriesSubOption
    }),
    toServerAddModel: (values) => ({
        ...values,
    }),
    toServerAddReplyModel: (values) => ({
        ...values,
    }),
    toServerCompleteModel: (values) => ({
        ...values,
    })
}


export default {
    onGetAllByFilterAsync,
    onGetAllForCaseAsync,
    onLoadAsync,
    onAddSubmitAsync,
    onAddReplySubmitAsync,
    onCompleteSubmitAsync
}