import React from "react";

import { caseArea, userIsCaseOwner, isClosed } from "../server-values";

import DependantsList from './list';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserFriends } from '@fortawesome/free-solid-svg-icons';


export default ({dependants, caseId}) => {
    
    const dependantCount = dependants.records?.length || 0;

    const addButton = userIsCaseOwner && !isClosed
        ? <a href={`/${caseArea}/Dependant?CaseId=${caseId}#/Add`} title="add new dependant" className="rbtn primary small">Add Dependant</a>
        : null;

    return (
        <div>
            <div className="summary-title">
                <span className="title">
                    <FontAwesomeIcon icon={faUserFriends} />
                    <span>{dependants.principalApplicantName} has {dependantCount} Dependant(s)</span>
                </span>
                { addButton }
            </div>

            <div className="stripedbox">
                <DependantsList dependants={dependants} />
            </div>
        </div>
    )
}
