import { useDispatch } from 'react-redux';
import { setPage, setSort, setFilters } from '../../../state/actions/search-actions';
import { useState, useEffect } from 'react';
import arraysAreEqual from '../../../extensions/array.equals';

function useMiddleware(state) {

  const [myPageIndex, setPageIndex] = useState(null);
  const [myPageSize, setPageSize] = useState(null);

  const [mySortBy, setSortBy] = useState(null);

  const [myColumnFilters, setColumnFilters] = useState([]);
  
  const dispatch = useDispatch();
  useEffect(() => {
      if(myPageSize !== state.pageSize || myPageIndex !== state.pageIndex){
          dispatch(setPage(state.pageIndex, state.pageSize));
          setPageIndex(state.pageIndex);
          setPageSize(state.pageSize);
      }

      if (mySortBy !== state.sortBy) {
        dispatch(setSort(state.sortBy));
        setSortBy(state.sortBy);
      }

      if (arraysAreEqual(myColumnFilters, state.filters) === false) {
        dispatch(setFilters(state.filters));
        setColumnFilters(state.filters);
      }
  }, [state.pageIndex, state.pageSize, state.sortBy, state.filters, myPageIndex, myPageSize, mySortBy, myColumnFilters, dispatch]);

  return state;
}

export default useMiddleware;