import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';

import { routes } from '../../routes';
import * as actions from '../../../state/actions/search-actions';
import { selector } from "../../../state/reducers/search-reducer";
import { selector as filtersSelector } from "../../../state/reducers/filters-reducer";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo, faAddressCard } from '@fortawesome/free-solid-svg-icons';

import SearchByListForm from './search-by-list-form';
import SearchByPersonalDetailsForm from './search-by-personal-details-form';
import SearchByAlert from './search-by-alert';
import SearchByRtpfForm from './search-by-rtpf-form';

const Forms = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const { isHomeOfficeUser } = useSelector(state => filtersSelector.userDetails(state));
    const isAutomatedSearch = useSelector(state => selector.isAutomatedSearch(state));

    const onReset = () => {
        return dispatch(actions.resetSearch());
    }

    const onCreate = useCallback(() => {
        history.push(routes.cases.create)
    }, [history]);

    const createButton = isHomeOfficeUser || isAutomatedSearch
        ? null
        : (
            <button className="rbtn large primary" onClick={onCreate} tabIndex={6}>
                <FontAwesomeIcon icon={faAddressCard} style={{ maxWidth: "20%" }} /><div>Create</div>
            </button>
        )

    const resetButton = isAutomatedSearch
        ? null
        : (
            <button className="rbtn large secondary" onClick={onReset} tabIndex={6}>
                <FontAwesomeIcon icon={faUndo} style={{ maxWidth: "20%" }} /><div>Reset</div>
            </button>
        )

    const searchForms = isAutomatedSearch
        ? null
        : (
            <>
                <div className="stripedbox search-header">
                    <h2>How do you want to find records?</h2>
                    <div className="search-buttons">
                        {resetButton}
                        {createButton}
                    </div>
                </div>
                <div className="stripedbox search-forms">
                    <div className="search-by-list">
                        <p className="form-header">Option 1: List cases</p>
                        <p className="form-description">List cases that have been entered onto NRPF Connect</p>
                        <SearchByListForm />
                    </div>
                    <div className="search-by-name">
                        <p className="form-header">Option 2: Search by personal details</p>
                        <p className="form-description">Find a case or begin case-create sequence if it does not exist</p>
                        <SearchByPersonalDetailsForm />
                    </div>
                    <div className="search-by-rtpf">
                        <p className="form-header">Option 3: RTPF Look-up</p>
                        <p className="form-description">Complete look-up using the Home Office Recourse to Public Funds checker</p>
                        <SearchByRtpfForm />
                    </div>
                </div>
            </>
        );

    return (
        <div className="search-page-forms">
            {searchForms}
            <SearchByAlert />
        </div>
    )
};

export default Forms;
