import * as actions from '../../actions/reports/performance-report-actions';

const initialState = {
  options: {
    legislations: [],
    caseWorkers: [],
    frequencies: []
  },
  formValues: {
    legislationIds : [],
    caseWorkerIds : [],
    startDate: '',
    endDate: '',
    frequency: 2,
    interestedNewReferrals : true,
    interestedFinanciallySupported: true,
    interestedCasesClosedReferrals: true,
    interestedCasesClosedFinanciallySupported: true,
    withinFrequency: 'both',
    endFrequency: 'both'
  },

  initialised: false,
  initialisedSuccessful: false,
  submitting: false,
  hasResults: false,

  table1Data: [],
  table2Data: [],
  table3Data: [],
  table4Data: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.LOAD_START:
      return {
        ...state,
        initialised: false,
        initialisedSuccessful: false
      };
    case actions.LOAD_SUCCESS:
      return {
        ...state,
        initialised: true,
        initialisedSuccessful: true,
        options: {
          ...state.options,
          ...action.data.options
        },
        formValues: {
          ...state.formValues,
          ...action.data.formValues
        }
      };
    case actions.LOAD_FAIL:
      return {
        ...state,
        initialised: true,
        initialisedSuccessful: false
      };
    case actions.SUBMIT_START:
      return {
        ...state,
        submitting: true,
        hasResults: false
      };
    case actions.SUBMIT_SUCCESS:
      return {
        ...state,
        submitting: false,
        hasResults: true,
        table1Data: action.data.table1Results,
        table2Data: action.data.table2Results,
        table3Data: action.data.table3Results,
        table4Data: action.data.table4Results,
      };
    case actions.SUBMIT_FAIL:
      return {
        ...state,
        submitting: false,
        hasResults: true,
        table1Data: [],
        table2Data: [],
        table3Data: [],
        table4Data: []
      }
    case actions.UPDATE_FORM_VALUES:
      return {
        ...state,
        formValues: {
          ...state.formValues,
          ...action.data
        }
      }
    case actions.RESET_RESULTS:
      return {
        ...state,
        hasResults: false
      }
    default:
      return state;
  }
}

export const selector = {
  options: (state) => state.reports.performance.options,
  formValues: (state) => state.reports.performance.formValues,

  initialised: (state) => state.reports.performance.initialised,
  initialisedSuccessful: (state) => state.reports.performance.initialisedSuccessful,
  submitting: (state) => state.reports.performance.submitting,
  hasResults: (state) => state.reports.performance.hasResults,

  table1Data: (state) => state.reports.performance.table1Data,
  table2Data: (state) => state.reports.performance.table2Data,
  table3Data: (state) => state.reports.performance.table3Data,
  table4Data: (state) => state.reports.performance.table4Data,
};
