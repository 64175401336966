import React from 'react';
import { Route } from 'react-router-dom';
import serverValues from './server-values';
import useRedirectIfNotLoggedIn from '../common/useRedirectIfNotLoggedIn';

import NavigationTabs from './navigation-tabs';

import Root from './root';
import UserList from './users';
import UserEdit from './users/edit';
import UserAdd from './users/add';
import UserResetPassword from './users/reset-password';
import FileUpload from './file-upload';

export default () => {

    const { userId, userIsAdmin, organisation } = serverValues;

    useRedirectIfNotLoggedIn();    

    return (
        <div className="react-admin-page">
            <NavigationTabs myUserId={userId} userIsAdmin={userIsAdmin} />
            <Route exact path="/admin/users/add">
                <div className="admin-users-edit-page">
                    <UserAdd />
                </div>
            </Route>
            <Route exact path="/admin/users/edit/:userId">
                <div className="admin-users-edit-page">
                    <UserEdit />
                </div>
            </Route>
            <Route exact path="/admin/users/reset-password/:userId">
                <div className="admin-users-edit-page">
                    <UserResetPassword />
                </div>
            </Route>
            <Route exact path="/admin/users">
                <div className="admin-users-page">
                    <UserList />
                </div>
            </Route>
            <Route exact path="/admin/file-upload">
                <div className="admin-file-upload-page">
                    <FileUpload organisation={organisation} />
                </div>
            </Route>
            <Route exact path="/admin">
                <Root userId={userId} userIsAdmin={userIsAdmin} />
            </Route>
        </div>
    );
}
