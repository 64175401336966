import React from 'react';

const notification = (message, className) => {

    if (!message)
        return null;

    return (
        <div className={`alert-message ${className}`}>
            { message }
        </div>
    );
}

export const successNotification = (message) => notification(message, "success");
export const errorNotification = (message) => notification(message, "error");
