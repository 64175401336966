import axios from 'axios';

export const onLoad = async (caseId, source) => {
    try {
        const response = await axios.get(`/api/case/summary/${caseId}`, { cancelToken: source.cancelToken });
        const results = response.data || {};

        return results;
    }
    catch (err) {
        return {}
    }
}


export const onAllocate = async (caseId) => {
    const response = await axios.post('/api/case/claim', { caseId });
    const result = response.data || {};

    return result;
}

export const onDeallocate = async (caseId) => {
    const response = await axios.post('/api/case/unclaim', { caseId });
    const result = response.data || {};

    return result;
}


export const onCloseAlert = async (caseId, alertTypeId) => {
    const response = await axios.post('/api/case/close-alert', { caseId, alertTypeId });
    const result = response.data || {};

    return result;
}


export default {
    onLoad,
    onAllocate,
    onDeallocate,
    onCloseAlert
}