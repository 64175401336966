import React, { useEffect, useState, useMemo, useCallback } from 'react';
import axios from 'axios';

import { caseId, isClosed, userIsCaseOwner, userIsHomeOffice, principalApplicantName, caseArea } from '../server-values';
import { onLoadAsync, onUpdateSubmitAsync } from '../../../api-endpoint-calls/case/applicants';

import scrollToTopOfPage from '../../../extensions/page.scrollToTop';

import ApplicantForm from './applicant-form';
import EditAliasModal from './aliases/edit';
import DeleteAliasModal from './aliases/delete';
import EditDocumentModal from './documents/edit';
import DeleteDocumentModal from './documents/delete';

import emptyGuid from '../../../extensions/guid.empty';
import useListState from '../use-list-state';
import useNoteState from '../notes/use-note-state';
import getHighlights from './highlights';

import useQueriesState from '../../queries/use-query-state';
import AddQueryModal from '../../queries/add';
import ViewQueryModal from '../../queries/view';

import { LoadingV1 } from '../../common/loading';


const ApplicantEdit = () => {

    const [loaded, setLoaded] = useState(false);
    const [successful, setSuccessful] = useState(false);

    const [successMessage, setSuccessMessage] = useState("");

    const [initialValues, setInitialValues] = useState({});
    const { applicantId } = initialValues;

    const disabled = isClosed || (!userIsCaseOwner && !userIsHomeOffice);
    const highlights = useMemo(() => getHighlights(initialValues.highlightProperties), [initialValues]);

    const {
        items: aliases,
        setItems: setAliases,
        onEditModalRequest: onEditAliasModalRequest,
        onDeleteModalRequest: onDeleteAliasModalRequest,
        editModal: editAliasModal,
        deleteModal: deleteAliasModal
    } = useListState(
        0,
        (id, onClose, onDelete, name, dateOfBirth) => <EditAliasModal onClose={onClose} applicantId={applicantId} aliasId={id} disabled={disabled} applicantName={name} applicantDateOfBirth={dateOfBirth} />,
        (id, onClose) => <DeleteAliasModal onClose={onClose} applicantId={applicantId} aliasId={id} disabled={disabled} />
    )

    const {
        items: documents,
        setItems: setDocuments,
        onEditModalRequest: onEditDocumentModalRequest,
        onDeleteModalRequest: onDeleteDocumentModalRequest,
        editModal: editDocumentModal,
        deleteModal: deleteDocumentModal
    } = useListState(
        emptyGuid,
        (id, onClose) => <EditDocumentModal onClose={onClose} applicantId={applicantId} documentId={id} disabled={disabled} />,
        (id, onClose) => <DeleteDocumentModal onClose={onClose} applicantId={applicantId} documentId={id} disabled={disabled} />
    )

    const {
        item: localAuthorityNote,
        setItem: setLocalAuthorityNote,
        onAddModalRequest: onAddLocalAuthorityNoteModalRequest,
        onHistoryModalRequest: onLocalAuthorityNoteHistoryModalRequest,
        addModal: addLocalAuthorityNoteModal,
        historyModal: localAuthorityNoteHistoryModal
    } = useNoteState()

    const {
        item: homeOfficeNote,
        setItem: setHomeOfficeNote,
        onAddModalRequest: onAddHomeOfficeNoteModalRequest,
        onHistoryModalRequest: onHomeOfficeNoteHistoryModalRequest,
        addModal: addHomeOfficeNoteModal,
        historyModal: homeOfficeNoteHistoryModal
    } = useNoteState()

    const {
        queries,
        setQueries,
        onAddQueryModalRequest,
        onViewQueryModalRequest,
        addQueryModal,
        viewQueryModal,
    } = useQueriesState(
        0,
        (onClose) => <AddQueryModal onClose={onClose} caseId={caseId} userIsHomeOffice={userIsHomeOffice} principalApplicantName={principalApplicantName}  disabled={isClosed} />,
        (id, onClose) => <ViewQueryModal onClose={onClose} caseId={caseId} caseQueryId={id} disabled={isClosed} />
    )

    const onPageLoadAsync = useCallback(async (source) => {
        const result = await onLoadAsync(caseId, source);
        
        setLoaded(result.isLoaded);
        setSuccessful(result.isSuccessful);

        if (result.initialValues) {
            setInitialValues({ ...result.initialValues });
            setAliases(result.initialValues.aliases);
            setDocuments(result.initialValues.documents);
            setLocalAuthorityNote(result.initialValues.localAuthorityNote);
            setHomeOfficeNote(result.initialValues.homeOfficeNote);
            setQueries(result.initialValues.queries);
        }
    }, [setInitialValues, setAliases, setDocuments, setLocalAuthorityNote, setHomeOfficeNote, setQueries, setLoaded, setSuccessful]);

    const onPageReload = async () => {
        const source = axios.CancelToken.source();
        await onPageLoadAsync(source);
    }

    useEffect(() => {
        const source = axios.CancelToken.source();
        onPageLoadAsync(source);

        return () => {
            source.cancel();
        }
        
    }, [onPageLoadAsync]);


    const showSpinner = (!loaded);
    const showFailure = (loaded && !successful);

    if (showSpinner) {
        return <LoadingV1 />;
    } else if (showFailure) {
        return <h2>An error has occurred!</h2>;
    }


    const onFormSubmit = async (formValues) => {

        const result = await onUpdateSubmitAsync(formValues);

        if (result.isSuccess) {
            window.dispatcher.dispatchEvent('change-tracker-reset');

            if (userIsHomeOffice) {
                window.location = `/${caseArea}/Summary/Display?iCaseID=${caseId}`;
            } else {
                setSuccessMessage("Principal Applicant has been updated successfully.");
                scrollToTopOfPage();
                onPageReload();
            }
        }

        return result.errors;
    }


    return (
        <>
            <ApplicantForm
                onSubmit={onFormSubmit}
                initialValues={initialValues}
                isClosed={isClosed}
                aliases={aliases}
                documents={documents}
                localAuthorityNote={localAuthorityNote}
                homeOfficeNote={homeOfficeNote}
                queries={queries}
                successMessage={successMessage}
                highlights={highlights}
                onEditAliasModalRequest={onEditAliasModalRequest}
                onDeleteAliasModalRequest={onDeleteAliasModalRequest}
                onEditDocumentModalRequest={onEditDocumentModalRequest}
                onDeleteDocumentModalRequest={onDeleteDocumentModalRequest}
                onAddLocalAuthorityNoteModalRequest={onAddLocalAuthorityNoteModalRequest}
                onLocalAuthorityNoteHistoryModalRequest={onLocalAuthorityNoteHistoryModalRequest}
                onAddHomeOfficeNoteModalRequest={onAddHomeOfficeNoteModalRequest}
                onHomeOfficeNoteHistoryModalRequest={onHomeOfficeNoteHistoryModalRequest}
                onAddQueryModalRequest={onAddQueryModalRequest}
                onViewQueryModalRequest={onViewQueryModalRequest}
            />
            { editAliasModal }
            { deleteAliasModal }
            { editDocumentModal }
            { deleteDocumentModal }
            { addLocalAuthorityNoteModal }
            { localAuthorityNoteHistoryModal }
            { addHomeOfficeNoteModal }
            { homeOfficeNoteHistoryModal }
            { addQueryModal }
            { viewQueryModal }
        </>
    );
}

export default ApplicantEdit;
