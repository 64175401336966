import React, { useMemo } from "react";
import { useSelector } from 'react-redux';

import { selector } from '../../../state/reducers/reports/performance-report-reducer';

import TableResults from '../../react-table/table-results';
import Row from './table-row';

import { getTable1Columns, getTable2Columns, getTable3Columns, table4Columns } from "./table-columns";
import { SimpleLoadingGif } from '../../common/loading';


const getTable = (submitting, columns, data, className="") => (
    submitting 
        ? <SimpleLoadingGif /> 
        : (
            <div className={`react-table ${className}`}>
                <TableResults columns={columns} data={data} sortBy={[{id: "id"}]} pageNumber={0} itemsPerPage={99999} Row={Row} />
            </div>
        )
)

export const Table1 = ({className}) => {

    const { 
        interestedNewReferrals, interestedFinanciallySupported, interestedCasesClosedReferrals, interestedCasesClosedFinanciallySupported,
    } = useSelector(state => selector.formValues(state));

    const submitting = useSelector((state) => selector.submitting(state));

    const columns = useMemo(() => 
        getTable1Columns(interestedNewReferrals, interestedFinanciallySupported, interestedCasesClosedReferrals, interestedCasesClosedFinanciallySupported),
        [interestedNewReferrals, interestedFinanciallySupported, interestedCasesClosedReferrals, interestedCasesClosedFinanciallySupported]);

    const data = useSelector((state) => selector.table1Data(state));

    return getTable(submitting, columns, data, className);
}

export const Table2 = ({className}) => {

    const { withinFrequency } = useSelector(state => selector.formValues(state));
    const submitting = useSelector((state) => selector.submitting(state));

    const columns = getTable2Columns(withinFrequency);
    const data = useSelector((state) => selector.table2Data(state));

    return getTable(submitting, columns, data, className);
}

export const Table3 = ({className}) => {

    const { endFrequency } = useSelector(state => selector.formValues(state));
    const submitting = useSelector((state) => selector.submitting(state));

    const columns = getTable3Columns(endFrequency);
    const data = useSelector((state) => selector.table3Data(state));

    return getTable(submitting, columns, data, className);
}


export const Table4 = ({className}) => {
    
    const submitting = useSelector((state) => selector.submitting(state));

    const columns = table4Columns;
    const data = useSelector((state) => selector.table4Data(state));

    return getTable(submitting, columns, data, className);
}
