import React from "react";
import Select from 'react-select';

import DatePicker from 'react-datepicker';
import Combobox from 'react-widgets/Combobox';

import customDatePickerHeader from './react-datepicker-custom-header';

export const MyDatepicker = ({ label, value, tabIndex, error, onChange = () => { }, ...rest }) => {
    return (
        <>
            <label>{label}</label>
            <DatePicker
                className={`search-field` + (error ? " invalid-search-input" : "")}
                autoComplete="off"
                dateFormat="dd/MM/yyyy"
                placeholderText="Choose date..."
                tabIndex={tabIndex}
                selected={value}
                onChange={onChange}
                renderCustomHeader={customDatePickerHeader}
                {...rest}
            />
        </>
    );
}

export const MyCombobox = ({ value, label, error, ...rest }) => {

    const errorMessage = (error)
        ? (
            <span className="field-validation-error">
                {error}
            </span>
        )
        : null;

    return (
        <>
            <label>{label}</label>
            <Combobox
                hideCaret={true}
                hideEmptyPopup={true}
                className={(error ? "keep-original input-validation-error" : "keep-original")}
                rel="changeTrackerIgnoreControl"
                value={value}
                dataKey='Value'
                textField='Label'
                filter={false}
                {...rest}
            />
            {errorMessage}
        </>
    );
}

export const SelectList = ({title, options, value, onChange, placeholder, disabled}) => {
    
    const hasGroups = options.length > 1;
    const filterableOptions = options.reduce((acc, val) => acc.concat(val.options), []);
    const selectedOption = filterableOptions.find(item => value === item.value);

    return (
        <div className="filter-option">
            <span className="filter-title">{title}</span>
            <Select 
                className={`keep-original ${hasGroups ? "has-groups" : ""}`}
                classNamePrefix="react-select"
                rel="changeTrackerIgnoreControl"
                value={selectedOption}
                placeholder={placeholder}
                onChange={((selectedOption) => onChange(selectedOption.value))}
                options={options}
                isDisabled={disabled} />
        </div>
    );
}

export const MultiSelectList = ({title, options, values, onChange, allText, disabled, isMandatory = false}) => {
    
    const hasGroups = options.length > 1;
    const filterableOptions = options.reduce((acc, val) => acc.concat(val.options), []);
    const selectedOptions = filterableOptions.filter(item => values.includes(parseInt(item.value)));
    const showClearAll = (selectedOptions.length > 0) && !disabled;

    const mandatoryStar = isMandatory ? <span className="mandatory-stars">*</span> : null;

    return (
        <div className="filter-option">
            <span className="filter-title">{title} {mandatoryStar}</span>
            { showClearAll > 0 ? <button className="rbtn link right" onClick={() => onChange([])}>Clear all</button> : null }
            <Select 
                className={`keep-original ${hasGroups ? "has-groups" : ""}`}
                classNamePrefix="react-multiselect"
                rel="changeTrackerIgnoreControl"
                value={selectedOptions}
                placeholder={allText}
                onChange={((selectedOptions) => onChange(selectedOptions.map(o => parseInt(o.value))))}
                options={options}
                isMulti
                isClearable={false}
                closeMenuOnSelect={false}
                isDisabled={disabled} />
        </div>
    );
}