import React from 'react';
import { useSelector } from 'react-redux';
import { selector } from '../../../state/reducers/queries-reducer';

const ExpansionToggleButton = ({caseId, queryId}) => {

    const isExpanded = useSelector(state => selector.isExpanded(state, queryId));
    return <span className={`expand-toggle-icon ${isExpanded ? 'collapse' : 'expand'}`}></span>;
}

export default ExpansionToggleButton;
