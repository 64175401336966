import axios from 'axios';
import { selector } from '../reducers/filters-reducer';

export const SEARCH_START = 'HOME_SEARCH_START';
export const SEARCH_SUCCESS = 'HOME_SEARCH_SUCCESS';
export const SEARCH_FAILED = 'HOME_SEARCH_FAILED';


const action = (type, data = null) => ({
    type, data
});

class HomeFiltersViewModel {
    constructor(model) {
        this.LegislationIds = model.legislationIds;
        this.LaCaseWorkerIds = model.caseWorkerIds;
        this.LocalAuthorityIds = model.localAuthorityIds;
        this.HoCaseWorkerIds = model.hoCaseWorkerIds;
    }
}

export function getCounts() {
    return (dispatch, getState) => {
        dispatch(action(SEARCH_START));

        var filters = selector.values(getState());
        const data = new HomeFiltersViewModel(filters);

        return axios
            .post('/api/home/counts', data)
            .then(response => {
                dispatch(action(SEARCH_SUCCESS, response.data || {}));
            }).catch(err  => {
                dispatch(action(SEARCH_FAILED));
            });
    }
}


