import React from "react";

import { onDownloadAsync } from "../../../../../api-endpoint-calls/case/documents";
import downloadFile from "../download-file";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

export default ({ applicantId, documentId, fileName, fileSize, uploaded }) => {
    
    const onDownloadClick = async (documentId) => {
        const download = await onDownloadAsync(applicantId, documentId);
        
        if (download.isSuccessful) {
            const { fileName, contents } = download.values;
            downloadFile(fileName, contents);
        }
    };

    return (
        <>
            <hr />

            <div className="header-with-button">
                <h2>Document</h2>
                <button type="button" className="rbtn small primary" onClick={() => onDownloadClick(documentId)}>
                    <FontAwesomeIcon icon={faDownload} />
                    <span>Download</span>
                </button>
            </div>

            <span className="document-information">
                <label>Filename:</label>
                <span><b>{fileName}</b></span>
                <label>Size:</label>
                <span><b>{fileSize}</b></span>
                <label>Uploaded:</label>
                <span><b>{uploaded}</b></span>
                <label></label>
                
            </span>
        </>
    )
};
