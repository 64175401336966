import React, { useEffect, useState } from 'react';
//import { useHistory } from 'react-router-dom';

import { caseId, addressId, caseArea } from '../../server-values';
import { onLoad, onUpdateSubmit } from '../../../../api-endpoint-calls/case/address';

import Form from './form';

import { LoadingV1 } from '../../../common/loading';

const CaseAddressEdit = () => {

    //const history = useHistory();

    const [loaded, setLoaded] = useState(false);
    const [successful, setSuccessful] = useState(false);
    const [initialValues, setInitialValues] = useState({});

    useEffect(() => {
        const onPageLoad = async () => {
            const result = await onLoad(caseId, addressId);
            
            setLoaded(result.isLoaded);
            setSuccessful(result.isSuccessful);

            if (result.initialValues) {
                setInitialValues(result.initialValues);
            }
        }

        onPageLoad();

    }, [setInitialValues, setLoaded, setSuccessful]);


    const showSpinner = (!loaded);
    const showFailure = (loaded && !successful);

    if (showSpinner) {
        return <LoadingV1 />;
    } else if (showFailure) {
        return <h2>An error has occured!</h2>;
    }


    const onFormSubmit = async (formValues) => {

        const result = await onUpdateSubmit(formValues);

        if (result.isSuccess) {
            window.dispatcher.dispatchEvent('change-tracker-reset');

            //history.push(`/${caseArea}/Address/History?CaseId=${caseId}`);
            window.location = `/${caseArea}/Address/History?CaseId=${caseId}`;
        }

        return result.errors;
    }

    return (
        <div className="case-address-edit-page">
            <Form
                onSubmit={onFormSubmit}
                initialValues={initialValues}
            />
        </div>
    );
}

export default CaseAddressEdit;
