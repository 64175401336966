import React, { useEffect, useReducer } from 'react';
import axios from 'axios';

import * as actions from '../../../state/actions/case/summary-actions';
import summaryReducer, { initialState } from '../../../state/reducers/case/summary-reducer';

import { caseId, userIsCaseOwner, userIsLocalAuthority } from '../server-values';

import useRedirectIfNotLoggedIn from '../../common/useRedirectIfNotLoggedIn';

import SummaryHeader from './header';
import DependantsList from '../applicants/dependants-summary-widget';
import CaseDates from './dates';
import SummaryActions from './actions';

import { LoadingV1 } from '../../common';
import useRtpfModal from './use-rtpf-modal';


export default () => {

    useRedirectIfNotLoggedIn();

    const [summary, dispatch] = useReducer(summaryReducer, initialState);

    useEffect(() => {
        const source = axios.CancelToken.source();

        const onPageLoadAsync = async () => {
            await actions.loadSummary(dispatch, caseId, source);
        }

        onPageLoadAsync();

        return () => {
            source.cancel();
        }
        
    }, [dispatch]);

    const showActions = userIsCaseOwner || !userIsLocalAuthority;
    const summaryActions = showActions
        ? <SummaryActions summary={summary} dispatch={dispatch} /> 
        : null;


    const { isLoaded, dependants, showRtpfModal, rtpfLookupDetails } = summary;
    const rtpfModal = useRtpfModal(showRtpfModal, rtpfLookupDetails, caseId, dispatch)

    if (!isLoaded)
        return <LoadingV1 />;

    return (
        <div className="react-case-summary-page">
            <SummaryHeader summary={summary} dispatch={dispatch} />

            <div className="summary-footer">
                <div className="footer-column left">
                    <DependantsList dependants={dependants} caseId={caseId} />
                    <CaseDates summary={summary} />
                </div>
                <div className="footer-column right">
                    { summaryActions }
                </div>
            </div>

            { rtpfModal }
        </div>
    );
}
