import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { renderHiddenInput, renderSelectList } from '../inputs';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faUserSlash } from '@fortawesome/free-solid-svg-icons';

const replacementUserIdRequired = value => value ? undefined : 'Please choose a user to allocate the deleted users cases to.';

const Form = ({handleSubmit, onClose, replacementUsers}) => {
    
    const replaceUserField = replacementUsers && replacementUsers.length > 0
    ? (
        <div>
            <span className="w-100 p-15 option-label">User's cases will be assigned to <span className="mandatory-stars">*</span></span>
            <div className="w-100 p-15">
                <Field name="replacementUserId" component={renderSelectList} tabIndex={1} validate={[replacementUserIdRequired]}>
                    <option value="">-- Please Select --</option>
                    {replacementUsers.map((item) => <option key={item.publicId} value={item.publicId}>{item.fullName}</option>)}
                </Field>
            </div>
        </div>
    )
    : null;

    return (
        <div className="stripedbox">
            <form autoComplete="off" onSubmit={handleSubmit}>

                { replaceUserField }

                <p>Are you sure you want to delete this user?</p>

                <div className="button-row p-15">
                    <Field name="userId" component={renderHiddenInput} />
                    
                    <button onClick={onClose} className="rbtn secondary"><FontAwesomeIcon icon={faTimesCircle} /><span>Cancel</span></button>
                    <button type="submit" className="rbtn primary"><FontAwesomeIcon icon={faUserSlash} /><span>Delete</span></button>
                </div>
            </form>
        </div>
    );
}

export default reduxForm({
    form: 'user-delete'
})(Form);
