import axios from 'axios';

import { onFormSubmitAsync } from '../form-submit';

const baseUrl = '/api/case/applicant/education';
export const urls = {
    addSubmit: `${baseUrl}/add`,
    updateSubmit: `${baseUrl}/edit`,
    delete: `${baseUrl}/delete`
}


export const onGetAllAsync = async (caseId) => {

    try {
        const response = await axios.get(`${baseUrl}/${caseId}`);
        const results = response.data || {};

        return results;
    }
    catch (err) {
        return {};
    }
}


export const onLoadAsync = async (caseId, educationId) => {
    try {
        const response = await axios.get(`${baseUrl}/${caseId}/${educationId}`);
        const values = response.data || {};

        return {
            isLoaded: true,
            isSuccessful: true,
            initialValues: mappers.toReduxForm(values)
        };
    }
    catch (err) {
        return {
            isLoaded: true,
            isSuccessful: false
        }
    }
}


export const onUpdateSubmitAsync = async (formValues) => {
    const serverModel = await mappers.toServerModel(formValues);
    const url = (formValues.educationId === 0) ? urls.addSubmit : urls.updateSubmit;
    return await onFormSubmitAsync(url, serverModel);
}

export const onDeleteSubmitAsync = async (formValues) => {
    var serverModel = mappers.toServerDeleteModel(formValues);
    return await onFormSubmitAsync(urls.delete, serverModel);
}



const mappers = {
    toReduxForm: (values) => ({
        ...values
    }),
    toServerModel: (values) => ({
        ...values
    }),
    toServerDeleteModel: (values) => ({
        id: values.educationId
    })
}


export default {
    onGetAllAsync,
    onLoadAsync,
    onUpdateSubmitAsync,
    onDeleteSubmitAsync
}