import { selector as filtersSelector } from '../reducers/filters-reducer';

import { onGetAllByFilterAsync } from '../../api-endpoint-calls/queries';

export const SEARCH_START = 'QUERIES::SEARCH_START';
export const SEARCH_SUCCESS = 'QUERIES::SEARCH_SUCCESS';
export const SEARCH_FAILED = 'QUERIES::SEARCH_FAILED';

export const SET_PAGE = 'QUERIES::SET_PAGE';
export const SET_SORT = 'QUERIES::SET_SORT';
export const SET_FILTERS = 'QUERIES::SET_FILTERS';
export const UPDATE_ISBEINGVIEWED = 'QUERIES::UPDATE_ISBEINGVIEWED';
export const UPDATE_USERID = 'QUERIES::UPDATE_USERID';
export const TOGGLE_EXPANSION = 'QUERIES::TOGGLE_EXPANSION';

const action = (type, data = null) => ({
    type, data
});

export function loadQueries() {
    return async (dispatch, getState) => {
        dispatch(action(SEARCH_START));

        try {
            const filters = filtersSelector.values(getState())
            const data = await onGetAllByFilterAsync(filters);

            dispatch(action(SEARCH_SUCCESS, data));
        }
        catch {
            dispatch(action(SEARCH_FAILED));
        }
    }
}

export function setPage(pageIndex, pageSize) {
    return (dispatch) => {
        dispatch(action(SET_PAGE, { pageIndex, pageSize }));
    }
}

export function setSort(sortBy) {
    return (dispatch) => {
        dispatch(action(SET_SORT, sortBy));
    }
}

export function setFilters(filters) {
    return (dispatch) => {
        dispatch(action(SET_FILTERS, filters));
    }
}

export function toggleExpansion(caseId, caseQueryId) {
    return (dispatch, getState) => {
        dispatch(action(TOGGLE_EXPANSION, caseQueryId));
    }
}
