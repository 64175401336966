import React from 'react';
import { Link } from "react-router-dom";

export default ({ userId, userIsAdmin }) => {

    const adminOptions = userIsAdmin
        ? (
            <div className="root-links">
                <p>
                    <b>Admin users only</b>
                </p>
                <ul>
                    <li>
                        <Link className="link" to="/admin/users">Add / Edit / Delete user accounts</Link>
                    </li>
                    <li>
                        <Link className="link" to="/admin/file-upload">Upload a File</Link>
                    </li>
                </ul>
            </div>
        ) : null;

    return (
        <div className="stripedbox">
            <div className="root-links">
                <p>
                    <b>My Profile</b>
                </p>
                <ul>
                    <li>
                        <Link className="link" to={`/admin/users/edit/${userId}`}>Edit My Details</Link>
                    </li>
                    <li>
                        <Link className="link" to={`/admin/users/reset-password/${userId}`}>Change My Password</Link>
                    </li>
                </ul>
            </div>

            {adminOptions}
        </div>
    );
}