import * as actions from '../../actions/admin/file-upload-actions';

function onUploadSuccess (state, action) {

  const selectedFile = action.data.saveMessage ? null : state.selectedFile;

  return { 
    ...state, 
    selectedFile: selectedFile,
    saveMessage: action.data.saveMessage,
    errorMessage: action.data.errorMessage
  };

}

const initialState = {
  selectedFile: null,
  saveMessage: null,
  errorMessage: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.FILE_SELECTED:
      return {
        ...state,
        selectedFile: action.data,
        saveMessage: null,
        errorMessage: null
      }
    case actions.SUBMIT_START:
      return { 
        ...state, 
        saveMessage: null,
        errorMessage: null
      };
    case actions.SUBMIT_SUCCESS:
      return onUploadSuccess(state, action);
    case actions.SUBMIT_FAIL:
      return { 
        ...state,
        saveMessage: null,
        errorMessage: null
      };

    default:
      return state;
  }
}


export const selector = ({
  selectedFile: (state) => state.adminFileUpload.selectedFile,
  saveMessage: (state) => state.adminFileUpload.saveMessage,
  errorMessage: (state) => state.adminFileUpload.errorMessage
});
