import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import DevTools from '../components/DevTools';

export const propsFn = (roodId) => {
    const containerSelector = document.getElementById(roodId);
    let extraProps = {};
    if(containerSelector !== null){
        if(containerSelector.hasAttributes()){
            let attributesDict = {};
            for (let i = 0, attributes = containerSelector.attributes, n = attributes.length; i < n; i++){
                    attributesDict[attributes[i].nodeName] = attributes[i].value;
            }
            extraProps = {...extraProps, ...attributesDict};
        }
    }
    return {extraProps: extraProps};
}

export default (rootId, store, children) => {
    return {
        dev: () => {
            var node = document.getElementById(rootId);
            if (node) {
        
                ReactDOM.render(
                    <Provider store={ store }>
                        <>
                            {children}
                            <DevTools />
                        </>
        
                    </Provider>
                , 
                node);
            }
        },
        prod: () => {
            var node = document.getElementById(rootId);
            if (node) {
        
                ReactDOM.render(
                    <Provider store={ store }>
                        {children}
                    </Provider>
                , 
                node);
            }
        }
    }
}