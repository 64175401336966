import React from "react";
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '../../../state/actions/reports/performance-report-actions';
import { selector } from '../../../state/reducers/reports/performance-report-reducer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo, faSearch } from '@fortawesome/free-solid-svg-icons';

import { DateTimePicker } from './form-inputs';
import { MultiSelectList, SelectList } from '../../form-inputs/index';

const isNullOrEmpty = (value) => {
    return (value === null || value === "");
}

const Form = () => {

    const dispatch = useDispatch();

    const { legislations, caseWorkers, frequencies } = useSelector(state => selector.options(state));

    const { 
        legislationIds, caseWorkerIds, startDate, endDate, frequency
    } = useSelector(state => selector.formValues(state));

    const formIsValid = !isNullOrEmpty(startDate) && !isNullOrEmpty(endDate);
    const submitting = useSelector((state) => selector.submitting(state));

    const onChange = (id, values) => {
        dispatch(actions.updateFormValue(id, values));
    }

    const onResetClick = (event) => {
        event.preventDefault();
        dispatch(actions.resetForm());
    }
    const onSearchClick = (event) => {
        event.preventDefault();
        dispatch(actions.submitForm())
    }

    return (
        <div className="stripedbox">

            <div className="reports-performance-monitoring-form">

                <h2 className="full-width">Select parameter for NRPF Connect data summary:</h2>

                <div className="input-wrapper">
                    <MultiSelectList
                        title="Select Legislations"
                        allText="All Legislations"
                        options={legislations}
                        values={legislationIds}
                        onChange={(values) => onChange('legislationIds', values)}
                        disabled={submitting}
                        tabIndex={1}
                     />
                </div>
                <div className="input-wrapper">
                    <MultiSelectList
                        title="Select caseworker"
                        allText="All Case Workers"
                        options={caseWorkers}
                        values={caseWorkerIds}
                        onChange={(values) => onChange('caseWorkerIds', values)}
                        disabled={submitting}
                        tabIndex={2}
                     />
                </div>

                <div className="flex-row">
                    <div className="input-wrapper">
                        <label>Start Date for report period</label>
                        <DateTimePicker 
                            id="startDate"
                            tabIndex={3}
                            title="Start Date"
                            placeholder="DD/MM/YYYY"
                            value={startDate}
                            onChange={onChange}
                            disabled={submitting}
                            maxDate={endDate || new Date()}
                        />
                    </div>
                    <div className="input-wrapper">
                        <label>End Date for report period</label>
                        <DateTimePicker 
                            id="endDate"
                            tabIndex={4}
                            title="End Date"
                            placeholder="DD/MM/YYYY"
                            value={endDate}
                            onChange={onChange}
                            disabled={submitting}
                            minDate={startDate}
                        />
                    </div>
                </div>
                <div className="input-wrapper">
                    <SelectList
                        title="Frequency"
                        options={frequencies}
                        value={frequency}
                        onChange={(value) => onChange('frequency', value)}
                        disabled={submitting}
                        tabIndex={5}
                     />
                </div>
                <div className="full-width">
                    <div className="form-buttons">
                        <button onClick={onResetClick} className="rbtn secondary" tabIndex={13} disabled={submitting}>
                            <FontAwesomeIcon icon={faRedo} /><div>Reset</div>
                        </button>
                        <button onClick={onSearchClick} className="rbtn primary" tabIndex={12} disabled={submitting || !formIsValid}>
                            <FontAwesomeIcon icon={faSearch} /><div>Search</div>
                        </button>
                    </div>
                </div>

            </div>
        </div>
    );
}


export default Form;