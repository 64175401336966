import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';

import { onLookupByDocument } from '../../../api-endpoint-calls/rtpf-lookup';
import stringIsNullOrEmpty from '../../../extensions/string.isNullOrEmpty';
import { nationalities, documentTypes } from '../dictionaries';

import Status from './status-description';

export default ({
    id,
    label,
    onResultFound,
    caseId,
    nationality,
    documentType,
    documentReference,
    dateOfBirth,
    autoUpdate,
    onNoResultFound
}) => {

    const [loading, setLoading] = useState(false);
    const [successful, setSuccessful] = useState(false);
    const [invalid, setInvalid] = useState(false);

    const [showDetails, setShowDetails] = useState(true);

    useEffect(() => {

        const source = axios.CancelToken.source();

        const onPageLoadAsync = async () => {

            if (!stringIsNullOrEmpty(documentReference)) {

                setLoading(true);

                const formValues = {
                    caseId,
                    nationality: nationality,
                    documentType: documentType,
                    documentReference: documentReference,
                    dateOfBirth: dateOfBirth,
                    autoUpdate
                }

                const lookUp = await onLookupByDocument(formValues, source);

                setLoading(false);

                if (lookUp.hasOwnProperty('result')) {
                    setSuccessful(true)
                    onResultFound(id, lookUp.result)
                } else {
                    setShowDetails(false)
                    onNoResultFound(id)
                }

            } else {
                setInvalid(true)
                setTimeout(() => {
                    setShowDetails(false)
                    onNoResultFound(id)
                }, 1000);
            }
        }

        onPageLoadAsync();

        return () => {
            source.cancel();
        }

    }, [id, caseId, nationality, documentType, documentReference, dateOfBirth, autoUpdate, setLoading, setInvalid, setSuccessful, onResultFound, onNoResultFound]);

    const documentTypeName = documentTypes.find(f => f.value === documentType).label;
    const NationalityName = nationalities.find(f => f.value === nationality).label;
    const dateOfBirthFormatted = moment(dateOfBirth).format('DD/MM/YYYY');

    return (
        <div className="performing-lookup-row">
            <div className="status" onClick={() => setShowDetails(!showDetails)}>
                <h3>
                    {label}
                </h3>
                <h3>
                    <Status
                        loading={loading}
                        successful={successful}
                        invalid={invalid}
                    />
                </h3>
            </div>
            {
                showDetails &&

                <ul>
                    <li>
                        <label>Document Type</label>
                        <span>{documentTypeName}</span>
                    </li>
                    <li>
                        <label>Document Reference</label>
                        <span>{documentReference ? <span>{documentReference}</span> : <span className="missing">MISSING</span>}</span>
                    </li>
                    <li>
                        <label>Nationality</label>
                        <span>{NationalityName}</span>
                    </li>
                    <li>
                        <label>Date of Birth</label>
                        <span>{dateOfBirthFormatted}</span>
                    </li>
                </ul>
            }
        </div>
    )
}

