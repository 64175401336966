import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { onLoadAsync, onUpdateSubmitAsync } from '../../../../api-endpoint-calls/admin/users';

import EditForm from './form';

import { LoadingV1, successNotification, errorNotification } from '../../../common';


const AdminUsersEdit = () => {

    const { userId } = useParams();

    const [initialValues, setInitialValues] = useState({});

    const [showSpinner, setShowSpinner] = useState(true);
    const [saveMessage, setSaveMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);


    useEffect(() => {
        const onPageLoadAsync = async () => {
            const result = await onLoadAsync(userId);
            
            setShowSpinner(!result.isLoaded || !result.isSuccessful);

            if (result.initialValues) {
                setInitialValues(result.initialValues);
            }
        }

        onPageLoadAsync();
        
    }, [setInitialValues, setShowSpinner, userId]);


    if (showSpinner) {
        return <LoadingV1 />;
    }

    const successAlert = successNotification(saveMessage);
    const errorAlert = errorNotification(errorMessage);

    const onFormSubmit = async (formValues) => {

        const result = await onUpdateSubmitAsync(formValues);

        if (result.isSuccess) {
            window.dispatcher.dispatchEvent('change-tracker-reset');
            setSaveMessage("All data saved successfully!");
            setErrorMessage(null);
        } else {
            setSaveMessage(null);
            setErrorMessage(result.message);
        }
    
        return result.errors;
    };

    return (
        <div className="stripedbox">
            {successAlert}
            {errorAlert}
            <EditForm onSubmit={onFormSubmit} initialValues={initialValues} userId={userId} />;
        </div>
    );
}

export default AdminUsersEdit;