import React, { useState, useEffect } from 'react';

import { onGetAllForCaseAsync, onAddSubmitAsync } from '../../../api-endpoint-calls/queries';

import Form from './form';

import { errorNotification } from '../../common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons';


export default ({onClose, caseId, userIsHomeOffice, principalApplicantName, disabled}) => {

    const [visible, setVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    
    const initialValues = {
        caseId,
        userIsHomeOffice,
        principalApplicantName
    };

    useEffect(() => {
        if (visible === false) {
            setTimeout(() =>  setVisible(true), 200);
        }
    }, [visible, setVisible]);

    useEffect(() => {
        var { unsubscribe } = window.dispatcher.subscribe("change-tracker-modal-close", () => onClose());

        return () => {
            unsubscribe();
        }
    }, [onClose]);

    
    const onCloseClick = () => {
        window.dispatcher.dispatchEvent("change-tracker-modal-closing");
    }

    const onSubmit = async (formValues) => {
        const result = await onAddSubmitAsync(formValues);
        
        if (result.isSuccess) {
            setErrorMessage(null);
            window.dispatcher.dispatchEvent("change-tracker-modal-reset");

            const queries = await onGetAllForCaseAsync(caseId);
            onClose(queries);
        } else {
            setErrorMessage(result.message);
        }

        return result.errors;
    }
    
    const errorAlert = errorNotification(errorMessage);

    return (
        <>
            <div className={ `react-modal-anchor ${(visible ? 'show' : '')}`}>
                <div className={ `react-modal add-query ${visible ? 'show' : ''}` }>
                    <button onClick={onCloseClick} className="close-button"><FontAwesomeIcon icon={faTimes} /></button>
                    <h1>
                        <FontAwesomeIcon icon={faEnvelopeOpen} />
                        Send a new query to the { userIsHomeOffice ? "Local Authority" : "Home Office" }
                    </h1>

                    <div className="stripedbox queries-add">
                        {errorAlert}
                        <Form onSubmit={onSubmit} initialValues={initialValues} disabled={disabled} />
                    </div>
                </div>
            </div>
            <div className={ `react-modal-backdrop ${visible ? 'show' : ''}` }></div>
        </>
    );
    
}