import React, { useEffect, useRef } from "react";
import { Field, Form } from 'react-final-form';

import { renderInput, renderTextArea } from '../../../form-inputs/final-form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlagCheckered } from '@fortawesome/free-solid-svg-icons';


const RtpfDescriptionForm = ({onSubmit, initialValues}) => {
    
    const formSubmitButton = useRef();

    useEffect(() => {
        var { unsubscribe } = window.dispatcher.subscribe("change-tracker-modal-submit-form", () => formSubmitButton.current.click());

        return () => {
            unsubscribe();
        }
    }, []);

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            render={({ handleSubmit, submitting, values }) => {

                const isDisabled = submitting;

                return (
                    <form autoComplete="off" onSubmit={handleSubmit} rel="change-tracked-modal">
    
                        <div className="mandatory"><span>*</span> Denotes a mandatory field </div>

                        <div className="modal-edit-form">
                            <div className="input-wrapper">
                                <Field name="productType" type="text" render={renderInput} label="Product Type" isMandatory={true} placeholder="" disabled={isDisabled} />
                            </div>
                            <div className="input-wrapper">
                                <Field name="immigrationStatus" type="text" render={renderInput} label="Immigration Status" isMandatory={true} placeholder="" disabled={isDisabled} />
                            </div>
                            <div className="input-wrapper">
                                <Field name="status" type="text" render={renderInput} label="Status" isMandatory={true} placeholder="" disabled={isDisabled} />
                            </div>
                            <div className="input-wrapper">
                                <Field name="description" type="text" render={renderTextArea} label="Description" isMandatory={true} placeholder="" disabled={isDisabled} />
                            </div>

                            <hr />

                            <div className="form-buttons">
                                <button type="submit" className="rbtn primary" disabled={isDisabled} ref={formSubmitButton}>
                                    <FontAwesomeIcon icon={faFlagCheckered} />
                                    <span>Save</span>
                                </button>
                            </div>
                        </div>
                        
                    </form>
                )
            }}
        />
    )
}


export default RtpfDescriptionForm;
