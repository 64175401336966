import React, { useState, useCallback } from 'react';
//import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { onAlertAccept } from '../../../../api-endpoint-calls/case/address';
import * as actions from '../../../../state/actions/case/address-list-actions';

import { caseArea, caseId, isClosed, userIsLocalAuthority } from '../../server-values';

import DeleteAddressModal from '../modals/delete-address';
import FinanceModal from '../modals/show-finance';

export default ({ row }) => {
    const addressId = row.original.id;

    const { hasFinanceAgainstProperty, finance, isHighlighted } = row.original;

    const [showAddressDelete, setShowAddressDelete] = useState(false);
    const [showFinance, setShowFinance] = useState(false);
    
    //const history = useHistory();
    const dispatch = useDispatch();

    const onDeleteModalClose = () => {
        setShowAddressDelete(false);
        dispatch(actions.loadAddresses(caseId));
    }

    const onDeleteClick = useCallback(
        (e) => {
            e.preventDefault(); 
            setShowAddressDelete(true);
        },
        [setShowAddressDelete]
    );

    const onAlertAcceptClick = useCallback(
        async (e) => {
            e.preventDefault();
            await onAlertAccept(caseId);
            //history.push(`/${caseArea}/Summary/Display?iCaseID=${caseId}`);
            window.location = `/${caseArea}/Summary/Display?iCaseID=${caseId}`;
        },
        []
    );

    const userDeleteModal = showAddressDelete 
        ? <DeleteAddressModal onClose={onDeleteModalClose} caseId={caseId} addressId={addressId} /> 
        : null;

    const financeModal = hasFinanceAgainstProperty && showFinance
        ? <FinanceModal onClose={() => setShowFinance(false)} caseId={caseId} finances={finance} /> 
        : null;

    const showFinanceButton = hasFinanceAgainstProperty
        ? (
            <button onClick={() => setShowFinance(true)} className="rbtn light-grey right" title="Show Finance">
                <span>£</span>
            </button>
        )
        : null

    const deleteAddressButton = userIsLocalAuthority && !isClosed
        ? (
            <button onClick={onDeleteClick} className="rbtn light-grey right" title="Delete Address">
                <span>Delete</span>
            </button>
        )
        : null

    const editAddressButton = userIsLocalAuthority
        ? (
            // <Link to={`/CaseOwner/Address/Update?CaseId=${caseId}&AddressId=${addressId}`} className="rbtn light-grey right"><span>Edit</span></Link>
            <a href={`/CaseOwner/Address/Update?CaseId=${caseId}&AddressId=${addressId}`} title="edit" className="rbtn light-grey right"><span>Edit</span></a>
        )
        : null;

    const acceptAlertButton = !userIsLocalAuthority && isHighlighted
        ? (
            <button onClick={onAlertAcceptClick} className="rbtn primary right" title="Accept Address Alert">
                <span>Accept</span>
            </button>
        )
        : null;

    return (
        <>
            { deleteAddressButton }
            { editAddressButton }
            { showFinanceButton }

            { acceptAlertButton }

            { userDeleteModal }
            { financeModal }
        </>
    );
}
