import React, { useEffect } from 'react';

import { caseId, userIsLocalAuthority, principalApplicantName, isClosed } from '../server-values';

import Alerts from './alerts';

import useQueriesState from '../../queries/use-query-state';
import Queries from '../../queries/widget';
import AddQueryModal from '../../queries/add';
import ViewQueryModal from '../../queries/view';

export default ({summary, dispatch}) => {

    const {
        queries,
        setQueries,
        onAddQueryModalRequest,
        onViewQueryModalRequest,
        addQueryModal,
        viewQueryModal,
    } = useQueriesState(
        0,
        (onClose) => <AddQueryModal onClose={onClose} caseId={caseId} userIsHomeOffice={!userIsLocalAuthority} principalApplicantName={principalApplicantName}  disabled={isClosed} />,
        (id, onClose) => <ViewQueryModal onClose={onClose} caseId={caseId} caseQueryId={id} disabled={isClosed} />
    )
    
    useEffect(() => {
        setQueries(summary.queries);
    }, [summary, setQueries]);


    return (
        <>
            <Alerts
                caseAlerts={summary.caseAlerts} 
                dispatch={dispatch} />

            <Queries
                queries={queries} 
                disabled={isClosed} 
                onAddQueryModalRequest={onAddQueryModalRequest} 
                onViewQueryModalRequest={onViewQueryModalRequest}
                fromSummary={true} />
                
            { addQueryModal }
            { viewQueryModal }
        </>
    );
}
