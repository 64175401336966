import axios from 'axios';

import emptyGuid from '../../extensions/guid.empty';

export const urls = {
    load: (userId) => `/api/superuser/users/${userId}`,
    addSubmit: `/api/superuser/users/add`,
    addSubmitValidate: `/api/superuser/users/add/validate`,
    updateSubmit: `/api/superuser/users/edit`,
    updateSubmitValidate: `/api/superuser/users/edit/validate`,
    unlock: `/api/superuser/users/unlock`,
    lock: `/api/superuser/users/lock`,
}

export const onLoad = async (userId) => {
    try {

        if (!userId) {
            userId = emptyGuid;
        }

        const response = await axios.get(urls.load(userId));
        const values = response.data || {};

        return {
            isLoaded: true,
            isSuccessful: true,
            initialValues: mappers.toReactForm(values)
        };
    }
    catch (err) {
        return {
            isLoaded: true,
            isSuccessful: false
        }
    }
}

export const onAddSubmit = async (formValues) => {
    
    const result = {
        isSuccess: false,
        errors: {}
    };

    const serverModel = mappers.toServerModel(formValues);

    try {
        const validateResponse = await axios.post(urls.addSubmitValidate, serverModel);

        if (Object.keys(validateResponse.data).length) {
            return { ...result, errors: validateResponse.data };
        }
    }
    catch (err) {

    }

    try {
        await axios.post(urls.addSubmit, serverModel);
       
        return { ...result, isSuccess: true };
    }
    catch (err) {
        
    }

    return result;
}

export const onUpdateSubmit = async (formValues) => {
    
    const result = {
        isSuccess: false,
        errors: {}
    };

    const serverModel = mappers.toServerModel(formValues);

    try {
        const validateResponse = await axios.post(urls.updateSubmitValidate, serverModel);

        if (Object.keys(validateResponse.data).length) {
            return { ...result, errors: validateResponse.data };
        }
    }
    catch (err) {

    }

    try {
        await axios.post(urls.updateSubmit, serverModel);
       
        return { ...result, isSuccess: true };
    }
    catch (err) {
        
    }

    return result;
}

export const onLockAccount = async (userId) => {

    if (!userId) {
        return false;
    }

    try {
        await axios.post(urls.lock, { userId });

        return true;
    }
    catch (err) {
        return false;
    }
}

export const onUnlockAccount = async (userId) => {

    if (!userId) {
        return false;
    }

    try {
        await axios.post(urls.unlock, { userId });

        return true;
    }
    catch (err) {
        return false;
    }
}

const mappers = {
    toReactForm: (values) => ({
        ...values,
        localAuthorityId: values.localAuthorityId?.toString(),
        homeOfficeId: values.homeOfficeId?.toString(),
        homeOfficeTeamId: values.homeOfficeTeamId?.toString(),
        accessLevel: values.accessLevel?.toString(),

        isPrimaryContact: values.isPrimaryContact.toString(),
        accountIsLocked: values.accountIsLocked.toString(),
        isShowingAliasDetails: values.isShowingAliasDetails.toString(),
        userIsHomeOffice: values.userIsHomeOffice.toString()
    }),
    toServerModel: (values) => {
        
        const userIsHomeOffice = JSON.parse(values.userIsHomeOffice);
        const isShowingAliasDetails = JSON.parse(values.isShowingAliasDetails);
        const isPrimaryContact = JSON.parse(values.isPrimaryContact);
        const accountIsLocked = JSON.parse(values.accountIsLocked);

        return {
            ...values,
            localAuthorityId: userIsHomeOffice ? null : parseInt(values.localAuthorityId),
            homeOfficeId: userIsHomeOffice ? parseInt(values.homeOfficeId) : null,
            homeOfficeTeamId: userIsHomeOffice ? parseInt(values.homeOfficeTeamId) : null,
            accessLevel: parseInt(values.accessLevel),

            isPrimaryContact,
            accountIsLocked,
            isShowingAliasDetails,
            userIsHomeOffice
        }
    }
}

export default {
    onLoad,
    onAddSubmit,
    onUpdateSubmit,
    onLockAccount,
    onUnlockAccount
}