import React from 'react';
import { Field } from "react-final-form";
import { renderDropdownList, renderDatePicker, renderRadioOption } from "../../../../form-inputs/final-form";

export default ({ values, highlights, disabled, applicationTypesOptions, applicationOutcomesOptions }) => {

    if (values.isImmigrationOrEeaApplication === "false")
        return null;

    highlights = highlights || {};

    return (
        <>
            <div className="input-wrapper-full-width">
                <label>Type of application</label>
                <Field
                    name="typeOfApplicationId"
                    type="text"
                    render={renderDropdownList}
                    options={applicationTypesOptions}
                    isHighlighted={highlights.typeOfApplicationId}
                    disabled={disabled}
                />
            </div>

            <div className="input-wrapper-full-width">
                <label>Outcome of immigration application</label>
                <Field
                    name="outcomeOfImmigrationApplicationId"
                    type="text"
                    render={renderDropdownList}
                    options={applicationOutcomesOptions}
                    isHighlighted={highlights.outcomeOfImmigrationApplicationId}
                    disabled={disabled}
                />
            </div>

            <div className="input-wrapper">
                <label>Continuing Leave - 3C granted?</label>
                <div className="radio-options">
                    <Field name="isContinuingLeave" type="radio" render={renderRadioOption} value="true" label="Yes" disabled={disabled} />
                    <Field name="isContinuingLeave" type="radio" render={renderRadioOption} value="false" label="No" disabled={disabled} />
                    <Field name="isContinuingLeave" type="radio" render={renderRadioOption} value="unknown" label="N/A" disabled={disabled} />
                </div>
            </div>

            <div className="double-dates">
                <div className="input-wrapper-full-width">
                    <Field
                        name="dateOfImmigrationApplication"
                        type="text"
                        render={renderDatePicker}
                        label="Date of application"
                        placeholder=""
                        isHighlighted={highlights.dateOfImmigrationApplication}
                        disabled={disabled}
                    />
                </div>

                <div className="input-wrapper-full-width">
                    <Field
                        name="dateOfImmigrationDecision"
                        type="text"
                        render={renderDatePicker}
                        label="Date of immi. or EEA decision"
                        placeholder=""
                        isHighlighted={highlights.dateOfImmigrationDecision}
                        disabled={disabled}
                    />
                </div>
            </div>
        </>
    )
}