import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { routes } from '../routes';
import * as actions from '../../state/actions/filters-actions';
import { selector } from '../../state/reducers/home-reducer';
import { selector as filtersSelector } from '../../state/reducers/filters-reducer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faEye, faSearch } from '@fortawesome/free-solid-svg-icons';

import { HomePageCountLoadingIcon } from '../common/loading';

const LocalAuthorityQueryNotifications = ({isLoading, queryCaseload, onSearchButtonClick, onQueryButtonClick}) => (
    <div className="notification-grid">
        <div className="divider-above">Number of outstanding Queries:<br/>Please reply or complete to reduce the count</div>
        <div className="divider-above">Households with an outstanding Home Office notification:<br/>Accept updates / close cases to reduce the count</div>
        <div className="notification-item">
            <div className="white-box">
                <FontAwesomeIcon icon={faEnvelope} className="notification-icon" /> 
                <span className="notification-text">Received - HO Replies</span>
                <span className="notification-count">{isLoading ? <HomePageCountLoadingIcon /> : queryCaseload.hoReplies}</span>
            </div>
            <button onClick={() => onQueryButtonClick({queriesTab: 'R', queriesSubOption: 'replies'})} disabled={isLoading} className="rbtn primary">
                <FontAwesomeIcon icon={faEye} /><div>View</div>
            </button>
        </div>
        <div className="notification-item">
            <div className="white-box">
                <span className="notification-text">Immigration Update Alert</span>
                <span className="notification-count">{isLoading ? <HomePageCountLoadingIcon /> : queryCaseload.imigrationUpdateAlert}</span>
            </div>
            <button onClick={() => onSearchButtonClick(4)} disabled={isLoading} className="rbtn primary">
                <FontAwesomeIcon icon={faSearch} /><div>View</div>
            </button>
        </div>
        <div className="notification-item">
            <div className="white-box">
                <FontAwesomeIcon icon={faEnvelope} className="notification-icon" />
                <span className="notification-text">Received - New HO Queries</span>
                <span className="notification-count">{isLoading ? <HomePageCountLoadingIcon /> : queryCaseload.newHOQueries}</span>
            </div>
            <button onClick={() => onQueryButtonClick({queriesTab: 'R', queriesSubOption: 'new'})} disabled={isLoading} className="rbtn primary">
                <FontAwesomeIcon icon={faEye} /><div>View</div>
            </button>
        </div>
        <div className="notification-item">
            <div className="white-box">
                <span className="notification-text">Closure Required Alert</span>
                <span className="notification-count">{isLoading ? <HomePageCountLoadingIcon /> : queryCaseload.closureRequiredAlert}</span>
            </div>
            <button onClick={() => onSearchButtonClick(6)} disabled={isLoading} className="rbtn primary">
                <FontAwesomeIcon icon={faSearch} /><div>View</div>
            </button>
        </div>
    </div>
);


const HomeOfficeQueryNotifications = ({isLoading, queryCaseload, onSearchButtonClick, onQueryButtonClick}) => (
    <div className="notification-grid extra-row">
        <div className="divider-above">Number of outstanding Queries:<br/>Please reply or complete to reduce the count</div>
        <div className="divider-above">Households with an outstanding Local Authority notification:<br/>View and update the record to reduce the count</div>
        <div className="notification-item">
            <div className="white-box">
                <FontAwesomeIcon icon={faEnvelope} className="notification-icon" /> 
                <span className="notification-text">Received - New LA Queries</span>
                <span className="notification-count">{isLoading ? <HomePageCountLoadingIcon /> : queryCaseload.newLAQueries}</span>
            </div>
            <button onClick={() => onQueryButtonClick({queriesTab: 'R', queriesSubOption: 'new'})} disabled={isLoading} className="rbtn primary">
                <FontAwesomeIcon icon={faEye} /><div>View</div>
            </button>
        </div>
        <div className="notification-item">
            <div className="white-box">
                <span className="notification-text">New Case Alert</span>
                <span className="notification-count">{isLoading ? <HomePageCountLoadingIcon /> : queryCaseload.newCaseAlert}</span>
            </div>
            <button onClick={() => onSearchButtonClick(1)} disabled={isLoading} className="rbtn primary">
                <FontAwesomeIcon icon={faSearch} /><div>View</div>
            </button>
        </div>
        <div className="notification-item">
            <div className="white-box">
                <FontAwesomeIcon icon={faEnvelope} className="notification-icon" />
                <span className="notification-text">Received - LA Replies</span>
                <span className="notification-count">{isLoading ? <HomePageCountLoadingIcon /> : queryCaseload.laReplies}</span>
            </div>
            <button onClick={() => onQueryButtonClick({queriesTab: 'R', queriesSubOption: 'replies'})} disabled={isLoading} className="rbtn primary">
                <FontAwesomeIcon icon={faEye} /><div>View</div>
            </button>
        </div>
        <div className="notification-item">
            <div className="white-box">
                <span className="notification-text">LA Update Alert</span>
                <span className="notification-count">{isLoading ? <HomePageCountLoadingIcon /> : queryCaseload.laUpdateAlert}</span>
            </div>
            <button onClick={() => onSearchButtonClick(2)} disabled={isLoading} className="rbtn primary">
                <FontAwesomeIcon icon={faSearch} /><div>View</div>
            </button>
        </div>
        <div></div>
        <div className="notification-item">
            <div className="white-box">
                <span className="notification-text">Case Closed Alert</span>
                <span className="notification-count">{isLoading ? <HomePageCountLoadingIcon /> : queryCaseload.caseClosedAlert}</span>
            </div>
            <button onClick={() => onSearchButtonClick(3)} disabled={isLoading} className="rbtn primary">
                <FontAwesomeIcon icon={faSearch} /><div>View</div>
            </button>
        </div>
        <div></div>
        <div className="notification-item">
            <div className="white-box">
                <span className="notification-text">Address Change Alert</span>
                <span className="notification-count">{isLoading ? <HomePageCountLoadingIcon /> : queryCaseload.addressChangedAlert}</span>
            </div>
            <button onClick={() => onSearchButtonClick(8)} disabled={isLoading} className="rbtn primary">
                <FontAwesomeIcon icon={faSearch} /><div>View</div>
            </button>
        </div>
    </div>
);



const QueryNotifications = () => {

    const loading = useSelector(state => selector.loading(state));
    const queryCaseload = useSelector(state => selector.queryCaseload(state));
    const { isHomeOfficeUser } = useSelector(state => filtersSelector.userDetails(state));

    const dispatch = useDispatch();
    const history = useHistory();

    const onQueryButtonClick = useCallback((value) => {
        dispatch(actions.applyFilter(value));
        history.push(routes.queries[0]);
    }, [dispatch, history]);

    const onSearchButtonClick = useCallback((value) => {
        dispatch(actions.applyAlertTypeFilter(value));
        history.push(routes.search[0]);
    }, [dispatch, history]);

    return isHomeOfficeUser 
        ? <HomeOfficeQueryNotifications isLoading={loading} queryCaseload={queryCaseload} onSearchButtonClick={onSearchButtonClick} onQueryButtonClick={onQueryButtonClick} /> 
        : <LocalAuthorityQueryNotifications isLoading={loading} queryCaseload={queryCaseload} onSearchButtonClick={onSearchButtonClick} onQueryButtonClick={onQueryButtonClick} />;
};

export default QueryNotifications;
