import axios from 'axios';
import moment from 'moment';

import { onFormSubmitAsync } from '../form-submit';

import dateFormatForServer from '../../extensions/date.formatForServer';
import emptyGuid from '../../extensions/guid.empty';


const baseUrl = '/api/case/applicant/alias';
export const urls = {
    load: (userId) => `${baseUrl}/${userId}`,
    addSubmit: `${baseUrl}/add`,
    updateSubmit: `${baseUrl}/edit`,
    delete: `${baseUrl}/delete`
}

export const onGetAllForApplicantAsync = async (applicantId) => {

    try {
        const response = await axios.post(`${baseUrl}/${applicantId}`);
        const results = response.data || {};

        return results;
    }
    catch (err) {
        return {};
    }
}


export const onLoadAsync = async (applicantId, aliasId) => {
    try {

        if (!applicantId) {
            applicantId = emptyGuid;
        }

        const response = await axios.get(`${baseUrl}/${applicantId}/${aliasId}`);
        const values = response.data || {};

        return {
            isLoaded: true,
            isSuccessful: true,
            initialValues: mappers.toReduxForm(values)
        };
    }
    catch (err) {
        return {
            isLoaded: true,
            isSuccessful: false
        }
    }
}


export const onUpdateSubmitAsync = async (formValues) => {
    const serverModel = mappers.toServerModel(formValues);
    const url = (formValues.aliasId === 0) ? urls.addSubmit : urls.updateSubmit;
    return await onFormSubmitAsync(url, serverModel);
}

export const onDeleteSubmitAsync = async (formValues) => {
    var serverModel = mappers.toServerDeleteModel(formValues);
    return await onFormSubmitAsync(urls.delete, serverModel);
}


const mappers = {
    toReduxForm: (values) => ({
        ...values,
        dateOfBirth: values.dateOfBirth ? moment(values.dateOfBirth)._d : null,
        dateOfDeath: values.dateOfDeath ? moment(values.dateOfDeath)._d : null,
    }),
    toServerModel: (values) => ({
        ...values,
        dateOfBirth: dateFormatForServer(values.dateOfBirth),
        dateOfDeath: dateFormatForServer(values.dateOfDeath)
    }),
    toServerDeleteModel: (values) => ({
        applicantId: values.applicantId,
        id: values.aliasId
    })
}


export default {
    onGetAllForApplicantAsync,
    onLoadAsync,
    onUpdateSubmitAsync,
    onDeleteSubmitAsync
}