import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '../../../state/actions/search-actions';
import * as filtersActions from '../../../state/actions/filters-actions';

import { selector } from '../../../state/reducers/search-reducer';
import { selector as filtersSelector } from '../../../state/reducers/filters-reducer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import { MultiSelectList } from '../../form-inputs/index';


const HomeOfficeSelects = (localAuthorities, homeOfficeCaseWorkers, localAuthorityIds, hoCaseWorkerIds, legislationIds, legislations, caseStatusIds, caseStatuses, disableComponents, onSelectChange, onStatusSelectChange) => (
    <>
        <div className="input-wrapper">
            <MultiSelectList title="Local Authority" allText="All Local Authorities" options={localAuthorities} values={localAuthorityIds} disabled={disableComponents} onChange={(v) => onSelectChange({localAuthorityIds: v})} />
        </div>
        <div className="input-wrapper">
            <MultiSelectList title="Case Worker / Team" allText="All Case Workers" options={homeOfficeCaseWorkers} values={hoCaseWorkerIds} disabled={disableComponents} onChange={(v) => onSelectChange({hoCaseWorkerIds: v})} />
        </div>
        <div className="input-wrapper">
            <MultiSelectList title="Legislation" allText="All Legislations" options={legislations} values={legislationIds} disabled={disableComponents} onChange={(v) => onSelectChange({legislationIds: v})} groupBy={item => item.groupName} />
        </div>
        <div className="input-wrapper">
            <MultiSelectList title="Case status selected:" isMandatory={true} allText="Select at least one from the list" options={caseStatuses} values={caseStatusIds} disabled={disableComponents} onChange={(v) => onStatusSelectChange({caseStatusIds: v})} />
        </div>
    </>
)

const LocalAuthoritySelects = (legislations, localAuthorityCaseWorkers, legislationIds, caseStatusIds, caseStatuses, caseWorkerIds, disableComponents, onSelectChange, onStatusSelectChange) => (
    <>
        <div className="input-wrapper">
            <MultiSelectList title="Case status selected:" isMandatory={true} allText="Select at least one from the list" options={caseStatuses} values={caseStatusIds} disabled={disableComponents} onChange={(v) => onStatusSelectChange({caseStatusIds: v})} />
        </div>
        <div className="input-wrapper">
            <MultiSelectList title="Legislation" allText="All Legislations" options={legislations} values={legislationIds} disabled={disableComponents} onChange={(v) => onSelectChange({legislationIds: v})} groupBy={item => item.groupName} />
        </div>
        <div className="input-wrapper">
            <MultiSelectList title="Case Worker" allText="All Case Workers" options={localAuthorityCaseWorkers} values={caseWorkerIds} disabled={disableComponents} onChange={(v) => onSelectChange({caseWorkerIds: v})} />
        </div>
    </>
)

const Form = () => {

    const dispatch = useDispatch();

    const loading = useSelector(state => selector.loading(state));
    const isAutomatedSearch = useSelector(state => selector.isAutomatedSearch(state));

    const { 
        legislationIds, caseWorkerIds, localAuthorityIds, hoCaseWorkerIds
    } = useSelector(state => filtersSelector.values(state));

    const { caseStatusIds, buttonIsDisabled } = useSelector(state => selector.byListFormValues(state));
    const { caseStatuses } = useSelector(state => selector.byListFormOptions(state));

    const onSubmit = () => {
        return dispatch(actions.searchByList());
    }

    const {
        localAuthorities, homeOfficeCaseWorkers, legislations, localAuthorityCaseWorkers 
    } = useSelector(state => filtersSelector.filterOptions(state));

    const { isHomeOfficeUser } = useSelector(state => filtersSelector.userDetails(state));

    const onSelectChange = useCallback((values) => {
        dispatch(filtersActions.applyFilter(values));
    }, [dispatch]);

    const onStatusSelectChange = useCallback((values) => {
        dispatch(actions.onSearchByListChange(values));
    }, [dispatch]);

    const disableComponents = loading || isAutomatedSearch;

    const lists = isHomeOfficeUser 
        ? HomeOfficeSelects(localAuthorities, homeOfficeCaseWorkers, localAuthorityIds, hoCaseWorkerIds, legislationIds, legislations, caseStatusIds, caseStatuses, disableComponents, onSelectChange, onStatusSelectChange) 
        : LocalAuthoritySelects(legislations, localAuthorityCaseWorkers, legislationIds, caseStatusIds, caseStatuses, caseWorkerIds, disableComponents, onSelectChange, onStatusSelectChange) 

    return (
        <div>
            { lists }

            

            <div className="form-buttons">
                <button onClick={onSubmit} className="rbtn large primary" disabled={buttonIsDisabled || disableComponents}>
                    <FontAwesomeIcon icon={faSearch} style={{maxWidth:"40%"}} />
                    <span>Find</span>
                </button>
            </div>
        </div>
    )
};

export default Form;
