import React, { useEffect, useRef } from "react";
import { Field, Form } from 'react-final-form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlagCheckered } from '@fortawesome/free-solid-svg-icons';

import { renderInput } from '../../../../form-inputs/final-form';

const UpdateForm = ({onSubmit, initialValues, disabled, onDeleteEducationModalRequest}) => {
    
    const formSubmitButton = useRef();

    useEffect(() => {
        var { unsubscribe } = window.dispatcher.subscribe("change-tracker-modal-submit-form", () => formSubmitButton.current.click());

        return () => {
            unsubscribe();
        }
    }, []);

    const onDeleteClick = (educationId) => {
        onDeleteEducationModalRequest(educationId);
    }

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            render={({ handleSubmit, submitting, values }) => {

                const isDisabled = (disabled || submitting);

                const { educationId } = values;

                const deleteButton = educationId === 0 || isDisabled
                    ? (
                        <span></span>
                    )
                    : (
                        <button type="button" className="rbtn link" onClick={() => onDeleteClick(educationId)} disabled={isDisabled}>
                            <span>Delete</span>
                        </button>
                    );

                return (
                    <form onSubmit={handleSubmit} rel="change-tracked-modal">

                        <div className="mandatory"><span>*</span> Denotes a mandatory field </div>

                        <div className="modal-edit-form">
                            <div className="input-wrapper">
                                <Field name="organisationName" type="text" render={renderInput} label="Organisation Name" isMandatory={true} placeholder="" disabled={isDisabled} />
                            </div>
                            <div className="input-wrapper">
                                <Field name="email" type="text" render={renderInput} label="Email" placeholder="" disabled={isDisabled} />
                            </div>
                            <div className="input-wrapper">
                                <Field name="telephone" type="text" render={renderInput} label="Telephone" placeholder="" disabled={isDisabled} />
                            </div>
                            <div className="input-wrapper">
                                <Field name="faxNumber" type="text" render={renderInput} label="Fax" placeholder="" disabled={isDisabled} />
                            </div>

                            <hr />

                            <div className="input-wrapper">
                                <Field name="houseNameNumber" type="text" render={renderInput} label="Address Line 1 / House Name - Number" placeholder="" disabled={isDisabled} />
                            </div>
                            <div className="input-wrapper">
                                <Field name="street" type="text" render={renderInput} label="Address Line 2 / Street" placeholder="" disabled={isDisabled} />
                            </div>
                            <div className="input-wrapper">
                                <Field name="area" type="text" render={renderInput} label="Address Line 3 / Area - Locality" placeholder="" disabled={isDisabled} />
                            </div>
                            <div className="input-wrapper">
                                <Field name="townCity" type="text" render={renderInput} label="Address Line 4 / Town - City" placeholder="" disabled={isDisabled} />
                            </div>
                            <div className="input-wrapper">
                                <Field name="county" type="text" render={renderInput} label="Address Line 5 / County" placeholder="" disabled={isDisabled} />
                            </div>
                            <div className="input-wrapper">
                                <Field name="postcode" type="text" render={renderInput} label="Postcode" placeholder="" disabled={isDisabled} />
                            </div>

                            <hr />

                            <div className="form-buttons edges">
                                { deleteButton }
                                <button type="submit" className="rbtn primary" disabled={isDisabled}>
                                    <FontAwesomeIcon icon={faFlagCheckered} />
                                    <span>Save</span>
                                </button>
                            </div>

                        </div>
                    </form>
                )
            }}
        />
    )
}


export default UpdateForm;