import React from 'react';

import useLocalAuthorityCaseworkerForGrid from './use-la-caseworker';

const NonCaseOwnerGrid = ({ summary }) => {

    const { principalApplicantDateOfBirth, caseId, hoLookupReference, principalApplicantReferenceNumber } = summary;

    return (
        <div className="case-details-grid-row">
            <label>
                Date of birth
            </label>
            <div className="fake-input">
                {principalApplicantDateOfBirth}
            </div>

            <label>
            </label>
            <div>
            </div>

            <label>
                Connect Case Id
            </label>
            <div className="fake-input">
                {caseId}
            </div>

            <label>
                Home Office Ref
            </label>
            <div className="fake-input">
                {hoLookupReference}
            </div>

            {useLocalAuthorityCaseworkerForGrid(summary)}

            <label>
                Local Authority Ref
            </label>
            <div className="fake-input">
                {principalApplicantReferenceNumber}
            </div>
        </div>
    )
}

export default NonCaseOwnerGrid;