import React from 'react';

export const getTable1Columns = (interestedNewReferrals, interestedFinanciallySupported, interestedCasesClosedReferrals, interestedCasesClosedFinanciallySupported) => {
    
    let result = [
        {
            accessor: 'id',
            disableSortBy: true,
            disableFilters: true,
            Cell: (<></>),
            width:0,
        },
        {
            Header: '',
            accessor: 'period',
            disableSortBy: true,
            disableFilters: true
        }
    ];

    if (interestedNewReferrals) {
        result.push({
            Header: 'Number of referrals',
            accessor: 'referrals',
            disableSortBy: true,
            disableFilters: true
        });
    }

    if (interestedFinanciallySupported) {
        result.push({
            Header: 'Number of cases taken on for financial support',
            accessor: 'casesTakenOnForFinancialSupport',
            disableSortBy: true,
            disableFilters: true
        });
    }

    if (interestedCasesClosedFinanciallySupported) {
        result.push({
            Header: 'Number of financially supported cases closed',
            accessor: 'financiallySupportedCasesThatWereClosed',
            disableSortBy: true,
            disableFilters: true
        });
    }

    if (interestedCasesClosedReferrals) {
        result.push({
            Header: 'Number of referral cases closed',
            accessor: 'referralCasesThatWereClosed',
            disableSortBy: true,
            disableFilters: true
        });
    }

    if (interestedCasesClosedReferrals && interestedCasesClosedFinanciallySupported) {
        result.push({
            Header: 'Number of referral and financially supported cases closed',
            accessor: 'referralAndFinanciallySupportedCasesThatWereClosed',
            disableSortBy: true,
            disableFilters: true
        })
    }

    return result;
};

export const getTable2Columns = (withinFrequency) => {
    
    let result = [
        {
            accessor: 'id',
            disableSortBy: true,
            disableFilters: true,
            Cell: (<></>),
            width:0,
        }, {
            Header: '',
            accessor: 'period',
            disableSortBy: true,
            disableFilters: true
        }
    ];

    if (withinFrequency === "financiallysupported" || withinFrequency === "both") {
        result.push({
            Header: 'Total financially supported within selected frequency',
            accessor: 'totalFinanciallySupported',
            disableSortBy: true,
            disableFilters: true
        }, {
            Header: 'Total property costs within the frequency period',
            accessor: 'totalPropertyCost',
            disableSortBy: true,
            disableFilters: true
        }, {
            Header: 'Total person costs within the frequency period',
            accessor: 'totalPersonCost',
            disableSortBy: true,
            disableFilters: true
        }, {
            Header: 'Total cost within the frequency period',
            accessor: 'totalCost',
            disableSortBy: true,
            disableFilters: true
        });
    }

    if (withinFrequency === "referrals" || withinFrequency === "both") {
        result.push({
            Header: 'Total referrals open within selected frequency',
            accessor: 'totalReferrals',
            disableSortBy: true,
            disableFilters: true
        });
    }

    if (withinFrequency === "both") {
        result.push({
            Header: 'Total referrals and financially supported within frequency',
            accessor: 'totalReferralsAndFinanciallySupported',
            disableSortBy: true,
            disableFilters: true
        })
    }

    return result;
}

export const getTable3Columns = (endFrequency) => {

    let result = [
        {
            accessor: 'id',
            disableSortBy: true,
            disableFilters: true,
            Cell: (<></>),
            width:0,
        }, {
            Header: '',
            accessor: 'period',
            disableSortBy: true,
            disableFilters: true
        }
    ];

    if (endFrequency === "financiallysupported" || endFrequency === "both") {
        result.push({
            Header: 'Total financially supported at end of frequency period',
            accessor: 'totalFinanciallySupported',
            disableSortBy: true,
            disableFilters: true
        },
        {
            Header: 'Average number of days on support',
            accessor: 'averageDaysOnSupport',
            disableSortBy: true,
            disableFilters: true
        },
        {
            Header: 'Total financially supported for over 1000 days',
            accessor: 'totalFinanciallySupportedForOver1000Days',
            disableSortBy: true,
            disableFilters: true
        })
    }

    if (endFrequency === "referrals" || endFrequency === "both") {
        result.push({
            Header: 'Total referrals open at end of frequency period',
            accessor: 'totalReferrals',
            disableSortBy: true,
            disableFilters: true
        });
    }

    if (endFrequency === "both") {
        result.push({
            Header: 'Total financially supported and referral cases',
            accessor: 'totalReferralsAndFinanciallySupported',
            disableSortBy: true,
            disableFilters: true
        })
    }

    return result;
}

export const table4Columns = [
    {
        accessor: 'id',
        disableSortBy: true,
        disableFilters: true,
        Cell: (<></>),
        width:0,
    }, 
    {
        Header: '',
        accessor: 'period',
        disableSortBy: true,
        disableFilters: true
    },
    {
        Header: 'Number at end of month',
        accessor: 'totalCases',
        disableSortBy: true,
        disableFilters: true
    },
    {
        Header: 'Projection at end of Month',
        accessor: 'totalCost',
        disableSortBy: true,
        disableFilters: true
    }
];