import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selector }  from '../../../state/reducers/admin/file-upload-reducer';
import * as actions from '../../../state/actions/admin/file-upload-actions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen, faUpload } from '@fortawesome/free-solid-svg-icons';

export default ({ organisation }) => {

    const fileInput = useRef();
    const dispatch = useDispatch();

    const selectedFile = useSelector(state => selector.selectedFile(state));
    const saveMessage = useSelector(state => selector.saveMessage(state));
    const errorMessage = useSelector(state => selector.errorMessage(state));


    const onFileSelected = (e) => {
        dispatch(actions.fileSelected(e.target.files[0]))
    }

    const onFileUpload = () => {
        dispatch(actions.uploadFile(selectedFile));
    };


    const fileDetails = selectedFile ? (
        <>
            <hr />
            <div className="flex-table">
                <div className="header">
                    <h6>File Details</h6>
                </div>

                <div>Filename</div>
                <div><b>{selectedFile.name}</b></div>

                <div>Size</div>
                <div><b>{(selectedFile.size / 1024).toFixed(0)} kb</b></div>

                <div>Last Modified</div>
                <div><b>{selectedFile.lastModifiedDate.toDateString()}</b></div>
            </div>
            <hr />
        </>
    ) : null;

    const successNotification = saveMessage ? (
        <div className="alert-message success">
            { saveMessage }
        </div>
    ) : null;

    const errorNotification = errorMessage ? (
        <div className="alert-message error">
            { errorMessage }
        </div>
    ) : null;

    return (
        <div className="stripedbox">

            { successNotification }
            { errorNotification }

            <h2>File Upload - {organisation}</h2>

            <div className="form">
                <div>
                    <input type="file" className="form-control-file" ref={fileInput} onChange={onFileSelected} />
                    <button className="rbtn primary" onClick={() => fileInput.current.click()}>
                        <FontAwesomeIcon icon={faFolderOpen} /> Choose File
                    </button>
                </div>

                {fileDetails}

                <div>
                    <button type="submit" className="rbtn primary" onClick={onFileUpload} disabled={!selectedFile}>
                        <FontAwesomeIcon icon={faUpload} /> Upload
                    </button>
                </div>
            </div>

        </div>
    );

}






