import React, { useState, useEffect } from 'react';

import { onGetAllAsync, onAddSubmitAsync } from '../../../../api-endpoint-calls/case/notes';

import Form from './form';

import { errorNotification } from '../../../common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faStickyNote } from '@fortawesome/free-solid-svg-icons';


export default ({onClose, caseId, applicantId, dependants, noteTypes, disabled}) => {

    const [visible, setVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    
    const initialValues = {
        caseId,
        applicantId,
        noteTypeId: noteTypes[0].value
    };

    if (dependants.length > 0) {
        initialValues.dependantId = dependants[0].value;
    }

    useEffect(() => {
        if (visible === false) {
            setTimeout(() =>  setVisible(true), 200);
        }
    }, [visible, setVisible]);

    useEffect(() => {
        var { unsubscribe } = window.dispatcher.subscribe("change-tracker-modal-close", () => onClose());

        return () => {
            unsubscribe();
        }
    }, [onClose]);

    
    const onCloseClick = () => {
        window.dispatcher.dispatchEvent("change-tracker-modal-closing");
    }

    const onSubmit = async (formValues) => {
        const result = await onAddSubmitAsync(formValues);
        
        if (result.isSuccess) {
            setErrorMessage(null);
            window.dispatcher.dispatchEvent("change-tracker-modal-reset");

            const notes = await onGetAllAsync(caseId);
            onClose(notes.data);
        } else {
            setErrorMessage(result.message);
        }

        return result.errors;
    }
    
    const errorAlert = errorNotification(errorMessage);

    return (
        <>
            <div className={ `react-modal-anchor ${(visible ? 'show' : '')}`}>
                <div className={ `react-modal add-note ${visible ? 'show' : ''}` }>
                    <button onClick={onCloseClick} className="close-button"><FontAwesomeIcon icon={faTimes} /></button>
                    <h1>
                        <FontAwesomeIcon icon={faStickyNote} />
                        Add Note
                    </h1>

                    <div className="stripedbox">
                        {errorAlert}
                        <Form onSubmit={onSubmit} initialValues={initialValues} disabled={disabled} dependants={dependants} noteTypes={noteTypes} />
                    </div>
                </div>
            </div>
            <div className={ `react-modal-backdrop ${visible ? 'show' : ''}` }></div>
        </>
    );
    
}