import axios from 'axios';
import moment from 'moment';

import { onFormSubmitAsync } from '../form-submit';

import dateFormatForServer from '../../extensions/date.formatForServer';
import emptyGuid from '../../extensions/guid.empty';

const baseUrl = '/api/case/dependant';
export const urls = {
    addSubmit: `${baseUrl}/add`,
    updateSubmit: `${baseUrl}/edit`,
    delete: `${baseUrl}/delete`
}


export const onGetAllForCaseAsync = async (caseId) => {

    try {
        const response = await axios.post(`${baseUrl}/${caseId}`);
        const results = response.data || {};

        return {
            isLoaded: true,
            isSuccessful: true,
            data: results
        };
    }
    catch (err) {
        return {
            isLoaded: true,
            isSuccessful: false
        }
    }
}


export const onLoadAsync = async (caseId, applicantId, source) => {
    try {

        if (!applicantId) {
            applicantId = emptyGuid;
        }

        const response = await axios.get(`${baseUrl}/${caseId}/${applicantId}`, { cancelToken: source.token });
        const values = response.data || {};

        return {
            isLoaded: true,
            isSuccessful: true,
            initialValues: mappers.toReduxForm(values)
        };
    }
    catch (err) {
        return {
            isLoaded: true,
            isSuccessful: false
        }
    }
}


export const onAddSubmitAsync = async (formValues) => {
    const serverModel = mappers.toServerModel(formValues);
    return await onFormSubmitAsync(urls.addSubmit, serverModel);
}

export const onUpdateSubmitAsync = async (formValues) => {
    const serverModel = mappers.toServerModel(formValues);
    return await onFormSubmitAsync(urls.updateSubmit, serverModel);
}

export const onDeleteSubmitAsync = async (formValues) => {
    var serverModel = mappers.toServerDeleteModel(formValues);
    return await onFormSubmitAsync(urls.delete, serverModel);
}


const mappers = {
    toReduxForm: (values) => ({
        ...values,
        dateOfBirth: values.dateOfBirth ? moment(values.dateOfBirth)._d : null,
        dateOfDeath: values.dateOfDeath ? moment(values.dateOfDeath)._d : null,
    }),
    toServerModel: (values) => ({
        ...values,
        dateOfBirth: dateFormatForServer(values.dateOfBirth),
        dateOfDeath: dateFormatForServer(values.dateOfDeath)
    }),
    toServerDeleteModel: (values) => ({
        caseId: values.caseId,
        id: values.id
    })
}


export default {
    onGetAllForCaseAsync,
    onLoadAsync,
    onAddSubmitAsync,
    onUpdateSubmitAsync,
    onDeleteSubmitAsync
}