import React from "react";

export const RecordCheckedByHomeOffice = ({hasHomeOfficeCheckedTheRecord}) => (
    <div className="alert-row">
        <span className="header">
            <span className="title">
                Record checked by Home Office
            </span>
        </span>
        <div className={`${hasHomeOfficeCheckedTheRecord ? "tick" : "cross"}`}></div>
    </div>
);


export const ImmigrationUpdateAlert = ({alert}) => (
    <div className="alert-row">
        <span className="header">
            <span className="title">
                Immigration Update
            </span>
            <span className="days">
                ({alert.daysOpen} days)
            </span>
        </span>
        <a href={`/CaseOwner/Immigration/Create?iCaseID=${alert.caseId}`} className="view-alert-link">View Alert</a>
    </div>
)

export const ClosureRequiredAlert = ({alert, applicantIsChildOrCareLeaver, ukbaOutcome}) => (
    <div className="alert-row with-rows">
        <div className="alert-row">
            <span className="header">
                <span className="title bold-red">
                    {applicantIsChildOrCareLeaver ? "Status Granted" : "Closure Required"}
                </span>
                <span className="days">
                    ({alert.daysOpen} days)
                </span>
            </span>
        </div>
        <div className="alert-reason">
            <span>{ukbaOutcome}</span>
        </div>
    </div>
)

export const OpenReferralAlert = ({alert}) => (
    <div className="alert-row with-rows">
        <div className="alert-row">
            <span className="header">
                <span className="title">
                    Open Referral
                </span>
                <span className="days">
                    ({alert.daysOpen} days)
                </span>
            </span>
        </div>
        <div className="alert-reason">
            <span>This case has an open referral</span>
        </div>
    </div>
)