import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import debounce from 'lodash.debounce';

import * as actions from '../../../state/actions/search-form/by-personal-details';
import { selector } from '../../../state/reducers/search-form/by-personal-details';
import { selector as searchSelector } from '../../../state/reducers/search-reducer';
import { onSearchByNameAutocompleteAsync } from '../../../api-endpoint-calls/search';

import { MyCombobox, MyDatepicker } from '../../form-inputs';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const Form = () => {

    const [names, setNames] = useState([]);
    const [isGettingNames, setIsGettingNames] = useState(false);

    const { canPerformFind } = useSelector(selector.state);
    const { name, dateOfBirth, reference } = useSelector(state => selector.formValues(state));
    const isAutomatedSearch = useSelector(state => searchSelector.isAutomatedSearch(state));


    const onNameChangeAsync = useMemo(() => 
        debounce(async (name) => {
            setIsGettingNames(true);

            let results = name.length > 0
                ? await onSearchByNameAutocompleteAsync(name)
                : [];
            setNames(results);

            setIsGettingNames(false);
        }, 500
    ), [setIsGettingNames, setNames]);

    useEffect(() => {
        onNameChangeAsync(name);
    }, [name, onNameChangeAsync]);


    const dispatch = useDispatch();

    const onChange = useCallback((value, name) => {
        dispatch(actions.onSearchByPersonalDetailsChange({ [name]: value }))
    }, [dispatch]);

    const onSubmit = useCallback((isExact) => {
        return dispatch(actions.searchByPersonalDetails(isExact));
    }, [dispatch]);

    return (
        <div>
            <div className="input-wrapper">
                <MyCombobox
                    name="name"
                    value={name}
                    data={names}
                    label="Name"
                    placeholder="Enter at least 1 character..."
                    onChange={v => onChange(v, "name")}
                    disabled={isAutomatedSearch}
                    busy={isGettingNames}
                />
            </div>
            <div className="input-wrapper">
                <MyDatepicker
                    name="dateOfBirth"
                    value={dateOfBirth}
                    label="Date of Birth"
                    placeholder="Choose date..."
                    onChange={v => onChange(v, "dateOfBirth")}
                    disabled={isAutomatedSearch}
                    max={new Date()}
                />
            </div>
            <div className="input-wrapper">
                <label>Reference number</label>
                <input
                    name="reference"
                    type="text"
                    value={reference}
                    placeholder="Enter connect Id or other ref..."
                    onChange={e => onChange(e.target.value, 'reference')}
                    disabled={isAutomatedSearch}
                />
            </div>

            <div className="form-buttons">
                <button onClick={onSubmit} className="rbtn large primary" disabled={!canPerformFind || isAutomatedSearch}>
                    <FontAwesomeIcon icon={faSearch} style={{ maxWidth: "40%" }} />
                    <span>Find</span>
                </button>
            </div>

        </div>
    )
};

export default Form;
