import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as actions from '../../state/actions/home-actions';

import { selector } from '../../state/reducers/filters-reducer';

import FiltersSelects from './filters-selects';
import QueryNotifications from "./query-notifications";
import ActivityNotifications from "./activity-notifications";
import Reports from './reports';

import DomConfigRepository from '../../repositories/domConfigRepository';
const reactConfig = DomConfigRepository.getJsonConfigById('reactConfig');

const Page = () => {

    const dispatch = useDispatch();

    const { isHomeOfficeUser, organisation, usersFullName } = useSelector(state => selector.userDetails(state));

    useEffect(() => {
        dispatch(actions.getCounts());
        document.getElementById("pageInfoHeader").innerText = `Welcome back ${usersFullName} | ${organisation}`;
    }, [dispatch, usersFullName, organisation])

    const filters = useSelector(state => selector.values(state));
    const filterOptions = useSelector(state => selector.filterOptions(state));

    return (
        <div className="react-home-page">
            <div className="purple-header">
                Outstanding Actions for { isHomeOfficeUser ? "the Home Office" : organisation } &amp; Caseload
            </div>
            <div className="greybox">
                <FiltersSelects filters={filters} filterOptions={filterOptions} isHomeOfficeUser={isHomeOfficeUser} />
                <QueryNotifications />
                <ActivityNotifications />
                { reactConfig.showPerformanceMonitoringReport ? <Reports /> : null }
            </div>
        </div>
    )
};

export default Page;
