import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';

import { onLookupByNino } from '../../../api-endpoint-calls/rtpf-lookup';
import stringIsNullOrEmpty from '../../../extensions/string.isNullOrEmpty';

import Status from './status-description';

export default ({
    id,
    label,
    onResultFound,
    caseId,
    ninoNumber,
    forename,
    surname,
    dateOfBirth,
    autoUpdate,
    onNoResultFound
}) => {

    const [loading, setLoading] = useState(false);
    const [successful, setSuccessful] = useState(false);
    const [invalid, setInvalid] = useState(false);

    const [showDetails, setShowDetails] = useState(true);

    useEffect(() => {

        const source = axios.CancelToken.source();

        const onPageLoadAsync = async () => {

            if (!stringIsNullOrEmpty(ninoNumber)) {

                setLoading(true);

                const formValues = {
                    caseId,
                    nino: ninoNumber,
                    firstName: forename,
                    surname: surname,
                    dateOfBirth: dateOfBirth,
                    autoUpdate
                }

                const lookUp = await onLookupByNino(formValues, source);

                setLoading(false);

                if (lookUp.hasOwnProperty('result')) {
                    setSuccessful(true)
                    onResultFound(id, lookUp.result)
                } else {
                    setShowDetails(false)
                    onNoResultFound(id)
                }

            } else {
                setInvalid(true)
                setTimeout(() => {
                    setShowDetails(false)
                    onNoResultFound(id)
                }, 1000);
            }

        }

        onPageLoadAsync();

        return () => {
            source.cancel();
        }

    }, [id, caseId, ninoNumber, forename, surname, dateOfBirth, autoUpdate, setLoading, setInvalid, setSuccessful, onResultFound, onNoResultFound]);

    const dateOfBirthFormatted = moment(dateOfBirth).format('DD/MM/YYYY');

    return (
        <div className="performing-lookup-row">
            <div className="status" onClick={() => setShowDetails(!showDetails)}>
                <h3>
                    {label}
                </h3>
                <h3>
                    <Status
                        loading={loading}
                        successful={successful}
                        invalid={invalid}
                    />
                </h3>
            </div>
            {
                showDetails &&

                <ul>
                    <li>
                        <label>National Insurance Number</label>
                        <span>{ninoNumber ? <span>{ninoNumber}</span> : <span className="missing">MISSING</span>}</span>
                    </li>
                    <li>
                        <label>Forename</label>
                        <span>{forename}</span>
                    </li>
                    <li>
                        <label>Surname</label>
                        <span>{surname}</span>
                    </li>
                    <li>
                        <label>Date of Birth</label>
                        <span>{dateOfBirthFormatted}</span>
                    </li>
                </ul>
            }
        </div>
    )
}

