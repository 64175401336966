export const localAuthorityReceivedOptions = [
    {
        id: 'radio-new',
        value: 'new',
        label: 'Show new queries from HO only',
        flex: 5
    },
    {
        id: 'radio-replies',
        value: 'replies',
        label: 'Show HO replies only',
        flex: 4
    },
    {
        id: 'radio-all',
        value: 'all',
        label: 'Show all',
        flex: 3
    },
];

export const localAuthoritySentOptions = [
    {
        id: 'radio-new',
        value: 'new',
        label: 'Show new LA queries sent only',
        flex: 5
    },
    {
        id: 'radio-replies',
        value: 'replies',
        label: 'Show LA replies only',
        flex: 4
    },
    {
        id: 'radio-all',
        value: 'all',
        label: 'Show all',
        flex: 3
    },
];

export const homeOfficeReceivedOptions = [
    {
        id: 'radio-new',
        value: 'new',
        label: 'Show new queries from LA only',
        flex: 5
    },
    {
        id: 'radio-replies',
        value: 'replies',
        label: 'Show LA replies only',
        flex: 4
    },
    {
        id: 'radio-all',
        value: 'all',
        label: 'Show all',
        flex: 3
    },
];

export const homeOfficeSentOptions = [
    {
        id: 'radio-new',
        value: 'new',
        label: 'Show new HO queries sent only',
        flex: 5
    },
    {
        id: 'radio-replies',
        value: 'replies',
        label: 'Show HO replies only',
        flex: 4
    },
    {
        id: 'radio-all',
        value: 'all',
        label: 'Show all',
        flex: 3
    },
];