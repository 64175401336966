import React from 'react';
import Store from '../../../state/stores';
import { selector } from '../../../state/reducers/search-reducer';
import {withClassNameSwitch} from '../../common'

const BaseRow = ({children, className='', row, url}) => (
  <div {...row.getRowProps()} data-case-id={row.original.caseId} className={`tr ${className}`} style={{flexDirection: "column"}}>
    <div className="result-row with-status">
      {
        row.cells.map((cell, index) => {
          let customCellProps = index < (row.cells.length - 1)
            ? { onClick: () => window.location.href = url }
            : null;

          return (
            <span {...cell.getCellProps()} {...customCellProps}>
              {cell.render('Cell')}
            </span>
          );
        })
      }
      <div className="clearfix"></div>
    </div>
    { children }
  </div>
);

const withUrl = (CaseRow) => ({row, ...rest}) => {
  let area = row.original.myLocalAuthorityId === 0
  ? "LIT"
  : row.original.myLocalAuthorityId === row.original.localAuthorityId
    ? "CaseOwner"
    : "NonCaseOwner";

  let url = `/${area}/Summary/Display?iCaseID=${row.original.caseId}`;
  return <CaseRow {...rest} url={url} row={row}/>
}

const RowWithCaseBeingView = withClassNameSwitch(props => selector.isCaseBeingViewed(Store.getState(), props.row.original.caseId) ? "case-is-being-viewed" : "")(BaseRow);
const RowWithCaseBeingViewAndOddEven = withClassNameSwitch(props => props.rowIndex % 2 === 0 ? 'odd' : 'even')(RowWithCaseBeingView);
const Row = withUrl(RowWithCaseBeingViewAndOddEven);

export default Row;