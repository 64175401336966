import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { onGetAllAsync, onLoadAsync, onUpdateSubmitAsync } from '../../../../api-endpoint-calls/superuser/rtpf-descriptions';

import Form from './form';

import { errorNotification, LoadingV1 } from '../../../common';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPeopleArrows } from '@fortawesome/free-solid-svg-icons';


export default ({onClose, descriptionId}) => {

    const [loaded, setLoaded] = useState(false);
    const [successful, setSuccessful] = useState(false);
    const [visible, setVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    
    const [initialValues, setInitialValues] = useState({
        descriptionId
    });

    useEffect(() => {
        const source = axios.CancelToken.source();

        const onPageLoadAsync = async () => {
            const result = await onLoadAsync(descriptionId, source);
            
            setLoaded(result.isLoaded);
            setSuccessful(result.isSuccessful);

            if (result.initialValues) {
                setInitialValues(result.initialValues);
            }
        }

        onPageLoadAsync();

        return () => {
            source.cancel();
        }

    }, [descriptionId, setLoaded, setSuccessful, setInitialValues]);

    useEffect(() => {
        if (visible === false) {
            setTimeout(() =>  setVisible(true), 200);
        }
    }, [visible, setVisible]);

    useEffect(() => {
        var { unsubscribe } = window.dispatcher.subscribe("change-tracker-modal-close", () => onClose());

        return () => {
            unsubscribe();
        }
    }, [onClose]);


    const onCloseClick = () => {
        window.dispatcher.dispatchEvent("change-tracker-modal-closing");
    }

    const onSubmit = async (formValues) => {
        const result = await onUpdateSubmitAsync(formValues);
        
        if (result.isSuccess) {
            setErrorMessage(null);
            window.dispatcher.dispatchEvent("change-tracker-modal-reset");

            const descriptions = await onGetAllAsync();
            onClose(descriptions.data);
        } else {
            setErrorMessage(result.message);
        }

        return result.errors;
    }
    
    const errorAlert = errorNotification(errorMessage);

    const showSpinner = (!loaded);
    const showFailure = (loaded && !successful);

    var content = showSpinner
        ? <LoadingV1 />
        : showFailure
            ? <h2>An error has occured!</h2>
            : (
                <div className="stripedbox">
                    {errorAlert}
                    <Form onSubmit={onSubmit} initialValues={initialValues} />
                </div>
            );

    return (
        <>
            <div className={ `react-modal-anchor ${(visible ? 'show' : '')}`}>
                <div className={ `react-modal rtpf-description ${visible ? 'show' : ''}` }>
                    <button onClick={onCloseClick} className="close-button"><FontAwesomeIcon icon={faTimes} /></button>
                    <h1>
                        <FontAwesomeIcon icon={faPeopleArrows} />
                        {descriptionId === 0 ? "Add" : "Edit"} Rtpf Description
                    </h1>

                    { content }
                </div>
            </div>
            <div className={ `react-modal-backdrop ${visible ? 'show' : ''}` }></div>
        </>
    );
    
}