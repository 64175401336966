import React, { useEffect, useRef, useState } from "react";
import { Field, Form } from "react-final-form";

import calculateAge from "../../../../extensions/date.calculateAge";
import {
    renderDropdownList,
    renderDatePicker,
    renderInput,
    toIntOptions,
    toSortedIntOptions,
} from "../../../form-inputs/final-form";
import { successNotification } from "../../../common";

import ImpairmentGroups from "../impairment-groups";
import Aliases from "../aliases";
import Documents from "../documents";
import NoteWidget from "../../notes/widget";
import QueriesWidget from "../../../queries/widget";
import RtpfLookupModal from "../../../rtpf-lookup/modal"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlagCheckered } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const defaultOptions = {
    titles: [],
    genders: [],
    nationalities: [],
    languages: [],
    ethnicities: [],
    impairmentGroups: [],
    isCaseKnownToHomeOffice: [],
    localAuthorityCaseWorkers: [],
};

const AddForm = ({
    onSubmit,
    initialValues,
    isClosed,
    aliases,
    documents,
    localAuthorityNote,
    homeOfficeNote,
    queries,
    successMessage,
    onEditAliasModalRequest,
    onDeleteAliasModalRequest,
    onEditDocumentModalRequest,
    onDeleteDocumentModalRequest,
    onAddLocalAuthorityNoteModalRequest,
    onLocalAuthorityNoteHistoryModalRequest,
    onAddHomeOfficeNoteModalRequest,
    onHomeOfficeNoteHistoryModalRequest,
    onAddQueryModalRequest,
    onViewQueryModalRequest,
}) => {

    const formSubmitButton = useRef();

    useEffect(() => {
        var { unsubscribe } = window.dispatcher.subscribe(
            "change-tracker-submit-form",
            () => formSubmitButton.current.click()
        );

        return () => {
            unsubscribe();
        };
    }, []);


    const {
        titles,
        genders,
        nationalities,
        languages,
        ethnicities,
        impairmentGroups,
        isCaseKnownToHomeOffice,
        localAuthorityCaseWorkers
    } = initialValues.options || defaultOptions;

    const today = new Date();

    const titlesOptions = titles;
    const gendersOptions = genders;
    const nationalitiesOptions = nationalities;
    const languagesOptions = toSortedIntOptions(languages);
    const ethnicitiesOptions = toSortedIntOptions(ethnicities);
    const isCaseKnownToHomeOfficeOptions = toIntOptions(isCaseKnownToHomeOffice);
    const localAuthorityCaseWorkersOptions = toSortedIntOptions(localAuthorityCaseWorkers);


    const [showRtpfModal, setShowRtpfModal] = useState(false);
    const onRtpfLookUpClick = () => setShowRtpfModal(true);
    const onRtpfLookUpClose = () => setShowRtpfModal(false);



    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            render={({ handleSubmit, submitting, values, form }) => {

                const {
                    caseId,
                    selectedHealthConditionIds,
                    dateOfBirth,
                    nationalityCode,
                    title,
                    forename,
                    surname,
                    brpNumber,
                    brcNumber,
                    euIdentityCardNumber,
                    ninoNumber,
                    passportNumber
                } = values;


                const disabled = submitting || isClosed;

                const age = calculateAge(dateOfBirth);
                const name = `${title || ''} ${forename || ''} ${surname || ''}`.trim();
                const success = successNotification(successMessage);


                const modalValues = {
                    caseId,
                    forename,
                    surname,
                    brpNumber,
                    brcNumber,
                    euIdentityCardNumber,
                    ninoNumber,
                    passportNumber,
                    dateOfBirth,
                    nationalityCode,
                    autoUpdate: true
                }

                const rtpfLookupModal = showRtpfModal
                    ? <RtpfLookupModal onClose={onRtpfLookUpClose} values={modalValues} />
                    : null

                return (
                    <>
                        <form onSubmit={handleSubmit} rel="change-tracked">
                            <div className="dependant-edit-wrapper">
                                <div className="stripedbox">
                                    <div className="mandatory">
                                        <span>*</span> Denotes a mandatory field{" "}
                                    </div>
                                    {success}
                                </div>
                                <div className="dependant-grid-wrapper">
                                    <div className="stripedbox left-column">
                                        <div className="dependant-edit-form">
                                            <h2>Personal Details</h2>

                                            <div className="input-wrapper">
                                                <label>Title</label>
                                                <Field
                                                    name="title"
                                                    type="text"
                                                    render={renderDropdownList}
                                                    options={titlesOptions}
                                                    disabled={disabled}
                                                />
                                            </div>
                                            <div className="input-wrapper">
                                                <Field
                                                    name="forename"
                                                    type="text"
                                                    render={renderInput}
                                                    label="Forename"
                                                    isMandatory={true}
                                                    placeholder=""
                                                    disabled={disabled}
                                                />
                                            </div>
                                            <div className="input-wrapper">
                                                <Field
                                                    name="surname"
                                                    type="text"
                                                    render={renderInput}
                                                    label="Surname"
                                                    isMandatory={true}
                                                    placeholder=""
                                                    disabled={disabled}
                                                />
                                            </div>
                                            <div className="input-wrapper">
                                                <label>
                                                    Gender <span className="mandatory-stars">*</span>
                                                </label>
                                                <Field
                                                    name="gender"
                                                    type="text"
                                                    render={renderDropdownList}
                                                    options={gendersOptions}
                                                    disabled={disabled}
                                                />
                                            </div>
                                            <div className="input-wrapper">
                                                <label>
                                                    Nationality <span className="mandatory-stars">*</span>
                                                </label>
                                                <Field
                                                    name="nationalityCode"
                                                    type="text"
                                                    render={renderDropdownList}
                                                    options={nationalitiesOptions}
                                                    disabled={disabled}
                                                />
                                            </div>
                                            <div className="input-wrapper">
                                                <label>Language</label>
                                                <Field
                                                    name="languageId"
                                                    type="text"
                                                    render={renderDropdownList}
                                                    options={languagesOptions}
                                                    disabled={disabled}
                                                />
                                            </div>
                                            <div className="input-wrapper">
                                                <label>Ethnicity</label>
                                                <Field
                                                    name="ethnicityId"
                                                    type="text"
                                                    render={renderDropdownList}
                                                    options={ethnicitiesOptions}
                                                    disabled={disabled}
                                                />
                                            </div>

                                            <hr />

                                            <h2>Date of Birth & Age</h2>

                                            <div className="input-wrapper">
                                                <Field
                                                    name="dateOfBirth"
                                                    type="text"
                                                    render={renderDatePicker}
                                                    isMandatory={true}
                                                    label="Date of Birth"
                                                    placeholder=""
                                                    maxDate={today}
                                                    disabled={disabled}
                                                />
                                            </div>
                                            <div className="input-wrapper">
                                                <label>Age</label>
                                                <div className="fake-input">{age}</div>
                                            </div>
                                            <div className="input-wrapper">
                                                <Field
                                                    name="dateOfDeath"
                                                    type="text"
                                                    render={renderDatePicker}
                                                    label="Date of Death"
                                                    placeholder=""
                                                    disabled={disabled}
                                                />
                                            </div>

                                            <hr />

                                            <h2>Contact Information</h2>

                                            <div className="input-wrapper">
                                                <Field
                                                    name="telephone"
                                                    type="text"
                                                    render={renderInput}
                                                    label="Telephone"
                                                    placeholder=""
                                                    disabled={disabled}
                                                />
                                            </div>
                                            <div className="input-wrapper">
                                                <Field
                                                    name="email"
                                                    type="text"
                                                    render={renderInput}
                                                    label="Email"
                                                    placeholder=""
                                                    disabled={disabled}
                                                />
                                            </div>

                                            <hr />

                                            <ImpairmentGroups
                                                form={form}
                                                selectedHealthConditionIds={selectedHealthConditionIds}
                                                impairmentGroups={impairmentGroups}
                                                disabled={disabled}
                                            />

                                            <hr />

                                            <h2>Local Authority Notes</h2>

                                            <NoteWidget
                                                note={localAuthorityNote}
                                                disabled={disabled}
                                                onAddModalRequest={onAddLocalAuthorityNoteModalRequest}
                                                onHistoryModalRequest={
                                                    onLocalAuthorityNoteHistoryModalRequest
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="right-column">
                                        <div className="stripedbox">
                                            <div className="dependant-edit-form">
                                                <h2>Reference Numbers</h2>

                                                <div className="input-wrapper">
                                                    <Field
                                                        name="referenceNumber"
                                                        type="text"
                                                        render={renderInput}
                                                        label="LA Reference Number"
                                                        placeholder=""
                                                        disabled={disabled}
                                                    />
                                                </div>
                                                <div className="input-wrapper">
                                                    <Field
                                                        name="homeOfficeReferenceNumber"
                                                        type="text"
                                                        render={renderInput}
                                                        label="Home Office Reference"
                                                        placeholder=""
                                                        disabled={disabled}
                                                    />
                                                </div>
                                                <div className="input-wrapper">
                                                    <Field
                                                        name="visaNumber"
                                                        type="text"
                                                        render={renderInput}
                                                        label="Home Office Person Id"
                                                        placeholder=""
                                                        disabled={disabled}
                                                    />
                                                </div>
                                                <div className="input-wrapper">
                                                    <Field
                                                        name="nassReference"
                                                        type="text"
                                                        render={renderInput}
                                                        label="NASS Reference Number"
                                                        placeholder=""
                                                        disabled={disabled}
                                                    />
                                                </div>
                                                <div className="input-wrapper">
                                                    <Field
                                                        name="uniqueAppNumber"
                                                        type="text"
                                                        render={renderInput}
                                                        label="Unique Application Number"
                                                        placeholder=""
                                                        disabled={disabled}
                                                    />
                                                </div>

                                                <hr />

                                                <h2>
                                                    Complete one field below for digital status check
                                                    purposes
                                                </h2>

                                                <div className="input-wrapper">
                                                    <Field
                                                        name="brpNumber"
                                                        type="text"
                                                        render={renderInput}
                                                        label="BRP Number"
                                                        placeholder=""
                                                        disabled={disabled}
                                                    />
                                                </div>
                                                <div className="input-wrapper">
                                                    <Field
                                                        name="brcNumber"
                                                        type="text"
                                                        render={renderInput}
                                                        label="BRC Number"
                                                        placeholder=""
                                                        disabled={disabled}
                                                    />
                                                </div>
                                                <div className="input-wrapper">
                                                    <Field
                                                        name="passportNumber"
                                                        type="text"
                                                        render={renderInput}
                                                        label="Passport Number"
                                                        placeholder=""
                                                        disabled={disabled}
                                                    />
                                                </div>
                                                <div className="input-wrapper">
                                                    <Field
                                                        name="euIdentityCardNumber"
                                                        type="text"
                                                        render={renderInput}
                                                        label="European National Identity Card Number"
                                                        placeholder=""
                                                        disabled={disabled}
                                                    />
                                                </div>
                                                <div className="input-wrapper">
                                                    <Field
                                                        name="ninoNumber"
                                                        type="text"
                                                        render={renderInput}
                                                        label="NINO Number"
                                                        placeholder=""
                                                        disabled={disabled}
                                                    />
                                                </div>
                                                <div className="form-buttons">
                                                    <button
                                                        type="button"
                                                        onClick={onRtpfLookUpClick}
                                                        className="rbtn green hover-tooltip"
                                                        disabled={disabled}
                                                        data-tooltip="Use the ‘Recourse to Public Funds checker’ to look-up digital immigration status information. If no valid credentials or no digital immigration status found, please use existing case create / query functions."
                                                    >
                                                        <FontAwesomeIcon icon={faSearch} />
                                                        <span>RTPF Look-up</span>
                                                    </button>
                                                </div>

                                                <hr />

                                                <h2>Caseworker</h2>

                                                <div className="input-wrapper-full-width">
                                                    <Field
                                                        name="laCaseWorkerId"
                                                        type="text"
                                                        render={renderDropdownList}
                                                        options={localAuthorityCaseWorkersOptions}
                                                        disabled={disabled}
                                                    />
                                                </div>

                                                <hr />

                                                <Aliases
                                                    aliases={aliases}
                                                    disabled={disabled}
                                                    onEditAliasModalRequest={onEditAliasModalRequest}
                                                    onDeleteAliasModalRequest={onDeleteAliasModalRequest}
                                                    applicantName={name}
                                                    applicantDateOfBirth={dateOfBirth}
                                                />

                                                <hr />

                                                <Documents
                                                    applicantId={values.applicantId}
                                                    documents={documents}
                                                    disabled={disabled}
                                                    onEditDocumentModalRequest={onEditDocumentModalRequest}
                                                    onDeleteDocumentModalRequest={
                                                        onDeleteDocumentModalRequest
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div className="stripedbox dark">
                                            <div className="dependant-edit-form">
                                                <h2>Home Office Actions</h2>

                                                <div className="input-wrapper">
                                                    <label>Case known to Home Office?</label>
                                                    <Field
                                                        name="homeOfficeActionId"
                                                        type="text"
                                                        render={renderDropdownList}
                                                        options={isCaseKnownToHomeOfficeOptions}
                                                        disabled={true}
                                                    />
                                                </div>

                                                <NoteWidget
                                                    note={homeOfficeNote}
                                                    disabled={true}
                                                    onAddModalRequest={onAddHomeOfficeNoteModalRequest}
                                                    onHistoryModalRequest={onHomeOfficeNoteHistoryModalRequest}
                                                />
                                            </div>
                                        </div>

                                        <div className="stripedbox">
                                            <div className="dependant-edit-form">
                                                <QueriesWidget
                                                    queries={queries}
                                                    disabled={disabled}
                                                    onAddQueryModalRequest={onAddQueryModalRequest}
                                                    onViewQueryModalRequest={onViewQueryModalRequest}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="stripedbox">
                                    <div className="form-buttons">
                                        <button
                                            type="submit"
                                            className="rbtn primary"
                                            disabled={submitting}
                                            ref={formSubmitButton}
                                        >
                                            <FontAwesomeIcon icon={faFlagCheckered} />
                                            <span>Save</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        {rtpfLookupModal}
                    </>
                );
            }}
        />

    );
};

export default AddForm;
