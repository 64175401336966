import React, { useState, useEffect } from 'react';

import { onGetLatestAsync, onAddSubmitAsync } from '../../../../api-endpoint-calls/case/notes';
import emptyGuid from '../../../../extensions/guid.empty';

import Form from './form';

import { errorNotification } from '../../../common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faStickyNote } from '@fortawesome/free-solid-svg-icons';

const isDependantNote = (noteTypeId) => {
    const dependantLaNote = 2;
    const dependantHoNote = 3;
    return noteTypeId === dependantLaNote || noteTypeId === dependantHoNote;
}

export default ({onClose, note, disabled}) => {

    const [visible, setVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    
    const { noteTypeId, caseId, applicantId } = note;
    const initialValues = {
        noteTypeId, 
        caseId, 
        applicantId,
        dependantId: isDependantNote(noteTypeId) ? applicantId : emptyGuid
    };

    useEffect(() => {
        if (visible === false) {
            setTimeout(() =>  setVisible(true), 200);
        }
    }, [visible, setVisible]);

    useEffect(() => {
        var { unsubscribe } = window.dispatcher.subscribe("change-tracker-modal-close", () => onClose());

        return () => {
            unsubscribe();
        }
    }, [onClose]);

    
    const onCloseClick = () => {
        window.dispatcher.dispatchEvent("change-tracker-modal-closing");
    }

    const onSubmit = async (formValues) => {
        const result = await onAddSubmitAsync(formValues);
        
        if (result.isSuccess) {
            setErrorMessage(null);
            window.dispatcher.dispatchEvent("change-tracker-modal-reset");

            const latestNote = await onGetLatestAsync(noteTypeId, caseId, applicantId);
            onClose(latestNote);
        } else {
            setErrorMessage(result.message);
        }

        return result.errors;
    }
    
    const errorAlert = errorNotification(errorMessage);

    return (
        <>
            <div className={ `react-modal-anchor ${(visible ? 'show' : '')}`}>
                <div className={ `react-modal add-note ${visible ? 'show' : ''}` }>
                    <button onClick={onCloseClick} className="close-button"><FontAwesomeIcon icon={faTimes} /></button>
                    <h1>
                        <FontAwesomeIcon icon={faStickyNote} />
                        Add Note
                    </h1>

                    <div className="stripedbox">
                        {errorAlert}
                        <Form onSubmit={onSubmit} initialValues={initialValues} disabled={disabled} />
                    </div>
                </div>
            </div>
            <div className={ `react-modal-backdrop ${visible ? 'show' : ''}` }></div>
        </>
    );
    
}