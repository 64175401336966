import React from 'react';
import { Field } from "react-final-form";
import { renderDropdownList, renderDatePicker } from "../../../../form-inputs/final-form";

export default ({ values, highlights, disabled, asylumOutcomesOptions }) => {

    if (values.isClaimedAsylum === "false")
        return null;

    highlights = highlights || {};

    return (
        <>
            <div className="input-wrapper-full-width">
                <label>Outcome of asylum application</label>
                <Field
                    name="outcomeOfAsylumApplicationId"
                    type="text"
                    render={renderDropdownList}
                    options={asylumOutcomesOptions}
                    isHighlighted={highlights.outcomeOfAsylumApplicationId}
                    disabled={disabled}
                />
            </div>

            <div className="double-dates">
                <div className="input-wrapper-full-width">
                    <Field
                        name="dateOfAsylumApplication"
                        type="text"
                        render={renderDatePicker}
                        label="Date of asylum application"
                        placeholder=""
                        isHighlighted={highlights.dateOfAsylumApplication}
                        disabled={disabled}
                    />
                </div>

                <div className="input-wrapper-full-width">
                    <Field
                        name="dateOfAsylumDecision"
                        type="text"
                        render={renderDatePicker}
                        label="Date of asylum decision"
                        placeholder=""
                        isHighlighted={highlights.dateOfAsylumDecision}
                        disabled={disabled}
                    />
                </div>
            </div>
        </>
    )
}