import dateFormatForServer from '../../../extensions/date.formatForServer';

export const toViewModel = (values) => ({
    legislationIds: values.legislationIds,
    caseWorkerIds: values.caseWorkerIds,
    startDate: dateFormatForServer(values.startDate),
    endDate: dateFormatForServer(values.endDate),
    frequency: parseInt(values.frequency)
});

export const toReducerOptions = (values) => ({
    legislations: values.legislations,
    caseWorkers: values.caseWorkers,
    frequencies: values.frequencies,
});

export const toReducerFormValues = (values) => ({
    legislationIds: values.legislationIds,
    caseWorkerIds: values.caseWorkerIds,
    startDate: values.startDate,
    endDate: values.endDate,
    frequency: values.frequency
});
