export default (highlightProperties) => {

    highlightProperties = highlightProperties || [];

    const result = {
        title: highlightProperties.includes("Title"),
        forename: highlightProperties.includes("Forename"),
        surname: highlightProperties.includes("Surname"),
        gender: highlightProperties.includes("Gender"),
        nationalityCode: highlightProperties.includes("NationalityCode"),
        relationshipId: highlightProperties.includes("RelationshipId"),
        languageId: highlightProperties.includes("LanguageId"),
        ethnicityId: highlightProperties.includes("EthnicityId"),
        dateOfBirth: highlightProperties.includes("DateOfBirth"),
        dateOfDeath: highlightProperties.includes("DateOfDeath"),
        educationId: highlightProperties.includes("EducationId"),
        homeOfficeReferenceNumber: highlightProperties.includes("HomeOfficeReferenceNumber"),
        visaNumber: highlightProperties.includes("VisaNumber"),
        referenceNumber: highlightProperties.includes("ReferenceNumber") || highlightProperties.includes("LocalAuthorityReferenceNumber"),
        nassReference: highlightProperties.includes("NassReference"),
        passportNumber: highlightProperties.includes("PassportNumber"),
        telephone: highlightProperties.includes("Telephone"),
        email: highlightProperties.includes("Email"),
        isPersonOnLicenseServingACommunity: highlightProperties.includes("IsPersonOnLicenseServingACommunity"),
        isPersonSchedule1Offender: highlightProperties.includes("IsPersonSchedule1Offender"),
        isPartOfMAPPA: highlightProperties.includes("IsPartOfMAPPA"),
        isSubjectToChildCareProceedings: highlightProperties.includes("IsSubjectToChildCareProceedings"),
        isOnChildProtection: highlightProperties.includes("IsOnChildProtection"),
        selectedHealthConditionIds: highlightProperties.includes("SelectedHealthConditionIds")
    };

    result.name = result.title || result.forename || result.surname;

    result.areActive = Object.keys(result).reduce((acc, key) => acc || result[key], false);
    
    return result;
}
