import axios from 'axios';
import moment from 'moment';

import { onFormSubmitAsync } from '../form-submit';

import dateFormatForServer from '../../extensions/date.formatForServer';

const baseUrl = '/api/case/reopen';
const baseUrlWithReferral = '/api/case/reopen-with-referral';
export const urls = {
    addSubmit: `${baseUrl}/add`,
    addWithReferralSubmit: `${baseUrlWithReferral}/add`
}


export const onLoadAsync = async (caseId, source) => {
    try {

        const response = await axios.get(`${baseUrl}/${caseId}`, { cancelToken: source.token });
        const values = response.data || {};

        return {
            isLoaded: true,
            isSuccessful: true,
            initialValues: mappers.toReduxForm(values)
        };
    }
    catch (err) {
        return {
            isLoaded: true,
            isSuccessful: false
        }
    }
}

export const onLoadWithReferralAsync = async (caseId, source) => {
    try {

        const response = await axios.get(`${baseUrlWithReferral}/${caseId}`, { cancelToken: source.token });
        const values = response.data || {};

        return {
            isLoaded: true,
            isSuccessful: true,
            initialValues: mappers.toReduxForm(values)
        };
    }
    catch (err) {
        return {
            isLoaded: true,
            isSuccessful: false
        }
    }
}


export const onAddSubmitAsync = async (formValues) => {
    const serverModel = mappers.toServerModel(formValues);
    return await onFormSubmitAsync(urls.addSubmit, serverModel);
}

export const onAddWithReferralSubmitAsync = async (formValues) => {
    const serverModel = mappers.toServerModel(formValues);
    return await onFormSubmitAsync(urls.addWithReferralSubmit, serverModel);
}


const mappers = {
    toReduxForm: (values) => {
        
        var result = { ...values };

        if (values.dateOfReferral) {
            result.dateOfReferral = moment(values.dateOfReferral)._d
        };
        
        return result;
    },
    toServerModel: (values) => {

        var result = { ...values };

        if (values.dateOfReferral) {
            result.dateOfReferral = dateFormatForServer(values.dateOfReferral);
        }
        
        return result;
    }
}


export default {
    onLoadAsync,
    onLoadWithReferralAsync,
    onAddSubmitAsync,
    onAddWithReferralSubmitAsync
}