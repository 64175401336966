import React, { useEffect, useRef } from "react";
import { Field, Form } from 'react-final-form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlagCheckered } from '@fortawesome/free-solid-svg-icons';

import { renderInput } from '../../../../form-inputs/final-form';

const UpdateForm = ({onSubmit, initialValues}) => {
    
    const formSubmitButton = useRef();

    useEffect(() => {
        var { unsubscribe } = window.dispatcher.subscribe("change-tracker-modal-submit-form", () => formSubmitButton.current.click());

        return () => {
            unsubscribe();
        }
    }, []);

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            render={({ handleSubmit, submitting }) => {

                return (
                    <form onSubmit={handleSubmit} rel="change-tracked-modal">
                        <div className="stripedbox">

                            <div className="mandatory"><span>*</span> Denotes a mandatory field </div>

                            <div className="modal-edit-form">
                                <div className="input-wrapper">
                                    <Field name="housingProviderName" type="text" render={renderInput} label="Housing Provider Name" isMandatory={true} placeholder="" tabIndex={1} />
                                </div>
                                <div className="input-wrapper">
                                    <Field name="contactNo" type="text" render={renderInput} label="Contact No" placeholder="" tabIndex={2} />
                                </div>
                                <div className="input-wrapper">
                                    <Field name="faxNumber" type="text" render={renderInput} label="Fax No" placeholder="" tabIndex={3} />
                                </div>
                                <div className="input-wrapper">
                                    <Field name="email" type="text" render={renderInput} label="Contact email" placeholder="" tabIndex={4} />
                                </div>
                                <div className="input-wrapper">
                                    <Field name="landlordVendorCode" type="text" render={renderInput} label="Landlord Vendor code" placeholder="" tabIndex={5} />
                                </div>

                                <div className="input-wrapper">
                                    <Field name="houseNameNumber" type="text" render={renderInput} label="Address Line 1 / House Name - Number" placeholder="" tabIndex={6} />
                                </div>
                                <div className="input-wrapper">
                                    <Field name="street" type="text" render={renderInput} label="Address Line 2 / Street" placeholder="" tabIndex={7} />
                                </div>
                                <div className="input-wrapper">
                                    <Field name="area" type="text" render={renderInput} label="Address Line 3 / Area - Locality" placeholder="" tabIndex={8} />
                                </div>
                                <div className="input-wrapper">
                                    <Field name="townCity" type="text" render={renderInput} label="Address Line 4 / Town - City" placeholder="" tabIndex={9} />
                                </div>
                                <div className="input-wrapper">
                                    <Field name="county" type="text" render={renderInput} label="Address Line 5 / County" placeholder="" tabIndex={10} />
                                </div>
                                <div className="input-wrapper">
                                    <Field name="postcode" type="text" render={renderInput} label="Postcode" placeholder="" tabIndex={11} />
                                </div>

                            </div>

                            <hr />

                            <div className="modal-edit-form">
                                <div className="full-row align-center">
                                    <div className="form-buttons">
                                        <button type="submit" className="rbtn primary" tabIndex={12} disabled={submitting} ref={formSubmitButton}>
                                            <FontAwesomeIcon icon={faFlagCheckered} />
                                            <span>Save</span>
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </form>
                )
            }}
        />
    )
}


export default UpdateForm;