import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { onGetAllForCaseAsync } from '../../../../api-endpoint-calls/case/closures';

import List from './list';
import { LoadingV1 } from '../../../common';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHistory, faTimes } from '@fortawesome/free-solid-svg-icons';

const defaultState = {
    rows: []
}

export default ({ onClose, caseId }) => {

    const [visible, setVisible] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [successful, setSuccessful] = useState(false);
    const [history, setHistory] = useState(defaultState);

    useEffect(() => {
        const source = axios.CancelToken.source();

        const onPageLoadAsync = async () => {
            const result = await onGetAllForCaseAsync(caseId, source);

            setLoaded(result.isLoaded);
            setSuccessful(result.isSuccessful);
            setHistory(result.data);
        }

        onPageLoadAsync();

        return () => {
            source.cancel();
        }

    }, [caseId, setHistory, setLoaded, setSuccessful]);

    useEffect(() => {
        if (visible === false) {
            setTimeout(() => setVisible(true), 200);
        }
    }, [visible, setVisible]);

    const showSpinner = (!loaded);
    const showFailure = (loaded && !successful);

    const page = showSpinner
        ? <LoadingV1 />
        : showFailure
            ? <h2>An error has occured!</h2>
            : <List rows={history.rows} />;

    return (
        <>
            <div className={`react-modal-anchor ${(visible ? 'show' : '')}`}>
                <div className={`react-modal closure-history ${visible ? 'show' : ''}`}>
                    <button onClick={onClose} className="close-button"><FontAwesomeIcon icon={faTimes} /></button>
                    <h1>
                        <FontAwesomeIcon icon={faHistory} />
                        Closure History
                    </h1>

                    <div className="stripedbox">
                        {page}
                    </div>
                </div>
            </div>
            <div className={`react-modal-backdrop ${visible ? 'show' : ''}`}></div>
        </>
    );
}
