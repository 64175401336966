import axios from 'axios';

import { onFormSubmitAsync } from '../form-submit';

import fileToBase64 from '../../extensions/string.fileToBase64';
import emptyGuid from '../../extensions/guid.empty';

const baseUrl = '/api/case/applicant/document';
export const urls = {
    addSubmit: `${baseUrl}/add`,
    updateSubmit: `${baseUrl}/edit`,
    delete: `${baseUrl}/delete`
}


export const onGetAllForApplicantAsync = async (applicantId) => {

    try {
        const response = await axios.get(`${baseUrl}/${applicantId}`);
        const results = response.data || {};

        return results;
    }
    catch (err) {
        return {};
    }
}


export const onLoadAsync = async (applicantId, documentId, source) => {
    try {

        if (!documentId) {
            documentId = emptyGuid;
        }

        const response = await axios.get(`${baseUrl}/${applicantId}/${documentId}`, { cancelToken: source.token });
        const values = response.data || {};

        return {
            isLoaded: true,
            isSuccessful: true,
            initialValues: mappers.toReduxForm(values)
        };
    }
    catch (err) {
        return {
            isLoaded: true,
            isSuccessful: false
        }
    }
}

export const onDownloadAsync = async (applicantId, documentId) => {
    try {

        if (!documentId) {
            documentId = emptyGuid;
        }

        const response = await axios.get(`${baseUrl}/download/${applicantId}/${documentId}`);
        const values = response.data || {};

        return {
            isSuccessful: true,
            values
        };
    }
    catch (err) {
        return {
            isSuccessful: false
        }
    }
}


export const onUpdateSubmitAsync = async (formValues) => {
    const serverModel = await mappers.toServerModel(formValues);
    const url = (formValues.documentId === emptyGuid) ? urls.addSubmit : urls.updateSubmit;
    return await onFormSubmitAsync(url, serverModel);
}

export const onDeleteSubmitAsync = async (formValues) => {
    var serverModel = mappers.toServerDeleteModel(formValues);
    return await onFormSubmitAsync(urls.delete, serverModel);
}


const mappers = {
    toReduxForm: (values) => ({
        ...values,
        isOriginal: values.isOriginal.toString()
    }),
    toServerModel: async (values) => {
        
        if (values.file) {
            values.fileName = values.file.name;
            values.fileContents = await fileToBase64(values.file);

            delete values.file;
        }

        return {
            ...values,
            isOriginal: JSON.parse(values.isOriginal)
        }
    },
    toServerDeleteModel: (values) => ({
        applicantId: values.applicantId,
        id: values.documentId
    })
}


export default {
    onGetAllForApplicantAsync,
    onLoadAsync,
    onDownloadAsync,
    onUpdateSubmitAsync,
    onDeleteSubmitAsync
}