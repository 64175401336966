import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selector } from '../../../state/reducers/reports/performance-report-reducer';
import * as actions from '../../../state/actions/reports/performance-report-actions';

import Form from './form';
import Results from './results';

import { SimpleLoadingGif } from '../../common/loading';

const Report = () => {

    const initialised = useSelector(state => selector.initialised(state));
    const initialisedSuccessful = useSelector(state => selector.initialisedSuccessful(state));
    const submitting = useSelector((state) => selector.submitting(state));
    const hasResults = useSelector((state) => selector.hasResults(state));

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.initialise());
    }, [dispatch]);


    const showSpinner = (!initialised);
    const showFailure = (initialised && !initialisedSuccessful);

    if (showSpinner) {
        return <SimpleLoadingGif />;
    } else if (showFailure) {
        return <h2>An error has occured!</h2>;
    }

    return (
        <>
            <Form />
            {
                hasResults 
                    ? <Results /> 
                    : submitting 
                        ? <SimpleLoadingGif /> 
                        : null }
        </>
    );
}

export default Report;