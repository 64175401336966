import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { routes } from '../routes';
import * as actions from '../../state/actions/filters-actions';
import { selector } from '../../state/reducers/home-reducer';
import { selector as filtersSelector } from '../../state/reducers/filters-reducer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import { HomePageCountLoadingIcon } from '../common/loading';

const ActivityNotifications = () => {

    const isLoading = useSelector(state => selector.loading(state));
    const activityCaseload = useSelector(state => selector.activityCaseload(state));
    const { isHomeOfficeUser } = useSelector(state => filtersSelector.userDetails(state));

    const dispatch = useDispatch();
    const history = useHistory();

    const onSearchButtonClick = useCallback((value) => {
        dispatch(actions.applyCaseLoadFilter(value));
        history.push(routes.search[0]);
    }, [dispatch, history]);

    const referralNotification = isHomeOfficeUser 
        ? <div></div> 
        : (
            <div className="notification-item">
                <div className="white-box">
                    <span className="notification-text">Number of new households added</span>
                    <span className="notification-count">{isLoading ? <HomePageCountLoadingIcon /> : activityCaseload.referrals}</span>
                </div>
                <button onClick={() => onSearchButtonClick(1)} disabled={isLoading} className="rbtn primary">
                    <FontAwesomeIcon icon={faSearch} /><div>View</div>
                </button>
            </div>
        )

    const newCasesSupportedNotification = isHomeOfficeUser
        ? <div></div>
        : (
            <div className="notification-item">
                <div className="white-box">
                    <span className="notification-text">Number of new financially supported</span>
                    <span className="notification-count">{isLoading ? <HomePageCountLoadingIcon /> : activityCaseload.newCasesSupported}</span>
                </div>
                <button onClick={() => onSearchButtonClick(2)} disabled={isLoading} className="rbtn primary">
                    <FontAwesomeIcon icon={faSearch} /><div>View</div>
                </button>
            </div>
        )

    return (
        <div className="notification-grid">
            <div className="divider-above">{ isHomeOfficeUser ? "" : "How many households added in the last 8 weeks?" }</div>
            <div className="divider-above">{ isHomeOfficeUser ? "Local authority caseload as of today's date" : "How many households are open or financially supported today?" }</div>
            
            { referralNotification }

            <div className="notification-item">
                <div className="white-box">
                    <span className="notification-text">OPEN - Not Financially Supported</span>
                    <span className="notification-count">{isLoading ? <HomePageCountLoadingIcon /> : activityCaseload.openNotFinanciallySupported}</span>
                </div>
                <button onClick={() => onSearchButtonClick(3)} disabled={isLoading} className="rbtn primary">
                    <FontAwesomeIcon icon={faSearch} /><div>View</div>
                </button>
            </div>
            
            { newCasesSupportedNotification }

            <div className="notification-item">
                <div className="white-box">
                    <span className="notification-text">OPEN - Financially Supported</span>
                    <span className="notification-count">{isLoading ? <HomePageCountLoadingIcon /> : activityCaseload.openFinanciallySupported}</span>
                </div>
                <button onClick={() => onSearchButtonClick(4)} disabled={isLoading} className="rbtn primary">
                    <FontAwesomeIcon icon={faSearch} /><div>View</div>
                </button>
            </div>

            <div></div>

            <div className="notification-item">
                <div className="white-box">
                    <span className="notification-text">OPEN - 1000 day 'priority' cases</span>
                    <span className="notification-count">{isLoading ? <HomePageCountLoadingIcon /> : activityCaseload.openThousandDayCases}</span>
                </div>
                <button onClick={() => onSearchButtonClick(5)} disabled={isLoading} className="rbtn primary">
                    <FontAwesomeIcon icon={faSearch} /><div>View</div>
                </button>
            </div>
        </div>
    )
};

export default ActivityNotifications;
