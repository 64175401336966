import React from "react";
import { Field } from "react-final-form";

import { renderDropdownList, renderDatePicker, renderInput } from "../../form-inputs/final-form";

import ImpairmentGroups from "../applicants/impairment-groups";

const ApplicantFormFields = ({
    form,
    selectedHealthConditionIds,
    age,
    today,
    impairmentGroups,
    gendersOptions,
    nationalitiesOptions,
    languagesOptions,
    ethnicitiesOptions,
    disabled
}) => {

    return (
        <div className="dependant-edit-wrapper">
            <div className="stripedbox page-header">
                <h2>1 / 2 : Add Principal Applicant details (lead adult)</h2>
                <div className="mandatory-text"><span>*</span> Denotes a mandatory field </div>
            </div>
            <div className="dependant-grid-wrapper">
                <div className="stripedbox left-column">
                    <div className="dependant-edit-form">
                        <h2>Personal Details</h2>

                        <div className="input-wrapper">
                            <Field
                                name="forename"
                                type="text"
                                render={renderInput}
                                label="Forename"
                                isMandatory={true}
                                placeholder=""
                                disabled={disabled}
                            />
                        </div>
                        <div className="input-wrapper">
                            <Field
                                name="surname"
                                type="text"
                                render={renderInput}
                                label="Surname"
                                isMandatory={true}
                                placeholder=""
                                disabled={disabled}
                            />
                        </div>
                        <div className="input-wrapper">
                            <label>
                                Gender <span className="mandatory-stars">*</span>
                            </label>
                            <Field
                                name="gender"
                                type="text"
                                render={renderDropdownList}
                                options={gendersOptions}
                                disabled={disabled}
                            />
                        </div>
                        <div className="input-wrapper">
                            <label>
                                Nationality <span className="mandatory-stars">*</span>
                            </label>
                            <Field
                                name="nationalityCode"
                                type="text"
                                render={renderDropdownList}
                                options={nationalitiesOptions}
                                disabled={disabled}
                            />
                        </div>
                        <div className="input-wrapper">
                            <label>Language</label>
                            <Field
                                name="languageId"
                                type="text"
                                render={renderDropdownList}
                                options={languagesOptions}
                                disabled={disabled}
                            />
                        </div>
                        <div className="input-wrapper">
                            <label>Ethnicity</label>
                            <Field
                                name="ethnicityId"
                                type="text"
                                render={renderDropdownList}
                                options={ethnicitiesOptions}
                                disabled={disabled}
                            />
                        </div>

                        <hr />

                        <h2>Date of Birth & Age</h2>

                        <div className="input-wrapper">
                            <Field
                                name="dateOfBirth"
                                type="text"
                                render={renderDatePicker}
                                isMandatory={true}
                                label="Date of Birth"
                                placeholder=""
                                maxDate={today}
                                disabled={disabled}
                            />
                        </div>
                        <div className="input-wrapper">
                            <label>Age</label>
                            <div className="fake-input">{age}</div>
                        </div>

                        <hr />

                        <ImpairmentGroups
                            form={form}
                            selectedHealthConditionIds={selectedHealthConditionIds}
                            impairmentGroups={impairmentGroups}
                            disabled={disabled}
                        />
                    </div>
                </div>
                <div className="stripedbox right-column">
                    <div className="dependant-edit-form">

                        <h2>Contact Information</h2>

                        <div className="input-wrapper">
                            <Field
                                name="telephone"
                                type="text"
                                render={renderInput}
                                label="Telephone"
                                placeholder=""
                                disabled={disabled}
                            />
                        </div>
                        <div className="input-wrapper">
                            <Field
                                name="email"
                                type="text"
                                render={renderInput}
                                label="Email"
                                placeholder=""
                                disabled={disabled}
                            />
                        </div>

                        <hr />

                        <h2>Reference Numbers</h2>

                        <div className="input-wrapper">
                            <Field
                                name="referenceNumber"
                                type="text"
                                render={renderInput}
                                label="LA Reference Number"
                                placeholder=""
                                disabled={disabled}
                            />
                        </div>
                        <div className="input-wrapper">
                            <Field
                                name="homeOfficeReferenceNumber"
                                type="text"
                                render={renderInput}
                                label="Home Office Reference"
                                placeholder=""
                                disabled={disabled}
                            />
                        </div>
                        <div className="input-wrapper">
                            <Field
                                name="visaNumber"
                                type="text"
                                render={renderInput}
                                label="Home Office Person Id"
                                placeholder=""
                                disabled={disabled}
                            />
                        </div>
                        <div className="input-wrapper">
                            <Field
                                name="nassReference"
                                type="text"
                                render={renderInput}
                                label="NASS Reference Number"
                                placeholder=""
                                disabled={disabled}
                            />
                        </div>
                        <div className="input-wrapper">
                            <Field
                                name="uniqueAppNumber"
                                type="text"
                                render={renderInput}
                                label="Unique Application Number"
                                placeholder=""
                                disabled={disabled}
                            />
                        </div>

                        <hr />

                        <h2>
                            Complete one field below for digital status check
                            purposes
                        </h2>

                        <div className="input-wrapper">
                            <Field
                                name="brpNumber"
                                type="text"
                                render={renderInput}
                                label="BRP Number"
                                placeholder=""
                                disabled={disabled}
                            />
                        </div>
                        <div className="input-wrapper">
                            <Field
                                name="brcNumber"
                                type="text"
                                render={renderInput}
                                label="BRC Number"
                                placeholder=""
                                disabled={disabled}
                            />
                        </div>
                        <div className="input-wrapper">
                            <Field
                                name="passportNumber"
                                type="text"
                                render={renderInput}
                                label="Passport Number"
                                placeholder=""
                                disabled={disabled}
                            />
                        </div>
                        <div className="input-wrapper">
                            <Field
                                name="euIdentityCardNumber"
                                type="text"
                                render={renderInput}
                                label="European National Identity Card Number"
                                placeholder=""
                                disabled={disabled}
                            />
                        </div>
                        <div className="input-wrapper">
                            <Field
                                name="ninoNumber"
                                type="text"
                                render={renderInput}
                                label="NINO Number"
                                placeholder=""
                                disabled={disabled}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default ApplicantFormFields;
