import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPoundSign, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

export default ({onClose, onSelection}) => {

    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (visible === false) {
            setTimeout(() =>  setVisible(true), 200);
        }
    }, [visible, setVisible]);

    const onYesClick = (e) => {
        e.preventDefault();
        onSelection(true);
    }

    const onNoClick = (e) => {
        e.preventDefault();
        onSelection(false);
    }

    return (
        <>
            <div className={ `react-modal-anchor ${(visible ? 'show' : '')}`}>
                <div className={ `react-modal user-delete ${visible ? 'show' : ''}` } style={{ width: '500px' }}>
                    <button onClick={onClose} className="close-button"><FontAwesomeIcon icon={faTimes} /></button>
                    <h1>
                        <FontAwesomeIcon icon={faPoundSign} />
                        Finance for address
                    </h1>

                    <div className="stripedbox">
                        <form autoComplete="off">

                            <p>Do you wish to add finance now?</p>

                            <div className="button-row p-15">
                                <button onClick={onYesClick} className="rbtn primary"><FontAwesomeIcon icon={faCheck} /><span>Yes</span></button>
                                <button onClick={onNoClick} className="rbtn primary"><FontAwesomeIcon icon={faTimes} /><span>No</span></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={ `react-modal-backdrop ${visible ? 'show' : ''}` }></div>
        </>
    );
    
}