import React, { useEffect, useRef } from "react";
import { Field, Form } from 'react-final-form';

import calculateAge from '../../../../extensions/date.calculateAge';
import { renderDropdownList, renderDatePicker, renderInput, toSortedIntOptions } from '../../../form-inputs/final-form';

import Aliases from "../aliases";

const defaultOptions = {
    genders: [],
    nationalities: [],
    languages: [],
    ethnicities: [],
    yesNoOptions: []
}

const AddForm = ({onSubmit, initialValues, aliases, 
    onEditAliasModalRequest, onDeleteAliasModalRequest }) => {
    
    const formSubmitButton = useRef();

    useEffect(() => {
        var { unsubscribe } = window.dispatcher.subscribe("change-tracker-submit-form", () => formSubmitButton.current.click());

        return () => {
            unsubscribe();
        }
    }, []);


    const { 
        genders, 
        nationalities, 
        languages 
    } = initialValues.options || defaultOptions;

    const gendersOptions = genders;
    const nationalitiesOptions = nationalities;
    const languagesOptions = toSortedIntOptions(languages);


    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            render={({ handleSubmit, values }) => {

                const { dateOfBirth, title, forename, surname } = values;
                const disabled = true;
                const age = calculateAge(dateOfBirth);
                const name = `${title || ''} ${forename || ''} ${surname || ''}`.trim();

                return (
                    <form onSubmit={handleSubmit}>
                        <div className="dependant-edit-wrapper">
                            <div className="stripedbox">
                                <div className="mandatory"><span>*</span> Denotes a mandatory field </div>
                            </div>
                            <div className="dependant-grid-wrapper">
                                <div className="stripedbox left-column">
                                    <div className="dependant-edit-form">

                                        <h2>Personal Details</h2>

                                        <div className="input-wrapper">
                                            <label>Name</label>
                                            <span className="fake-input">{name}</span>
                                        </div>
                                        <div className="input-wrapper">
                                            <label>Gender</label>
                                            <Field name="gender" type="text" render={renderDropdownList} options={gendersOptions} disabled={disabled} />
                                        </div>
                                        <div className="input-wrapper">
                                            <label>Nationality</label>
                                            <Field name="nationalityCode" type="text" render={renderDropdownList} options={nationalitiesOptions} disabled={disabled} />
                                        </div>
                                        <div className="input-wrapper">
                                            <label>Language</label>
                                            <Field name="languageId" type="text" render={renderDropdownList} options={languagesOptions} disabled={disabled} />
                                        </div>

                                        <hr />

                                        <h2>Date of Birth & Age</h2>

                                        <div className="input-wrapper">
                                            <Field name="dateOfBirth" type="text" render={renderDatePicker} label="Date of Birth" placeholder="" disabled={disabled} />
                                        </div>
                                        <div className="input-wrapper">
                                            <label>Age</label>
                                            <div className="fake-input">
                                                { age }
                                            </div>
                                        </div>
                                        <div className="input-wrapper">
                                            <Field name="dateOfDeath" type="text" render={renderDatePicker} label="Date of Death" placeholder="" disabled={disabled} />
                                        </div>
                                        
                                    </div>

                                </div>
                                <div className="right-column">
                                    <div className="stripedbox">
                                        <div className="dependant-edit-form">
                                            
                                            <h2>Reference Numbers</h2>

                                            <div className="input-wrapper">
                                                <Field name="referenceNumber" type="text" render={renderInput} label="LA Reference Number" placeholder="" disabled={disabled} />
                                            </div>
                                            <div className="input-wrapper">
                                                <Field name="homeOfficeReferenceNumber" type="text" render={renderInput} label="Home Office Reference" placeholder="" disabled={disabled} />
                                            </div>
                                            <div className="input-wrapper">
                                                <Field name="visaNumber" type="text" render={renderInput} label="PERSON ID" placeholder="" disabled={disabled} />
                                            </div>
                                            <div className="input-wrapper">
                                                <Field name="nassReference" type="text" render={renderInput} label="NASS Reference Number" placeholder="" disabled={disabled} />
                                            </div>
                                            <div className="input-wrapper">
                                                <Field name="uniqueAppNumber" type="text" render={renderInput} label="Unique Application Number" placeholder="" disabled={disabled} />
                                            </div>
                                            
                                            <hr />

                                            <Aliases aliases={aliases} disabled={disabled} onEditAliasModalRequest={onEditAliasModalRequest} onDeleteAliasModalRequest={onDeleteAliasModalRequest} applicantName={name} applicantDateOfBirth={dateOfBirth} />

                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </form>
                )
            }}
        />
    )
}


export default AddForm;
