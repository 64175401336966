import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { isClosed } from '../../server-values';
import { onLoadWithReferralAsync, onAddWithReferralSubmitAsync } from '../../../../api-endpoint-calls/case/reopens';

import ReopenWithReferralForm from './form';
import EditReferralOrganisationModal from '../../referral/referral-organisation/edit';
import DeleteReferralOrganisationModal from '../../referral/referral-organisation/delete';

import useListState from '../../use-list-state';
import useNoteState from '../../notes/use-note-state';

import { errorNotification, LoadingV1 } from '../../../common';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faUndo } from '@fortawesome/free-solid-svg-icons';

export default ({ onClose, caseId }) => {

    const [visible, setVisible] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [successful, setSuccessful] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const [initialValues, setInitialValues] = useState({
        caseId
    });

    const {
        items: referralOrganisations,
        setItems: setReferralOrganisations,
        onEditModalRequest: onEditReferralOrganisationModalRequest,
        editModal: editReferralOrganisationModal,
        deleteModal: deleteReferralOrganisationModal
    } = useListState(
        0,
        (id, onClose, onDeleteRequest) => <EditReferralOrganisationModal onClose={onClose} referralOrganisationId={id} disabled={isClosed} onDeleteModalRequest={onDeleteRequest} />,
        (id, onClose) => <DeleteReferralOrganisationModal onClose={onClose} referralOrganisationId={id} disabled={isClosed} />
    )

    const {
        item: referralNote,
        setItem: setReferralNote,
        onAddModalRequest: onAddReferralNoteModalRequest,
        onHistoryModalRequest: onReferralNoteHistoryModalRequest,
        addModal: addReferralNoteModal,
        historyModal: ReferralNoteHistoryModal
    } = useNoteState()


    useEffect(() => {
        const source = axios.CancelToken.source();

        const onPageLoadAsync = async () => {
            const result = await onLoadWithReferralAsync(caseId, source);

            setLoaded(result.isLoaded);
            setSuccessful(result.isSuccessful);

            if (result.initialValues) {
                setInitialValues(result.initialValues);
                setReferralOrganisations(result.initialValues.options.referralOrganisations);
                setReferralNote(result.initialValues.note);
            }
        }

        onPageLoadAsync();

        return () => {
            source.cancel();
        }

    }, [caseId, setLoaded, setSuccessful, setInitialValues, setReferralOrganisations, setReferralNote]);


    useEffect(() => {
        if (visible === false) {
            setTimeout(() => setVisible(true), 200);
        }
    }, [visible, setVisible]);

    useEffect(() => {
        var { unsubscribe } = window.dispatcher.subscribe("change-tracker-modal-close", () => onClose());

        return () => {
            unsubscribe();
        }
    }, [onClose]);


    const onCloseClick = () => {
        window.dispatcher.dispatchEvent("change-tracker-modal-closing");
    }

    const onFormSubmit = async (formValues) => {
        const result = await onAddWithReferralSubmitAsync(formValues);

        if (result.isSuccess) {
            setErrorMessage(null);
            window.dispatcher.dispatchEvent("change-tracker-modal-reset");

            onClose("Case reopened and Home Office notified.  Please proceed to update address and finance information as required");
        } else {
            setErrorMessage(result.message);
        }

        return result.errors;
    };

    const errorAlert = errorNotification(errorMessage);

    const showSpinner = (!loaded);
    const showFailure = (loaded && !successful);

    const contents = showSpinner
        ? <LoadingV1 />
        : showFailure
            ? <h2>An error has occured!</h2>
            : (
                <>
                    {errorAlert}
                    <ReopenWithReferralForm
                        onSubmit={onFormSubmit}
                        initialValues={initialValues}
                        referralNote={referralNote}
                        onAddReferralNoteModalRequest={onAddReferralNoteModalRequest}
                        onReferralNoteHistoryModalRequest={onReferralNoteHistoryModalRequest}
                        referralOrganisations={referralOrganisations}
                        onEditReferralOrganisationModalRequest={onEditReferralOrganisationModalRequest}
                    />
                </>
            )


    return (
        <>
            <div className={`react-modal-anchor ${(visible ? 'show' : '')}`}>
                <div className={`react-modal reopen-referral ${visible ? 'show' : ''}`}>
                    <button onClick={onCloseClick} className="close-button"><FontAwesomeIcon icon={faTimes} /></button>
                    <h1>
                        <FontAwesomeIcon icon={faUndo} />
                        Reopen the case
                    </h1>

                    <div className="stripedbox">
                        {contents}

                        {editReferralOrganisationModal}
                        {deleteReferralOrganisationModal}
                        {addReferralNoteModal}
                        {ReferralNoteHistoryModal}
                    </div>
                </div>
            </div>
            <div className={`react-modal-backdrop ${visible ? 'show' : ''}`}></div>
        </>
    );

}