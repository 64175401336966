import React, { useState, useEffect } from 'react';

import { onLoad, onSubmit } from '../../../../../api-endpoint-calls/case/housing-provider';

import Form from './form';

import { LoadingV1 } from '../../../../common/loading';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faTimes } from '@fortawesome/free-solid-svg-icons';

export default ({onClose, housingProviderId}) => {

    const defaultLoadingState = (!housingProviderId || housingProviderId === 0);

    const [visible, setVisible] = useState(false);
    const [loaded, setLoaded] = useState(defaultLoadingState);
    const [successful, setSuccessful] = useState(defaultLoadingState);
    const [initialValues, setInitialValues] = useState({ housingProviderId });

    useEffect(() => {
        if (visible === false) {
            setTimeout(() =>  setVisible(true), 200);
        }
    }, [visible, setVisible]);

    useEffect(() => {
        if (housingProviderId > 0) {
            onLoad(housingProviderId, setInitialValues, setLoaded, setSuccessful);
        }
    }, [housingProviderId, setInitialValues, setLoaded, setSuccessful]);

    useEffect(() => {
        var { unsubscribe } = window.dispatcher.subscribe("change-tracker-modal-close", () => onClose());

        return () => {
            unsubscribe();
        }
    }, [onClose]);

    
    const onCloseClick = () => {
        window.dispatcher.dispatchEvent("change-tracker-modal-closing");
    }

    const onFormSubmit = (formValues) => {
        return onSubmit(formValues, onClose);
    };

    const showSpinner = (!loaded);
    const showFailure = (loaded && !successful);

    const modalBody = showSpinner
        ? <LoadingV1 />
        : showFailure
            ? <h2>An error has occured!</h2>
            : <Form onSubmit={onFormSubmit} initialValues={initialValues} />;

    return (
        <>
            <div className={ `react-modal-anchor ${(visible ? 'show' : '')}`}>
                <div className={ `react-modal housing-providers ${visible ? 'show' : ''}` }>
                    <button onClick={onCloseClick} className="close-button"><FontAwesomeIcon icon={faTimes} /></button>
                    <h1>
                        <FontAwesomeIcon icon={faHome} />
                        Add New Housing Provider
                    </h1>

                    { modalBody }
                </div>
            </div>
            <div className={ `react-modal-backdrop ${visible ? 'show' : ''}` }></div>
        </>
    );
    
}