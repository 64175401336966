import { actions } from '../../actions/search-form/by-personal-details';

const initialState = {
    formValues: {
        name: '',
        dateOfBirth: null,
        reference: ''
    },
    canPerformFind: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.setFormValues:
            return {
                ...state,
                formValues: {
                    ...state.formValues,
                    ...action.data.byPersonalDetailsFormValues
                },
                canPerformFind: action.data.canPerformFind
            };
        case actions.reset:
            return {
                ...initialState
            };
        default:
            return state;
    }
}

export const selector = {
    state: (s) => s.searchByPersonalDetails,
    formValues: (state) => state.searchByPersonalDetails.formValues
}