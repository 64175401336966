import * as actions from '../../actions/superuser/users-list-actions';

const updateUserStatus = (state, action) => {

  const publicId = action.data.userId;
  const originalRow = state.results[publicId];

  const newRow = {
    ...originalRow,
    isLocked: action.data.isLocked
  };

  const newResults = { ...state.results };
  newResults[publicId] = newRow;

  return {
    ...state,
    results: newResults,
    resultValues: Object.values(newResults)
  }
}

const userDeleteInitialise = (state, action) => {
  return {
      ...state, 
      modals: {
        ...state.modals,
        userDelete: initialState.modals.userDelete
      }
  };
}

const userDeleteStarted = (state, action) => {
  let userDelete = {
    ...state.modals.userDelete,
    processing: true, 
    completed: false, 
    successful: false
  };

  return { ...state, modals: { ...state.modals, userDelete }};
}

const userDeleteSuccess = (state, action) => {
  let userDelete = {
    ...state.modals.userDelete,
    processing: false, 
    completed: true, 
    successful: true
  };

  return { ...state, modals: { ...state.modals, userDelete }};
}

const userDeleteFailed = (state, action) => {
  let userDelete = {
    ...state.modals.userDelete,
    processing: false, 
    completed: true, 
    successful: false
  };

  return { ...state, modals: { ...state.modals, userDelete }};
}

const userResetPasswordInitialise = (state, action) => {
  return {
      ...state, 
      modals: {
        ...state.modals,
        userResetPassword: initialState.modals.userResetPassword
      }
  };
}

const userResetPasswordStarted = (state, action) => {
  let userResetPassword = {
    ...state.modals.userResetPassword,
    processing: true, 
    completed: false, 
    successful: false
  };

  return { ...state, modals: { ...state.modals, userResetPassword }};
}

const userResetPasswordSuccess = (state, action) => {
  let userResetPassword = {
    ...state.modals.userResetPassword,
    processing: false, 
    completed: true, 
    successful: true
  };

  return { ...state, modals: { ...state.modals, userResetPassword }};
}

const userResetPasswordFailed = (state, action) => {
  let userResetPassword = {
    ...state.modals.userResetPassword,
    processing: false, 
    completed: true, 
    successful: false
  };

  return { ...state, modals: { ...state.modals, userResetPassword }};
}

const initialState = {
  results: {},
  resultValues: [],
  organisationUsers: {},
  pageIndex: 0,
  pageSize: 100,
  sortBy: [{
    id: 'fullName', 
    desc: false
  }],
  filters: [],
  loading: false,
  modals: {
    userDelete: {
      processing: false,
      completed: false,
      successful: false
    },
    userResetPassword: {
      processing: false,
      completed: false,
      successful: false
    },
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.SEARCH_START:
      return { 
        ...state, 
        results: {},
        resultValues: [],
        organisationUsers: {},
        loading: true, 
      };
    case actions.SEARCH_SUCCESS:
      return { 
        ...state, 
        results: action.data.users || {},
        resultValues: action.data && action.data.users ? Object.values(action.data.users) : [],
        organisationUsers: action.data.organisationUsers || {},
        loading: false 
      };
    case actions.SEARCH_FAILED:
      return { ...state, loading: false };
    case actions.SET_PAGE:
      return { 
        ...state, 
        pageIndex: action.data.pageIndex, 
        pageSize: action.data.pageSize 
      };
    case actions.SET_SORT:
      return { 
        ...state,
        sortBy: action.data
      };
    case actions.SET_FILTERS:
      return {
        ...state,
        filters: action.data
      };
    case actions.SET_USER_STATUS:
      return updateUserStatus(state, action);

    case actions.MODALS_USERDELETE_INITALISE:
      return userDeleteInitialise(state, action);
    case actions.MODALS_USERDELETE_START:
      return userDeleteStarted(state, action);
    case actions.MODALS_USERDELETE_SUCCESS:
      return userDeleteSuccess(state, action);
    case actions.MODALS_USERDELETE_FAILED:
      return userDeleteFailed(state, action);

    case actions.MODALS_USERRESETPASSWORD_INITALISE:
      return userResetPasswordInitialise(state, action);
    case actions.MODALS_USERRESETPASSWORD_START:
      return userResetPasswordStarted(state, action);
    case actions.MODALS_USERRESETPASSWORD_SUCCESS:
      return userResetPasswordSuccess(state, action);
    case actions.MODALS_USERRESETPASSWORD_FAILED:
      return userResetPasswordFailed(state, action);
  
    default:
        return state;
  }
}


export const selector = {
  results: (state) => state.superuserUsers.results,
  resultValues: (state) => state.superuserUsers.resultValues,
  hasResults: (state) => Object.keys(state.superuserUsers.results).length > 0,
  pageSize: (state) => state.superuserUsers.pageSize,
  pageIndex: (state) => state.superuserUsers.pageIndex,
  sortBy: (state) => state.superuserUsers.sortBy,
  filters: (state) => state.superuserUsers.filters,
  loading: (state) => state.superuserUsers.loading,
  userDelete: (state) => state.superuserUsers.modals.userDelete,
  userResetPassword: (state) => state.superuserUsers.modals.userResetPassword,
  organisationUsers: (organisation, userId) => 
    (state) => filteredOrganisationResults(state.superuserUsers.results, state.superuserUsers.organisationUsers[organisation], userId)
};

const filteredOrganisationResults = (allUsers, organisationUserIds, userId) => {

  const otherOrganisationUsers = organisationUserIds.filter(u => u !== userId);

  const result = otherOrganisationUsers.map(x => allUsers[x]);

  return result;
}