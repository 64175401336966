import React, { useState } from "react";

import CloseAlertAdviseModal from "./close-alert-advise-modal";


export const AddressChangedAlert = ({alert}) => (
    <div className="alert-row">
        <span className="header">
            <span className="title">
                {alert.alertTypeName}
            </span>
            <span className="days">
                ({alert.daysOpen} days)
            </span>
        </span>
        <a href={`/LIT/Address/History?caseId=${alert?.caseId}`} className="view-alert-link">View Alert</a>
    </div>
)

export const CaseClosedAlert = ({alert, endDate, closureReason, onCloseAlertClick}) => (
    <div className="alert-row with-rows">
        <div className="alert-row">
            <span className="header">
                <span className="title bold-purple">
                    Case Closed
                </span>
                {
                    alert.dateClosed === null 
                        ? (
                            <>
                                <span className={`days ${alert.daysOpen < 7 ? "green" : "red"}`}>
                                    ({alert.daysOpen} days)
                                </span>
                                <button type="button" className="rbtn link small" onClick={onCloseAlertClick}>[ Close Alert ]</button>
                            </>
                        )
                        : null
                }
            </span>
        </div>
        <div className="list">
            <div className="label">
                End Date
            </div>
            <div className="value">
                <span>{endDate}</span>
            </div>
            <div className="label">
                Reason
            </div>
            <div className="value">
                <span style={{fontSize:"12px"}}>{closureReason}</span>
            </div>
        </div>
    </div>
)


export const StatusCheckAlert = ({alert, nextDependantLink}) => {
    
    const [showCloseAlertModal, setShowCloseAlertModal] = useState(false);

    const onCloseAlertClick = () => {
        setShowCloseAlertModal(true);
    }
    const onCloseAlertClose = () => {
        setShowCloseAlertModal(false);
    }

    const Modal = showCloseAlertModal
        ? <CloseAlertAdviseModal onClose={onCloseAlertClose} /> 
        : null;

    const principalApplicantLink = alert.applicantDetailsChecked
        ? <span>Principal Applicant Details Checked</span>
        : <a href={`/LIT/Applicant/Edit?iCaseID=${alert.caseId}`}>Principal Applicant Details Checked</a>;

    const dependantLink = alert.dependantsDetailsChecked
        ? <span>Dependant Details Checked</span>
        : <a href={`/LIT/Dependant?CaseId=${alert.caseId}#/Edit/${nextDependantLink}`}>Dependant Details Checked</a>;

    const immigrationLink = alert.immigrationStatusChecked
        ? <span>Immigration Details Checked</span>
        : <a href={`/LIT/Immigration/Create?iCaseID=${alert.caseId}`}>Immigration Details Checked</a>;

    return (
        <>
            <div className="alert-row with-rows">
                <div className="alert-row">
                    <span className="header">
                        <span className="title bold-purple">
                            {alert.alertTypeName}
                        </span>
                        {
                            alert.dateClosed === null 
                                ? (
                                    <span className={`days ${alert.daysOpen < 7 ? "green" : "red"}`}>
                                        ({alert.daysOpen} days)
                                    </span>
                                )
                                : null
                        }
                        <button type="button" className="rbtn link small" onClick={onCloseAlertClick}>[ Close Alert ]</button>
                    </span>
                </div>

                <div className="check-list">
                    <div className="check-list-item">
                        { principalApplicantLink }
                        <span className={alert.applicantDetailsChecked ? 'tick' : 'cross'}></span>
                    </div>
                    <div className="check-list-item">
                        { dependantLink }
                        <span className={alert.dependantsDetailsChecked ? 'tick' : 'cross'}></span>
                    </div>
                    <div className="check-list-item">
                        { immigrationLink }
                        <span className={alert.immigrationStatusChecked ? 'tick' : 'cross'}></span>
                    </div>
                </div>
            </div>
            { Modal }
        </>
    );
}