import axios from 'axios';
import moment from 'moment';

import dateFormatForServer from '../../extensions/date.formatForServer';
import emptyGuid from '../../extensions/guid.empty';

const baseUrl = '/api/case/address';

export const onGetAllForCase = async (caseId) => {

    try {
        const response = await axios.post(`${baseUrl}/${caseId}`);
        const results = response.data.addresses || [];

        return results;
    }
    catch (err) {
        return {};
    }
}


export const onLoad = async (caseId, addressId) => {
    try {

        if (!addressId) {
            addressId = emptyGuid;
        }

        const response = await axios.get(`${baseUrl}/${caseId}/${addressId}`);
        const values = response.data || {};

        return {
            isLoaded: true,
            isSuccessful: true,
            initialValues: mappers.toReduxForm(values)
        };
    }
    catch (err) {
        return {
            isLoaded: true,
            isSuccessful: false
        }
    }
}


export const onUpdateSubmit = async (formValues) => {

    const result = {
        isSuccess: false,
        errors: {}
    };

    var serverModel = mappers.toServerModel(formValues);

    const url = (formValues.id === emptyGuid)
        ? `${baseUrl}/add`
        : `${baseUrl}/edit`;


    const validateResponse = await axios.post(`${url}/validate`, serverModel);

    if (Object.keys(validateResponse.data).length) {
        return { ...result, errors: validateResponse.data };
    }

    
    try {
        const response = await axios.post(url, serverModel);
        const serverResult = response.data;
        
        return { ...result, isSuccess: true, addressId: serverResult };
    }
    catch (err) {
        result.errors = {
            axios: err
        };
    }

    return result;
}

export const onDeleteSubmit = async (formValues) => {

    var serverModel = mappers.toServerDeleteModel(formValues);

    const url = `${baseUrl}/delete`;

    try {
        await axios.post(url, serverModel);
    }
    catch (err) {
    }
}


export const onPostcodeLookup = async (postcode, nameOrNumber) => {

    try {
        let url = `${baseUrl}/postcode-lookup/${postcode}`
        
        if (nameOrNumber) {
            url = `${url}/${nameOrNumber}`
        }

        const response = await axios.post(url);
        const results = response.data || [];

        return results;
    }
    catch (err) {
        return [];
    }
}


export const onAlertAccept = async (caseId) => {

    try {
        let url = `${baseUrl}/accept-alert/${caseId}`;
        
        await axios.post(url);
    }
    catch (err) {
    }

}


const mappers = {
    toReduxForm: (values) => ({
        accommodationTypeId: values.accommodationTypeId?.toString(),
        accommodationTypes: values.accommodationTypes,
        addressId: values.addressId,
        area: values.area,
        caseId: values.caseId,
        changeTrackingName: values.changeTrackingName,
        county: values.county,
        dateMovedIn: values.dateMovedIn ? moment(values.dateMovedIn)._d : null,
        dateMovedOut: values.dateMovedOut ? moment(values.dateMovedOut)._d : null,
        finance: values.finance,
        fullAddress: values.fullAddress,
        hasFinanceAgainstProperty: values.hasFinanceAgainstProperty,
        hasOpenFinanceAgainstProperty: values.hasOpenFinanceAgainstProperty,
        houseNameNumber: values.houseNameNumber,
        housingProviderId: values.housingProviderId?.toString(),
        housingProviders: values.housingProviders,
        id: values.id,
        isActive: values.isActive,
        isAddingFinanceAfterAddingAddress: values.isAddingFinanceAfterAddingAddress,
        isClosingFinanceAfterUpdatingAddress: values.isClosingFinanceAfterUpdatingAddress,
        isFromPreviousClosure: values.isFromPreviousClosure,
        isSharedWithHomeOffice: values.isSharedWithHomeOffice.toString(),
        isStayingWithFriendFamily: values.isStayingWithFriendFamily.toString(),
        isSwitchedToManualEntry: values.isSwitchedToManualEntry,
        isVoided: values.isVoided,
        otherAddressExist: values.otherAddressExist,
        postcode: values.postcode,
        propertyDescription: values.propertyDescription,
        propertyDescriptions: values.propertyDescriptions,
        street: values.street,
        townCity: values.townCity,
    }),
    toServerModel: (values) => {
        
        const hasOpenFinanceAgainstProperty = JSON.parse(values.hasOpenFinanceAgainstProperty);
        const isActive = JSON.parse(values.isActive);
        const isAddingFinanceAfterAddingAddress = JSON.parse(values.isAddingFinanceAfterAddingAddress);
        const isClosingFinanceAfterUpdatingAddress = JSON.parse(values.isClosingFinanceAfterUpdatingAddress);
        const isFromPreviousClosure = JSON.parse(values.isFromPreviousClosure);
        const isSharedWithHomeOffice = JSON.parse(values.isSharedWithHomeOffice);
        const isStayingWithFriendFamily = JSON.parse(values.isStayingWithFriendFamily);
        const isSwitchedToManualEntry = JSON.parse(values.isSwitchedToManualEntry);
        const isVoided = JSON.parse(values.isVoided);
        const otherAddressExist = JSON.parse(values.otherAddressExist);

        const parsedAccommodationTypeId = parseInt(values.accommodationTypeId);
        const parsedHousingProviderId = parseInt(values.housingProviderId);

        return {
            ...values,
            accommodationTypeId: parsedAccommodationTypeId === 0 ? null : parsedAccommodationTypeId,
            dateMovedIn: dateFormatForServer(values.dateMovedIn),
            dateMovedOut: dateFormatForServer(values.dateMovedOut),
            housingProviderId: parsedHousingProviderId === 0 ? null : parsedHousingProviderId,
            hasOpenFinanceAgainstProperty,
            isActive,
            isAddingFinanceAfterAddingAddress,
            isClosingFinanceAfterUpdatingAddress,
            isFromPreviousClosure,
            isSharedWithHomeOffice,
            isStayingWithFriendFamily,
            isSwitchedToManualEntry,
            isVoided,
            otherAddressExist
        }
    },
    toServerDeleteModel: (values) => ({
        caseId: values.caseId,
        id: values.addressId
    })
}


export default {
    onGetAllForCase,
    onLoad,
    onUpdateSubmit,
    onDeleteSubmit,
    onPostcodeLookup
}