import React from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpenText, faExclamationTriangle, faCheck } from '@fortawesome/free-solid-svg-icons';

const defaultState = {
    rows: []
}

const QueryWidget = ({queries, disabled, onAddQueryModalRequest, onViewQueryModalRequest, fromSummary = false}) => {

    const state = queries || defaultState;

    const onAddClick = () => {
        onAddQueryModalRequest();
    }

    const onHistoryClick = (id) => {
        onViewQueryModalRequest(id);
    }
    
    const addButton = !disabled
        ? <button type="button" onClick={onAddClick} className="rbtn primary small">Add Query</button>
        : null;

    const header = fromSummary
        ? (
            <div className="queries-control-title">
                <span className="title">
                    <FontAwesomeIcon icon={faEnvelopeOpenText} />
                    <span>Queries</span>
                </span>
                <span>
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                    <span className="small"> = Reply or Complete</span>
                </span>
                { addButton }
            </div>
        )
        : (
            <div className="header-with-button">
                <h2>Queries</h2>
                <span style={{lineHeight: "30px"}}>
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                    <span className="small"> = Reply or Complete</span>
                </span>
                { addButton }
            </div>
        )

    return (
        <div>
            { header }

            <div className={`queries-control ${fromSummary ? "stripedbox" : ""}`}>
                <ul className="vertical-scroll">
                    
                {
                    state.rows.map(query => (

                        <li key={query.caseQueryId} className={`query-row ${query.isClosed === false ? "border-orange" : ""}`} onClick={() => onHistoryClick(query.caseQueryId)}>

                            <span>
                                <span className="icon">
                                    {
                                        query.alertType === "A" 
                                            ? <FontAwesomeIcon icon={faExclamationTriangle} />
                                            : query.alertType === "C"
                                                ? <FontAwesomeIcon icon={faCheck} />
                                                : null
                                    }
                                </span>

                                { query.title }
                            </span>

                            {
                                query.isClosed
                                    ? (
                                        <span className="status-complete">
                                            Completed
                                        </span>
                                    )
                                    : (
                                        <span className={`days-elapsed ${query.daysElapsed > 10 ? "red" : ""}`}>
                                            {query.daysElapsed} days
                                        </span>
                                    )
                            }
                        </li>
                    ))
                }

                </ul>
            </div>

        </div>
    )
}


export default QueryWidget;
