import React from "react";
import { useSelector } from "react-redux";
import { selector } from '../../../state/reducers/search-reducer';

import TableResults from '../../react-table/table-results';
import NoResults from '../../react-table/no-results';
import { switchComponent, LoadingV1 } from "../../common";

import ShowDependants from './show-dependants';
import getColumns from './table-columns';
import Row from './table-row';
import ExpandableDetails from './table-expandable-details';
import useMiddleware from './table-middleware';

import DomConfigRepository from '../../../repositories/domConfigRepository';
const reactConfig = DomConfigRepository.getJsonConfigById('reactConfig');


const SearchResults = ({ results, pageSize, pageIndex, sortBy, filters, showDependants, isOneThousandDaySearch }) => {

  const ps = (pageSize === 0) ? reactConfig.pageSize : pageSize;

  const expandableDetails = showDependants
    ? ExpandableDetails
    : null;

  const columns = getColumns(isOneThousandDaySearch);

  return (
    <div className="react-table">
      <ShowDependants />
      <div className="stripedbox">
        <TableResults 
          columns={columns}
          data={results} 
          itemsPerPage={ps} 
          pageNumber={pageIndex} 
          sortBy={sortBy}
          filters={filters}
          Row={Row} 
          ExpandableDetails={expandableDetails} 
          isHomeOfficeUser={reactConfig.isHomeOfficeUser}
          useMiddleware={useMiddleware} />
      </div>
    </div>
  );
};

const SearchResultsSwitch = (props) => switchComponent(props)(SearchResults, NoResults, () => props.results.length > 0);
const SearchResultsWithLoading = (props) => switchComponent(props)(LoadingV1, SearchResultsSwitch, () => props.loading);
const SearchResultsWithNoSearchTainted = (props) => switchComponent(props)(SearchResultsWithLoading, () => null, () => props.searchTainted);


export default (props) => {
  props = {
    ...props,
    results: useSelector(state => selector.results(state)),
    pageSize: useSelector(state => selector.pageSize(state)),
    pageIndex: useSelector(state => selector.pageIndex(state)),
    sortBy: useSelector(state => selector.sortBy(state)),
    filters: useSelector(state => selector.filters(state)),
    showDependants: useSelector(state => selector.showDependants(state)),
    loading: useSelector(state => selector.loading(state)),
    searchTainted: useSelector(state => selector.searchTainted(state)),
    isOneThousandDaySearch: useSelector(state => selector.isOneThousandDaySearch(state))
  }

  return SearchResultsWithNoSearchTainted(props);
}
