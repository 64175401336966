import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '../../../state/actions/queries-actions';
import * as filtersActions from '../../../state/actions/filters-actions';
import { MultiSelectList } from '../../form-inputs';

const HomeOfficeSelects = ({localAuthorityIds, localAuthorities, hoCaseWorkerIds, homeOfficeCaseWorkers, legislationIds, legislations, onChange}) => (
    <>
        <MultiSelectList title="Filter by Local Authority" allText="All Local Authorities" options={localAuthorities} values={localAuthorityIds} onChange={(v) => onChange({localAuthorityIds: v})} />
        <MultiSelectList title="Filter by Home Office Team, My Cases, Allocated HO User" allText="All Case Workers" options={homeOfficeCaseWorkers} values={hoCaseWorkerIds} onChange={(v) => onChange({hoCaseWorkerIds: v})} />
        <MultiSelectList title="Filter by legislation" allText="All Legislations" options={legislations} values={legislationIds} onChange={(v) => onChange({legislationIds: v})} />
    </>
);


const LocalAuthoritySelects = ({legislationIds, legislations, caseWorkerIds, localAuthorityCaseWorkers, onChange}) => (
    <>
        <MultiSelectList title="Filter by legislation" allText="All Legislations" options={legislations} values={legislationIds} onChange={(v) => onChange({legislationIds: v})} />
        <MultiSelectList title="Filter by my cases / allocated caseworker" allText="All Case Workers" options={localAuthorityCaseWorkers} values={caseWorkerIds} onChange={(v) => onChange({caseWorkerIds: v})} />
    </>
);


const FiltersSelects = ({filters, filterOptions, isHomeOfficeUser }) => {

    const { legislationIds, caseWorkerIds, localAuthorityIds, hoCaseWorkerIds } = filters;
    const { localAuthorities, homeOfficeCaseWorkers, legislations, localAuthorityCaseWorkers } = filterOptions;

    const dispatch = useDispatch();
    
    const handleFilterChange = useCallback((values) => {
        dispatch(filtersActions.applyFilter(values));
        dispatch(actions.loadQueries());
    }, [dispatch]);

    return (
        <div className="query-options">
            {
                isHomeOfficeUser 
                    ? <HomeOfficeSelects
                            localAuthorityIds={localAuthorityIds}
                            localAuthorities={localAuthorities}
                            hoCaseWorkerIds={hoCaseWorkerIds}
                            homeOfficeCaseWorkers={homeOfficeCaseWorkers}
                            legislationIds={legislationIds}
                            legislations={legislations}
                            onChange={handleFilterChange} /> 
                    : <LocalAuthoritySelects
                            legislationIds={legislationIds}
                            legislations={legislations}
                            caseWorkerIds={caseWorkerIds}
                            localAuthorityCaseWorkers={localAuthorityCaseWorkers}
                            onChange={handleFilterChange} />
            }
        </div>
    );
}

export default FiltersSelects;