import * as actions from '../../actions/admin/users-list-actions';

const updateUserStatus = (state, action) => {

  const publicId = action.data.userId;
  const originalRow = state.results[publicId];

  const newRow = {
    ...originalRow,
    isLocked: action.data.isLocked
  };

  const newResults = { ...state.results };
  newResults[publicId] = newRow;

  return {
    ...state,
    results: newResults,
    resultValues: Object.values(newResults)
  }
}


const initialState = {
  results: {},
  resultValues: [],
  pageIndex: 0,
  pageSize: 100,
  sortBy: [{
    id: 'fullName', 
    desc: false
  }],
  filters: [],
  loading: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.SEARCH_START:
      return { 
        ...state, 
        results: {},
        resultValues: [],
        loading: true, 
      };
    case actions.SEARCH_SUCCESS:
      return { 
        ...state, 
        results: action.data || {},
        resultValues: action.data ? Object.values(action.data) : [],
        loading: false 
      };
    case actions.SEARCH_FAILED:
      return { ...state, loading: false };
    case actions.SET_PAGE:
      return { 
        ...state, 
        pageIndex: action.data.pageIndex, 
        pageSize: action.data.pageSize 
      };
    case actions.SET_SORT:
      return { 
        ...state,
        sortBy: action.data
      };
    case actions.SET_FILTERS:
      return {
        ...state,
        filters: action.data
      };
    case actions.SET_USER_STATUS:
      return updateUserStatus(state, action);

    default:
        return state;
  }
}


export const selector = {
  results: (state) => state.adminUsers.results,
  resultValues: (state) => state.adminUsers.resultValues,
  hasResults: (state) => Object.keys(state.adminUsers.results).length > 0,
  pageSize: (state) => state.adminUsers.pageSize,
  pageIndex: (state) => state.adminUsers.pageIndex,
  sortBy: (state) => state.adminUsers.sortBy,
  filters: (state) => state.adminUsers.filters,
  loading: (state) => state.adminUsers.loading,
  replacementUsers: (userId) => (state) => state.adminUsers.resultValues?.filter(u => u.id !== userId) || []
};
