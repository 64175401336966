import * as actions from '../../actions/case/address-list-actions';

const initialState = {
  results: {},
  resultValues: [],
  pageIndex: 0,
  pageSize: 9999,
  sortBy: [],
  filters: [],
  loading: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.SEARCH_START:
      return { 
        ...state, 
        results: {},
        resultValues: [],
        loading: true, 
      };
    case actions.SEARCH_SUCCESS:
      return { 
        ...state, 
        results: action.data || {},
        resultValues: action.data ? Object.values(action.data) : [],
        loading: false 
      };
    case actions.SEARCH_FAILED:
      return { ...state, loading: false };
    case actions.SET_PAGE:
      return { 
        ...state, 
        pageIndex: action.data.pageIndex, 
        pageSize: action.data.pageSize 
      };
    case actions.SET_SORT:
      return { 
        ...state,
        sortBy: action.data
      };
    case actions.SET_FILTERS:
      return {
        ...state,
        filters: action.data
      };

    default:
        return state;
  }
}


export const selector = {
  results: (state) => state.case.address.results,
  resultValues: (state) => state.case.address.resultValues,
  hasResults: (state) => Object.keys(state.case.address.results).length > 0,
  pageSize: (state) => state.case.address.pageSize,
  pageIndex: (state) => state.case.address.pageIndex,
  sortBy: (state) => state.case.address.sortBy,
  filters: (state) => state.case.address.filters,
  loading: (state) => state.case.address.loading
};
