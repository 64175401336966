import React from "react";
import { Field } from 'react-final-form';

import { renderDropdownList, renderTextArea } from '../../../form-inputs/final-form';

var reasonIdIsRequired = value => value ? undefined : "Reason is required.";
var noteIsRequired = value => value ? undefined : "Further details are required.";

const ReopenFormFields = ({ referralDate, startDateOfService, previousEndDate, reopenReasonsOptions, disabled }) => (
    <>
        <div className="input-wrapper">
            <label>Reason for reopening the case <span className="mandatory-stars">*</span></label>
            <Field name="reasonId" render={renderDropdownList} options={reopenReasonsOptions} disabled={disabled} validate={reasonIdIsRequired} />
        </div>
        <div className="input-wrapper full-width">
            <label>Please explain current circumstance for the applicant: <span className="mandatory-stars">*</span></label>
            <Field name="reopenNote" type="text" render={renderTextArea} placeholder="" disabled={disabled} validate={noteIsRequired} />
        </div>

        <div className="input-wrapper">
            <label>Previous referral date</label>
            <div className="fake-input">
                {referralDate}
            </div>
        </div>

        <div className="input-wrapper">
            <label>Previous start date of service</label>
            <div className="fake-input">
                {startDateOfService}
            </div>
        </div>

        <div className="input-wrapper">
            <label>Previous end date</label>
            <div className="fake-input">
                {previousEndDate}
            </div>
        </div>
    </>
)


export default ReopenFormFields;