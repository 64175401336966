export default (highlightProperties) => {

    highlightProperties = highlightProperties || [];

    const result = {
        dateOfStatusCheck: highlightProperties.includes("DateOfStatusCheck"),
        immigrationStatusId: highlightProperties.includes("ImmigrationStatusId"),
        dateEnteredUK: highlightProperties.includes("DateEnteredUK"),
        isClaimedAsylum: highlightProperties.includes("IsClaimedAsylum"),
        dateOfAsylumApplication: highlightProperties.includes("DateOfAsylumApplication"),
        solicitorId: highlightProperties.includes("SolicitorId"),
        litOfficeId: highlightProperties.includes("LitOfficeId"),
        litCaseWorkerId: highlightProperties.includes("LitCaseWorkerId"),
        fileOwnershipId: highlightProperties.includes("FileOwnershipId"),
        dateLeaveGranted: highlightProperties.includes("DateLeaveGranted"),
        expiryDateOfLeave: highlightProperties.includes("ExpiryDateOfLeave"),
        isForeignNationalOffender: highlightProperties.includes("IsForeignNationalOffender"),
        dateOfAsylumDecision: highlightProperties.includes("DateOfAsylumDecision"),
        isImmigrationOrEeaApplication: highlightProperties.includes("IsImmigrationOrEeaApplication"),
        outcomeOfAsylumApplicationId: highlightProperties.includes("OutcomeOfAsylumApplicationId"),
        typeOfApplicationId: highlightProperties.includes("TypeOfApplicationId"),
        outcomeOfImmigrationApplicationId: highlightProperties.includes("OutcomeOfImmigrationApplicationId"),
        isContinuingLeave: highlightProperties.includes("IsContinuingLeave"),
        dateOfImmigrationApplication: highlightProperties.includes("DateOfImmigrationApplication"),
        dateOfImmigrationDecision: highlightProperties.includes("DateOfImmigrationDecision"),
        isAppealOngoing: highlightProperties.includes("IsAppealOngoing"),
        dateOfAppealHearing: highlightProperties.includes("DateOfAppealHearing"),
        dateOfAppealRightsExhausted: highlightProperties.includes("DateOfAppealRightsExhausted"),
        typeOfAsylumSupportId: highlightProperties.includes("TypeOfAsylumSupportId"),
        dateAsylumSupportStarted: highlightProperties.includes("DateAsylumSupportStarted"),
        dateAsylumSupportEnded: highlightProperties.includes("DateAsylumSupportEnded"),
        asylumSupportStatusId: highlightProperties.includes("AsylumSupportStatusId"),
        dateAsylumGracePeriod: highlightProperties.includes("DateAsylumGracePeriod"),
        asylumSupportEndedReasonId: highlightProperties.includes("AsylumSupportEndedReasonId"),
        enforcementBarrierToReturnId: highlightProperties.includes("EnforcementBarrierToReturnId"),
        isRemovalDecisionMade: highlightProperties.includes("IsRemovalDecisionMade"),
        dateOfReturn: highlightProperties.includes("DateOfReturn"),
        dateVrsAvrAppliedFor: highlightProperties.includes("DateVrsAvrAppliedFor"),
        voluntaryReturnsServiceTypeId: highlightProperties.includes("VoluntaryReturnsServiceTypeId"),
        voluntaryReturnsServiceOutcomeId: highlightProperties.includes("VoluntaryReturnsServiceOutcomeId"),
        dateOfVrsOutcome: highlightProperties.includes("DateOfVrsOutcome"),
        familyReturnsUnitInvolvementId: highlightProperties.includes("FamilyReturnsUnitInvolvementId"),
        familyReturnsUnitDateOfAction: highlightProperties.includes("FamilyReturnsUnitDateOfAction"),
    };

    result.areActive = Object.keys(result).reduce((acc, key) => acc || result[key], false);
    
    return result;
}
