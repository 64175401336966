import axios from 'axios';

import { onFormSubmitAsync } from '../form-submit';
import emptyGuid from '../../extensions/guid.empty';


const baseUrl = '/api/case/notes';
export const urls = {
    addSubmit: `${baseUrl}/add`,
}


export const onGetAllAsync = async (caseId, source) => {

    const axiosOptions = {};
    if (source) {
        axiosOptions.cancelToken = source.token;
    }
    try {
        const response = await axios.get(`${baseUrl}/${caseId}`, axiosOptions);
        const result = response.data || {};

        return {
            isLoaded: true,
            isSuccessful: true,
            data: result
        };
    }
    catch (err) {
        return {
            isLoaded: true,
            isSuccessful: false
        }
    }
}

export const onGetAllOfTypeAsync = async (noteTypeId, caseId, applicantId, source) => {

    try {
        if (!applicantId) {
            applicantId = emptyGuid;
        }

        const response = await axios.get(`${baseUrl}/${noteTypeId}/${caseId}/${applicantId}`, { cancelToken: source.token});
        const result = response.data || {};

        return {
            isLoaded: true,
            isSuccessful: true,
            data: result
        };
    }
    catch (err) {
        return {
            isLoaded: true,
            isSuccessful: false
        }
    }
}

export const onGetLatestAsync = async (noteTypeId, caseId, applicantId) => {

    try {
        if (!applicantId) {
            applicantId = emptyGuid;
        }

        const response = await axios.get(`${baseUrl}/latest/${noteTypeId}/${caseId}/${applicantId}`);
        const results = response.data || {};

        return results;
    }
    catch (err) {
        return {};
    }
}


export const onAddSubmitAsync = async (formValues) => {
    const serverModel = mappers.toServerModel(formValues);
    return await onFormSubmitAsync(urls.addSubmit, serverModel);
}

const mappers = {
    toReduxForm: (values) => ({
        ...values
    }),
    toServerModel: (values) => ({
        ...values
    })
}


export default {
    onGetAllAsync,
    onGetAllOfTypeAsync,
    onGetLatestAsync,
    onAddSubmitAsync
}