import React, { useEffect, useRef } from "react";
import { Field, Form } from 'react-final-form';

import { renderInput, renderTextArea } from '../../form-inputs/final-form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlagCheckered } from '@fortawesome/free-solid-svg-icons';


const homeOfficeAdvice = (
    <ul>
        <li>Use Queries to clarify information with the local authority</li>
        <li>10 working days = agreed response time</li>
        <li>Complete the Query when the LA response is received</li>
        <li>If no response, request assistance from the NRPF Network: npfconnect@islington.gov.uk</li>
    </ul>
)

const localAuthorityAdvice = (
    <>
        <ul>
            <li>Use after first immigration status-check</li>
            <li>Keep the Query specific</li>
            <li>Complete the Query chain after Home Office replies</li>
            <li>10 working days = agreed response time</li>
            <li>Check that address and dependants’ details are correct</li>
            <li>Refer to ‘Help’ button for more info</li>
        </ul>
        <p>The Data and Sanctions Team (DAST) will:</p>
        <ul>
            <li>Provide current immigration information</li>
            <li>Chase other decision-making teams where possible</li>
            <li>Advocate for the local authority</li>
        </ul>
        <p>DAST does not:</p>
        <ul>
            <li>Provide policy or casework advice</li>
            <li>Have direct control over decisions made by other teams</li>
        </ul>
        <p>Thank you for your understanding!</p>
    </>
)


const QueriesAddForm = ({onSubmit, initialValues, disabled}) => {
    
    const formSubmitButton = useRef();

    useEffect(() => {
        var { unsubscribe } = window.dispatcher.subscribe("change-tracker-modal-submit-form", () => formSubmitButton.current.click());

        return () => {
            unsubscribe();
        }
    }, []);

    return (
        
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            render={({ handleSubmit, submitting, values }) => {

                const isDisabled = disabled || submitting;

                return (
                    <form autoComplete="off" onSubmit={handleSubmit} rel="change-tracked-modal">
                        <div className="queries-columns">
                            <div className="queries-form">
                                <div className="mandatory"><span>*</span> Denotes a mandatory field </div>

                                    <div className="modal-edit-form">
                                        <div className="input-wrapper">
                                            <label>Main Applicant</label>
                                            <span className="fake-input">{values.principalApplicantName}</span>
                                        </div>
                                        
                                        <div className="input-wrapper">
                                            <Field name="subject" type="text" render={renderInput} label="Subject" isMandatory={true} disabled={isDisabled} maxlength={18} />
                                        </div>
                                        <div className="input-wrapper">
                                            <Field name="message" type="text" render={renderTextArea} label="Message" isMandatory={true} disabled={isDisabled} isFullWidth={true} />
                                        </div>
                                    </div>
                                
                            </div>

                            <div className="queries-help-text">
                                <h2>Getting the most out of queries</h2>
                                { values.userIsHomeOffice ? homeOfficeAdvice : localAuthorityAdvice }
                            </div>
                        </div>
                        <div className="modal-edit-form">
                            <div className="form-buttons">
                                <button type="submit" className="rbtn primary" disabled={isDisabled} ref={formSubmitButton}>
                                    <FontAwesomeIcon icon={faFlagCheckered} />
                                    <span>Send</span>
                                </button>
                            </div>
                        </div>
                    </form>
                )
            }}
        />
            
    )
}


export default QueriesAddForm;
