import { useState, useCallback, useMemo } from 'react';

const defaultState = {
    rows: []
};

export default (initialId, addModalFunc, viewModalFunc) => {

    const [queries, setQueries] = useState(defaultState);
    const [id, setId] = useState(initialId);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showViewModal, setShowViewModal] = useState(false);


    const onAddQueryModalRequest = useCallback(() => {
        setId(initialId);
        setShowAddModal(true);
    }, [initialId, setId, setShowAddModal]);

    const onViewQueryModalRequest = useCallback((id) => {
        setId(id);
        setShowViewModal(true);
    }, [setId, setShowViewModal]);

    const onModalClose = useCallback((items) => {
        setShowAddModal(false);
        setShowViewModal(false);
        if (items) {
            setQueries(items);
        }
    }, [setQueries, setShowAddModal, setShowViewModal]);

    
    const addQueryModal = useMemo(() => 
        showAddModal ? addModalFunc(onModalClose) : null
    , [showAddModal, addModalFunc, onModalClose]);

    const viewQueryModal = useMemo(() => 
        showViewModal ? viewModalFunc(id, onModalClose) : null
    , [id, showViewModal, viewModalFunc, onModalClose]);

    
    return {
        queries,
        setQueries,
        onAddQueryModalRequest,
        onViewQueryModalRequest,
        addQueryModal,
        viewQueryModal
    };
}