import DomConfigRepository from '../../repositories/domConfigRepository';
const sv = DomConfigRepository.getJsonConfigById('reactConfig') || {};

export const userId = sv.userId;
export const userIsAdmin = sv.isAdmin;
export const userIsLocalAuthority = sv.isLocalAuthorityUser;
export const organisation = sv.organisation;

export default {
    userId,
    userIsAdmin,
    userIsLocalAuthority,
    organisation,
};