import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";

import { onGetAllAsync } from '../../../api-endpoint-calls/superuser/rtpf-descriptions';

import useListState from "./use-list-state";
import EditDescriptionModal from "./edit";
import DeleteDescriptionModal from "./delete";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { LoadingV1 } from '../../common';

const RtpfDescriptions = () => {

    const [loaded, setLoaded] = useState(false);
    const [successful, setSuccessful] = useState(false);

    const {
        items,
        setItems,
        onEditModalRequest,
        onDeleteModalRequest,
        editModal,
        deleteModal
    } = useListState(
        0,
        (id, onClose) => <EditDescriptionModal onClose={onClose} descriptionId={id} />,
        (id, onClose) => <DeleteDescriptionModal onClose={onClose} descriptionId={id} />
    )

    const onPageLoadAsync = useCallback(async (source) => {
        const result = await onGetAllAsync(source);

        setLoaded(result.isLoaded);
        setSuccessful(result.isSuccessful);

        if (result.isSuccessful) {
            setItems(result.data);
        }
    }, [setLoaded, setSuccessful, setItems]);

    useEffect(() => {
        const source = axios.CancelToken.source();
        onPageLoadAsync(source);

        return () => {
            source.cancel();
        }
    }, [onPageLoadAsync]);


    const onEditClick = (descriptionId) => {
        onEditModalRequest(descriptionId);
    }

    const onDeleteClick = (descriptionId) => {
        onDeleteModalRequest(descriptionId);
    }

    const showSpinner = (!loaded);
    const showFailure = (loaded && !successful);

    if (showSpinner) {
        return <LoadingV1 />;
    } else if (showFailure) {
        return <h2>An error has occured!</h2>;
    }

    return (
        <div className="stripedbox">
            <div className="header-with-button">
                <h2>Rtpf Descriptions</h2>
                <button type="button" onClick={() => onEditClick(0)} className="rbtn primary small">Add Description</button>
            </div>

            <div className="description-summary-rows">
                <div className="header">
                    <span>Id</span>
                    <span>Product Type</span>
                    <span>Immigration Status</span>
                    <span>Status</span>
                    <span>Description</span>
                    <span></span>
                </div>
                {
                    items?.map((item) => (

                        <div className="body-row" key={item.descriptionId}>
                            <span>{item.descriptionId}</span>
                            <span>{item.productType}</span>
                            <span>{item.immigrationStatus}</span>
                            <span>{item.status}</span>
                            <span>{item.description}</span>
                            <span>
                                <div className="list-buttons">
                                    <button type="button" onClick={() => onEditClick(item.descriptionId)} className="rbtn light-grey">
                                        <span><FontAwesomeIcon icon={faEdit} /></span>
                                    </button>
                                    <button type="button" onClick={() => onDeleteClick(item.descriptionId)} className="rbtn light-grey">
                                        <span><FontAwesomeIcon icon={faTrash} /></span>
                                    </button>
                                </div>
                            </span>
                        </div>
                    ))
                }
            </div>

            {editModal}
            {deleteModal}
        </div>
    )
}


export default RtpfDescriptions;
