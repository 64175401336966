import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter, HashRouter, Switch, Route } from "react-router-dom";
import * as actions from '../state/actions/filters-actions';

import ErrorBoundary from './error-boundary';

import LoginPage from "./login-page";

import Home from './home';
import Search from "./search";
import Queries from "./queries";
import Admin from './admin';
import Reports from './reports';
import CaseCreate from './case/create';

import CaseSummary from './case/summary';
import CaseAddress from './case/address';
import CaseAddressAdd from './case/address/add';
import CaseAddressEdit from './case/address/edit';
import CaseNotes from './case/notes';
import CaseDependants from './case/applicants/dependants-routing';
import CaseApplicantEdit from './case/applicants/applicant-edit';
import CaseReferral from './case/referral';
import CaseImmigration from './case/immigration';
import CaseClosure from './case/closure';

import SuperuserUserList from "./superuser/users";
import SuperuserUserAdd from './superuser/users/add';
import SuperuserUserEdit from './superuser/users/edit';
import SuperuserRtpfDescriptions from './superuser/rtpf-descriptions';

import RtpfLookup from './rtpf-lookup';

import { routes } from "./routes";

import SignalRCases from './signalR/cases'

import "react-widgets/styles.css";
import './index.css';

import DomConfigRepository from '../repositories/domConfigRepository';
const reactConfig = DomConfigRepository.getJsonConfigById('reactConfig');


function SubRouter() {

  const dispatch = useDispatch();

  useEffect(() => {
      dispatch(actions.applyServerFilters(reactConfig));
  }, [dispatch])

  return (
    <HashRouter>
      <Switch>
        <Route exact path={routes.home} component={Home} />
        <Route exact path={routes.search} component={Search} />
        <Route exact path={routes.queries} component={Queries} />

        <Route exact path={routes.cases.create} component={CaseCreate} />

        <Route path={routes.admin} component={Admin} />

        <Route exact path={routes.rtpfLookup} component={RtpfLookup} />
      </Switch>
    </HashRouter>
  );
}

function Router() {
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <Route path={routes.account.login} component={LoginPage} />

        <Route exact path={routes.home} component={SubRouter} />

        <Route exact path={routes.reports.performanceMonitoring} component={Reports} />

        <Route exact path={routes.cases.summary} component={CaseSummary} />

        <Route exact path={routes.cases.dependants} component={CaseDependants} />
        <Route exact path={routes.cases.applicant.edit} component={CaseApplicantEdit} />

        <Route exact path={routes.cases.address.add} component={CaseAddressAdd} />
        <Route exact path={routes.cases.address.edit} component={CaseAddressEdit} />
        <Route exact path={routes.cases.address.list} component={CaseAddress} />

        <Route exact path={routes.cases.referral} component={CaseReferral} />
        <Route exact path={routes.cases.immigration} component={CaseImmigration} />
        <Route exact path={routes.cases.closure} component={CaseClosure} />

        <Route exact path={routes.cases.notes} component={CaseNotes} />

        <Route exact path={routes.admin} component={Admin} />

        <Route path={routes.su.users.add} component={SuperuserUserAdd} />
        <Route path={routes.su.users.edit} component={SuperuserUserEdit} />
        <Route exact path={routes.su.users.root} component={SuperuserUserList} />
        <Route exact path={routes.su.rtpfDescriptions} component={SuperuserRtpfDescriptions} />
      </BrowserRouter>
      <SignalRCases/>
    </ErrorBoundary>
  );
}

export default Router;
