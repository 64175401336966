import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";

import { onLoadAsync, onAddSubmitAsync } from '../../../../api-endpoint-calls/admin/users';

import AddForm from './form';

import { LoadingV1, errorNotification } from '../../../common';



const AdminUsersAdd = () => {

    const { userId } = useParams();
    const history = useHistory();

    const [initialValues, setInitialValues] = useState({});

    const [showSpinner, setShowSpinner] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);


    useEffect(() => {
        const onPageLoad = async () => {
            const result = await onLoadAsync(userId);
            
            setShowSpinner(!result.isLoaded || !result.isSuccessful);

            if (result.initialValues) {
                setInitialValues(result.initialValues);
            }
        }

        onPageLoad();
        
    }, [setInitialValues, setShowSpinner, userId]);


    if (showSpinner) {
        return <LoadingV1 />;
    }

    
    const onFormSubmit = async (formValues) => {

        const result = await onAddSubmitAsync(formValues);

        if (result.isSuccess) {
            window.dispatcher.dispatchEvent('change-tracker-reset');
            history.push("/admin/users");
        } else {
            setErrorMessage(result.message);
        }

        return result.errors;
    };

    const errorAlert = errorNotification(errorMessage);

    return (
        <div className="stripedbox">
            {errorAlert}
            <AddForm onSubmit={onFormSubmit} initialValues={initialValues} />
        </div>
    );
}

export default AdminUsersAdd;