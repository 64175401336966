import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '../../../state/actions/queries-actions';
import * as filtersActions from '../../../state/actions/filters-actions';
import { localAuthorityReceivedOptions, localAuthoritySentOptions, homeOfficeReceivedOptions, homeOfficeSentOptions } from './radios-options';

const FiltersRadios = ({filters: { queriesTab, queriesSubOption }, isHomeOfficeUser}) => {

    const dispatch = useDispatch();

    const handleRadioChange = useCallback((selectedOption) => {
        if (selectedOption !== queriesSubOption) {
            dispatch(filtersActions.applyFilter({
                queriesSubOption: selectedOption
            }));
            dispatch(actions.loadQueries());
        }
    }, [dispatch, queriesSubOption]);

    const isSentTab = (queriesTab === "S");
    const isCompletedTab = (queriesTab === "C");

    const options = React.useMemo(() => isHomeOfficeUser
        ? isSentTab ? homeOfficeSentOptions : homeOfficeReceivedOptions
        : isSentTab ? localAuthoritySentOptions : localAuthorityReceivedOptions
    ,
    [isHomeOfficeUser, isSentTab]);

    return isCompletedTab ? null : (
        <div className="radio-options">
            <ul>
                { options.map((o) => 
                    <li key={o.value} style={{flex: `${o.flex} ${o.flex}`}}>
                        <label htmlFor={o.id}>{o.label}</label>
                        <input id={o.id} type="radio" name="queriesSubOption" value={o.value} rel="changeTrackerIgnoreControl" checked={queriesSubOption === o.value} onChange={(e) => handleRadioChange(e.target.value)} />
                    </li>
                )}
            </ul>
        </div>
    );
}

export default FiltersRadios;
