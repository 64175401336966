import React from "react";
import { Field, Form } from "react-final-form";

import {
    renderInput,
    renderDropdownList,
    renderDatePicker,
    renderRadioOption,
    toIntOptions
} from "../../../form-inputs/final-form";
import stringIsNullOrEmpty from '../../../../extensions/string.isNullOrEmpty';

import ClaimedAsylum from "../form/sections/claimed-asylum";
import ImmigrationApplication from "../form/sections/immigration-application";
import AsylumApplication from "../form/sections/asylum-application";
import VoluntaryReturnsService from "../form/sections/voluntary-returns-service";
import FamilyReturnsUnit from "../form/sections/family-returns-unit";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";



const defaultOptions = {
    applicationOutcomes: [],
    applicationTypes: [],
    asylumEndedReasons: [],
    asylumOutcomes: [],
    asylumStatuses: [],
    asylumTypes: [],
    barrierToReturnReasons: [],
    fruInvolvements: [],
    immigrationStatuses: [],
    vrsTypes: [],
    vrsOutcomes: [],
    solicitors: [],
    homeOfficeCaseWorkers: [],
    litOffices: [],
    fileOwners: []
};

const HistoryFormIA2016 = ({
    onSubmit,
    initialValues,
    onEditSolicitorModalRequest,
    onCloseHistoryRecord
}) => {

    const {
        applicationOutcomes,
        applicationTypes,
        asylumEndedReasons,
        asylumOutcomes,
        asylumStatuses,
        asylumTypes,
        barrierToReturnReasons,
        fruInvolvements,
        immigrationStatuses,
        vrsTypes,
        vrsOutcomes,
        solicitors,
        homeOfficeCaseWorkers,
        litOffices,
        fileOwners,
    } = initialValues.options || defaultOptions;

    const applicationOutcomesOptions = toIntOptions(applicationOutcomes);
    const applicationTypesOptions = toIntOptions(applicationTypes);
    const asylumEndedReasonsOptions = toIntOptions(asylumEndedReasons);
    const asylumOutcomesOptions = toIntOptions(asylumOutcomes);
    const asylumStatusesOptions = toIntOptions(asylumStatuses);
    const asylumTypesOptions = toIntOptions(asylumTypes);
    const barrierToReturnReasonsOptions = toIntOptions(barrierToReturnReasons);
    const fruInvolvementsOptions = toIntOptions(fruInvolvements);
    const immigrationStatusesOptions = toIntOptions(immigrationStatuses);
    const vrsTypesOptions = toIntOptions(vrsTypes);
    const vrsOutcomesOptions = toIntOptions(vrsOutcomes);
    const solicitorsOptions = toIntOptions(solicitors);
    const homeOfficeCaseWorkersOptions = toIntOptions(homeOfficeCaseWorkers);
    const litOfficesOptions = toIntOptions(litOffices);
    const fileOwnersOptions = toIntOptions(fileOwners);


    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            render={({ handleSubmit, values }) => {

                const disabled = true;

                const rtpfLookupFields = stringIsNullOrEmpty(values.rtpfStatus) === false && stringIsNullOrEmpty(values.rtpfDescription) === false
                    ? (
                        <>
                            <label>Recourse to Public Funds Checker - record of digital status</label>
                
                            <div className="input-wrapper-full-width">
                                <Field name="rtpfStatus" type="text" render={renderInput} disabled={disabled} style={{ width: "auto" }} />
                            </div>
                
                            <div className="input-wrapper-full-width">
                                <Field name="rtpfDescription" type="text" render={renderInput} disabled={disabled} style={{ width: "auto" }} />
                            </div>
                        </>
                    )
                    : null;


                return (
                    <form onSubmit={handleSubmit} rel="change-tracked">
                        <div className="immigration-edit-wrapper">
                            <div className="immigration-grid-wrapper">
                                <div className="stripedbox left-column">
                                    <div className="immigration-edit-form">

                                        <h2>Immigration Status</h2>

                                        <div className="input-wrapper">
                                            <Field
                                                name="dateOfStatusCheck"
                                                type="text"
                                                render={renderDatePicker}
                                                label="Date of status check"
                                                placeholder=""
                                                disabled={disabled}
                                            />
                                        </div>
                                        <div className="input-wrapper-full-width">
                                            <label>Current Status</label>
                                            <Field
                                                name="immigrationStatusId"
                                                type="text"
                                                render={renderDropdownList}
                                                options={immigrationStatusesOptions}
                                                disabled={disabled}
                                            />
                                        </div>

                                        {rtpfLookupFields}

                                        <div className="input-wrapper">
                                            <Field
                                                name="dateEnteredUK"
                                                type="text"
                                                render={renderDatePicker}
                                                label="Date entered the UK"
                                                placeholder=""
                                                disabled={disabled}
                                            />
                                        </div>
                                        <div className="input-wrapper">
                                            <Field
                                                name="dateLeaveGranted"
                                                type="text"
                                                render={renderDatePicker}
                                                label="Date leave granted"
                                                placeholder=""
                                                disabled={disabled}
                                            />
                                        </div>
                                        <div className="input-wrapper">
                                            <Field
                                                name="expiryDateOfLeave"
                                                type="text"
                                                render={renderDatePicker}
                                                label="Expiry date of leave"
                                                placeholder=""
                                                disabled={disabled}
                                            />
                                        </div>

                                        <div className="input-wrapper">
                                            <label>Foreign National Offender?</label>
                                            <div className="radio-options">
                                                <Field name="isForeignNationalOffender" type="radio" render={renderRadioOption} value="true" label="Yes" disabled={disabled} />
                                                <Field name="isForeignNationalOffender" type="radio" render={renderRadioOption} value="false" label="No" disabled={disabled} />
                                            </div>
                                        </div>


                                        <hr />

                                        <div className="input-wrapper section-header">
                                            <label>Claimed Asylum?</label>
                                            <div className="radio-options">
                                                <Field name="isClaimedAsylum" type="radio" render={renderRadioOption} value="true" label="Yes" disabled={disabled} />
                                                <Field name="isClaimedAsylum" type="radio" render={renderRadioOption} value="false" label="No" disabled={disabled} />
                                            </div>
                                        </div>

                                        <ClaimedAsylum
                                            values={values}
                                            disabled={disabled}
                                            asylumOutcomesOptions={asylumOutcomesOptions}
                                        />

                                        <hr />


                                        <div className="input-wrapper section-header">
                                            <label>Immigration application?</label>
                                            <div className="radio-options">
                                                <Field name="isImmigrationOrEeaApplication" type="radio" render={renderRadioOption} value="true" label="Yes" disabled={disabled} />
                                                <Field name="isImmigrationOrEeaApplication" type="radio" render={renderRadioOption} value="false" label="No" disabled={disabled} />
                                            </div>
                                        </div>


                                        <ImmigrationApplication
                                            values={values}
                                            disabled={disabled}
                                            applicationTypesOptions={applicationTypesOptions}
                                            applicationOutcomesOptions={applicationOutcomesOptions}
                                        />


                                        <hr />


                                        <div className="input-wrapper section-header">
                                            <label>Appeal ongoing?</label>
                                            <div className="radio-options">
                                                <Field name="isAppealOngoing" type="radio" render={renderRadioOption} value="true" label="Yes" disabled={disabled} />
                                                <Field name="isAppealOngoing" type="radio" render={renderRadioOption} value="false" label="No" disabled={disabled} />
                                                <Field name="isAppealOngoing" type="radio" render={renderRadioOption} value="unknown" label="N/A" disabled={disabled} />
                                            </div>
                                        </div>

                                        <div className="input-wrapper">
                                            <Field
                                                name="dateOfAppealHearing"
                                                type="text"
                                                render={renderDatePicker}
                                                label="Date of appeal hearing"
                                                placeholder=""
                                                disabled={disabled}
                                            />
                                        </div>

                                        <div className="input-wrapper">
                                            <Field
                                                name="dateOfAppealRightsExhausted"
                                                type="text"
                                                render={renderDatePicker}
                                                label="Appeal Rights Exhausted date"
                                                placeholder=""
                                                disabled={disabled}
                                            />
                                        </div>


                                        <hr />


                                        <h2>Immigration Represenative Instructed</h2>

                                        <div className="input-wrapper-with-options">
                                            <Field
                                                name="solicitorId"
                                                render={renderDropdownList}
                                                options={solicitorsOptions}
                                            />
                                            {values.solicitorId > 0 ? (
                                                <div className="dropdown-options">
                                                    <button
                                                        type="button"
                                                        onClick={() =>
                                                            onEditSolicitorModalRequest(values.solicitorId)
                                                        }
                                                        className="rbtn primary small"
                                                    >
                                                        View / Edit
                                                    </button>
                                                </div>
                                            ) : null}
                                        </div>

                                    </div>
                                </div>
                                <div className="right-column stripedbox dark">
                                    <div className="immigration-edit-form">

                                        <h2>Home Office Involvements</h2>

                                        <div className="input-wrapper">
                                            <label>Home Office Team</label>
                                            <Field
                                                name="litOfficeId"
                                                type="text"
                                                render={renderDropdownList}
                                                options={litOfficesOptions}
                                                disabled={disabled}
                                            />
                                        </div>

                                        <div className="input-wrapper">
                                            <label>Home Office Allocated User</label>
                                            <Field
                                                name="litCaseWorkerId"
                                                type="text"
                                                render={renderDropdownList}
                                                options={homeOfficeCaseWorkersOptions}
                                                disabled={disabled}
                                            />
                                        </div>

                                        <div className="input-wrapper">
                                            <label>Home Office File Ownership</label>
                                            <Field
                                                name="fileOwnershipId"
                                                type="text"
                                                render={renderDropdownList}
                                                options={fileOwnersOptions}
                                                disabled={disabled}
                                            />
                                        </div>


                                        <hr />


                                        <h2>Asylum Support</h2>

                                        <div className="input-wrapper-full-width">
                                            <label>Type of asylum support (last provided)</label>
                                            <Field
                                                name="typeOfAsylumSupportId"
                                                type="text"
                                                render={renderDropdownList}
                                                options={asylumTypesOptions}
                                                disabled={disabled}
                                            />
                                        </div>

                                        <AsylumApplication
                                            values={values}
                                            disabled={disabled}
                                            asylumStatusesOptions={asylumStatusesOptions}
                                            asylumEndedReasonsOptions={asylumEndedReasonsOptions}
                                        />


                                        <hr />


                                        <h2>Enforcement Action</h2>

                                        <div className="input-wrapper-full-width">
                                            <label>Barrier to return</label>
                                            <Field
                                                name="enforcementBarrierToReturnId"
                                                type="text"
                                                render={renderDropdownList}
                                                options={barrierToReturnReasonsOptions}
                                                disabled={disabled}
                                            />
                                        </div>

                                        <div className="input-wrapper-long-question">
                                            <label>Has a removal decision been made?</label>
                                            <div className="radio-options">
                                                <Field name="isRemovalDecisionMade" type="radio" render={renderRadioOption} value="true" label="Yes" disabled={disabled} />
                                                <Field name="isRemovalDecisionMade" type="radio" render={renderRadioOption} value="false" label="No" disabled={disabled} />
                                                <Field name="isRemovalDecisionMade" type="radio" render={renderRadioOption} value="unknown" label="N/A" disabled={disabled} />
                                            </div>
                                        </div>

                                        <div className="input-wrapper">
                                            <Field
                                                name="dateOfReturn"
                                                type="text"
                                                render={renderDatePicker}
                                                label="Date of return"
                                                placeholder=""
                                                disabled={disabled}
                                            />
                                        </div>


                                        <hr />


                                        <h2>Voluntary Returns Service</h2>

                                        <div className="input-wrapper">
                                            <label>VRS applied for</label>
                                            <Field
                                                name="voluntaryReturnsServiceTypeId"
                                                type="text"
                                                render={renderDropdownList}
                                                options={vrsTypesOptions}
                                                disabled={disabled}
                                            />
                                        </div>

                                        <VoluntaryReturnsService
                                            values={values}
                                            disabled={disabled}
                                            vrsOutcomesOptions={vrsOutcomesOptions}
                                        />


                                        <hr />


                                        <h2>Family Returns Unit</h2>

                                        <div className="input-wrapper">
                                            <label>Involvement</label>
                                            <Field
                                                name="familyReturnsUnitInvolvementId"
                                                type="text"
                                                render={renderDropdownList}
                                                options={fruInvolvementsOptions}
                                                disabled={disabled}
                                            />
                                        </div>

                                        <FamilyReturnsUnit
                                            values={values}
                                            disabled={disabled}
                                        />

                                    </div>
                                </div>
                            </div>
                            <div className="stripedbox">
                                <div className="form-buttons">
                                    <button type="button" className="rbtn secondary" onClick={onCloseHistoryRecord}>
                                        <FontAwesomeIcon icon={faArrowLeft} />
                                        <span>Go Back</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                );
            }}
        />
    );
};

export default HistoryFormIA2016;
