import React from 'react';
import { SelectColumnFilter, SelectStatusColumnFilter, SelectLoggedInColumnFilter } from '../../../react-table/filters';

import ActionButtons from './action-buttons';

export const localAuthorityColumns = [
  {
    Header: 'Name',
    accessor: 'name',
    width: 110
  },
  {
    Header: 'Username',
    accessor: 'username',
    width: 110
  },
  {
    Header: 'Email',
    accessor: 'email',
    width: 110
  },
  {
    Header: 'Access Level',
    accessor: 'accessLevel',
    Filter: SelectColumnFilter,
    width: 90
  },
  {
    Header: 'Status',
    accessor: 'isLocked',
    Cell: ({value}) => value 
      ? <div className="account-status locked"><span>LOCKED</span></div>
      : <div className="account-status unlocked"><span>Active</span></div>,
    Filter: SelectStatusColumnFilter,
    width: 60
  },
  {
    Header: 'Last Logged In',
    accessor: 'lastLoggedIn',
    Cell: ({row}) => <>{row.original.lastLoggedInFormatted}</>,
    Filter: SelectLoggedInColumnFilter,
    filter: 'date',
    width: 110
  },
  {
    Header: 'Actions',
    disableSortBy: true,
    Cell: ({row}) => <ActionButtons row={row} />,
    width: 80,
    disableFilters: true
  }
];

export const homeOfficeColumns = [
  {
    Header: 'Name',
    accessor: 'name',
    width: 100
  },
  {
    Header: 'Username',
    accessor: 'username',
    width: 100
  },
  {
    Header: 'Team',
    accessor: 'homeOfficeTeamName',
    width: 130
  },
  {
    Header: 'Access Level',
    accessor: 'accessLevel',
    Filter: SelectColumnFilter,
    width: 90
  },
  {
    Header: 'Status',
    accessor: 'isLocked',
    Cell: ({value}) => value 
      ? <div className="account-status locked"><span>LOCKED</span></div>
      : <div className="account-status unlocked"><span>Active</span></div>,
    Filter: SelectStatusColumnFilter,
    width: 60
  },
  {
    Header: 'Last Logged In',
    accessor: 'lastLoggedIn',
    Cell: ({row}) => <>{row.original.lastLoggedInFormatted}</>,
    Filter: SelectLoggedInColumnFilter,
    filter: 'date',
    width: 110
  },
  {
    Header: 'Actions',
    disableSortBy: true,
    Cell: ({row}) => <ActionButtons row={row} />,
    width: 80,
    disableFilters: true
  }
];

export default localAuthorityColumns;