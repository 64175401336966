import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { onGetAllForCaseAsync } from '../../../api-endpoint-calls/case/dependants';

import DependantsList from './list';

import { LoadingV1 } from '../../common';
import { caseArea, caseId, isClosed, userIsCaseOwner } from '../server-values';
import emptyGuid from '../../../extensions/guid.empty';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

export default () => {

    const [dependants, setDependants] = useState({
        principalApplicantId: emptyGuid
    });

    const [loaded, setLoaded] = useState(false);
    const [successful, setSuccessful] = useState(false);

    useEffect(() => {
        const source = axios.CancelToken.source();

        const onPageLoadAsync = async () => {
            const result = await onGetAllForCaseAsync(caseId, source);
            
            setLoaded(result.isLoaded);
            setSuccessful(result.isSuccessful);

            if (result.isSuccessful) {
                setDependants(result.data);
            }
        }

        onPageLoadAsync();

        return () => {
            source.cancel();
        }
    }, []);

    const addDependantButton = userIsCaseOwner && !isClosed
    ? (
        <a href={`/${caseArea}/Dependant?CaseId=${caseId}#/Add`} title="add new dependant" className="rbtn primary right">
            <FontAwesomeIcon icon={faPlusCircle} />
            <span>Add Dependant</span>
        </a>
    )
    : null;


    const showSpinner = (!loaded);
    const showFailure = (loaded && !successful);

    if (showSpinner) {
        return <LoadingV1 />;
    } else if (showFailure) {
        return <h2>An error has occured!</h2>;
    }

    return (
        <div className="stripedbox">
            { addDependantButton }
            <h2>Dependants</h2>

            <DependantsList dependants={dependants} />
        </div>
    )
}