import React from 'react';
import { Field, Form } from 'react-final-form';
import { renderSelectList } from '../../../../../form-inputs/final-form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faUserSlash } from '@fortawesome/free-solid-svg-icons';


const DeleteForm = ({onSubmit, onClose, initialValues, replacementUsers}) => {
    
    const hasReplacementUsers = (replacementUsers && replacementUsers.length > 0);

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            render={({handleSubmit}) => {
        
                const replaceUserField = hasReplacementUsers
                ? (
                    <div>
                        <span className="w-100 p-15 option-label">User's cases will be assigned to <span className="mandatory-stars">*</span></span>
                        <div className="w-100 p-15">
                            <Field name="replacementUserId" component={renderSelectList} tabIndex={1}>
                                <option value="">-- Please Select --</option>
                                {replacementUsers.map((item) => <option key={item.id} value={item.id}>{item.name}</option>)}
                            </Field>
                        </div>
                    </div>
                )
                : null;

                return (
                    <div className="stripedbox">
                        <form autoComplete="off" onSubmit={handleSubmit}>

                            { replaceUserField }

                            <p>Are you sure you want to delete this user?</p>

                            <div className="button-row p-15">
                                <button onClick={onClose} className="rbtn secondary"><FontAwesomeIcon icon={faTimesCircle} /><span>Cancel</span></button>
                                <button type="submit" className="rbtn primary"><FontAwesomeIcon icon={faUserSlash} /><span>Delete</span></button>
                            </div>
                        </form>
                    </div>
                );
            }}
        />
    )
}

export default DeleteForm;
