import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from '../../../state/actions/superuser/users-list-actions';
import { selector } from '../../../state/reducers/superuser/users-reducer';

import TableResults from '../../react-table/table-results';
import NoResults from '../../react-table/no-results';
import { switchComponent, LoadingV1 } from "../../common";

import columns from './list/table-columns';
import Row from './list/table-row';
import useMiddleware from './list/table-middleware';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync, faUserPlus } from '@fortawesome/free-solid-svg-icons'

const Results = () => {

    const results = useSelector(state => selector.resultValues(state));
    const pageSize = useSelector(state => selector.pageSize(state));
    const pageIndex = useSelector(state => selector.pageIndex(state));
    const sortBy = useSelector(state => selector.sortBy(state));
    const filters = useSelector(state => selector.filters(state));

    return (
        <div className="react-table mt-10">
            <div className="stripedbox">
                <TableResults 
                    columns={columns} 
                    data={results} 
                    itemsPerPage={pageSize} 
                    pageNumber={pageIndex} 
                    sortBy={sortBy}
                    filters={filters}
                    Row={Row} 
                    useMiddleware={useMiddleware}
                    rowDescription="active accounts" />
            </div>
        </div>
    )
}

const TableWrapperSwitch = (props) => switchComponent(props)(Results, NoResults, () => props.hasResults);
const TableWrapperWithLoading = (props) => switchComponent(props)(LoadingV1, TableWrapperSwitch, () => props.loading);

export default () => {

    const dispatch = useDispatch();

    const hasResults = useSelector(state => selector.hasResults(state));
    const loading = useSelector(state => selector.loading(state));

    useEffect(() => {
        dispatch(actions.loadUsers());
    }, [dispatch])

    const onResetClick = useCallback((e) => {
        dispatch(actions.resetFiltersAndLoadUsers());
    }, [dispatch]);

    return (
        <>
            <div className="stripedbox">
                <a href='/SuperUser/Users/Add' className="rbtn primary right"><FontAwesomeIcon icon={faUserPlus} />Add</a>
                <button onClick={onResetClick} className="rbtn primary right"><FontAwesomeIcon icon={faSync} />Reset</button>
                <h2>User Accounts</h2>
            </div>

            <TableWrapperWithLoading hasResults={hasResults} loading={loading} />
        </>
    )
};
