import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { onGetAllOfTypeAsync } from '../../../../api-endpoint-calls/case/notes';

import { LoadingV1 } from '../../../common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faStickyNote } from '@fortawesome/free-solid-svg-icons';


export default ({onClose, note, disabled}) => {

    const [visible, setVisible] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [successful, setSuccessful] = useState(false);

    const [notes, setNotes] = useState([]);
    
    const { noteTypeId, caseId, applicantId } = note;


    useEffect(() => {
        if (visible === false) {
            setTimeout(() =>  setVisible(true), 200);
        }
    }, [visible, setVisible]);


    useEffect(() => {
        const source = axios.CancelToken.source();

        const onPageLoadAsync = async () => {
            const result = await onGetAllOfTypeAsync(noteTypeId, caseId, applicantId, source);
            
            setLoaded(result.isLoaded);
            setSuccessful(result.isSuccessful);

            if (result.isSuccessful) {
                setNotes(result.data.notes);
            }
        }

        onPageLoadAsync();

        return () => {
            source.cancel();
        }
        
    }, [noteTypeId, caseId, applicantId, setLoaded, setSuccessful]);

    const showSpinner = (!loaded);
    const showFailure = (loaded && !successful);

    const noNotesToShow = notes.length === 0
        ? (
            <div className="note-container">
                <div className="fake-input notes-control">
                    <div className="notes-text">No notes to show.</div>
                </div>
            </div>
        )
        : null;

    return (
        <>
            <div className={ `react-modal-anchor ${(visible ? 'show' : '')}`}>
                <div className={ `react-modal add-note ${visible ? 'show' : ''}` }>
                    <button onClick={() => onClose()} className="close-button"><FontAwesomeIcon icon={faTimes} /></button>
                    <h1>
                        <FontAwesomeIcon icon={faStickyNote} />
                        Note History
                    </h1>

                    <div className="stripedbox">
                        { showSpinner ? <LoadingV1 /> : ( showFailure ? <h2>An error has occured!</h2> : noNotesToShow) }
                        { notes.map((item) => {
                            
                            const formattedContent = item.content ? item.content.replace(/\n/g, '<br />') : item.content;
                            return (
                                <div className="notes-container" key={item.dateCreated}>
                                    <div className="fake-input notes-control">
                                        <div className="notes-text" dangerouslySetInnerHTML={{__html: formattedContent}}></div>
                                        <div className="notes-widget-footer">
                                            <span>
                                                <span className="purple">{item.noteCreator}</span>
                                                <span> on </span>
                                                <span className="darkGray">{item.dateCreated}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )
                        }) }
                    </div>
                </div>
            </div>
            <div className={ `react-modal-backdrop ${visible ? 'show' : ''}` }></div>
        </>
    );
    
}