import React from 'react';

export default ({ rows, onViewHistoryRecord }) => (
    <div className="stripedbox immigration-history">
        <div className="summary-rows">
            <div className="header">
                <span style={{width: "110px"}}>Date of Status Check</span>
                <span style={{width: "220px"}}>Immigration Status</span>
                <span style={{width: "120px"}}>Barrier to Removal / Return</span>
                <span style={{width: "120px"}}>Edited By</span>
                <span style={{width: "120px"}}>Organisation</span>
                <span style={{width: "75px"}}></span>
            </div>
            {
                rows.map((values, index) =>
                    <div key={`history_${values.dateOfStatusCheck}_${index}`} className="body-row">
                        <span>{values.dateOfStatusCheck}</span>
                        <span>{values.immigrationStatus}</span>
                        <span>{values.barrierToRemovalReturn}</span>
                        <span>{values.editedBy}</span>
                        <span>{values.organisation}</span>
                        <span>
                            <button type="button" className="rbtn light-grey" onClick={() => onViewHistoryRecord(values.id)}>
                                View
                            </button>
                        </span>
                    </div>
                )
            }
        </div>
    </div>
)