import React from 'react';
import {matchSorter} from 'match-sorter';
import moment from 'moment';

export const DefaultColumnFilter = ({ column }) => {
    const { filterValue, setFilter } = column;

    return (
        <input
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
            placeholder={`Search ${column.Header}`}
        />
    )
}

export const SelectColumnFilter = ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
    const options = React.useMemo(() => {
        const options = new Set()
        preFilteredRows.forEach(row => {
            options.add(row.values[id]);
        });
        return [...Array.from(options.values()).sort()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
        <select
            value={filterValue}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
        >
            <option value="">All</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
    )
}

export const SelectStatusColumnFilter = ({ column: { filterValue, setFilter } }) => {
    return (
        <select
            value={filterValue}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
        >
            <option value="">All</option>
            <option value="false">Active</option>
            <option value="true">LOCKED</option>
        </select>
    )
}

export const SelectLoggedInColumnFilter = ({ column: { filterValue, setFilter } }) => {
    return (
        <select
            value={filterValue}
            onChange={e => {
                setFilter(e.target.value || undefined);
            }}
        >
            <option value="">All</option>
            <option value="lw">Last week</option>
            <option value="lm">Last month</option>
            <option value="l6m">&lt; 6 months</option>
            <option value="g6m">&gt; 6 months</option>
            <option value="g1y">&gt; 1 year</option>
            <option value="g2y">&gt; 2 years</option>
            <option value="n">Never</option>
        </select>
    )
}

export const getDatesForSelectFilter = (optionValue) => {

    const minDate = moment("2010-01-01", "YYYY-MM-DD").toDate();
    const now = moment().toDate();

    switch(optionValue) {
        case "lw":
            return {
                from: moment().subtract(7,'days').toDate(),
                to: now
            }
        case "lm":
            return {
                from: moment().subtract(1,'month').toDate(),
                to: now
            }
        case "l6m":
            return {
                from: moment().subtract(6,'months').toDate(),
                to: now
            }
        case "g6m":
            return {
                from: minDate,
                to: moment().subtract(6,'months').toDate()
            }
        case "g1y":
            return {
                from: minDate,
                to: moment().subtract(1,'year').toDate()
            }
        case "g2y":
            return {
                from: minDate,
                to: moment().subtract(2,'years').toDate()
            }
        case "n":
            return {
                from: '',
                to: ''
            }
        default:
            return {
                from: minDate,
                to: now
            }
    }
}

const fuzzyTextFilterFnSetup = (rows, id, filterValue) => {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}
// Let the table remove the filter if the string is empty
fuzzyTextFilterFnSetup.autoRemove = val => !val;
export const fuzzyTextFilterFn = fuzzyTextFilterFnSetup;