import { useState } from 'react';

const useSequentialLookUps = (lookUps) => {
    const onSuccess = (id, result) => {
        setResult(result)
    }
    const onFailure = (id) => {
        const lookUpsToShow = renderedLookUps.length > id ? id + 1 : id;
        setLookUpStatuses(renderedLookUps.slice(0, lookUpsToShow))
    }
    const renderedLookUps = lookUps.map((item, index) => {
        return item(index + 1, onSuccess, onFailure)
    })
    const [lookUpStatuses, setLookUpStatuses] = useState([renderedLookUps[0]])
    const [result, setResult] = useState(null)

    return { lookUpStatuses, result }
}

export default useSequentialLookUps