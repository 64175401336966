import React from "react";
import { Field, Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faFlagCheckered } from '@fortawesome/free-solid-svg-icons';

import { renderInput, renderDropdownList, renderRadioOption, toOptionsWithDefault } from '../../../form-inputs/final-form';


const HomeOfficeOptions = ({isShowingAliasDetails, homeOfficeTeamOptions}) => {

    const aliasOptions = isShowingAliasDetails
    ? (
        <>
            <label className="full-row">
                Please enter the preferred details to be shown:
            </label>

            <div className="input-wrapper">
                <Field name="aliasEmailAddress" type="text" component={renderInput} label="Email Address" tabIndex={12} />
            </div>
            <div className="input-wrapper">
                <Field name="aliasTelephoneNumber" type="text" component={renderInput} label="Telephone Number" tabIndex={13} />
            </div>
        </>
    ) : null;

    return (
        <>
            <div className="input-wrapper">
                <label>Home Office <span className="mandatory-stars">*</span></label>
                <Field name="authorityName" type="text" component={renderInput} disabled={true} tabIndex={8} />
            </div>
            <div className="input-wrapper">
                <label>Home Office Team <span className="mandatory-stars">*</span></label>
                <Field name="homeOfficeTeamId" component={renderDropdownList} options={homeOfficeTeamOptions} tabIndex={9} />
            </div>

            <div className="full-row radio-option input-wrapper">
                <label>When case is allocated to this user, should we display their personal contact details?</label>
                
                <Field name="isShowingAliasDetails" type="radio" render={renderRadioOption} value="false" label="Yes" tabIndex={11} />
                <Field name="isShowingAliasDetails" type="radio" render={renderRadioOption} value="true" label="No" tabIndex={11} />
            </div>

            { aliasOptions }
        </>
    );
}


const LocalAuthorityOptions = () => {

    return (
        <div className="input-wrapper">
            <label>Local Authority <span className="mandatory-stars">*</span></label>
            <Field name="authorityName" type="text" component={renderInput} isMandatory={true} disabled={true} tabIndex={8} />
        </div>
    );
}

const AddForm = ({ onSubmit, initialValues }) => {

    const history = useHistory();

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            render={({ handleSubmit, submitting, values }) => {
            
                let { userIsHomeOffice, isShowingAliasDetails } = values;

                userIsHomeOffice = (userIsHomeOffice && JSON.parse(userIsHomeOffice)) || false;
                isShowingAliasDetails = (isShowingAliasDetails && JSON.parse(isShowingAliasDetails)) || false;

                const accessLevelOptions = values.accessLevels || [];
                const homeOfficeTeamOptions = toOptionsWithDefault(values.litOffices || []);

                var organisationOptions = userIsHomeOffice 
                    ? <HomeOfficeOptions isShowingAliasDetails={isShowingAliasDetails} homeOfficeTeamOptions={homeOfficeTeamOptions}  />
                    : <LocalAuthorityOptions />

                return (
                    <form onSubmit={handleSubmit} autoComplete="off">

                        <div className="mandatory"><span>*</span> Denotes a mandatory field </div>

                        <div className="user-edit-form">
                            <div className="input-wrapper">
                                <Field name="userName" type="text" component={renderInput} label="Username" isMandatory={true} tabIndex={1} />
                            </div>
                            <div className="input-wrapper">
                                <Field name="telNumber" type="text" component={renderInput} label="Telephone Number" isMandatory={true} tabIndex={4} />
                            </div>

                            <div className="input-wrapper">
                                <Field name="fullName" type="text" component={renderInput} label="Full Name" isMandatory={true} tabIndex={2} />
                            </div>
                            <div className="input-wrapper">
                                <Field name="email" type="text" component={renderInput} label="Email Address" isMandatory={true} tabIndex={5} />
                            </div>

                            <div className="input-wrapper">
                                <label>Access Level <span className="mandatory-stars">*</span></label>
                                <Field name="accessLevel" component={renderDropdownList} options={accessLevelOptions} isClearable={false} tabIndex={3} />
                            </div>
                        </div>

                        <hr />

                        <div className="user-edit-form">
                            <div className="input-wrapper">
                                <Field name="password" type="password" component={renderInput} label="New Password" isMandatory={true} tabIndex={6} />
                            </div>
                            <div className="input-wrapper">
                                <Field name="confirmPassword" type="password" component={renderInput} label="Confirm Password" isMandatory={true} tabIndex={7} />
                            </div>
                        </div>

                        <hr />

                        <div className="user-edit-form">
                            { organisationOptions }
                        </div>

                        <hr />

                        <div className="user-edit-form">
                            <div className="full-row align-center">
                                <div className="form-buttons">
                                    <button type="button" onClick={() => history.go(-1)} className="rbtn secondary" tabIndex={15} disabled={submitting}>
                                        <FontAwesomeIcon icon={faArrowLeft} /><div>Back</div>
                                    </button>
                                    <button type="submit" className="rbtn primary" tabIndex={14} disabled={submitting}>
                                        <FontAwesomeIcon icon={faFlagCheckered} /><div>Save</div>
                                    </button>
                                </div>
                            </div>
                        </div>

                    </form>
                );
            }}
        />
    );
}


export default AddForm;