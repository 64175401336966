import React from 'react';
import { useSelector } from "react-redux";

import { selector } from '../../../state/reducers/queries-reducer';

import QueryContent from '../content';


export default ({row}) => {

	const { original: { caseId, queryId } } = row;
	const disabled = false;
	
	const isExpanded = useSelector(state => selector.isExpanded(state, queryId));
	
	if (!isExpanded)
		return null;
	
	return (
		<div className="result-row-details">
			<QueryContent
				caseId={caseId}
				caseQueryId={queryId}
				disabled={disabled}
				fromCase={false}
			/>
		</div>
	);
}