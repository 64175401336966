import React, { useState, useEffect } from 'react';

import { onGetAllForCaseAsync, onDeleteSubmitAsync } from '../../../../../api-endpoint-calls/case/dependants';

import Form from './form';

import { errorNotification } from '../../../../common';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faTimes } from '@fortawesome/free-solid-svg-icons';


export default ({onClose, caseId, applicantId}) => {

    const [visible, setVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const initialValues = {
        caseId,
        id: applicantId
    };
    
    useEffect(() => {
        if (visible === false) {
            setTimeout(() =>  setVisible(true), 200);
        }
    }, [visible, setVisible]);

    const onSubmit = async (formValues) => {
        const result = await onDeleteSubmitAsync(formValues);
        
        if (result.isSuccess) {
            setErrorMessage(null);
            
            const dependants = await onGetAllForCaseAsync(caseId);
            onClose(dependants.data);
        } else {
            setErrorMessage(result.message);
        }

        return result.errors;
    }

    const errorAlert = errorNotification(errorMessage);
    
    return (
        <>
            <div className={ `react-modal-anchor ${(visible ? 'show' : '')}`}>
                <div className={ `react-modal user-delete ${visible ? 'show' : ''}` } style={{ width: '500px' }}>
                    <button onClick={() => onClose()} className="close-button"><FontAwesomeIcon icon={faTimes} /></button>
                    <h1>
                        <FontAwesomeIcon icon={faTrash} />
                        Delete Dependant?
                    </h1>

                    <div className="stripedbox">
                        {errorAlert}
                        <Form
                            onSubmit={onSubmit}
                            onClose={onClose}
                            initialValues={initialValues} />
                    </div>
                </div>
            </div>
            <div className={ `react-modal-backdrop ${visible ? 'show' : ''}` }></div>
        </>
    );
    
}