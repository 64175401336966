import React, { useState, useEffect } from 'react';

import { onUpdateSubmitAsync } from '../../../../../../api-endpoint-calls/case/applicants-image';

import Form from './form';

import { errorNotification } from '../../../../../common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhotoVideo, faTimes } from '@fortawesome/free-solid-svg-icons';

export default ({onClose, caseId, applicantId}) => {

    const initialValues = {
        caseId,
        applicantId
    }

    const [visible, setVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        if (visible === false) {
            setTimeout(() =>  setVisible(true), 200);
        }
    }, [visible, setVisible]);

    useEffect(() => {
        var { unsubscribe } = window.dispatcher.subscribe("change-tracker-modal-close", () => onClose());

        return () => {
            unsubscribe();
        }
    }, [onClose]);

    
    const onCloseClick = () => {
        window.dispatcher.dispatchEvent("change-tracker-modal-closing");
    }

    const onFormSubmit = async (formValues) => {
        const result = await onUpdateSubmitAsync(formValues);

        if (result.isSuccess) {
            window.dispatcher.dispatchEvent("change-tracker-modal-reset");

            onClose(result.value);
        } else {
            setErrorMessage(result.message);
        }

        return result.errors;
    };

    const errorAlert = errorNotification(errorMessage);

    return (
        <>
            <div className={ `react-modal-anchor ${(visible ? 'show' : '')}`}>
                <div className={ `react-modal update-photo ${visible ? 'show' : ''}` }>
                    <button onClick={onCloseClick} className="close-button"><FontAwesomeIcon icon={faTimes} /></button>
                    <h1>
                        <FontAwesomeIcon icon={faPhotoVideo} />
                        Update Photo
                    </h1>

                    <div className="stripedbox">
                        {errorAlert}
                        <Form onSubmit={onFormSubmit} initialValues={initialValues} />
                    </div>
                </div>
            </div>
            <div className={ `react-modal-backdrop ${visible ? 'show' : ''}` }></div>
        </>
    );
    
}