import React, { useState, useEffect } from 'react';

import { onDeleteSubmitAsync } from '../../../../../../api-endpoint-calls/admin/users';

import Form from './form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserSlash, faTimes } from '@fortawesome/free-solid-svg-icons';


export default ({onSuccess, onClose, userId, replacementUsers}) => {

    const [visible, setVisible] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);

    const initialValues = {
        userId
    };
    
    useEffect(() => {
        if (visible === false) {
            setTimeout(() =>  setVisible(true), 200);
        }
    }, [visible, setVisible]);

    const onSubmit = async (formValues) => {
        const result = await onDeleteSubmitAsync(formValues);
        
        if (result.isSuccess) {
            setShowConfirmation(true);
            onSuccess();
        }

        return result.errors;
    }

    if (showConfirmation)
        return null;

    return (
        <>
            <div className={ `react-modal-anchor ${(visible ? 'show' : '')}`}>
                <div className={ `react-modal user-delete ${visible ? 'show' : ''}` } style={{ width: '500px' }}>
                    <button onClick={onClose} className="close-button"><FontAwesomeIcon icon={faTimes} /></button>
                    <h1>
                        <FontAwesomeIcon icon={faUserSlash} />
                        Delete User?
                    </h1>

                    <Form
                        onSubmit={onSubmit}
                        onClose={onClose}
                        initialValues={initialValues}
                        replacementUsers={replacementUsers} />
                </div>
            </div>
            <div className={ `react-modal-backdrop ${visible ? 'show' : ''}` }></div>
        </>
    );
    
}