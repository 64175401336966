import React, { useEffect, useState, useMemo, useCallback } from 'react';
import axios from 'axios';

import { caseArea, caseId, isClosed, userIsCaseOwner, userIsHomeOffice, principalApplicantName } from '../server-values';
import { onLoadLatestAsync, onAddSubmitAsync } from '../../../api-endpoint-calls/case/immigrations';

import scrollToTopOfPage from '../../../extensions/page.scrollToTop';

import ImmigrationForm from './form';
import EditSolicitorModal from './solicitor/edit';
import DeleteSolicitorModal from './solicitor/delete';
import EditFileOwnerModal from './file-owner/edit';
import DeleteFileOwnerModal from './file-owner/delete';
import HistoryModal from "./history";

import emptyGuid from '../../../extensions/guid.empty';
import useListState from '../use-list-state';
import useNoteState from '../notes/use-note-state';
import getHighlights from './highlights';

import useQueriesState from '../../queries/use-query-state';
import AddQueryModal from '../../queries/add';
import ViewQueryModal from '../../queries/view';

import { LoadingV1, successNotification } from '../../common';

var defaultValues = {
    rtpfLookupDetails: {}
}

const Immigration = () => {

    const [loaded, setLoaded] = useState(false);
    const [successful, setSuccessful] = useState(false);
    const [initialValues, setInitialValues] = useState(defaultValues);

    const [saveMessage, setSaveMessage] = useState(null);

    const disabled = isClosed || (!userIsCaseOwner && !userIsHomeOffice);
    const highlights = useMemo(() => getHighlights(initialValues.highlightProperties), [initialValues]);

    const {
        items: solicitors,
        setItems: setSolicitors,
        onEditModalRequest: onEditSolicitorModalRequest,
        editModal: editSolicitorModal,
        deleteModal: deleteSolicitorModal
    } = useListState(
        emptyGuid,
        (id, onClose, onDeleteRequest) => <EditSolicitorModal onClose={onClose} caseId={caseId} solicitorId={id} disabled={disabled} onDeleteSolicitorModalRequest={onDeleteRequest} />,
        (id, onClose) => <DeleteSolicitorModal onClose={onClose} caseId={caseId} solicitorId={id} disabled={disabled} />
    )

    const {
        items: fileOwners,
        setItems: setFileOwners,
        onEditModalRequest: onEditFileOwnerModalRequest,
        editModal: editFileOwnerModal,
        deleteModal: deleteFileOwnerModal
    } = useListState(
        emptyGuid,
        (id, onClose, onDeleteRequest) => <EditFileOwnerModal onClose={onClose} fileOwnerId={id} disabled={disabled} onDeleteFileOwnerModalRequest={onDeleteRequest} />,
        (id, onClose) => <DeleteFileOwnerModal onClose={onClose} fileOwnerId={id} disabled={disabled} />
    )

    const {
        item: localAuthorityNote,
        setItem: setLocalAuthorityNote,
        onAddModalRequest: onAddLocalAuthorityNoteModalRequest,
        onHistoryModalRequest: onLocalAuthorityNoteHistoryModalRequest,
        addModal: addLocalAuthorityNoteModal,
        historyModal: localAuthorityNoteHistoryModal
    } = useNoteState()

    const {
        item: homeOfficeNote,
        setItem: setHomeOfficeNote,
        onAddModalRequest: onAddHomeOfficeNoteModalRequest,
        onHistoryModalRequest: onHomeOfficeNoteHistoryModalRequest,
        addModal: addHomeOfficeNoteModal,
        historyModal: homeOfficeNoteHistoryModal
    } = useNoteState()

    const {
        queries,
        setQueries,
        onAddQueryModalRequest,
        onViewQueryModalRequest,
        addQueryModal,
        viewQueryModal,
    } = useQueriesState(
        0,
        (onClose) => <AddQueryModal onClose={onClose} caseId={caseId} userIsHomeOffice={userIsHomeOffice} principalApplicantName={principalApplicantName} disabled={isClosed} />,
        (id, onClose) => <ViewQueryModal onClose={onClose} caseId={caseId} caseQueryId={id} disabled={isClosed} />
    )

    const [showHistoryModal, setShowHistoryModal] = useState(false);
    const onHistoryModalRequest = () => {
        setShowHistoryModal(true);
    }
    const onHistoryModalClose = () => {
        setShowHistoryModal(false);
    }

    const historyModal = showHistoryModal
        ? <HistoryModal onClose={onHistoryModalClose} caseId={caseId} />
        : null;

    const onPageLoadAsync = useCallback(async (source) => {
        const result = await onLoadLatestAsync(caseId, source);

        setLoaded(result.isLoaded);
        setSuccessful(result.isSuccessful);

        if (result.initialValues) {
            setInitialValues({ ...result.initialValues });
            setSolicitors(result.initialValues.options.solicitors);
            setFileOwners(result.initialValues.options.fileOwners);
            setLocalAuthorityNote(result.initialValues.localAuthorityNote);
            setHomeOfficeNote(result.initialValues.homeOfficeNote);
            setQueries(result.initialValues.queries);
        }
    }, [setInitialValues, setSolicitors, setFileOwners, setLocalAuthorityNote, setHomeOfficeNote, setQueries, setLoaded, setSuccessful]);

    const onPageReload = async () => {
        const source = axios.CancelToken.source();
        await onPageLoadAsync(source);
    }

    useEffect(() => {
        const source = axios.CancelToken.source();
        onPageLoadAsync(source);

        return () => {
            source.cancel();
        }

    }, [onPageLoadAsync]);


    const showSpinner = (!loaded);
    const showFailure = (loaded && !successful);

    if (showSpinner) {
        return <LoadingV1 />;
    } else if (showFailure) {
        return <h2>An error has occured!</h2>;
    }

    const successAlert = successNotification(saveMessage);

    const onFormSubmit = async (formValues) => {

        const result = await onAddSubmitAsync(formValues);

        if (result.isSuccess) {
            window.dispatcher.dispatchEvent('change-tracker-reset');

            if (userIsHomeOffice) {
                window.location = `/${caseArea}/Summary/Display?iCaseID=${caseId}`;
            } else {
                setSaveMessage("All Data Saved Successfully!");
                scrollToTopOfPage();
                onPageReload();
            }
        }

        return result.errors;
    }


    return (
        <div className="case-immigration-edit-page">
            { successAlert }
            <ImmigrationForm
                onSubmit={onFormSubmit}
                initialValues={initialValues}
                isClosed={isClosed}
                solicitors={solicitors}
                fileOwners={fileOwners}
                localAuthorityNote={localAuthorityNote}
                homeOfficeNote={homeOfficeNote}
                queries={queries}
                highlights={highlights}
                onEditSolicitorModalRequest={onEditSolicitorModalRequest}
                onEditFileOwnerModalRequest={onEditFileOwnerModalRequest}
                onAddLocalAuthorityNoteModalRequest={onAddLocalAuthorityNoteModalRequest}
                onLocalAuthorityNoteHistoryModalRequest={onLocalAuthorityNoteHistoryModalRequest}
                onAddHomeOfficeNoteModalRequest={onAddHomeOfficeNoteModalRequest}
                onHomeOfficeNoteHistoryModalRequest={onHomeOfficeNoteHistoryModalRequest}
                onAddQueryModalRequest={onAddQueryModalRequest}
                onViewQueryModalRequest={onViewQueryModalRequest}
                onHistoryModalRequest={onHistoryModalRequest}
            />
            {editSolicitorModal}
            {deleteSolicitorModal}
            {editFileOwnerModal}
            {deleteFileOwnerModal}
            {addLocalAuthorityNoteModal}
            {localAuthorityNoteHistoryModal}
            {addHomeOfficeNoteModal}
            {homeOfficeNoteHistoryModal}
            {addQueryModal}
            {viewQueryModal}
            {historyModal}
        </div>
    );
}

export default Immigration;
