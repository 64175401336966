import React from 'react';

export default ({gotoPage, canPreviousPage, canNextPage, rowCount, pageCount, pageIndex, pageOptions, pageSize, setPageSize, isHomeOfficeUser, rowDescription}) => (
    <div className="pagination">
        <div className="total-records">
          <span>Displaying </span> <span className="bold">{rowCount} {rowDescription}</span>
        </div>

        <div className="per-page">
          <span>Results per page: </span>
          <select className="keep-original" value={pageSize} onChange={e => {setPageSize(Number(e.target.value))}}>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={500}>500</option>
            { isHomeOfficeUser ? null : <option value={9999}>ALL</option> }
          </select>
        </div>

        <div className="page-buttons">
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {'<<'}
          </button>{' '}
          <button onClick={() => gotoPage(pageIndex - 1)} disabled={!canPreviousPage}>
            {'<'}
          </button>{' '}
          <span>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </span>
          <button onClick={() => gotoPage(pageIndex + 1)} disabled={!canNextPage}>
            {'>'}
          </button>{' '}
          <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            {'>>'}
          </button>
        </div>
      </div>
)