import axios from 'axios';
import {selector} from '../../reducers/search-form/by-personal-details';
import { searchTypes } from '../../reducers/search-reducer';
import * as searchActions from '../search-actions';

import dateFormatForServer from '../../../extensions/date.formatForServer';
import stringIsNullOrEmpty from '../../../extensions/string.isNullOrEmpty';

export const actions = {
    setFormValues: 'SEARCH_BYPERSONALDETAILS_SET_FORM_VALUES',
    reset: 'SEARCH_BYPERSONALDETAILS_RESET',
};

const action = (type, data = null) => ({
    type, data
});

export function onSearchByPersonalDetailsChange(changes) {
    return (dispatch, getState) => {

        const formValues = selector.formValues(getState());
        const byPersonalDetailsFormValues = {
            ...formValues,
            ...changes
        };

        const { name, dateOfBirth, reference } = byPersonalDetailsFormValues;
        const canPerformFind = stringIsNullOrEmpty(name) === false
            || dateOfBirth !== null
            || stringIsNullOrEmpty(reference) === false;

        dispatch(action(actions.setFormValues, {byPersonalDetailsFormValues, canPerformFind}));
    }
}

export function searchByPersonalDetails() {

    const { SEARCH_START, SEARCH_SUCCESS, SEARCH_FAILED } = searchActions;

    return async (dispatch, getState) => {

        const fv = selector.formValues(getState());

        const formValues = {
            name: fv.name,
            DateOfBirth: dateFormatForServer(fv.dateOfBirth),
            Reference: fv.reference
        }

        dispatch(action(SEARCH_START, { searchType: searchTypes.byPersonalDetails, searchParams: formValues }));

        try {
            const response = await axios.post('/api/search/by-personal-details', formValues);
            dispatch(action(SEARCH_SUCCESS, response.data || {}));
        } catch (err) {
            dispatch(action(SEARCH_FAILED));
        }
    }
}