import React from "react";
import { Field } from 'react-final-form';


import { renderDropdownList, renderGroupedDropdownList, renderDatePicker, renderErrorMessage } from '../../form-inputs/final-form';

import ReferralServices from './services';
import NoteWidget from "../notes/widget";

const ReferralFormFields = ({ form, selectedServiceIds, services, referralOrganisationId, 
    reasonsForApplyingOptions, legislationOptions, referralOrganisationOptions, disabled, 
    referralNote, onEditReferralOrganisationModalRequest, onAddReferralNoteModalRequest, 
    onReferralNoteHistoryModalRequest }) => {

    const referralOrganisationButtons = !disabled
        ? (
            <div className="select-with-options-buttons">
                <button type="button" className="rbtn primary small" onClick={() => onEditReferralOrganisationModalRequest(0)}>Add Referral Organisation</button>
                {referralOrganisationId ? <button type="button" className="rbtn primary small" onClick={() => onEditReferralOrganisationModalRequest(referralOrganisationId)}>View / Manage</button> : null}
            </div>
        )
        : null;

    return (
        <>
            <div className="input-wrapper">
                <Field name="dateOfReferral" type="text" render={renderDatePicker} label="Date Of Referral" isMandatory={true} placeholder="" maxDate={new Date()} disabled={disabled} />
            </div>

            <div className="input-wrapper">
                <label>Referral Organisation</label>
                <div className="select-with-options">
                    <Field name="referralOrganisationId" render={renderDropdownList} options={referralOrganisationOptions} disabled={disabled} />
                    {referralOrganisationButtons}
                </div>

            </div>

            <div className="input-wrapper">
                <label>Primary reason for referral <span className="mandatory-stars">*</span></label>
                <Field name="applicationReasonId" render={renderDropdownList} options={reasonsForApplyingOptions} disabled={disabled} />
            </div>


            <div className="input-wrapper">
                <label>Services Requested <span className="mandatory-stars">*</span></label>
                <Field name="selectedServiceIds" render={renderErrorMessage}>
                    <ReferralServices form={form} selectedServiceIds={selectedServiceIds} services={services} disabled={disabled} />
                </Field>
            </div>

            <div className="input-wrapper">
                <label>Legislation <span className="mandatory-stars">*</span></label>
                <Field name="legislationId" render={renderGroupedDropdownList} options={legislationOptions} disabled={disabled} />
            </div>

            <div className="input-wrapper">
                <label>Notes</label>
                <NoteWidget
                    note={referralNote}
                    disabled={disabled}
                    onAddModalRequest={onAddReferralNoteModalRequest}
                    onHistoryModalRequest={onReferralNoteHistoryModalRequest} />
            </div>

        </>


    )
}


export default ReferralFormFields;
