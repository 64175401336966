import React, { useState } from 'react';

import CaseWorkerModal from '../caseworker-modal';

const useLocalAuthorityCaseworkerForGrid = ({ localAuthorityCaseWorker }) => {

    const [showLocalAuthority, setShowLocalAuthority] = useState(false);

    const onLocalAuthorityClick = () => {
        setShowLocalAuthority(true);
    }
    const onLocalAuthorityClose = () => {
        setShowLocalAuthority(false);
    }


    const localAuthorityUserModal = showLocalAuthority
        ? <CaseWorkerModal onClose={onLocalAuthorityClose} name={localAuthorityCaseWorker.name} telephone={localAuthorityCaseWorker.telephone} email={localAuthorityCaseWorker.email} />
        : null;

    return (
        <>
            <label>
                Local Authority User
            </label>
            <span className="input-with-button">
                <div className="fake-input">
                    {localAuthorityCaseWorker.name}
                </div>
                <button type="button" className="rbtn primary" style={{ width: "80px" }} onClick={onLocalAuthorityClick}>
                    <span>
                        View
                    </span>
                </button>
            </span>

            {localAuthorityUserModal}
        </>
    )
}

export default useLocalAuthorityCaseworkerForGrid;