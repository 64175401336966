import React from 'react';
import { Form } from 'react-final-form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faTrash } from '@fortawesome/free-solid-svg-icons';


const DeleteForm = ({onSubmit, onClose, initialValues}) => {

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            render={({handleSubmit}) => {

                return (
                    <form autoComplete="off" onSubmit={handleSubmit}>

                        <p>Are you sure you want to delete this solicitor?</p>

                        <div className="button-row p-15">
                            <button onClick={() => onClose()} className="rbtn secondary"><FontAwesomeIcon icon={faTimesCircle} /><span>Cancel</span></button>
                            <button type="submit" className="rbtn primary"><FontAwesomeIcon icon={faTrash} /><span>Delete</span></button>
                        </div>
                    </form>
                );
            }}
        />
    )
}

export default DeleteForm;
