import React, { useState } from 'react';

import CaseWorkerModal from '../caseworker-modal';

const useHomeOfficeCaseworkerForGrid = ({ homeOfficeCaseWorker }) => {

    const [showHomeOffice, setShowHomeOffice] = useState(false);

    const onHomeOfficeClick = () => {
        setShowHomeOffice(true);
    }
    const onHomeOfficeClose = () => {
        setShowHomeOffice(false);
    }

    const homeOfficeUserModal = showHomeOffice
        ? <CaseWorkerModal onClose={onHomeOfficeClose} name={homeOfficeCaseWorker.name} telephone={homeOfficeCaseWorker.telephone} email={homeOfficeCaseWorker.email} />
        : null;

    return (
        <>
            <label>
                Home Office User
            </label>
            <span className="input-with-button">
                <div className="fake-input">
                    {homeOfficeCaseWorker.name}
                </div>
                <button type="button" className="rbtn primary" style={{ width: "80px" }} onClick={onHomeOfficeClick}>
                    <span>
                        View
                    </span>
                </button>
            </span>

            {homeOfficeUserModal}
        </>
    )
}

export default useHomeOfficeCaseworkerForGrid;