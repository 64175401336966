import * as actions from '../actions/filters-actions';

const initialState = {
  values: {
    legislationIds: [],
    caseWorkerIds: [],
    localAuthorityIds: [],
    hoCaseWorkerIds: [],
    alertTypeId: 0,
    caseLoadCurrentActivityId: 0,
    queriesTab: "R",
    queriesSubOption: "all",
  },
  filterOptions: {
    localAuthorities: [], 
    homeOfficeCaseWorkers: [], 
    legislations: [], 
    localAuthorityCaseWorkers: []
  },
  userDetails: {
    isHomeOfficeUser: false,
    organisation: ''
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.INITALISE:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          ...action.data
        }
      }
    case actions.SET_FILTERS_OPTIONS:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          ...action.data
        }
      }
    case actions.SET_FILTERS:
      return {
        ...state,
        values: {
          ...state.values,
          ...action.data
        }
      }
    case actions.RESET:
      return {
        ...state,
        values: initialState.values
      }
    default:
        return state;
  }
}


export const selector = {
  values: (state) => state.filters.values,
  filterOptions: (state) => state.filters.filterOptions,
  userDetails: (state) => state.filters.userDetails,
};
