import React from 'react';

import CaseOwnerForm from './case-owner';
import NonCaseOwnerForm from './non-case-owner';
import HomeOfficeForm from './home-office';

import { userIsCaseOwner, userIsHomeOffice } from '../../server-values';

export default (props) => {

    if (userIsCaseOwner)
        return <CaseOwnerForm {...props} />;

    if (userIsHomeOffice)
        return <HomeOfficeForm {...props} />;

    return <NonCaseOwnerForm {...props} />;
}