import axios from 'axios';
import moment from 'moment';

import { onFormSubmitAsync } from '../form-submit';

import dateFormatForServer from '../../extensions/date.formatForServer';

const baseUrl = '/api/case/applicant-with-referral';
export const urls = {
    new: `${baseUrl}`,
    duplicateCheck: `${baseUrl}/duplicate-check`,
    addSubmit: `${baseUrl}/add`
}



export const onLoadAsync = async (source) => {
    try {

        const response = await axios.get(urls.new, { cancelToken: source.token });
        const values = response.data || {};

        return {
            isLoaded: true,
            isSuccessful: true,
            initialValues: mappers.toReduxForm(values)
        };
    }
    catch (err) {
        return {
            isLoaded: true,
            isSuccessful: false
        }
    }
}

export const onDuplicateCheckAsync = async (formValues) => {
    try {
        var serverValues = mappers.toServerModel(formValues);
        const response = await axios.post(urls.duplicateCheck, serverValues);
        const values = response.data || {};

        return {
            isSuccessful: true,
            data: values
        };
    }
    catch (err) {
        return {
            isSuccessful: false
        }
    }
}


export const onAddSubmitAsync = async (formValues) => {
    const serverModel = mappers.toServerModel(formValues);
    return await onFormSubmitAsync(urls.addSubmit, serverModel);
}


const mappers = {
    toReduxForm: (values) => ({
        ...values,
        dateOfBirth: values.dateOfBirth ? moment(values.dateOfBirth)._d : null,
        dateOfDeath: values.dateOfDeath ? moment(values.dateOfDeath)._d : null,
        dateOfReferral: values.dateOfReferral ? moment(values.dateOfReferral)._d : null
    }),
    toServerModel: (values) => ({
        ...values,
        dateOfBirth: dateFormatForServer(values.dateOfBirth),
        dateOfDeath: dateFormatForServer(values.dateOfDeath),
        dateOfReferral: dateFormatForServer(values.dateOfReferral)
    })
}


export default {
    onLoadAsync,
    onDuplicateCheckAsync,
    onAddSubmitAsync,
}