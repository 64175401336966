import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { onLoadAsync, onAddSubmitAsync } from '../../../../api-endpoint-calls/case/reopens';

import Form from './form';

import { errorNotification, LoadingV1 } from '../../../common';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faUndo } from '@fortawesome/free-solid-svg-icons';


export default ({ onClose, caseId }) => {

    const [visible, setVisible] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [successful, setSuccessful] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const [initialValues, setInitialValues] = useState({
        caseId
    });

    useEffect(() => {
        const source = axios.CancelToken.source();

        const onPageLoadAsync = async () => {
            const result = await onLoadAsync(caseId, source);

            setLoaded(result.isLoaded);
            setSuccessful(result.isSuccessful);

            if (result.initialValues) {
                setInitialValues(result.initialValues);
            }
        }

        onPageLoadAsync();

        return () => {
            source.cancel();
        }

    }, [caseId, setLoaded, setSuccessful, setInitialValues]);

    useEffect(() => {
        if (visible === false) {
            setTimeout(() => setVisible(true), 200);
        }
    }, [visible, setVisible]);

    useEffect(() => {
        var { unsubscribe } = window.dispatcher.subscribe("change-tracker-modal-close", () => onClose());

        return () => {
            unsubscribe();
        }
    }, [onClose]);


    const onCloseClick = () => {
        window.dispatcher.dispatchEvent("change-tracker-modal-closing");
    }

    const onSubmit = async (formValues) => {
        const result = await onAddSubmitAsync(formValues);

        if (result.isSuccess) {
            setErrorMessage(null);
            window.dispatcher.dispatchEvent("change-tracker-modal-reset");

            onClose("Saved Successfully, Case re-instated!");
            //onClose("Case reopened and Home Office notified.  Please proceed to update address and finance information as required");
        } else {
            setErrorMessage(result.message);
        }

        return result.errors;
    };


    const errorAlert = errorNotification(errorMessage);

    const showSpinner = (!loaded);
    const showFailure = (loaded && !successful);

    const contents = showSpinner
        ? <LoadingV1 />
        : showFailure
            ? <h2>An error has occured!</h2>
            : (
                <>
                    {errorAlert}
                    <Form onSubmit={onSubmit} initialValues={initialValues} />
                </>
            )


    return (
        <>
            <div className={`react-modal-anchor ${(visible ? 'show' : '')}`}>
                <div className={`react-modal reopen ${visible ? 'show' : ''}`}>
                    <button onClick={onCloseClick} className="close-button"><FontAwesomeIcon icon={faTimes} /></button>
                    <h1>
                        <FontAwesomeIcon icon={faUndo} />
                        Reopen the case
                    </h1>

                    <div className="stripedbox">
                        { contents }
                    </div>
                </div>
            </div>
            <div className={`react-modal-backdrop ${visible ? 'show' : ''}`}></div>
        </>
    );

}