import React, { useState, useEffect } from 'react';

import { onResetPasswordSubmitAsync } from '../../../../../../api-endpoint-calls/admin/users';

import Confirmation from './confirmation';
import Form from './form';

import { errorNotification } from '../../../../../common';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faTimes } from '@fortawesome/free-solid-svg-icons';


export default ({onClose, userId, isCurrentUser}) => {

    const [visible, setVisible] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    
    const initialValues = {
        userId
    };

    useEffect(() => {
        if (visible === false) {
            setTimeout(() =>  setVisible(true), 200);
        }
    }, [visible, setVisible]);


    const onSubmit = async (formValues) => {
        const result = await onResetPasswordSubmitAsync(formValues);
        
        if (result.isSuccess) {
            setShowConfirmation(true);
            setErrorMessage(null);
        } else {
            setErrorMessage(result.message);
        }

        return result.errors;
    }
    
    const errorAlert = errorNotification(errorMessage);
    
    let style = showConfirmation ? { width: '400px' } : { width: '500px' };
    let page = showConfirmation
        ? <Confirmation onClose={onClose} />
        : <div className="stripedbox">
            {errorAlert}
            <Form onSubmit={onSubmit} onClose={onClose} initialValues={initialValues} isCurrentUser={isCurrentUser} />;
        </div>

    return (
        <>
            <div className={ `react-modal-anchor ${(visible ? 'show' : '')}`}>
                <div className={ `react-modal reset-password ${visible ? 'show' : ''}` } style={ style }>
                    <button onClick={onClose} className="close-button"><FontAwesomeIcon icon={faTimes} /></button>
                    <h1>
                        <FontAwesomeIcon icon={faKey} />
                        Reset User Password
                    </h1>

                    { page }
                </div>
            </div>
            <div className={ `react-modal-backdrop ${visible ? 'show' : ''}` }></div>
        </>
    );
    
}