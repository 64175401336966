import React, { useMemo } from 'react';
import * as actions from '../../../../state/actions/case/summary-actions';

import { caseArea, userIsLocalAuthority, userIsHomeOffice, userIsCaseOwner, isClosed } from '../../server-values';

import DetailsGrid from './grid';
import Image from './image';
import AgeBadge from './age-badge';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPlus } from '@fortawesome/free-solid-svg-icons';


export default ({ summary, dispatch }) => {


    const onAllocateCaseToMeClick = (e) => {
        e.preventDefault();
        actions.allocateCaseWorker(dispatch, caseId, userIsLocalAuthority);
    }

    const onDeallocateCaseFromMeClick = (e) => {
        e.preventDefault();
        actions.deallocateCaseWorker(dispatch, caseId, userIsLocalAuthority);
    }

    const onPrincipalApplicantClick = (e) => {
        e.preventDefault();
        window.location = `/${caseArea}/Applicant/Edit?iCaseID=${caseId}`;
    }

    const onImmigrationClick = (e) => {
        e.preventDefault();
        window.location = `/${caseArea}/Immigration/Create?iCaseID=${caseId}`;
    }

    const onAddressClick = (e) => {
        e.preventDefault();
        window.location = `/${caseArea}/Address/History?CaseId=${caseId}`;
    }

    const onRtpfLookupClick = (e) => {
        e.preventDefault();
        actions.toggleRtpfModal(dispatch);
    }

    const {
        caseId, caseIsClosed,

        applicantId, principalApplicantName, photoFileName,

        immigrationStatus, currentAddress,
        laLookupReference,
        showChildLookedAfterBadge, showCareLeaverBadge,
        startDateOfServices
    } = summary;


    const imageIsChangeable = ((userIsCaseOwner || userIsHomeOffice) && caseIsClosed === false);
    const showReferralBadge = (userIsHomeOffice && caseIsClosed === false && startDateOfServices === "N/A" && showChildLookedAfterBadge === false && showCareLeaverBadge === false);
    const showCaseAllocationButton = ((userIsCaseOwner || userIsHomeOffice) && caseIsClosed === false);
    const showAddress = ((userIsCaseOwner || userIsHomeOffice));
    const showRtpfLookup = ((userIsCaseOwner || userIsHomeOffice));

    const referralBadge = useMemo(() => showReferralBadge ? <div className="referral-badge">REFERRAL</div> : null, [showReferralBadge]);

    const allocationButton = showCaseAllocationButton
        ? summary.isMyCase
            ? (
                <button type="button" className="rbtn primary allocation" onClick={onDeallocateCaseFromMeClick}>
                    <FontAwesomeIcon icon={faTimes} />
                    <span>Deallocate case from me</span>
                </button>
            )
            : (
                <button type="button" className="rbtn primary allocation" onClick={onAllocateCaseToMeClick}>
                    <FontAwesomeIcon icon={faPlus} />
                    <span>Allocate case to me</span>
                </button>
            )
        : null;



    const buttons = {
        viewUpdate: {
            text: "View / Update",
            style: { width: "145px" }
        },
        view: {
            text: "View",
            style: { width: "80px" }
        }
    };

    const canEditApplicant = userIsCaseOwner;
    const applicantButtonStyle = canEditApplicant ? buttons.viewUpdate.style : buttons.view.style;
    const applicantButtonText = canEditApplicant ? buttons.viewUpdate.text : buttons.view.text;

    const canEditImmigration = userIsCaseOwner || userIsHomeOffice;
    const immigrationButtonStyle = canEditImmigration ? buttons.viewUpdate.style : buttons.view.style;
    const immigrationButtonText = canEditImmigration ? buttons.viewUpdate.text : buttons.view.text;

    const addressButton = showAddress && userIsCaseOwner
        ? (
            <button type="button" className="rbtn primary" style={buttons.viewUpdate.style} onClick={onAddressClick}>
                <span>
                    {buttons.viewUpdate.text}
                </span>
            </button>
        )
        : null;

    const address = showAddress
        ? (
            <div className="case-details-flex-row">
                <label>
                    Current Address
                </label>
                <span className="input-with-button">
                    <div className="fake-input">
                        {currentAddress}
                    </div>
                    {addressButton}
                </span>
            </div>
        )
        : null;

    const rtpfButtonTooltip = userIsLocalAuthority
        ? "Use the ‘Recourse to Public Funds checker’ to look-up digital immigration status information. If no valid credentials or no digital immigration status found, please use existing case create / query functions."
        : "The ‘Recourse to Public Funds checker’ accesses a person’s UK Visa and Immigration account to confirm any digital immigration status held by the applicant."

    const rtpfLookup = showRtpfLookup
        ? (
            <div className="case-details-flex-row">
                <label>
                    Lookup Ref
                </label>
                <span className="input-with-button">
                    <div className="fake-input">
                        {laLookupReference}
                    </div>
                    <button
                        type="button"
                        className="rbtn green hover-tooltip"
                        style={buttons.viewUpdate.style}
                        onClick={onRtpfLookupClick}
                        disabled={isClosed}
                        data-tooltip={rtpfButtonTooltip}
                    >
                        <span>
                            RTPF Look-up
                        </span>
                    </button>
                </span>
            </div>
        )
        : null;

    return (
        <div className="stripedbox">
            <div className="summary-header">
                <div className="case-details-left">
                    <div className="case-details-flex-row">
                        <label>
                            Principal Applicant
                        </label>
                        <span className="input-with-button">
                            <div className="fake-input">
                                {principalApplicantName}
                            </div>
                            <button type="button" className="rbtn primary" style={applicantButtonStyle} onClick={onPrincipalApplicantClick}>
                                <span>
                                    {applicantButtonText}
                                </span>
                            </button>
                        </span>
                    </div>

                    <DetailsGrid
                        userIsCaseOwner={userIsCaseOwner}
                        userIsHomeOffice={userIsHomeOffice}
                        summary={summary} />

                    <div className="case-details-flex-row">
                        <label>
                            Immigration Status
                        </label>
                        <span className="input-with-button">
                            <div className="fake-input">
                                {immigrationStatus}
                            </div>
                            <button type="button" className="rbtn primary" style={immigrationButtonStyle} onClick={onImmigrationClick}>
                                <span>
                                    {immigrationButtonText}
                                </span>
                            </button>
                        </span>
                    </div>

                    {address}

                    {rtpfLookup}
                </div>

                <div className="case-details-right">
                    <div className="case-details-right-top">
                        {caseIsClosed ? <div className="closed-badge"></div> : null}
                        <Image isChangeable={imageIsChangeable} caseIsClosed={caseIsClosed} photoFileName={photoFileName} principalApplicantName={principalApplicantName} caseId={caseId} applicantId={applicantId} dispatch={dispatch} />

                        {referralBadge}

                        {allocationButton}

                    </div>

                    <AgeBadge userIsCaseOwner={userIsCaseOwner} summary={summary} />
                </div>
            </div>
        </div>
    );
}
