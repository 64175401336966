export default (fileName, contents) => {

    const binaryString = window.atob(contents);
    const byteArray = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      byteArray[i] = binaryString.charCodeAt(i);
    }

    const downloadedFile = new Blob([byteArray]);
    const url = URL.createObjectURL(downloadedFile);

    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;

    link.click();

    URL.revokeObjectURL(url);

}