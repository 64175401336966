import React from 'react';
import { Field } from "react-final-form";
import { renderDropdownList, renderDatePicker } from "../../../../form-inputs/final-form";

export default ({ values, highlights, disabled, asylumStatusesOptions, asylumEndedReasonsOptions }) => {

    if (!values.typeOfAsylumSupportId)
        return null;

    highlights = highlights || {};

    return (
        <>
            <div className="double-dates">
                <div className="input-wrapper-full-width">
                    <Field
                        name="dateAsylumSupportStarted"
                        type="text"
                        render={renderDatePicker}
                        label="Start date"
                        placeholder=""
                        isHighlighted={highlights.dateAsylumSupportStarted}
                        disabled={disabled}
                    />
                </div>

                <div className="input-wrapper-full-width">
                    <Field
                        name="dateAsylumSupportEnded"
                        type="text"
                        render={renderDatePicker}
                        label="End date"
                        placeholder=""
                        isHighlighted={highlights.dateAsylumSupportEnded}
                        disabled={disabled}
                    />
                </div>
            </div>

            <div className="input-wrapper-full-width">
                <label>Status of support</label>
                <Field
                    name="asylumSupportStatusId"
                    type="text"
                    render={renderDropdownList}
                    options={asylumStatusesOptions}
                    isHighlighted={highlights.asylumSupportStatusId}
                    disabled={disabled}
                />
            </div>

            <div className="input-wrapper">
                <Field
                    name="dateAsylumGracePeriod"
                    type="text"
                    render={renderDatePicker}
                    label="Start date of grace period"
                    placeholder=""
                    isHighlighted={highlights.dateAsylumGracePeriod}
                    disabled={disabled}
                />
            </div>

            <div className="input-wrapper-full-width">
                <label>Reason asylum support ended / due to end</label>
                <Field
                    name="asylumSupportEndedReasonId"
                    type="text"
                    render={renderDropdownList}
                    options={asylumEndedReasonsOptions}
                    isHighlighted={highlights.asylumSupportEndedReasonId}
                    disabled={disabled}
                />
            </div>
        </>
    )
}