import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faTimes } from '@fortawesome/free-solid-svg-icons';

export default ({onClose}) => {

    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (visible === false) {
            setTimeout(() =>  setVisible(true), 200);
        }
    }, [visible, setVisible]);

    return (
        <>
            <div className={ `react-modal-anchor ${(visible ? 'show' : '')}`}>
                <div className={ `react-modal alert-advise-modal ${visible ? 'show' : ''}` } style={{ width: "860px" }}>
                    <button onClick={onClose} className="close-button"><FontAwesomeIcon icon={faTimes} /></button>
                    <h1>
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                        Please complete the following actions before closing the alert!
                    </h1>

                    <div className="stripedbox">
                        <div className="grid">
                            <div className="heading">Principal Applicant Page</div>
                            <div>The Home Office action can be Yes or Yes but with spelling /dob or No for a tick. As long as an action has been done then that is okay.</div>
                            <div className="heading">Dependant Page</div>
                            <div>The Home Office action can be Yes or Yes but with spelling /dob or No for a tick. As long as an action has been done then that is okay.</div>
                            <div className="heading">Immigration Page</div>
                            <div>The Home Office action must be to make a change, click Update Record, and then Save. The Home Office can do this to close the alert even if they do not make a change.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={ `react-modal-backdrop ${visible ? 'show' : ''}` }></div>
        </>
    );
    
}