import React from "react";
import { Field, Form } from "react-final-form";

import {
    renderInput,
    renderDropdownList,
    renderDatePicker,
    renderRadioOption,
    toIntOptions
} from "../../../form-inputs/final-form";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";



const defaultOptions = {
    immigrationStatuses: [],
    assistedReturns: [],
    ensuredReturns: [],
    requiredReturns: [],
    scheduled3Statuses: [],
    barrierToEnforceReturns: [],
    caseConclusions: [],
    solicitors: [],
    homeOfficeCaseWorkers: [],
    litOffices: [],
    fileOwners: []
};

const HistoryForm = ({
    onSubmit,
    initialValues,
    onEditSolicitorModalRequest,
    onCloseHistoryRecord
}) => {

    const {
        immigrationStatuses,
        assistedReturns,
        ensuredReturns,
        requiredReturns,
        scheduled3Statuses,
        barrierToEnforceReturns,
        caseConclusions,
        solicitors,
        homeOfficeCaseWorkers,
        litOffices,
        fileOwners,
    } = initialValues.options || defaultOptions;


    const immigrationStatusesOptions = toIntOptions(immigrationStatuses);

    const assistedReturnsOptions = toIntOptions(assistedReturns);
    const ensuredReturnsOptions = toIntOptions(ensuredReturns);
    const requiredReturnsOptions = toIntOptions(requiredReturns);
    const scheduled3StatusesOptions = toIntOptions(scheduled3Statuses);
    const barrierToEnforceReturnsOptions = toIntOptions(barrierToEnforceReturns);
    const caseConclusionsOptions = toIntOptions(caseConclusions);

    const solicitorsOptions = toIntOptions(solicitors);
    const homeOfficeCaseWorkersOptions = toIntOptions(homeOfficeCaseWorkers);
    const litOfficesOptions = toIntOptions(litOffices);
    const fileOwnersOptions = toIntOptions(fileOwners);

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            render={({ handleSubmit, values }) => {

                const disabled = true;

                return (
                    <form onSubmit={handleSubmit}>
                        <div className="immigration-edit-wrapper">
                            <div className="stripedbox">
                                <div className="mandatory">
                                    <span>*</span> Denotes a mandatory field
                                </div>
                            </div>
                            <div className="immigration-grid-wrapper">
                                <div className="left-column">
                                    <div className="stripedbox">
                                        <div className="immigration-edit-form">

                                            <h2>Immigration Status</h2>

                                            <div className="input-wrapper">
                                                <Field
                                                    name="dateOfStatusCheck"
                                                    type="text"
                                                    render={renderDatePicker}
                                                    label="Date of status check"
                                                    placeholder=""
                                                    disabled={disabled}
                                                />
                                            </div>
                                            <div className="input-wrapper-full-width">
                                                <label>Current Status</label>
                                                <Field
                                                    name="immigrationStatusId"
                                                    type="text"
                                                    render={renderDropdownList}
                                                    options={immigrationStatusesOptions}
                                                    disabled={disabled}
                                                />
                                            </div>
                                            <div className="input-wrapper">
                                                <Field
                                                    name="dateEnteredUK"
                                                    type="text"
                                                    render={renderDatePicker}
                                                    label="Date entered the UK"
                                                    placeholder=""
                                                    disabled={disabled}
                                                />
                                            </div>
                                            <div className="input-wrapper">
                                                <label>Claimed Asylum?</label>
                                                <div className="radio-options">
                                                    <Field name="isClaimedAsylum" type="radio" render={renderRadioOption} value="true" label="Yes" disabled={disabled} />
                                                    <Field name="isClaimedAsylum" type="radio" render={renderRadioOption} value="false" label="No" disabled={disabled} />
                                                </div>
                                            </div>
                                            <div className="input-wrapper">
                                                <Field
                                                    name="dateOfAsylumApplication"
                                                    type="text"
                                                    render={renderDatePicker}
                                                    label="Date of asylum application"
                                                    placeholder=""
                                                    disabled={disabled}
                                                />
                                            </div>

                                            <div className="input-wrapper">
                                                <Field
                                                    name="homeOfficeReference"
                                                    type="text"
                                                    render={renderInput}
                                                    label="Home Office Reference"
                                                    placeholder=""
                                                    disabled={disabled}
                                                />
                                            </div>

                                            <div className="input-wrapper-full-width">
                                                <label>Schedule 3 Status</label>
                                                <Field
                                                    name="schedule3StatusId"
                                                    type="text"
                                                    render={renderDropdownList}
                                                    options={scheduled3StatusesOptions}
                                                    disabled={disabled}
                                                />
                                            </div>

                                            <div className="input-wrapper">
                                                <label>Barrier to Enforced Removal</label>
                                                <Field
                                                    name="barrierToEnforcedReturnId"
                                                    type="text"
                                                    render={renderDropdownList}
                                                    options={barrierToEnforceReturnsOptions}
                                                    disabled={disabled}
                                                />
                                            </div>

                                            <div className="input-wrapper">
                                                <Field
                                                    name="dateBarrierLogged"
                                                    type="text"
                                                    render={renderDatePicker}
                                                    label="Date Barrier Lodged"
                                                    disabled={disabled}
                                                />
                                            </div>

                                        </div>
                                    </div>

                                    <div className="stripedbox dark">
                                        <div className="immigration-edit-form">

                                            <h2>Home Office Actions</h2>

                                            <div className="input-wrapper">
                                                <label>Reason Case Concluded</label>
                                                <Field
                                                    name="caseConclusionId"
                                                    render={renderDropdownList}
                                                    options={caseConclusionsOptions}
                                                    disabled={disabled}
                                                />
                                            </div>

                                            <div className="input-wrapper">
                                                <Field
                                                    name="dateOfOutcome"
                                                    type="text"
                                                    render={renderDatePicker}
                                                    label="Date Barrier Lodged"
                                                    disabled={disabled}
                                                />
                                            </div>

                                        </div>
                                    </div>

                                    <div className="stripedbox">
                                        <div className="immigration-edit-form">

                                            <h2>Solicitors</h2>

                                            <div className="input-wrapper">
                                                <label>Main solicitor working on case</label>
                                                <Field
                                                    name="solicitorId"
                                                    render={renderDropdownList}
                                                    options={solicitorsOptions}
                                                />
                                            </div>

                                            <div className="input-wrapper">
                                                <label>Secondary solicitor working on case</label>
                                                <Field
                                                    name="solicitor2Id"
                                                    render={renderDropdownList}
                                                    options={solicitorsOptions}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="right-column stripedbox dark">
                                    <div className="immigration-edit-form">

                                        <h2>Home Office Involvements</h2>

                                        <div className="input-wrapper">
                                            <label>Home Office Team</label>
                                            <Field
                                                name="litOfficeId"
                                                type="text"
                                                render={renderDropdownList}
                                                options={litOfficesOptions}
                                                disabled={disabled}
                                            />
                                        </div>

                                        <div className="input-wrapper">
                                            <label>Home Office Allocated User</label>
                                            <Field
                                                name="litCaseWorkerId"
                                                type="text"
                                                render={renderDropdownList}
                                                options={homeOfficeCaseWorkersOptions}
                                                disabled={disabled}
                                            />
                                        </div>

                                        <div className="input-wrapper">
                                            <label>Home Office File Ownership</label>
                                            <Field
                                                name="fileOwnershipId"
                                                type="text"
                                                render={renderDropdownList}
                                                options={fileOwnersOptions}
                                                disabled={disabled}
                                            />
                                        </div>


                                        <hr />


                                        <h2>Removal Process</h2>

                                        <div className="input-wrapper">
                                            <label>Removal decision required</label>
                                            <div className="radio-options">
                                                <Field name="isRemovalDecisionRequired" type="radio" render={renderRadioOption} value="true" label="Yes" disabled={disabled} />
                                                <Field name="isRemovalDecisionRequired" type="radio" render={renderRadioOption} value="false" label="No" disabled={disabled} />
                                            </div>
                                        </div>

                                        <div className="input-wrapper">
                                            <Field
                                                name="dateOfRemovalDecision"
                                                type="text"
                                                render={renderDatePicker}
                                                label="Date of removal decision"
                                                disabled={disabled}
                                            />
                                        </div>

                                        <div className="input-wrapper">
                                            <label>HO Pursuing barrier</label>
                                            <div className="radio-options">
                                                <Field name="isHomeOfficePursuingBarrier" type="radio" render={renderRadioOption} value="true" label="Yes" disabled={disabled} />
                                                <Field name="isHomeOfficePursuingBarrier" type="radio" render={renderRadioOption} value="false" label="No" disabled={disabled} />
                                            </div>
                                        </div>

                                        <div className="input-wrapper">
                                            <label>Prev supported by HO</label>
                                            <div className="radio-options">
                                                <Field name="isPreviouslySupportedByUK" type="radio" render={renderRadioOption} value="true" label="Yes" disabled={disabled} />
                                                <Field name="isPreviouslySupportedByUK" type="radio" render={renderRadioOption} value="false" label="No" disabled={disabled} />
                                            </div>
                                        </div>


                                        <hr />


                                        <h2>Family Returns</h2>

                                        <div className="input-wrapper">
                                            <label>Assisted Return</label>
                                            <Field
                                                name="assistedReturnId"
                                                type="text"
                                                render={renderDropdownList}
                                                options={assistedReturnsOptions}
                                                disabled={disabled}
                                            />
                                        </div>

                                        <div className="input-wrapper">
                                            <label>Required Return</label>
                                            <Field
                                                name="requiredReturnId"
                                                type="text"
                                                render={renderDropdownList}
                                                options={requiredReturnsOptions}
                                                disabled={disabled}
                                            />
                                        </div>

                                        <div className="input-wrapper">
                                            <label>Ensured Return</label>
                                            <Field
                                                name="ensuredReturnId"
                                                type="text"
                                                render={renderDropdownList}
                                                options={ensuredReturnsOptions}
                                                disabled={disabled}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="stripedbox">
                                <div className="form-buttons">
                                    <button type="button" className="rbtn secondary" onClick={onCloseHistoryRecord}>
                                        <FontAwesomeIcon icon={faArrowLeft} />
                                        <span>Go Back</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                );
            }}
        />
    );
};

export default HistoryForm;
