import React, { useEffect, useRef } from "react";
import { Field, Form } from 'react-final-form';

import calculateAge from '../../../../../extensions/date.calculateAge';
import dateToEnglishFormat from '../../../../../extensions/date.toEnglishFormat';
import { renderDropdownList, renderDatePicker, renderInput } from '../../../../form-inputs/final-form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlagCheckered } from '@fortawesome/free-solid-svg-icons';


const defaultOptions = {
    titles: [],
    nationalities: []
}


const AliasForm = ({onSubmit, initialValues, disabled, applicantName, applicantDateOfBirth}) => {
    
    const formSubmitButton = useRef();

    useEffect(() => {
        var { unsubscribe } = window.dispatcher.subscribe("change-tracker-modal-submit-form", () => formSubmitButton.current.click());

        return () => {
            unsubscribe();
        }
    }, []);

    const aDateOfBirth = dateToEnglishFormat(applicantDateOfBirth);

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            render={({ handleSubmit, submitting, values }) => {

                const { options, dateOfBirth } = values;
                const { titles, nationalities } = options || defaultOptions;
                const isDisabled = disabled || submitting;

                const titlesOptions = titles;
                const nationalitiesOptions = nationalities;

                const age = calculateAge(dateOfBirth);


                const submitButton = !disabled
                    ? (
                        <>
                            <hr />
                        
                            <div className="form-buttons">
                                <button type="submit" className="rbtn primary" disabled={isDisabled} ref={formSubmitButton}>
                                    <FontAwesomeIcon icon={faFlagCheckered} />
                                    <span>Save</span>
                                </button>
                            </div>
                        </>
                    )
                    : null;

                return (
                    <form autoComplete="off" onSubmit={handleSubmit} rel="change-tracked-modal">
    
                        <div className="mandatory"><span>*</span> Denotes a mandatory field </div>

                        <div className="modal-edit-form">
                            <h2>For reference, recorded PA name:</h2>

                            <div className="input-wrapper">
                                <label>Name</label>
                                <div className="fake-input">{applicantName || 'N/A'}</div>
                            </div>
                            <div className="input-wrapper">
                                <label>Date of Birth</label>
                                <div className="fake-input">{aDateOfBirth}</div>
                            </div>

                            <hr />

                            <h2>Add alias name / nationality</h2>

                            <div className="input-wrapper">
                                <label>Title</label>
                                <Field name="title" render={renderDropdownList} options={titlesOptions} disabled={isDisabled} />
                            </div>
                            <div className="input-wrapper">
                                <Field name="forename" type="text" render={renderInput} label="Forename" isMandatory={true} placeholder="" disabled={isDisabled} />
                            </div>
                            <div className="input-wrapper">
                                <Field name="surname" type="text" render={renderInput} label="Surname" isMandatory={true} placeholder="" disabled={isDisabled} />
                            </div>
                            <div className="input-wrapper">
                                <label>Nationality</label>
                                <Field name="nationalityCode" type="text" render={renderDropdownList} options={nationalitiesOptions} disabled={isDisabled} />
                            </div>

                            <hr />

                            <h2>Add alias Date of Birth</h2>

                            <div className="input-wrapper">
                                <Field name="dateOfBirth" type="text" render={renderDatePicker} label="Date of Birth" placeholder="" disabled={isDisabled} />
                            </div>
                            <div className="input-wrapper">
                                <label>Age</label>
                                <div className="fake-input">
                                    { age }
                                </div>
                            </div>
                            <div className="input-wrapper">
                                <Field name="dateOfDeath" type="text" render={renderDatePicker} label="Date of Death" placeholder="" disabled={isDisabled} />
                            </div>

                            <hr />

                            <h2>Add alias reference numbers</h2>

                            <div className="input-wrapper">
                                <Field name="referenceNumber" type="text" render={renderInput} label="LA Reference" placeholder="" disabled={isDisabled} />
                            </div>
                            <div className="input-wrapper">
                                <Field name="homeOfficeReference" type="text" render={renderInput} label="Home Office Reference	" placeholder="" disabled={isDisabled} />
                            </div>
                        
                            { submitButton }
                        </div>
                        
                    </form>
                )
            }}
        />
    )
}


export default AliasForm;
