import React from "react";

import DatePicker from 'react-datepicker';

import customDatePickerHeader from '../../../components/form-inputs/react-datepicker-custom-header';


export const DateTimePicker = ({ id, value, onChange=(()=>{}), minDate, maxDate, placeholder, ...rest }) => {

    let limits = {};
    if (minDate)
        limits.minDate = minDate;
    if (maxDate)
        limits.maxDate = maxDate;

    return (
        <DatePicker
            id={id}
            className={`search-field`}
            autoComplete="off"
            dateFormat="dd/MM/yyyy"
            closeOnScroll={true}
            selected={value === "" ? null : value}
            placeholderText={placeholder}
            
            onChange={(selectedValue) => onChange(id, selectedValue)}
            renderCustomHeader={customDatePickerHeader}
            { ...limits }
            { ...rest }
        />
    );
}

export const BigCheckbox = ({ id, label, tabIndex, checked, disabled, onChange = (()=>{}) }) => (
    <>
        <label htmlFor={id} className="big-checkbox-label">{label}</label>
        <input
            id={id}
            type="checkbox"
            tabIndex={tabIndex}
            checked={checked}
            disabled={disabled}
            className="regular-checkbox big-checkbox"
            onChange={onChange}
        />
        <label htmlFor={id}></label>
    </>
);

export const BigRadioOption = ({ name, radiovalue, label, tabIndex, disabled, value, onChange = (()=>{}) }) => {

    const id = `${name}_${radiovalue}`;

    return (
        <>
            <label htmlFor={id} className="big-checkbox-label">{label}</label>
            <input
                id={id}
                name={name}
                type="radio"
                value={radiovalue}
                tabIndex={tabIndex}
                checked={radiovalue === value}
                disabled={disabled}
                className="regular-radio big-radio"
                onChange={onChange}
            />
            <label htmlFor={id}></label>
        </>
    );
}