export const documentTypes = [
  { value: 'PASSPORT', label: 'Passport' },
  { value: 'NAT', label: 'European national identity card' },
  { value: 'BRC', label: 'BRC' },
  { value: 'BRP', label: 'BRP' }
];

export const nationalities = [
  { value: 'AFG', label: 'Afghanistan [AFG]' },
  { value: 'ALA', label: 'Åland Islands [ALA]' },
  { value: 'ALB', label: 'Albania [ALB]' },
  { value: 'DZA', label: 'Algeria [DZA]' },
  { value: 'ASM', label: 'American Samoa [ASM]' },
  { value: 'AND', label: 'Andorra [AND]' },
  { value: 'AGO', label: 'Angola [AGO]' },
  { value: 'AIA', label: 'Anguilla [AIA]' },
  { value: 'ATA', label: 'Antarctica [ATA]' },
  { value: 'ATG', label: 'Antigua and Barbuda [ATG]' },
  { value: 'ARG', label: 'Argentina [ARG]' },
  { value: 'ARM', label: 'Armenia [ARM]' },
  { value: 'ABW', label: 'Aruba [ABW]' },
  { value: 'AUS', label: 'Australia [AUS]' },
  { value: 'AUT', label: 'Austria [AUT]' },
  { value: 'AZE', label: 'Azerbaijan [AZE]' },
  { value: 'BHS', label: 'Bahamas (the) [BHS]' },
  { value: 'BHR', label: 'Bahrain [BHR]' },
  { value: 'BGD', label: 'Bangladesh [BGD]' },
  { value: 'BRB', label: 'Barbados [BRB]' },
  { value: 'BLR', label: 'Belarus [BLR]' },
  { value: 'BEL', label: 'Belgium [BEL]' },
  { value: 'BLZ', label: 'Belize [BLZ]' },
  { value: 'BEN', label: 'Benin [BEN]' },
  { value: 'BMU', label: 'Bermuda [BMU]' },
  { value: 'BTN', label: 'Bhutan [BTN]' },
  { value: 'BOL', label: 'Bolivia (Plurinational State of) [BOL]' },
  { value: 'BES', label: 'Bonaire, Sint Eustatius and Saba [BES]' },
  { value: 'BIH', label: 'Bosnia and Herzegovina [BIH]' },
  { value: 'BWA', label: 'Botswana [BWA]' },
  { value: 'BVT', label: 'Bouvet Island [BVT]' },
  { value: 'BRA', label: 'Brazil [BRA]' },
  { value: 'IOT', label: 'British Indian Ocean Territory (the) [IOT]' },
  { value: 'BRN', label: 'Brunei Darussalam [BRN]' },
  { value: 'BGR', label: 'Bulgaria [BGR]' },
  { value: 'BFA', label: 'Burkina Faso [BFA]' },
  { value: 'BDI', label: 'Burundi [BDI]' },
  { value: 'CPV', label: 'Cabo Verde [CPV]' },
  { value: 'KHM', label: 'Cambodia [KHM]' },
  { value: 'CMR', label: 'Cameroon [CMR]' },
  { value: 'CAN', label: 'Canada [CAN]' },
  { value: 'CYM', label: 'Cayman Islands (the) [CYM]' },
  { value: 'CAF', label: 'Central African Republic (the) [CAF]' },
  { value: 'TCD', label: 'Chad [TCD]' },
  { value: 'CHL', label: 'Chile [CHL]' },
  { value: 'CHN', label: 'China [CHN]' },
  { value: 'CXR', label: 'Christmas Island [CXR]' },
  { value: 'CCK', label: 'Cocos (Keeling) Islands (the) [CCK]' },
  { value: 'COL', label: 'Colombia [COL]' },
  { value: 'COM', label: 'Comoros (the) [COM]' },
  { value: 'COD', label: 'Congo (the Democratic Republic of the) [COD]' },
  { value: 'COG', label: 'Congo (the) [COG]' },
  { value: 'COK', label: 'Cook Islands (the) [COK]' },
  { value: 'CRI', label: 'Costa Rica [CRI]' },
  { value: 'HRV', label: 'Croatia [HRV]' },
  { value: 'CUB', label: 'Cuba [CUB]' },
  { value: 'CUW', label: 'Curaçao [CUW]' },
  { value: 'CYP', label: 'Cyprus [CYP]' },
  { value: 'CZE', label: 'Czechia [CZE]' },
  { value: 'CIV', label: 'Côte d\'Ivoire [CIV]' },
  { value: 'DNK', label: 'Denmark [DNK]' },
  { value: 'DJI', label: 'Djibouti [DJI]' },
  { value: 'DMA', label: 'Dominica [DMA]' },
  { value: 'DOM', label: 'Dominican Republic (the) [DOM]' },
  { value: 'ECU', label: 'Ecuador [ECU]' },
  { value: 'EGY', label: 'Egypt [EGY]' },
  { value: 'SLV', label: 'El Salvador [SLV]' },
  { value: 'GNQ', label: 'Equatorial Guinea [GNQ]' },
  { value: 'ERI', label: 'Eritrea [ERI]' },
  { value: 'EST', label: 'Estonia [EST]' },
  { value: 'SWZ', label: 'Eswatini [SWZ]' },
  { value: 'ETH', label: 'Ethiopia [ETH]' },
  { value: 'FLK', label: 'Falkland Islands (the) [Malvinas] [FLK]' },
  { value: 'FRO', label: 'Faroe Islands (the) [FRO]' },
  { value: 'FJI', label: 'Fiji [FJI]' },
  { value: 'FIN', label: 'Finland [FIN]' },
  { value: 'FRA', label: 'France [FRA]' },
  { value: 'GUF', label: 'French Guiana [GUF]' },
  { value: 'PYF', label: 'French Polynesia [PYF]' },
  { value: 'ATF', label: 'French Southern Territories (the) [ATF]' },
  { value: 'GAB', label: 'Gabon [GAB]' },
  { value: 'GMB', label: 'Gambia (the) [GMB]' },
  { value: 'GEO', label: 'Georgia [GEO]' },
  { value: 'DEU', label: 'Germany [DEU]' },
  { value: 'GHA', label: 'Ghana [GHA]' },
  { value: 'GIB', label: 'Gibraltar [GIB]' },
  { value: 'GRC', label: 'Greece [GRC]' },
  { value: 'GRL', label: 'Greenland [GRL]' },
  { value: 'GRD', label: 'Grenada [GRD]' },
  { value: 'GLP', label: 'Guadeloupe [GLP]' },
  { value: 'GUM', label: 'Guam [GUM]' },
  { value: 'GTM', label: 'Guatemala [GTM]' },
  { value: 'GGY', label: 'Guernsey [GGY]' },
  { value: 'GIN', label: 'Guinea [GIN]' },
  { value: 'GNB', label: 'Guinea-Bissau [GNB]' },
  { value: 'GUY', label: 'Guyana [GUY]' },
  { value: 'HTI', label: 'Haiti [HTI]' },
  { value: 'HMD', label: 'Heard Island and McDonald Islands [HMD]' },
  { value: 'VAT', label: 'Holy See (the) [VAT]' },
  { value: 'HND', label: 'Honduras [HND]' },
  { value: 'HKG', label: 'Hong Kong [HKG]' },
  { value: 'HUN', label: 'Hungary [HUN]' },
  { value: 'ISL', label: 'Iceland [ISL]' },
  { value: 'IND', label: 'India [IND]' },
  { value: 'IDN', label: 'Indonesia [IDN]' },
  { value: 'IRN', label: 'Iran (Islamic Republic of) [IRN]' },
  { value: 'IRQ', label: 'Iraq [IRQ]' },
  { value: 'IRL', label: 'Ireland [IRL]' },
  { value: 'IMN', label: 'Isle of Man [IMN]' },
  { value: 'ISR', label: 'Israel [ISR]' },
  { value: 'ITA', label: 'Italy [ITA]' },
  { value: 'JAM', label: 'Jamaica [JAM]' },
  { value: 'JPN', label: 'Japan [JPN]' },
  { value: 'JEY', label: 'Jersey [JEY]' },
  { value: 'JOR', label: 'Jordan [JOR]' },
  { value: 'KAZ', label: 'Kazakhstan [KAZ]' },
  { value: 'KEN', label: 'Kenya [KEN]' },
  { value: 'KIR', label: 'Kiribati [KIR]' },
  { value: 'PRK', label: 'Korea (the Democratic People\'s Republic of) [PRK]' },
  { value: 'KOR', label: 'Korea (the Republic of) [KOR]' },
  { value: 'KWT', label: 'Kuwait [KWT]' },
  { value: 'KGZ', label: 'Kyrgyzstan [KGZ]' },
  { value: 'LAO', label: 'Lao People\'s Democratic Republic (the) [LAO]' },
  { value: 'LVA', label: 'Latvia [LVA]' },
  { value: 'LBN', label: 'Lebanon [LBN]' },
  { value: 'LSO', label: 'Lesotho [LSO]' },
  { value: 'LBR', label: 'Liberia [LBR]' },
  { value: 'LBY', label: 'Libya [LBY]' },
  { value: 'LIE', label: 'Liechtenstein [LIE]' },
  { value: 'LTU', label: 'Lithuania [LTU]' },
  { value: 'LUX', label: 'Luxembourg [LUX]' },
  { value: 'MAC', label: 'Macao [MAC]' },
  { value: 'MDG', label: 'Madagascar [MDG]' },
  { value: 'MWI', label: 'Malawi [MWI]' },
  { value: 'MYS', label: 'Malaysia [MYS]' },
  { value: 'MDV', label: 'Maldives [MDV]' },
  { value: 'MLI', label: 'Mali [MLI]' },
  { value: 'MLT', label: 'Malta [MLT]' },
  { value: 'MHL', label: 'Marshall Islands (the) [MHL]' },
  { value: 'MTQ', label: 'Martinique [MTQ]' },
  { value: 'MRT', label: 'Mauritania [MRT]' },
  { value: 'MUS', label: 'Mauritius [MUS]' },
  { value: 'MYT', label: 'Mayotte [MYT]' },
  { value: 'MEX', label: 'Mexico [MEX]' },
  { value: 'FSM', label: 'Micronesia (Federated States of) [FSM]' },
  { value: 'MDA', label: 'Moldova (the Republic of) [MDA]' },
  { value: 'MCO', label: 'Monaco [MCO]' },
  { value: 'MNG', label: 'Mongolia [MNG]' },
  { value: 'MNE', label: 'Montenegro [MNE]' },
  { value: 'MSR', label: 'Montserrat [MSR]' },
  { value: 'MAR', label: 'Morocco [MAR]' },
  { value: 'MOZ', label: 'Mozambique [MOZ]' },
  { value: 'MMR', label: 'Myanmar [MMR]' },
  { value: 'NAM', label: 'Namibia [NAM]' },
  { value: 'NRU', label: 'Nauru [NRU]' },
  { value: 'NPL', label: 'Nepal [NPL]' },
  { value: 'NLD', label: 'Netherlands (the) [NLD]' },
  { value: 'NCL', label: 'New Caledonia [NCL]' },
  { value: 'NZL', label: 'New Zealand [NZL]' },
  { value: 'NIC', label: 'Nicaragua [NIC]' },
  { value: 'NER', label: 'Niger (the) [NER]' },
  { value: 'NGA', label: 'Nigeria [NGA]' },
  { value: 'NIU', label: 'Niue [NIU]' },
  { value: 'NFK', label: 'Norfolk Island [NFK]' },
  { value: 'MKD', label: 'North Macedonia [MKD]' },
  { value: 'MNP', label: 'Northern Mariana Islands (the) [MNP]' },
  { value: 'NOR', label: 'Norway [NOR]' },
  { value: 'OMN', label: 'Oman [OMN]' },
  { value: 'PAK', label: 'Pakistan [PAK]' },
  { value: 'PLW', label: 'Palau [PLW]' },
  { value: 'PSE', label: 'Palestine, State of [PSE]' },
  { value: 'PAN', label: 'Panama [PAN]' },
  { value: 'PNG', label: 'Papua New Guinea [PNG]' },
  { value: 'PRY', label: 'Paraguay [PRY]' },
  { value: 'PER', label: 'Peru [PER]' },
  { value: 'PHL', label: 'Philippines (the) [PHL]' },
  { value: 'PCN', label: 'Pitcairn [PCN]' },
  { value: 'POL', label: 'Poland [POL]' },
  { value: 'PRT', label: 'Portugal [PRT]' },
  { value: 'PRI', label: 'Puerto Rico [PRI]' },
  { value: 'QAT', label: 'Qatar [QAT]' },
  { value: 'ROU', label: 'Romania [ROU]' },
  { value: 'RUS', label: 'Russian Federation (the) [RUS]' },
  { value: 'RWA', label: 'Rwanda [RWA]' },
  { value: 'REU', label: 'Réunion [REU]' },
  { value: 'BLM', label: 'Saint Barthélemy [BLM]' },
  { value: 'SHN', label: 'Saint Helena, Ascension and Tristan da Cunha [SHN]' },
  { value: 'KNA', label: 'Saint Kitts and Nevis [KNA]' },
  { value: 'LCA', label: 'Saint Lucia [LCA]' },
  { value: 'MAF', label: 'Saint Martin (French part) [MAF]' },
  { value: 'SPM', label: 'Saint Pierre and Miquelon [SPM]' },
  { value: 'VCT', label: 'Saint Vincent and the Grenadines [VCT]' },
  { value: 'WSM', label: 'Samoa [WSM]' },
  { value: 'SMR', label: 'San Marino [SMR]' },
  { value: 'STP', label: 'Sao Tome and Principe [STP]' },
  { value: 'SAU', label: 'Saudi Arabia [SAU]' },
  { value: 'SEN', label: 'Senegal [SEN]' },
  { value: 'SRB', label: 'Serbia [SRB]' },
  { value: 'SYC', label: 'Seychelles [SYC]' },
  { value: 'SLE', label: 'Sierra Leone [SLE]' },
  { value: 'SGP', label: 'Singapore [SGP]' },
  { value: 'SXM', label: 'Sint Maarten (Dutch part) [SXM]' },
  { value: 'SVK', label: 'Slovakia [SVK]' },
  { value: 'SVN', label: 'Slovenia [SVN]' },
  { value: 'SLB', label: 'Solomon Islands [SLB]' },
  { value: 'SOM', label: 'Somalia [SOM]' },
  { value: 'ZAF', label: 'South Africa [ZAF]' },
  { value: 'SGS', label: 'South Georgia and the South Sandwich Islands [SGS]' },
  { value: 'SSD', label: 'South Sudan [SSD]' },
  { value: 'ESP', label: 'Spain [ESP]' },
  { value: 'LKA', label: 'Sri Lanka [LKA]' },
  { value: 'SDN', label: 'Sudan (the) [SDN]' },
  { value: 'SUR', label: 'Suriname [SUR]' },
  { value: 'SJM', label: 'Svalbard and Jan Mayen [SJM]' },
  { value: 'SWE', label: 'Sweden [SWE]' },
  { value: 'CHE', label: 'Switzerland [CHE]' },
  { value: 'SYR', label: 'Syrian Arab Republic (the) [SYR]' },
  { value: 'TWN', label: 'Taiwan (Province of China) [TWN]' },
  { value: 'TJK', label: 'Tajikistan [TJK]' },
  { value: 'TZA', label: 'Tanzania, the United Republic of [TZA]' },
  { value: 'THA', label: 'Thailand [THA]' },
  { value: 'TLS', label: 'Timor-Leste [TLS]' },
  { value: 'TGO', label: 'Togo [TGO]' },
  { value: 'TKL', label: 'Tokelau [TKL]' },
  { value: 'TON', label: 'Tonga [TON]' },
  { value: 'TTO', label: 'Trinidad and Tobago [TTO]' },
  { value: 'TUN', label: 'Tunisia [TUN]' },
  { value: 'TKM', label: 'Turkmenistan [TKM]' },
  { value: 'TCA', label: 'Turks and Caicos Islands (the) [TCA]' },
  { value: 'TUV', label: 'Tuvalu [TUV]' },
  { value: 'TUR', label: 'Türkiye [TUR]' },
  { value: 'UGA', label: 'Uganda [UGA]' },
  { value: 'UKR', label: 'Ukraine [UKR]' },
  { value: 'ARE', label: 'United Arab Emirates (the) [ARE]' },
  { value: 'GBR', label: 'United Kingdom of Great Britain and Northern Ireland (the) [GBR]' },
  { value: 'UMI', label: 'United States Minor Outlying Islands (the) [UMI]' },
  { value: 'USA', label: 'United States of America (the) [USA]' },
  { value: 'URY', label: 'Uruguay [URY]' },
  { value: 'UZB', label: 'Uzbekistan [UZB]' },
  { value: 'VUT', label: 'Vanuatu [VUT]' },
  { value: 'VEN', label: 'Venezuela (Bolivarian Republic of) [VEN]' },
  { value: 'VNM', label: 'Viet Nam [VNM]' },
  { value: 'VGB', label: 'Virgin Islands (British) [VGB]' },
  { value: 'VIR', label: 'Virgin Islands (U.S.) [VIR]' },
  { value: 'WLF', label: 'Wallis and Futuna [WLF]' },
  { value: 'ESH', label: 'Western Sahara* [ESH]' },
  { value: 'YEM', label: 'Yemen [YEM]' },
  { value: 'ZMB', label: 'Zambia [ZMB]' },
  { value: 'ZWE', label: 'Zimbabwe [ZWE]' }
]
