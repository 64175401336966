import React from 'react';


export default ({userIsCaseOwner, summary}) => {

    const { caseIsClosed, childrenAndCareLeaversIsActive, showChildLookedAfterBadge, showCareLeaverBadge, isOverSixteen, isUnderSeventeenAndAHalf, isUnderEighteen, isUnderTwentyOne, daysRemainingUntilNextAgeGroup } = summary;

    if (caseIsClosed)
        return null;


    if (childrenAndCareLeaversIsActive)
    {
        if (showChildLookedAfterBadge && isOverSixteen === false)
        {
            return (
                <div className="age-stamp green">
                    CHILD LOOKED AFTER<br />
                    Under 16 years of age
                </div>
            )
        }
        else if (showChildLookedAfterBadge && isUnderSeventeenAndAHalf)
        {
            return (
                <div className="age-stamp green hover-tooltip" data-tooltip="Timely decisions on applications minimises uncertainty for children: please work to ensure applications have been submitted and – where possible - the first decision from the Home Office obtained.">
                    CHILD LOOKED AFTER<br />
                    Days until 17.5 years of age: <br />
                    <span className={daysRemainingUntilNextAgeGroup < 100 ? "text-red" : ""}>{daysRemainingUntilNextAgeGroup}</span>
                </div>
            )
        }
        else if (showChildLookedAfterBadge && isUnderEighteen)
        {
            return (
                <div className="age-stamp orange hover-tooltip" data-tooltip="Please ensure that post-18 entitlements based on immigration status are discussed with the young person and that all necessary steps with regards to a person’s application to the Home Office are being taken.">
                    CHILD LOOKED AFTER<br />
                    Days until 18 years of age: <br />
                    <span className="text-red">{daysRemainingUntilNextAgeGroup}</span>
                </div>
            )
        }
        else if (showCareLeaverBadge && isUnderTwentyOne)
        {
            return userIsCaseOwner
                ? (
                    <div className="age-stamp orange hover-tooltip" data-tooltip="If a young person has NRPF, please ensure LA expenditure is recorded by adding person and property related costs on the finance page.">
                        CARE LEAVER<br />
                        Days until 21 years of age: <br />
                        <span className={daysRemainingUntilNextAgeGroup < 100 ? "text-red" : ""}>{daysRemainingUntilNextAgeGroup}</span>
                    </div>
                )
                : (
                    <div className="age-stamp orange">
                        CARE LEAVER<br />
                        18-20 years of age
                    </div>
                );
        }
        else if (showCareLeaverBadge)
        {
            const tooltip = userIsCaseOwner
                ? "If a young person has NRPF, please ensure LA expenditure is recorded by adding person and property related costs on the finance page."
                : "";

            return (
                <div className={`age-stamp orange ${userIsCaseOwner ? " hover-tooltip" : ""}`} data-tooltip={tooltip}>
                    CARE LEAVER<br/>
                    21-25 years of age
                </div>
            )
        }
    }

    return null;

}
