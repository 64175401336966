import { onGetAllForCase } from '../../../api-endpoint-calls/case/address';
import { selector } from '../../reducers/case/address-reducer';


export const SEARCH_START = 'CASE_ADDRESS_LIST::SEARCH_START';
export const SEARCH_SUCCESS = 'CASE_ADDRESS_LIST::SEARCH_SUCCESS';
export const SEARCH_FAILED = 'CASE_ADDRESS_LIST::SEARCH_FAILED';

export const SET_PAGE = 'CASE_ADDRESS_LIST::SET_PAGE';
export const SET_SORT = 'CASE_ADDRESS_LIST::SET_SORT';
export const SET_FILTERS = 'CASE_ADDRESS_LIST::SET_FILTERS';


const action = (type, data = null) => ({
    type, data
});

export function loadAddresses(caseId) {
    return async (dispatch) => {
        dispatch(action(SEARCH_START));

        try {
            const result = await onGetAllForCase(caseId);
            dispatch(action(SEARCH_SUCCESS, result));

        } catch (err) {
            dispatch(action(SEARCH_FAILED));
        }
    }
}

export function resetFiltersAndLoadAddresses() {
    return (dispatch, getState) => {

        const pageSize = selector.pageSize(getState());

        dispatch(setPage(0, pageSize));
        dispatch(setFilters([]));
        dispatch(loadAddresses());
    }
}

export function setPage(pageIndex, pageSize) {
    return (dispatch) => {
        dispatch(action(SET_PAGE, { pageIndex, pageSize }));
    }
}

export function setSort(sortBy) {
    return (dispatch) => {
        dispatch(action(SET_SORT, sortBy));
    }
}

export function setFilters(filters) {
    return (dispatch) => {
        dispatch(action(SET_FILTERS, filters));
    }
}
