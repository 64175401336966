import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export default ({onClose}) => (
    <div className="stripedbox">
        <p>The password has been reset and the account has been unlocked.</p>
    
        <div className="button-row p-15">
            <button onClick={onClose} className="rbtn primary"><FontAwesomeIcon icon={faTimes} /><span>Close</span></button>
        </div>
    </div>
)