import React, { useState, useEffect } from 'react';

import { userIsLocalAuthority, caseArea } from '../../../server-values';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPoundSign, faTimes } from '@fortawesome/free-solid-svg-icons';

export default ({onClose, caseId, finances}) => {

    const [ visible, setVisible ] = useState(false);

    useEffect(() => {
        if (visible === false) {
            setTimeout(() =>  setVisible(true), 200);
        }
    }, [visible, setVisible]);

    return (
        <>
            <div className={ `react-modal-anchor ${(visible ? 'show' : '')}`}>
                <div className={ `react-modal user-delete ${visible ? 'show' : ''}` } style={{ width: '700px' }}>
                    <button onClick={onClose} className="close-button"><FontAwesomeIcon icon={faTimes} /></button>
                    <h1>
                        <FontAwesomeIcon icon={faPoundSign} />
                        Associated Finance
                    </h1>

                    <div className="stripedbox">
                        <div className="finance-summary-rows">
                            <div className="header">
                                <span width="95">Cost Type</span>
                                <span width="50">Amount</span>
                                <span width="70">Frequency</span>
                                <span width="80">Budget Code</span>
                                <span width="70">Start Date</span>
                                <span width="70">End Date</span>
                                <span width="160"></span>
                            </div>
                            { 
                                finances.map(values => 
                                    <div key={values.recordID} className="body-row">
                                        <span>{values.costType}</span>
                                        <span>{values.amount}</span>
                                        <span>{values.frequency}</span>
                                        <span>{values.budgetCode}</span>
                                        <span>{values.startDate}</span>
                                        <span>{values.endDate}</span>
                                        <span width="160">
                                            {
                                                userIsLocalAuthority
                                                    ? <a href={`/${caseArea}/Finance/Display?iCaseID=${caseId}&caseCostID=${values.recordID}`} className="rbtn primary"><span>Edit</span></a>
                                                    : null
                                            }                                            
                                        </span>
                                    </div>        
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className={ `react-modal-backdrop ${visible ? 'show' : ''}` }></div>
        </>
    );
}
