import React from 'react';

import { nationalities } from '../dictionaries';

import NoResults from '../no-results';
import DigitalRecordResult from '../digital-record-result';

const Results = ({ result }) => {

    const nationality = nationalities.find(n => n.value === result.nationality);

    const hasResults = result.statuses.length > 0;
    const digitalRecords = hasResults
        ? result.statuses.map((status, index) => <DigitalRecordResult status={status} key={`lookup_result_${index}`} />)
        : <NoResults />

    return (
        <div className="home-office-record">
            <div className="top-section">
                <div>
                    <h2>Person's details:</h2>
                    <ul className="details-list">
                        <li>
                            <label>Full Name</label>
                            <span>{result.fullName}</span>
                        </li>
                        <li>
                            <label>Nationality</label>
                            <span>{nationality?.label || 'N/A'}</span>
                        </li>
                        <li>
                            <label>Date Of Birth</label>
                            <span>{result.dateOfBirth}</span>
                        </li>
                    </ul>
                </div>
                <img alt="Home Office" src="/images/footer_image_4.jpg"></img>
            </div>
            
            <h2>Digital immigration records within the last 2 years:</h2>

            { digitalRecords }
        </div>
    )
}

// {status.isExpired &&
//     <>
//         <div><strong>This permission has expired </strong></div>
//         <div>Conditions no longer apply, please check with applicant and Home Office for further information.</div>
//     </>
// }

export default Results;
