import React from "react";

const NoteWidget = ({note, disabled, onAddModalRequest, onHistoryModalRequest}) => {

    const { content, dateCreated, isEditable, userDetails } = note || {};

    const onAddClick = () => {
        onAddModalRequest();
    }

    const onHistoryClick = () => {
        onHistoryModalRequest();
    }


    const footer = userDetails
        ? (
            <span>
                <span className="purple">{userDetails}</span>
                <span> on </span>
                <span className="darkGray">{dateCreated}</span>
            </span>
        )
        : <span>Click below to add a new note...</span>

    
    const addButton = isEditable && !disabled
        ? <button type="button" onClick={onAddClick} className="rbtn primary small">Add Note</button>
        : null;

    const formattedContent = content ? content.replace(/\n/g, '<br />') : content;

    return (
        <div className="notes-container">
            <div className="fake-input notes-control">
                <div className="notes-text" dangerouslySetInnerHTML={{__html: formattedContent}}></div>
                <div className="notes-widget-footer">
                    { footer }
                </div>
            </div>
            <div className="notes-options">
                { addButton }
                <button type="button" onClick={onHistoryClick} className="rbtn primary small">History</button>
            </div>
        </div>
    )
}


export default NoteWidget;
