import React from 'react';

export default ({options: {page, prepareRow, getTableBodyProps, Row, ExpandableDetails}, ...rest}) => (
    <div className="tbody" {...getTableBodyProps()} {...rest}>
      {
        page.map((row, i) => {
          prepareRow(row);
  
          return (
            <Row key={`search-results-table-body-${i}`} rowIndex={i} row={row}>
              { ExpandableDetails ? <ExpandableDetails row={row} /> : null }
            </Row>
          )
        })
      }
    </div>
  )