import React from 'react';

import HomeOfficeGrid from './home-office';
import CaseOwnerGrid from './case-owner';
import NonCaseOwnerGrid from './non-case-owner';

export default ({ userIsCaseOwner, userIsHomeOffice, summary }) =>
    userIsHomeOffice
        ? <HomeOfficeGrid summary={summary} />
        : userIsCaseOwner
            ? <CaseOwnerGrid summary={summary} />
            : <NonCaseOwnerGrid summary={summary} />
