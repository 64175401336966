import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '../../../../state/actions/superuser/users-list-actions';

import DeleteUserModal from './modals/delete-user';
import ResetUserPasswordModal from './modals/reset-password';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faLockOpen, faKey, faEdit, faUserSlash } from '@fortawesome/free-solid-svg-icons';

export default function ActionButtons (props) {

    const { row } = props;
    const userId = row.original.publicId;
    const { isDeletable, isLocked, organisation } = row.original;
    const dispatch = useDispatch();

    const [showUserDelete, setShowUserDelete] = useState(false);
    const [showUserResetPassword, setShowUserResetPassword] = useState(false);

    const onResetPasswordClose = useCallback(() => {
        setShowUserResetPassword(false);
        dispatch(actions.loadUsers());
    }, [dispatch]);

    const onRefreshPasswordClick = useCallback(() => {
        setShowUserResetPassword(true)
    }, [setShowUserResetPassword]);

    const onDeleteClick = useCallback(() => {
        setShowUserDelete(true)
    }, [setShowUserDelete]);

    const onLockClick = useCallback(() => {
        dispatch(actions.lockAccount(userId));
    }, [dispatch, userId]);

    const onUnlockClick = useCallback(() => {
        dispatch(actions.unlockAccount(userId));
    }, [dispatch, userId]);


    const userDeleteModal = showUserDelete 
        ? <DeleteUserModal onClose={() => setShowUserDelete(false)} userId={userId} organisation={organisation} /> 
        : null;

    const userResetPasswordModal = showUserResetPassword 
        ? <ResetUserPasswordModal onClose={onResetPasswordClose} userId={userId} /> 
        : null;

    var statusButton = isLocked 
    ? <button onClick={onUnlockClick} title="Unlock" className="link"><FontAwesomeIcon icon={faLockOpen} /></button>
    : <button onClick={onLockClick} title="Lock" className="link"><FontAwesomeIcon icon={faLock} /></button>;

    return (
        <>
            { statusButton }
            <button onClick={onRefreshPasswordClick} className="link" title="Reset User's Password"><FontAwesomeIcon icon={faKey} /></button>
            <a href={`/SuperUser/Users/Edit/${userId}`} className="link" title="Edit User"><FontAwesomeIcon icon={faEdit} /></a>
            <button onClick={onDeleteClick} className="link" title="Delete User" disabled={!isDeletable}><FontAwesomeIcon icon={faUserSlash} /></button>
            { userResetPasswordModal }
            { userDeleteModal }
        </>
    );
}