import React, { useEffect, useRef } from "react";
import { Form } from 'react-final-form';

import { isClosed } from '../server-values';

import { toIntOptions, toSortedIntOptions, toGroupedIntOptions } from '../../form-inputs/final-form';

import ReferralFormFields from "./form-fields";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlagCheckered } from '@fortawesome/free-solid-svg-icons';

const defaultOptions = {
    reasonsForApplying: [],
    legislations: [],
    services: []
}

const AddForm = ({onSubmit, initialValues, referralOrganisations, referralNote,
    onEditReferralOrganisationModalRequest, onAddReferralNoteModalRequest, onReferralNoteHistoryModalRequest}) => {
    
    const formSubmitButton = useRef();

    useEffect(() => {
        var { unsubscribe } = window.dispatcher.subscribe("change-tracker-submit-form", () => formSubmitButton.current.click());

        return () => {
            unsubscribe();
        }
    }, []);

    
    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            render={({ handleSubmit, submitting, values, form }) => {

                const { options, selectedServiceIds, referralOrganisationId } = values;
                const { reasonsForApplying, legislations, services } = options || defaultOptions;

                const reasonsForApplyingOptions = toIntOptions(reasonsForApplying || []);
                const legislationOptions = toGroupedIntOptions(legislations || []);
                const referralOrganisationOptions = toSortedIntOptions(referralOrganisations || []);
                const disabled = (isClosed || submitting);
                
                const handleFormSubmit = (e) => {
                    e.preventDefault();
                    handleSubmit(); 
                }

                const onFormSubmit = (e) => {
                    values.goToImmigration = false;
                    handleFormSubmit(e);
                }

                const onRecordImmigrationFormSubmit = (e) => {
                    values.goToImmigration = true;
                    handleFormSubmit(e);
                }

                return (
                    <form onSubmit={handleSubmit} rel="change-tracked">
                        <div className="stripedbox">

                            <div className="mandatory"><span>*</span> Denotes a mandatory field </div>

                            <div className="referral-edit-form">
                                
                                <ReferralFormFields
                                    form={form}
                                    selectedServiceIds={selectedServiceIds}
                                    services={services}
                                    referralOrganisationId={referralOrganisationId}
                                    reasonsForApplyingOptions={reasonsForApplyingOptions}
                                    legislationOptions={legislationOptions}
                                    referralOrganisationOptions={referralOrganisationOptions}
                                    disabled={disabled}
                                    referralNote={referralNote}
                                    onEditReferralOrganisationModalRequest={onEditReferralOrganisationModalRequest}
                                    onAddReferralNoteModalRequest={onAddReferralNoteModalRequest}
                                    onReferralNoteHistoryModalRequest={onReferralNoteHistoryModalRequest}
                                />
                                    
                            </div>

                            <hr />

                            <div className="referral-edit-form">
                                <div className="full-row align-center">
                                    <div className="form-buttons">
                                        <button type="button" onClick={onFormSubmit} className="rbtn primary" disabled={disabled} ref={formSubmitButton}>
                                            <FontAwesomeIcon icon={faFlagCheckered} />
                                            <span>Save</span>
                                        </button>
                                        <button type="button" onClick={onRecordImmigrationFormSubmit} className="rbtn primary" disabled={disabled} style={{maxWidth: "215px"}}>
                                            <FontAwesomeIcon icon={faFlagCheckered} />
                                            <span>Record Immigration Status</span>
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </form>
                )
            }}
        />
    )
}


export default AddForm;
