import React from 'react';
import { useHistory } from 'react-router-dom';

export default () => {
    
    const history = useHistory();

    return (
        <div className="error-page">
            <div>
                <h2>An error has occured</h2>
                <h3>Please contact the Support team on 0161 696 3690 / support@locta.co.uk</h3>
            </div>

            <div style={{lineHeight: "20px"}}>
                <button type="button" onClick={() => history.go(-1)} className="as-href">Go Back</button>
                <br />
                or 
                <br />
                <a href="/" className="btn">Return to the Home page</a>
            </div>
        </div>
    )
}