import axios from 'axios';

import { selector } from '../../reducers/admin/users-reducer';

export const SEARCH_START = 'ADMIN_USERS_LIST_SEARCH_START';
export const SEARCH_SUCCESS = 'ADMIN_USERS_LIST_SEARCH_SUCCESS';
export const SEARCH_FAILED = 'ADMIN_USERS_LIST_SEARCH_FAILED';

export const SET_PAGE = 'ADMIN_USERS_LIST_SET_PAGE';
export const SET_SORT = 'ADMIN_USERS_LIST_SET_SORT';
export const SET_FILTERS = 'ADMIN_USERS_LIST_SET_FILTERS';
export const SET_USER_STATUS = 'ADMIN_USERS_LIST_SET_USER_STATUS';


export const urls = {
    users: "/api/admin/users",
    unlock: `/api/admin/users/unlock`,
}

const action = (type, data = null) => ({
    type, data
});

export function loadUsers() {
    return (dispatch) => {
        dispatch(action(SEARCH_START));

        return axios
            .post(urls.users)
            .then(response => {
                dispatch(action(SEARCH_SUCCESS, response.data || []));
            }).catch(err  => {
                dispatch(action(SEARCH_FAILED));
            });
    }
}

export function resetFiltersAndLoadUsers() {
    return (dispatch, getState) => {

        const pageSize = selector.pageSize(getState());

        dispatch(setPage(0, pageSize));
        dispatch(setFilters([]));
        dispatch(loadUsers());
    }
}

export function setPage(pageIndex, pageSize) {
    return (dispatch) => {
        dispatch(action(SET_PAGE, { pageIndex, pageSize }));
    }
}

export function setSort(sortBy) {
    return (dispatch) => {
        dispatch(action(SET_SORT, sortBy));
    }
}

export function setFilters(filters) {
    return (dispatch) => {
        dispatch(action(SET_FILTERS, filters));
    }
}


export function unlockAccount(userId) {
    return async (dispatch) => {
        try {
            await axios.post(urls.unlock, { userId });
            dispatch(action(SET_USER_STATUS, {userId, isLocked: false}));
        }
        catch (err) {
        }
    }
}
