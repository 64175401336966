import axios from 'axios';
import moment from 'moment';

import { onFormSubmitAsync } from '../form-submit';

import dateFormatForServer from '../../extensions/date.formatForServer';

const baseUrl = '/api/case/immigrations';
export const urls = {
    addSubmit: `${baseUrl}/add`
}


export const onGetAllForCaseAsync = async (caseId) => {

    try {
        const response = await axios.post(`${baseUrl}/${caseId}`);
        const results = response.data || {};

        return {
            isLoaded: true,
            isSuccessful: true,
            data: results
        };
    }
    catch (err) {
        return {
            isLoaded: true,
            isSuccessful: false
        }
    }
}


export const onLoadLatestAsync = async (caseId, source) => {
    try {

        const response = await axios.get(`${baseUrl}/${caseId}/latest`, { cancelToken: source.token });
        const values = response.data || {};

        return {
            isLoaded: true,
            isSuccessful: true,
            initialValues: mappers.toReduxForm(values)
        };
    }
    catch (err) {
        return {
            isLoaded: true,
            isSuccessful: false
        }
    }
}

export const onLoadAsync = async (caseId, recordId) => {
    try {

        const response = await axios.get(`${baseUrl}/${caseId}/${recordId}`);
        const values = response.data || {};

        return {
            isLoaded: true,
            isSuccessful: true,
            initialValues: mappers.toReduxForm(values)
        };
    }
    catch (err) {
        return {
            isLoaded: true,
            isSuccessful: false
        }
    }
}


export const onAddSubmitAsync = async (formValues) => {
    const serverModel = mappers.toServerModel(formValues);
    return await onFormSubmitAsync(urls.addSubmit, serverModel);
}



const mappers = {
    toReduxForm: (values) => ({
        ...values,
        dateAsylumGracePeriod: values.dateAsylumGracePeriod ? moment(values.dateAsylumGracePeriod)._d : null,
        dateAsylumSupportEnded: values.dateAsylumSupportEnded ? moment(values.dateAsylumSupportEnded)._d : null,
        dateAsylumSupportStarted: values.dateAsylumSupportStarted ? moment(values.dateAsylumSupportStarted)._d : null,
        dateBarrierLogged: values.dateBarrierLogged ? moment(values.dateBarrierLogged)._d : null,
        dateEnteredUK: values.dateEnteredUK ? moment(values.dateEnteredUK)._d : null,
        dateLeaveGranted: values.dateLeaveGranted ? moment(values.dateLeaveGranted)._d : null,
        dateOfAppealHearing: values.dateOfAppealHearing ? moment(values.dateOfAppealHearing)._d : null,
        dateOfAppealRightsExhausted: values.dateOfAppealRightsExhausted ? moment(values.dateOfAppealRightsExhausted)._d : null,
        dateOfAsylumApplication: values.dateOfAsylumApplication ? moment(values.dateOfAsylumApplication)._d : null,
        dateOfAsylumDecision: values.dateOfAsylumDecision ? moment(values.dateOfAsylumDecision)._d : null,
        dateOfImmigrationApplication: values.dateOfImmigrationApplication ? moment(values.dateOfImmigrationApplication)._d : null,
        dateOfImmigrationDecision: values.dateOfImmigrationDecision ? moment(values.dateOfImmigrationDecision)._d : null,
        dateOfOutcome: values.dateOfOutcome ? moment(values.dateOfOutcome)._d : null,
        dateOfRemovalDecision: values.dateOfRemovalDecision ? moment(values.dateOfRemovalDecision)._d : null,
        dateOfReturn: values.dateOfReturn ? moment(values.dateOfReturn)._d : null,
        dateOfStatusCheck: values.dateOfStatusCheck ? moment(values.dateOfStatusCheck)._d : null,
        dateOfVrsOutcome: values.dateOfVrsOutcome ? moment(values.dateOfVrsOutcome)._d : null,
        dateVrsAvrAppliedFor: values.dateVrsAvrAppliedFor ? moment(values.dateVrsAvrAppliedFor)._d : null,
        expiryDateOfLeave: values.expiryDateOfLeave ? moment(values.expiryDateOfLeave)._d : null,
        familyReturnsUnitDateOfAction: values.familyReturnsUnitDateOfAction ? moment(values.familyReturnsUnitDateOfAction)._d : null,

        isAppealOngoing: values.isAppealOngoing != null ? values.isAppealOngoing.toString() : "unknown",
        isClaimedAsylum: values.isClaimedAsylum.toString(),
        isContinuingLeave: values.isContinuingLeave != null ? values.isContinuingLeave.toString() : "unknown",
        isForeignNationalOffender: values.isForeignNationalOffender?.toString() || false,
        isImmigrationOrEeaApplication: values.isImmigrationOrEeaApplication?.toString() || false,
        isPreviouslySupportedByUK: values.isPreviouslySupportedByUK?.toString() || false,
        isRemovalDecisionMade: values.isRemovalDecisionMade != null ? values.isRemovalDecisionMade.toString() : "unknown",
        isRemovalDecisionRequired: values.isRemovalDecisionRequired?.toString() || false,
        isHomeOfficePursuingBarrier: values.isHomeOfficePursuingBarrier?.toString() || false
    }),
    toServerModel: (values) => {

        const isAppealOngoing = values.isAppealOngoing === "unknown" ? null : JSON.parse(values.isAppealOngoing);
        const isClaimedAsylum = JSON.parse(values.isClaimedAsylum);
        const isContinuingLeave = values.isContinuingLeave === "unknown" ? null : JSON.parse(values.isContinuingLeave);
        const isForeignNationalOffender = JSON.parse(values.isForeignNationalOffender);
        const isImmigrationOrEeaApplication = JSON.parse(values.isImmigrationOrEeaApplication);
        const isPreviouslySupportedByUK = values.isPreviouslySupportedByUK ? JSON.parse(values.isPreviouslySupportedByUK) : null;
        const isRemovalDecisionMade = values.isRemovalDecisionMade === "unknown" ? null : JSON.parse(values.isRemovalDecisionMade);
        const isRemovalDecisionRequired = values.isRemovalDecisionRequired ? JSON.parse(values.isRemovalDecisionRequired) : null;
        const isHomeOfficePursuingBarrier = values.isHomeOfficePursuingBarrier ? JSON.parse(values.isHomeOfficePursuingBarrier) : null;

        return {
            ...values,
            dateAsylumGracePeriod: dateFormatForServer(values.dateAsylumGracePeriod),
            dateAsylumSupportEnded: dateFormatForServer(values.dateAsylumSupportEnded),
            dateAsylumSupportStarted: dateFormatForServer(values.dateAsylumSupportStarted),
            dateBarrierLogged: dateFormatForServer(values.dateBarrierLogged),
            dateEnteredUK: dateFormatForServer(values.dateEnteredUK),
            dateLeaveGranted: dateFormatForServer(values.dateLeaveGranted),
            dateOfAppealHearing: dateFormatForServer(values.dateOfAppealHearing),
            dateOfAppealRightsExhausted: dateFormatForServer(values.dateOfAppealRightsExhausted),
            dateOfAsylumApplication: dateFormatForServer(values.dateOfAsylumApplication),
            dateOfAsylumDecision: dateFormatForServer(values.dateOfAsylumDecision),
            dateOfImmigrationApplication: dateFormatForServer(values.dateOfImmigrationApplication),
            dateOfImmigrationDecision: dateFormatForServer(values.dateOfImmigrationDecision),
            dateOfOutcome: dateFormatForServer(values.dateOfOutcome),
            dateOfRemovalDecision: dateFormatForServer(values.dateOfRemovalDecision),
            dateOfReturn: dateFormatForServer(values.dateOfReturn),
            dateOfStatusCheck: dateFormatForServer(values.dateOfStatusCheck),
            dateOfVrsOutcome: dateFormatForServer(values.dateOfVrsOutcome),
            dateVrsAvrAppliedFor: dateFormatForServer(values.dateVrsAvrAppliedFor),
            expiryDateOfLeave: dateFormatForServer(values.expiryDateOfLeave),
            familyReturnsUnitDateOfAction: dateFormatForServer(values.familyReturnsUnitDateOfAction),
            isAppealOngoing,
            isClaimedAsylum,
            isContinuingLeave,
            isForeignNationalOffender,
            isImmigrationOrEeaApplication,
            isPreviouslySupportedByUK,
            isRemovalDecisionMade,
            isRemovalDecisionRequired,
            isHomeOfficePursuingBarrier
        }
    }
}


export default {
    onGetAllForCaseAsync,
    onLoadLatestAsync,
    onLoadAsync,
    onAddSubmitAsync
}