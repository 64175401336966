import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import * as actions from '../../../state/actions/search-actions';
import { selector } from '../../../state/reducers/search-reducer';


const ShowDependants = () => {
    const dispatch = useDispatch();
    const toggleDependants = () => dispatch(actions.toggleDependants());
    const showDependants = useSelector(state => selector.showDependants(state));

    return (
        <div className="show-dependants-row">
            <button onClick={toggleDependants}>{showDependants ? 'HIDE' : 'SHOW'} DEPENDANTS / ALIASES</button>
        </div>
    )
}

export default ShowDependants;
  