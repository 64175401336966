import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '../../../state/actions/queries-actions';
import Store from '../../../state/stores';
import { selector } from '../../../state/reducers/queries-reducer';
import {withClassNameSwitch} from '../../common'

const BaseRow = ({children, className='', row, url}) => {
  
  const dispatch = useDispatch();
  const handleClick = useCallback(
      (e) => {
          dispatch(actions.toggleExpansion(row.original.caseId, row.original.queryId));
      },
      [dispatch, row]
  );

  return (
    <div {...row.getRowProps()} data-case-id={row.original.caseId} className={`tr ${className}`} style={{flexDirection: "column"}}>
      <div className="result-row with-status">
        {
          row.cells.map((cell) => (
            <span {...cell.getCellProps()} onClick={handleClick}>
              {cell.render('Cell')}
            </span>
          ))
        }
        <div className="clearfix"></div>
      </div>
      { children }
    </div>
  );
}

const RowWithCaseBeingView = withClassNameSwitch(props => selector.isCaseBeingViewed(Store.getState(), props.row.original.caseId) ? "case-is-being-viewed" : "")(BaseRow);
const RowWithCaseBeingViewAndOddEven = withClassNameSwitch(props => props.rowIndex % 2 === 0 ? 'odd' : 'even')(RowWithCaseBeingView);
const Row = RowWithCaseBeingViewAndOddEven;

export default Row;


