import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { onGetAllForCaseAsync, onLoadAsync } from '../../../../api-endpoint-calls/case/immigrations';

import List from './list';
import HistoryFormIA2016 from './form-ia2016';
import HistoryForm from './form';
import { LoadingV1 } from '../../../common';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHistory, faTimes } from '@fortawesome/free-solid-svg-icons';

const defaultState = {
    rows: []
}


export default ({ onClose, caseId }) => {

    const [visible, setVisible] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [successful, setSuccessful] = useState(false);
    const [history, setHistory] = useState(defaultState);

    const [record, setRecord] = useState(null);

    useEffect(() => {
        const source = axios.CancelToken.source();

        const onPageLoadAsync = async () => {
            const result = await onGetAllForCaseAsync(caseId, source);

            setLoaded(result.isLoaded);
            setSuccessful(result.isSuccessful);
            setHistory(result.data);
        }

        onPageLoadAsync();

        return () => {
            source.cancel();
        }

    }, [caseId, setHistory, setLoaded, setSuccessful]);

    useEffect(() => {
        if (visible === false) {
            setTimeout(() => setVisible(true), 200);
        }
    }, [visible, setVisible]);


    const onViewHistoryRecord = async (recordId) => {
        const historyRecord = await onLoadAsync(caseId, recordId);

        if (historyRecord.isSuccessful) {
            setRecord(historyRecord.initialValues);
        }
    }

    const onCloseHistoryRecord = () => {
        setRecord(null);
    }

    const showSpinner = (!loaded);
    const showFailure = (loaded && !successful);

    const page = showSpinner
        ? <LoadingV1 />
        : showFailure
            ? <h2>An error has occured!</h2>
            : record
                ? record.hasIA2016Fields
                    ? <HistoryFormIA2016 onSubmit={() => {}} initialValues={record} onCloseHistoryRecord={onCloseHistoryRecord} />
                    : <HistoryForm onSubmit={() => {}} initialValues={record} onCloseHistoryRecord={onCloseHistoryRecord} />
                : <List rows={history.rows} onViewHistoryRecord={onViewHistoryRecord} />;

    return (
        <>
            <div className={`react-modal-anchor ${(visible ? 'show' : '')}`}>
                <div className={`react-modal immigration-history ${visible ? 'show' : ''}`}>
                    <button onClick={onClose} className="close-button"><FontAwesomeIcon icon={faTimes} /></button>
                    <h1>
                        <FontAwesomeIcon icon={faHistory} />
                        Immigration History
                    </h1>

                    {page}
                </div>
            </div>
            <div className={`react-modal-backdrop ${visible ? 'show' : ''}`}></div>
        </>
    );
}
