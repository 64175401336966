import React, { useEffect, useRef } from "react";
import { Field, Form } from "react-final-form";

import {
    renderDropdownList,
    renderDatePicker,
    renderRadioOption,
    toIntOptions
} from "../../../form-inputs/final-form";

import ClaimedAsylum from "./sections/claimed-asylum";
import ImmigrationApplication from "./sections/immigration-application";


const defaultOptions = {
    applicationOutcomes: [],
    applicationTypes: [],
    asylumOutcomes: [],
    immigrationStatuses: []
};

const NonCaseOwnerForm = ({
    onSubmit,
    initialValues,
    highlights,
    solicitors
}) => {
    
    const formSubmitButton = useRef();

    useEffect(() => {
        var { unsubscribe } = window.dispatcher.subscribe(
            "change-tracker-submit-form",
            () => formSubmitButton.current.click()
        );

        return () => {
            unsubscribe();
        };
    }, []);

    const {
        applicationOutcomes,
        applicationTypes,
        asylumOutcomes,
        immigrationStatuses,
    } = initialValues.options || defaultOptions;

    const applicationOutcomesOptions = toIntOptions(applicationOutcomes);
    const applicationTypesOptions = toIntOptions(applicationTypes);
    const asylumOutcomesOptions = toIntOptions(asylumOutcomes);
    const immigrationStatusesOptions = toIntOptions(immigrationStatuses);
    const solicitorsOptions = toIntOptions(solicitors);

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            render={({ handleSubmit, values }) => {

                const disabled = true;

                return (
                    <form onSubmit={handleSubmit} rel="change-tracked">
                        <div className="immigration-edit-wrapper">
                            <div className="immigration-grid-wrapper">
                                <div className="stripedbox left-column">
                                    <div className="immigration-edit-form">

                                        <h2>Immigration Status</h2>

                                        <div className="input-wrapper">
                                            <Field
                                                name="dateOfStatusCheck"
                                                type="text"
                                                render={renderDatePicker}
                                                label="Date of status check"
                                                placeholder=""
                                                isHighlighted={highlights.dateOfStatusCheck}
                                                disabled={disabled}
                                            />
                                        </div>
                                        <div className="input-wrapper-full-width">
                                            <label>Current Status</label>
                                            <Field
                                                name="immigrationStatusId"
                                                type="text"
                                                render={renderDropdownList}
                                                options={immigrationStatusesOptions}
                                                isHighlighted={highlights.immigrationStatusId}
                                                disabled={disabled}
                                            />
                                        </div>
                                        <div className="input-wrapper">
                                            <Field
                                                name="dateEnteredUK"
                                                type="text"
                                                render={renderDatePicker}
                                                label="Date entered the UK"
                                                placeholder=""
                                                isHighlighted={highlights.dateEnteredUK}
                                                disabled={disabled}
                                            />
                                        </div>
                                        <div className="input-wrapper">
                                            <Field
                                                name="dateLeaveGranted"
                                                type="text"
                                                render={renderDatePicker}
                                                label="Date leave granted"
                                                placeholder=""
                                                isHighlighted={highlights.dateLeaveGranted}
                                                disabled={disabled}
                                            />
                                        </div>
                                        <div className="input-wrapper">
                                            <Field
                                                name="expiryDateOfLeave"
                                                type="text"
                                                render={renderDatePicker}
                                                label="Expiry date of leave"
                                                placeholder=""
                                                isHighlighted={highlights.expiryDateOfLeave}
                                                disabled={disabled}
                                            />
                                        </div>

                                        <div className="input-wrapper">
                                            <label>Foreign National Offender?</label>
                                            <div className={`radio-options ${highlights.isForeignNationalOffender ? 'is-highlighted' : ''}`}>
                                                <Field name="isForeignNationalOffender" type="radio" render={renderRadioOption} value="true" label="Yes" disabled={disabled} />
                                                <Field name="isForeignNationalOffender" type="radio" render={renderRadioOption} value="false" label="No" disabled={disabled} />
                                            </div>
                                        </div>


                                        <hr />


                                        <div className="input-wrapper section-header">
                                            <label>Claimed Asylum?</label>
                                            <div className={`radio-options ${highlights.isClaimedAsylum ? 'is-highlighted' : ''}`}>
                                                <Field name="isClaimedAsylum" type="radio" render={renderRadioOption} value="true" label="Yes" disabled={disabled} />
                                                <Field name="isClaimedAsylum" type="radio" render={renderRadioOption} value="false" label="No" disabled={disabled} />
                                            </div>
                                        </div>

                                        <ClaimedAsylum
                                            values={values}
                                            highlights={highlights}
                                            disabled={disabled}
                                            asylumOutcomesOptions={asylumOutcomesOptions}
                                        />

                                    </div>
                                </div>
                                <div className="stripedbox right-column">
                                    <div className="immigration-edit-form">
                                        <div className="input-wrapper section-header">
                                            <label>Immigration application?</label>
                                            <div className={`radio-options ${highlights.isImmigrationOrEeaApplication ? 'is-highlighted' : ''}`}>
                                                <Field name="isImmigrationOrEeaApplication" type="radio" render={renderRadioOption} value="true" label="Yes" disabled={disabled} />
                                                <Field name="isImmigrationOrEeaApplication" type="radio" render={renderRadioOption} value="false" label="No" disabled={disabled} />
                                            </div>
                                        </div>


                                        <ImmigrationApplication
                                            values={values}
                                            highlights={highlights}
                                            disabled={disabled}
                                            applicationTypesOptions={applicationTypesOptions}
                                            applicationOutcomesOptions={applicationOutcomesOptions}
                                        />


                                        <hr />


                                        <div className="input-wrapper section-header">
                                            <label>Appeal ongoing?</label>
                                            <div className={`radio-options ${highlights.isAppealOngoing ? 'is-highlighted' : ''}`}>
                                                <Field name="isAppealOngoing" type="radio" render={renderRadioOption} value="true" label="Yes" disabled={disabled} />
                                                <Field name="isAppealOngoing" type="radio" render={renderRadioOption} value="false" label="No" disabled={disabled} />
                                                <Field name="isAppealOngoing" type="radio" render={renderRadioOption} value="unknown" label="N/A" disabled={disabled} />
                                            </div>
                                        </div>

                                        <div className="input-wrapper">
                                            <Field
                                                name="dateOfAppealHearing"
                                                type="text"
                                                render={renderDatePicker}
                                                label="Date of appeal hearing"
                                                placeholder=""
                                                isHighlighted={highlights.dateOfAppealHearing}
                                                disabled={disabled}
                                            />
                                        </div>

                                        <div className="input-wrapper">
                                            <Field
                                                name="dateOfAppealRightsExhausted"
                                                type="text"
                                                render={renderDatePicker}
                                                label="Appeal Rights Exhausted date"
                                                placeholder=""
                                                isHighlighted={highlights.dateOfAppealRightsExhausted}
                                                disabled={disabled}
                                            />
                                        </div>


                                        <hr />


                                        <h2>Immigration Represenative Instructed</h2>

                                        <div className="input-wrapper-with-options">
                                            <Field
                                                name="solicitorId"
                                                render={renderDropdownList}
                                                options={solicitorsOptions}
                                                isHighlighted={highlights.solicitorId}
                                                disabled={disabled}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                );
            }}
        />
    );
};

export default NonCaseOwnerForm;
