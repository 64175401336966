import axios from 'axios';
import moment from 'moment';

import { onFormSubmitAsync } from '../form-submit';

import dateFormatForServer from '../../extensions/date.formatForServer';

const baseUrl = '/api/case/closure';
export const urls = {
    addSubmit: `${baseUrl}/add`
}

export const onGetAllForCaseAsync = async (caseId, source) => {

    try {
        const response = await axios.post(`${baseUrl}/${caseId}`, { cancelToken: source.token });
        const results = response.data || {};

        return {
            isLoaded: true,
            isSuccessful: true,
            data: results
        };
    }
    catch (err) {
        return {
            isLoaded: true,
            isSuccessful: false
        }
    }
}

export const onLoadAsync = async (caseId, source) => {
    try {

        const response = await axios.get(`${baseUrl}/${caseId}/latest`, { cancelToken: source.token });
        const values = response.data || {};

        return {
            isLoaded: true,
            isSuccessful: true,
            initialValues: mappers.toReduxForm(values)
        };
    }
    catch (err) {
        return {
            isLoaded: true,
            isSuccessful: false
        }
    }
}


export const onAddSubmitAsync = async (formValues) => {
    const serverModel = mappers.toServerModel(formValues);
    return await onFormSubmitAsync(urls.addSubmit, serverModel);
}


const mappers = {
    toReduxForm: (values) => ({
        ...values,
        closureDate: values.closureDate ? moment(values.closureDate)._d : null
    }),
    toServerModel: (values) => ({
        ...values,
        closureDate: dateFormatForServer(values.closureDate)
    })
}


export default {
    onGetAllForCaseAsync,
    onLoadAsync,
    onAddSubmitAsync
}