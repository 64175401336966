import * as actions from '../actions/queries-actions';

const initialState = {
  results: {},
  resultKeys: [],
  resultValues: [],
  pageIndex: 0,
  pageSize: 100,
  sortBy: [{
    id: "lastMessageDate",
    desc: true,
  }],
  rtfilters: [],
  loading: false,
  casesBeingViewed: {},
  userId: 0,
  expandedQueryId: 0
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.SEARCH_START:
      return { 
        ...state, 
        results: {},
        resultKeys: [],
        resultValues: [],
        pageIndex: 0,
        loading: true, 
      };
    case actions.SEARCH_SUCCESS:
      return { 
        ...state, 
        results: action.data.summaries || {}, 
        resultKeys: Object.keys(action.data.summaries || {}).map(k => Number(k)),
        resultValues: Object.values(action.data.summaries || {}).reduce((acc, val) => acc.concat(val), []),
        loading: false 
      };
    case actions.SEARCH_FAILED:
      return { ...state, loading: false };
    case actions.SET_PAGE:
      return { 
        ...state, 
        pageIndex: action.data.pageIndex, 
        pageSize: action.data.pageSize 
      };
    case actions.SET_SORT:
      return { 
        ...state,
        sortBy: action.data
      };
    case actions.SET_FILTERS:
      return {
        ...state,
        rtfilters: action.data
      };
    case actions.UPDATE_ISBEINGVIEWED:
      return {
        ...state,
        casesBeingViewed: action.data
      }
    case actions.UPDATE_USERID:
      return {
        ...state,
        userId: action.data
      }
    case actions.TOGGLE_EXPANSION:
      return {
        ...state,
        expandedQueryId: (state.expandedQueryId === action.data) ? 0 : action.data
      }
    default:
        return state;
  }
}

const isCaseBeingViewed = (state, caseId) => {
  const hasCaseId = state.queriesResults.casesBeingViewed.hasOwnProperty(caseId);
  
  if(!hasCaseId) return false;

  const myCase = state.queriesResults.casesBeingViewed[caseId];
  const myUserId = state.queriesResults.userId;

  const isBeingViewedByOthers = myCase.filter(x => x !== myUserId).length > 0;
  return isBeingViewedByOthers;
}


export const selector = {
  resultKeys: (state) => state.queriesResults.resultKeys,
  resultValues: (state) => state.queriesResults.resultValues,
  hasResults: (state) => state.queriesResults.resultValues.length > 0,
  isCaseBeingViewed: (state, caseId) => isCaseBeingViewed(state, caseId),
  isExpanded: (state, queryId) => state.queriesResults.expandedQueryId === queryId,
  query: (state) => state.queriesResults.expandedQuery,
  queryLoading: (state) => state.queriesResults.expandedQueryLoading,
  pageSize: (state) => state.queriesResults.pageSize,
  pageIndex: (state) => state.queriesResults.pageIndex,
  sortBy: (state) => state.queriesResults.sortBy,
  filters: (state) => state.queriesResults.rtfilters,
  loading: (state) => state.queriesResults.loading
};
