import axios from 'axios';

const defaultResult = {
    isSuccess: false,
    errors: {},
    message: "Something went wrong, please contact support if you require assistance."
};

const toFormResult = (responseData) => ({
    isSuccess: responseData.isSuccess,
    errors: responseData.validationErrors,
    message: responseData.errorMessage,
    value: responseData.value
});

export const onFormSubmitAsync = async (url, model) => {

    const response = await axios.post(url, model);
    return response.data
        ? toFormResult(response.data)
        : defaultResult;
}

export default {
    onFormSubmitAsync
}