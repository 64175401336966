import React, { Fragment, useState, useEffect, useRef } from "react";
import { Field, Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';

import { onGetAll } from "../../../../api-endpoint-calls/case/housing-provider";
import { isClosed } from '../../server-values';

import { renderDropdownList, renderRadioOption, renderDatePicker, toSortedOptions } from '../../../form-inputs/final-form';

import HousingProvidersModal from '../modals/housing-providers';
import CloseFinanceModal from '../modals/close-finance';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faFlagCheckered } from '@fortawesome/free-solid-svg-icons';



const AddForm = ({onSubmit, initialValues}) => {
    
    const history = useHistory();
    const [ showAddHousingProvidelModal, setShowAddHousingProviderModal ] = useState(false);
    const [ showEditHousingProvidelModal, setShowEditHousingProviderModal ] = useState(false);
    const [ showCloseFinanceModal, setShowCloseFinanceModal ] = useState(false);
    
    const formSubmitButton = useRef();

    const onHousingProviderEditClick = (e) => {
        e.preventDefault(); 
        setShowEditHousingProviderModal(true);
    }
    
    const onHousingProviderAddClick = (e) => {
        e.preventDefault(); 
        setShowAddHousingProviderModal(true);
    }


    useEffect(() => {
        var { unsubscribe } = window.dispatcher.subscribe("change-tracker-submit-form", () => formSubmitButton.current.click());

        return () => {
            unsubscribe();
        }
    }, []);

    
    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            validate={values => {

                const errors = {};

                if (!values.dateMovedIn) {
                    errors.dateMovedIn = 'Date moved in is required';
                }

                if (values.isFromPreviousClosure && !values.dateMovedOut) {
                    errors.dateMovedOut = 'This record is from before a previous closure and is required to have a Date Moved Out.';
                }

                return errors;
            }}
            render={({ handleSubmit, submitting, values, form, valid }) => {


                const { accommodationTypes, propertyDescriptions, housingProviders, fullAddress, otherAddressExist, housingProviderId, isFromPreviousClosure, isVoided, hasOpenFinanceAgainstProperty } = values;

                const accommodationTypesOptions = accommodationTypes || [];
                const propertyDescriptionsOptions = propertyDescriptions || [];
                const housingProvidersOptions = toSortedOptions(housingProviders || []);
                const inputsAreDisabled = (isClosed || isVoided);
                

                const reloadHousingProviders = async () => {
                    var results = await onGetAll();
                    form.change('housingProviders', results);
                }

                const onHousingProviderAddModalClose = () => {
                    setShowAddHousingProviderModal(false);
                    reloadHousingProviders();
                }
                const onHousingProviderEditModalClose = () => {
                    setShowEditHousingProviderModal(false);
                    reloadHousingProviders();
                }

                const onFormSubmit = (e) => {
                    e.preventDefault();

                    if (valid) {
                        if (values.dateMovedOut && hasOpenFinanceAgainstProperty) {
                            setShowCloseFinanceModal(true);
                        } else {
                            handleSubmit();
                        }
                    }
                }

                const onCloseFinanceModalSelection = (closeFinance) => {
                    form.change('isClosingFinanceAfterUpdatingAddress', closeFinance);
                    setShowCloseFinanceModal(false);
                    handleSubmit();
                }


                const backButton = otherAddressExist 
                ? (
                    <button type="button" onClick={() => history.go(-1)} className="rbtn secondary" tabIndex={11} disabled={submitting}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                        <span>Back</span>
                    </button>
                ) 
                : null;
                

                const housingProviderModal = showAddHousingProvidelModal 
                    ? <HousingProvidersModal onClose={onHousingProviderAddModalClose} /> 
                    : showEditHousingProvidelModal
                        ? <HousingProvidersModal onClose={onHousingProviderEditModalClose} housingProviderId={housingProviderId} /> 
                        : null ;

                const closeFinanceModal = showCloseFinanceModal
                    ? <CloseFinanceModal onClose={() => setShowCloseFinanceModal(false)} onSelection={onCloseFinanceModalSelection} />
                    : null;

                const fromPreviousClosureWarning = isFromPreviousClosure
                    ? <div className="error-alert">Data entered before the case was restarted should not be altered.</div>
                    : null;


                const housingProviderButtons = !inputsAreDisabled
                    ? (
                        <div className="select-with-options-buttons">
                            <button type="button" className="rbtn primary small" onClick={onHousingProviderAddClick}>Add New Provider</button>
                            { housingProviderId ? <button type="button" className="rbtn primary small" onClick={onHousingProviderEditClick}>View / Manage</button> : null }
                        </div>
                    )
                    : null;

                return (
                    <>
                        <form onSubmit={handleSubmit} rel="change-tracked">
                            <div className="stripedbox">

                                <div className="mandatory"><span>*</span> Denotes a mandatory field </div>

                                { fromPreviousClosureWarning }

                                <h2 style={{margin: "15px 0px 15px 50px"}}>Current Address</h2>

                                <div className="address-edit-form">
                                    <div className="input-wrapper">
                                        <Field name="dateMovedIn" type="text" render={renderDatePicker} label="Date Moved In" isMandatory={true} placeholder="" tabIndex={1} maxDate={new Date()} disabled={inputsAreDisabled} />
                                    </div>
                                    <div className="input-wrapper">
                                        <Field name="dateMovedOut" type="text" render={renderDatePicker} label="Date Moved Out" isMandatory={isFromPreviousClosure} tabIndex={2} placeholder="" minDate={values.dateMovedIn} disabled={inputsAreDisabled} />
                                    </div>
                                    <div className="input-wrapper">
                                        <label>Share address with the Home Office?</label>
                                        <div className="radio-options">
                                            <Field name="isSharedWithHomeOffice" type="radio" render={renderRadioOption} value="true" label="Yes" tabIndex={3} disabled={inputsAreDisabled} />
                                            <Field name="isSharedWithHomeOffice" type="radio" render={renderRadioOption} value="false" label="No" tabIndex={3} disabled={inputsAreDisabled} />
                                        </div>
                                    </div>
                                </div>

                                <hr />

                                <div className="address-edit-form">
                                    <div className="input-wrapper">
                                        <label>Address</label>
                                        <div className="address-display">
                                            <div className="full-address">{ fullAddress && fullAddress.split(',').map(value => <Fragment key={value}><span>{value}</span><br/></Fragment>)}</div>
                                        </div>
                                    </div>
                                </div>
                                
                                <hr />

                                <h2 style={{margin: "30px 0px 15px 50px"}}>Additional Information (Optional)</h2>

                                <div className="address-edit-form">
                                    <div className="input-wrapper">
                                        <label>Property Type</label>
                                        <Field name="accommodationTypeId" render={renderDropdownList} tabIndex={6} options={accommodationTypesOptions} disabled={inputsAreDisabled} />
                                    </div>
                                    <div className="input-wrapper">
                                        <label>Property Description</label>
                                        <Field name="propertyDescription" render={renderDropdownList} tabIndex={7} options={propertyDescriptionsOptions} disabled={inputsAreDisabled} />
                                    </div>
                                    <div className="input-wrapper">
                                        <label>Staying with friends / family</label>
                                        <div className="radio-options">
                                            <Field name="isStayingWithFriendFamily" type="radio" render={renderRadioOption} value="true" label="Yes" tabIndex={8} disabled={inputsAreDisabled} />
                                            <Field name="isStayingWithFriendFamily" type="radio" render={renderRadioOption} value="false" label="No" tabIndex={8} disabled={inputsAreDisabled} />
                                        </div>
                                    </div>

                                    <div className="input-wrapper">
                                        <label>Name of property provider</label>
                                        <div className="select-with-options">
                                            <Field name="housingProviderId" render={renderDropdownList} tabIndex={9} options={housingProvidersOptions} disabled={inputsAreDisabled} />
                                            { housingProviderButtons }
                                        </div>
                                        
                                    </div>
                                </div>

                                <hr />

                                <div className="address-edit-form">
                                    <div className="full-row align-center">
                                        <div className="form-buttons">
                                            { backButton }
                                            <button type="button" onClick={onFormSubmit} className="rbtn primary" tabIndex={10} disabled={submitting || inputsAreDisabled} ref={formSubmitButton}>
                                                <FontAwesomeIcon icon={faFlagCheckered} />
                                                <span>Save</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </form>
                        { housingProviderModal }
                        { closeFinanceModal }
                    </>
                )
            }}
        />
    )
}


export default AddForm;
