import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { onLoadAsync, onAddReplySubmitAsync, onCompleteSubmitAsync } from '../../../api-endpoint-calls/queries';

import Reply from './reply';
import Close from './close';
import Messages from './messages';
import Details from './message-details';

import { errorNotification, LoadingV1 } from '../../common';


export default ({ caseId, caseQueryId, disabled, fromCase }) => {

    const [loaded, setLoaded] = useState(false);
    const [successful, setSuccessful] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const [query, setQuery] = useState({
        summary: {
            caseId,
            caseQueryId,
            isClosed: false, 
            dateClosed: null
        },
        messages: [],
        userCanCompleteQuery: false,
        userIsHomeOffice: false
    });

    const { summary, userCanCompleteQuery, messages, userIsHomeOffice } = query;
    const { isClosed, dateClosed } = summary;

    const addReplyInitialValues = {
        caseId,
        caseQueryId
    };

    const closeInitialValues = {
        caseId,
        caseQueryId,
        isClosed,
        dateClosed,
        userCanCompleteQuery
    }

    useEffect(() => {
        const source = axios.CancelToken.source();

        const onPageLoadAsync = async () => {
            const result = await onLoadAsync(caseId, caseQueryId, source);
            
            setLoaded(result.isLoaded);
            setSuccessful(result.isSuccessful);

            if (result.initialValues) {
                setQuery(result.initialValues);
            }
        }

        onPageLoadAsync();

        return () => {
            source.cancel();
        }

    }, [caseId, caseQueryId, setLoaded, setSuccessful, setQuery]);


    const onSuccess = async () => {
        const query = await onLoadAsync(caseId, caseQueryId);
        if (query.initialValues) {
            setQuery(query.initialValues);
        }
    }

    const onAddReplySubmit = async (formValues) => {
        const result = await onAddReplySubmitAsync(formValues);
        
        if (result.isSuccess) {
            setErrorMessage(null);
            onSuccess();
        } else {
            setErrorMessage(result.message);
        }

        return result.errors;
    }

    const onCloseSubmit = async (formValues) => {
        const result = await onCompleteSubmitAsync(formValues);

        if (result.isSuccess) {
            setErrorMessage(null);
            onSuccess();
        } else {
            setErrorMessage(result.message);
        }

        return result;
    }
    
    const errorAlert = errorNotification(errorMessage);

    const showSpinner = (!loaded);
    const showFailure = (loaded && !successful);

    if (showSpinner) {
        return <LoadingV1 />;
    } else if (showFailure) {
        return <h2>An error has occured!</h2>;
    }

    return (
        <div className="stripedbox result-query-content">
            { errorAlert }
            <div className="rounded-box header">
                <Details summary={summary} userIsHomeOffice={userIsHomeOffice} fromCase={fromCase} />
                { isClosed ? null : <Reply onSubmit={onAddReplySubmit} initialValues={addReplyInitialValues} /> }
            </div>
            <Close onSubmit={onCloseSubmit} initialValues={closeInitialValues} />
            <Messages messages={messages} userIsHomeOffice={userIsHomeOffice} />
        </div>
    );
}