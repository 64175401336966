import React from 'react';
import moment from "moment";

import MyCaseCheckbox from './my-case-checkbox';

export default (isOneThousandDaySearch) => [
    {
        Header: 'Alerts Active',
        accessor: 'alertsActive',
        width: 109,
        minWidth: 0,
        maxWidth: 109,
        disableSortBy: true,
        disableFilters: true
    },
    {
        id: 'daysCount',
        Header: () => isOneThousandDaySearch ? 'Days on Support' : 'Days alert active',
        accessor: (row, index) => isOneThousandDaySearch ? row.daysSupported : row.daysElapsed,
        width: 92,
        minWidth: 0,
        maxWidth: 92,
        isSorted: true,
        isSortedDesc: true,
        Cell: ({ value }) => {
            let colour = value === 0 ? "text-grey" : (value > 5 ? "text-red" : "text-green");
            return (<div className={`${colour} bold`}>{value === 0 ? "N/A" : value}</div>)
        },
        disableFilters: true
    },
    {
        Header: 'Case Status',
        accessor: 'caseStatusClass',
        width: 100,
        minWidth: 0,
        maxWidth: 100,
        disableSortBy: true,
        Cell: ({ value }) => <div className={value}></div>,
        disableFilters: true
    },
    {
        Header: 'Referral Date',
        accessor: 'refferalDate',
        width: 105,
        minWidth: 0,
        maxWidth: 105,
        Cell: ({ value }) => <>{moment(value).format('DD/MM/YYYY')}</>,
        disableFilters: true
    },
    {
        Header: 'Forename',
        accessor: 'forename',
        width: 90,
        minWidth: 0,
        maxWidth: 90,
        disableFilters: true
    },
    {
        Header: 'Surname',
        accessor: 'surname',
        width: 90,
        minWidth: 0,
        maxWidth: 90,
        disableFilters: true
    },
    {
        Header: 'Caseworker',
        accessor: 'caseWorkersName',
        width: 140,
        minWidth: 0,
        maxWidth: 140,
        disableFilters: true
    },
    {
        Header: 'Local Authority',
        accessor: 'localAuthority',
        width: 128,
        minWidth: 0,
        maxWidth: 128,
        disableFilters: true
    },
    {
        Header: 'My Case',
        accessor: 'isThisMyCase',
        width: 80,
        minWidth: 0,
        maxWidth: 80,
        disableSortBy: true,
        Cell: ({ row, value }) => <div className="center"><MyCaseCheckbox row={row} value={value} /></div>,
        disableFilters: true
    }
]