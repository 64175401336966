import axios from 'axios';

import stringIsNullOrEmpty from '../extensions/string.isNullOrEmpty';

const baseUrl = '/api/search';
export const urls = {
    byName: (value) => `${baseUrl}/by-personal-details/names/${value}`
}

export const onSearchByNameAutocompleteAsync = async (terms) => {
    try {
        terms = terms?.trim() || '';

        if (stringIsNullOrEmpty(terms))
            return [];

        const response = await axios.post(urls.byName(terms));
        const results = response?.data || [];

        return results;
    }
    catch (err) {
        return {};
    }
}

export default {
    onSearchByNameAutocompleteAsync
}