import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from '../../state/actions/queries-actions';
import { selector } from '../../state/reducers/queries-reducer';
import { selector as filtersSelector } from '../../state/reducers/filters-reducer';

import TableResults from '../react-table/table-results';
import NoResults from '../react-table/no-results';
import { switchComponent, LoadingV1 } from "../common";

import FiltersTabs from './filters/tabs';
import FiltersSelects from './filters/selects';
import FiltersRadios from './filters/radios';

import columns from './table/table-columns';
import Row from './table/table-row';
import ExpandableDetails from './table/table-expandable-details';
import useMiddleware from './table/table-middleware';


const Results = () => {

    const results = useSelector(state => selector.resultValues(state));
    const pageSize = useSelector(state => selector.pageSize(state));
    const pageIndex = useSelector(state => selector.pageIndex(state));
    const sortBy = useSelector(state => selector.sortBy(state));
    const filters = useSelector(state => selector.filters(state));

    return (
        <div className="react-table">
            <div className="stripedbox">
                <TableResults 
                    columns={columns} 
                    data={results} 
                    itemsPerPage={pageSize} 
                    pageNumber={pageIndex} 
                    sortBy={sortBy}
                    filters={filters}
                    Row={Row} 
                    ExpandableDetails={ExpandableDetails} 
                    useMiddleware={useMiddleware} />
            </div>
        </div>
    )
}

const TableWrapperSwitch = (props) => switchComponent(props)(Results, NoResults, () => props.hasResults);
const TableWrapperWithLoading = (props) => switchComponent(props)(LoadingV1, TableWrapperSwitch, () => props.loading);

const QueryResults = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.loadQueries());
        document.getElementById("pageInfoHeader").innerText = `Queries`;
    }, [dispatch])

    const hasResults = useSelector(state => selector.hasResults(state));
    const loading = useSelector(state => selector.loading(state));

    const { isHomeOfficeUser } = useSelector(state => filtersSelector.userDetails(state));
    const filters = useSelector(state => filtersSelector.values(state));
    const filterOptions = useSelector(state => filtersSelector.filterOptions(state));

    return (
        <div className="react-queries-page">
            <FiltersTabs filters={filters} />
            <div className="stripedbox filters">
                <FiltersSelects filters={filters} filterOptions={filterOptions} isHomeOfficeUser={isHomeOfficeUser} />
                <FiltersRadios filters={filters} isHomeOfficeUser={isHomeOfficeUser} />
                <div className="clearfix"></div>
            </div>
            
            <TableWrapperWithLoading hasResults={hasResults} loading={loading} />
        </div>
    )
};

export default QueryResults;
