import React from 'react';

import useLocalAuthorityCaseworkerForGrid from './use-la-caseworker';
import useHomeOfficeCaseworkerForGrid from './use-ho-caseworker';

const CaseOwnerGrid = ({ summary }) => {

    const { principalApplicantDateOfBirth, principalApplicantTelephone, caseId, principalApplicantReferenceNumber, hoLookupReference, principalApplicantNationality } = summary;
    return (
        <div className="case-details-grid-row">
            <label>
                Date of birth
            </label>
            <div className="fake-input">
                {principalApplicantDateOfBirth}
            </div>

            <label>
                Telephone
            </label>
            <div className="fake-input">
                {principalApplicantTelephone}
            </div>

            <label>
                Connect Case Id
            </label>
            <div className="fake-input">
                {caseId}
            </div>

            <label>
                Nationality
            </label>
            <div className="fake-input">
                {principalApplicantNationality}
            </div>

            <label>
                Local Authority Ref
            </label>
            <div className="fake-input">
                {principalApplicantReferenceNumber}
            </div>

            <label>
                Home Office Ref
            </label>
            <div className="fake-input">
                {hoLookupReference}
            </div>

            {useLocalAuthorityCaseworkerForGrid(summary)}
            {useHomeOfficeCaseworkerForGrid(summary)}
        </div>
    )
}

export default CaseOwnerGrid;