import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';


export default ({ status }) => {

    var statusClass = status.isExpired ? "expired" : status.noRecourseToPublicFunds ? "no" : "yes";
    const formattedDescription = status.description ? status.description.replace(/\n/g, '<br />') : status.description;
    return (
        <div className={`digital-record ${statusClass}`}>
            <div className={`header ${statusClass}`}>
                <div className="title">
                    <FontAwesomeIcon icon={status.noRecourseToPublicFunds ? faTimes : faCheck} />
                    {
                        status.noRecourseToPublicFunds
                            ? <h3>No Recourse To Public Funds (i.e. benefits and housing)</h3>
                            : <h3>Recourse To Public Funds (i.e. benefits and housing)</h3>
                    }
                </div>
                { status.isExpired && <div className='stamp'>EXPIRED</div> }
            </div>

            <ul className="details-list">
                <li>
                    <label>Status</label>
                    <span>{status.status}</span>
                </li>
                <li>
                    <label>Start Date</label>
                    <span>{status.statusStartDate}</span>
                </li>
                <li>
                    <label>End Date</label>
                    <span>{status.statusEndDate || 'N/A'}</span>
                </li>
                <li>
                    <label>Description</label>
                    <span dangerouslySetInnerHTML={{__html: formattedDescription}}></span>
                </li>
            </ul>
        </div>
    )
}