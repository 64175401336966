import axios from 'axios';
import moment from 'moment';

import { onFormSubmitAsync } from '../form-submit';

import dateFormatForServer from '../../extensions/date.formatForServer';

const baseUrl = '/api/case/referral';
export const urls = {
    addSubmit: `${baseUrl}/add`,
    updateSubmit: `${baseUrl}/edit`
}



export const onLoadAsync = async (caseId, source) => {
    try {

        const response = await axios.get(`${baseUrl}/${caseId}`, { cancelToken: source.token });
        const values = response.data || {};

        return {
            isLoaded: true,
            isSuccessful: true,
            initialValues: mappers.toReduxForm(values)
        };
    }
    catch (err) {
        return {
            isLoaded: true,
            isSuccessful: false
        }
    }
}


export const onAddSubmitAsync = async (formValues) => {
    const serverModel = mappers.toServerModel(formValues);
    return await onFormSubmitAsync(urls.addSubmit, serverModel);
}

export const onUpdateSubmitAsync = async (formValues) => {
    const serverModel = mappers.toServerModel(formValues);
    return await onFormSubmitAsync(urls.updateSubmit, serverModel);
}


const mappers = {
    toReduxForm: (values) => ({
        ...values,
        dateOfReferral: values.dateOfReferral ? moment(values.dateOfReferral)._d : null
    }),
    toServerModel: (values) => ({
        ...values,
        dateOfReferral: dateFormatForServer(values.dateOfReferral)
    })
}


export default {
    onLoadAsync,
    onAddSubmitAsync,
    onUpdateSubmitAsync
}