import React from 'react';
import { Field, Form } from 'react-final-form';

import { renderTextArea } from '../../form-inputs/final-form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlagCheckered } from '@fortawesome/free-solid-svg-icons';

export default ({onSubmit, initialValues}) => (
	<Form
		onSubmit={onSubmit}
		initialValues={initialValues}
		render={({ handleSubmit, submitting, form }) => (
			<form
				onSubmit={(event) => {
					handleSubmit(event).then(() => form.reset());
				}}
				className="reply">
				<Field name="message" type="text" render={renderTextArea} isMandatory={true} disabled={submitting} isFullWidth={true} />
				<button type="submit" className="rbtn primary" disabled={submitting}>
					<FontAwesomeIcon icon={faFlagCheckered} />
					<span>Reply</span>
				</button>
			</form>
		)}
	/>
);
