import React, { useState } from 'react';
import { useParams } from 'react-router';

import { onResetPasswordSubmitAsync } from '../../../../api-endpoint-calls/admin/users';

import Form from './form';
import { successNotification, errorNotification } from '../../../common';


export default () => {

    const [saveMessage, setSaveMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const { userId } = useParams();

    const initialValues = {
        userId
    };

    const successAlert = successNotification(saveMessage);
    const errorAlert = errorNotification(errorMessage);


    const onSubmit = async (formValues) => {
        const result = await onResetPasswordSubmitAsync(formValues);

        if (result.isSuccess) {
            setSaveMessage("Your password has been updated.");
            setErrorMessage(null);
        } else {
            setSaveMessage(null);
            setErrorMessage(result.message);
        }

        return result.errors;
    }
    
    return (
        <div className="reset-password stripedbox">
            {successAlert}
            {errorAlert}
            <Form onSubmit={onSubmit} initialValues={initialValues} />
        </div>
    );
    
}