import stringIsNullOrEmpty from '../../extensions/string.isNullOrEmpty';

const documentReferencePattern = /^[a-zA-Z0-9-]{1,30}$/


export default (values) => {
    const errors = {};

    if (values.lookupType === "document") {

        if (stringIsNullOrEmpty(values.documentType)) {
            errors.documentType = "Please choose the document type you want to search.";
        }

        if (stringIsNullOrEmpty(values.documentReference)) {
            errors.documentReference = "Please provide the document's reference number.";
        } else if (values.documentReference.length > 30) {
            errors.documentReference = "Invalid - You can only provide a maximum of 30 characters for this field.";
        } else if (!documentReferencePattern.test(values.documentReference)) {
            errors.documentReference = "Invalid - Only letters A-Z, the numbers 0-9 and hyphens are allowed.";
        }

        if(values.documentType === 'PASSPORT' || values.documentType === 'NAT') {
            if (stringIsNullOrEmpty(values.nationality)) {
                errors.nationality = "When choosing a document type of Passport or National Identity Card, the nationality is required.";
            }
        }


    } else if (values.lookupType === "nino") {

        if (stringIsNullOrEmpty(values.nino)) {
            errors.nino = "Please provide the person's national insurance number.";
        }

        if (stringIsNullOrEmpty(values.firstName)) {
            errors.firstName = "Please provide the person's first name.";
        }

        if (stringIsNullOrEmpty(values.surname) || values.surname.length < 2) {
            errors.surname = "Please provide the person's surname, this needs to be at least 2 characters long.";
        }
    }


    if (stringIsNullOrEmpty(values.dateOfBirth)) {
        errors.dateOfBirth = "Please provide the date of birth."
    }

    return errors;
}