import React, { useState, useEffect } from 'react';

import { onGetAllForCaseAsync } from '../../../api-endpoint-calls/queries';

import Content from '../content';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';


export default ({onClose, caseId, caseQueryId, disabled}) => {

    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (visible === false) {
            setTimeout(() =>  setVisible(true), 200);
        }
    }, [visible, setVisible]);

    const onModalClose = async () => {
        const queries = await onGetAllForCaseAsync(caseId);
        onClose(queries);
    }

    return (
        <>
            <div className={ `react-modal-anchor ${(visible ? 'show' : '')}`}>
                <div className={ `react-modal view-query ${visible ? 'show' : ''}` } style={{width: "880px"}}>
                    <button onClick={onModalClose} className="close-button"><FontAwesomeIcon icon={faTimes} /></button>
                    <Content caseId={caseId} caseQueryId={caseQueryId} disabled={disabled} fromCase={true} onClose={onClose} />
                </div>
            </div>
            <div className={ `react-modal-backdrop ${visible ? 'show' : ''}` }></div>
        </>
    );
    
}