import { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import axios from "axios"


const stillLoggedIn = async () => {
    var result = await axios.post("/api/account/still-logged-in");
    return result.data;
}

export default () => {

    var history = useHistory();

    useEffect(() => {
      return history.listen(async (location) => {
        const weAreStillLoggedIn = await stillLoggedIn();
        if (!weAreStillLoggedIn) {
            window.location = `/Account/Login?returnUrl=${location.pathname}${location.hash}`;
        }
      })
    })
}
