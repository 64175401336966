import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-final-form";

import calculateAge from "../../../extensions/date.calculateAge";
import {
    toIntOptions,
    toSortedOptions,
    toSortedIntOptions,
    toGroupedIntOptions
} from "../../form-inputs/final-form";

import ApplicantFormFields from "./applicant-form-fields";
import ReferralFormFields from "./referral-form-fields";
import DuplicateCaseList from "./duplicate-case-list";

import validateApplicantForm from './applicant-form-validate';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

const defaultOptions = {
    genders: [],
    nationalities: [],
    languages: [],
    ethnicities: [],
    impairmentGroups: [],
    reasonsForApplying: [],
    legislations: [],
    services: []
};

const CreateCaseForm = ({
    onSubmit,
    initialValues,
    submitButtonIsdisabled,
    onDuplicateCheckAsync,
    referralOrganisations,
    referralNote,
    onEditReferralOrganisationModalRequest,
    onAddReferralNoteModalRequest,
    onReferralNoteHistoryModalRequest
}) => {

    const [step, setStep] = useState(1);
    const [duplicateCases, setDuplicateCases] = useState([]);

    const formSubmitButton = useRef();

    useEffect(() => {
        var { unsubscribe } = window.dispatcher.subscribe(
            "change-tracker-submit-form",
            () => formSubmitButton.current.click()
        );

        return () => {
            unsubscribe();
        };
    }, []);


    const onFormSubmit = async (values) => {

        if (showReferralForm) {
            return onSubmit(values);

        } else if (showApplicantForm) {
            var errors = validateApplicantForm(values);
            if (Object.entries(errors).length > 0) {
                return errors;
            } else {
                const duplicateResult = await onDuplicateCheckAsync(values);
                
                if (duplicateResult.isSuccessful && duplicateResult.data.hasDuplicates) {
                    setDuplicateCases(duplicateResult.data.summaries);
                    setStep(2);
                } else {
                    setStep(3);
                }
            }

        } else {
            setStep(3);
        }
    };

    const onPreviousClick = () => {
        if (step === 3 && duplicateCases.length === 0) {
            setStep(1)
        } else {
            setStep(step - 1);
        }
    };


    const showApplicantForm = step === 1;
    const showDuplicateCasesList = step === 2;
    const showReferralForm = step === 3;

    const {
        genders,
        nationalities,
        languages,
        ethnicities,
        impairmentGroups,
        reasonsForApplying,
        legislations,
        services
    } = initialValues.options || defaultOptions;

    const today = new Date();

    const gendersOptions = genders;
    const nationalitiesOptions = nationalities;
    const languagesOptions = toSortedIntOptions(languages);
    const ethnicitiesOptions = toSortedIntOptions(ethnicities);
    const reasonsForApplyingOptions = toIntOptions(reasonsForApplying || []);
    const legislationOptions = toGroupedIntOptions(legislations || []);
    const referralOrganisationOptions = toSortedOptions(referralOrganisations || []);




    return (
        <Form
            onSubmit={onFormSubmit}
            initialValues={initialValues}
            render={({ handleSubmit, submitting, values, form }) => {

                const {
                    selectedHealthConditionIds,
                    dateOfBirth,
                    selectedServiceIds,
                    referralOrganisationId
                } = values;


                const disabled = submitButtonIsdisabled || submitting;

                const age = calculateAge(dateOfBirth);

                const previousButton = step > 1
                    ? (
                        <button type="button" className="rbtn secondary" onClick={onPreviousClick} disabled={disabled}>
                            <FontAwesomeIcon icon={faArrowLeft} />
                            <span>Previous</span>
                        </button>
                    )
                    : null;


                const applicantForm = showApplicantForm
                    ? (
                        <ApplicantFormFields
                            form={form}
                            selectedHealthConditionIds={selectedHealthConditionIds}
                            age={age}
                            today={today}
                            impairmentGroups={impairmentGroups}
                            gendersOptions={gendersOptions}
                            nationalitiesOptions={nationalitiesOptions}
                            languagesOptions={languagesOptions}
                            ethnicitiesOptions={ethnicitiesOptions}
                            disabled={disabled}
                        />
                    )
                    : null

                const duplicateCaseList = showDuplicateCasesList
                    ? <DuplicateCaseList summaries={duplicateCases} />
                    : null;

                const referralForm = showReferralForm
                    ? (
                        <ReferralFormFields
                            form={form}
                            selectedServiceIds={selectedServiceIds}
                            services={services}
                            referralOrganisationId={referralOrganisationId}
                            reasonsForApplyingOptions={reasonsForApplyingOptions}
                            legislationOptions={legislationOptions}
                            referralOrganisationOptions={referralOrganisationOptions}
                            disabled={disabled}
                            referralNote={referralNote}
                            onEditReferralOrganisationModalRequest={onEditReferralOrganisationModalRequest}
                            onAddReferralNoteModalRequest={onAddReferralNoteModalRequest}
                            onReferralNoteHistoryModalRequest={onReferralNoteHistoryModalRequest}
                        />
                    )
                    : null;

                return (
                    <form onSubmit={handleSubmit} rel="change-tracked">

                        {applicantForm}
                        {duplicateCaseList}
                        {referralForm}


                        <div className="stripedbox form-buttons edges">
                            {previousButton}
                            <span></span>
                            <button type="submit" className="rbtn primary" disabled={disabled} ref={formSubmitButton}>
                                <FontAwesomeIcon icon={faArrowRight} />
                                <span>{showReferralForm ? "Submit" : "Next"}</span>
                            </button>
                        </div>
                    </form>
                );
            }}
        />

    );
};

export default CreateCaseForm;
