import React from "react";

import * as searchActions from '../../state/actions/search-actions';
import {useDispatch} from 'react-redux';
import { useEffect } from 'react';

const Page = (props) => {
  
  const dispatch = useDispatch();

    useEffect(() => {
        dispatch(searchActions.resetSearch());
    }, [dispatch]);

  return (<></>);
}

export default Page;
