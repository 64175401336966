import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';

import { onGetAllAsync } from '../../../api-endpoint-calls/case/notes';

import { LoadingV1 } from '../../common';
import { MultiSelectList } from '../../form-inputs';
import { toIntOptions } from '../../form-inputs/final-form';

import AddNoteModal from './add';

import { caseId, isClosed } from '../server-values';
import useRedirectIfNotLoggedIn from '../../common/useRedirectIfNotLoggedIn';
import { quickFilters } from './options';

const defaultState = {
    notes: [],
    applicantId: null,
    dependants: [],
    noteTypes: []
};

export default () => {

    useRedirectIfNotLoggedIn();

    const [state, setState] = useState(defaultState);
    const { notes, applicantId, dependants, noteTypes } = state;
    const dependantOptions = dependants;
    const noteTypeOptions = toIntOptions(noteTypes);
    const groupedNoteTypes = [{ options: noteTypeOptions }];

    const [loaded, setLoaded] = useState(false);
    const [successful, setSuccessful] = useState(false);

    const [selectedOrganisations, setSelectedOrganisations] = useState([]);
    const [selectedNoteTypes, setSelectedNoteTypes] = useState([]);


    const [showAddModal, setShowAddModal] = useState(false);
    const onModalClose = (notes) => {
        setShowAddModal(false);
        if (notes) {
            setState(notes);
        }
    }
    
    const addModal = showAddModal
        ? <AddNoteModal onClose={onModalClose} caseId={caseId} applicantId={applicantId} dependants={dependantOptions} noteTypes={noteTypeOptions} />
        : null;

    useEffect(() => {
        const source = axios.CancelToken.source();

        const onPageLoadAsync = async () => {
            const result = await onGetAllAsync(caseId, source);
            
            setLoaded(result.isLoaded);
            setSuccessful(result.isSuccessful);

            if (result.isSuccessful) {
                setState(result.data);
            }
        }

        onPageLoadAsync();

        return () => {
            source.cancel();
        }
    }, []);


    const filteredNotes = useMemo(() => {

        var result = notes;

        const selectedPages = noteTypeOptions.filter(i => selectedNoteTypes.includes(i.value));

        if (selectedPages.length > 0) {
            const pages = selectedPages.map(x => x.label);
            result = result.filter(note => pages.includes(note.noteType));
        }

        const filterableQuickFilters = quickFilters.reduce((acc, val) => acc.concat(val.options), []);
        const selectedQuickFilters = filterableQuickFilters.filter(i => selectedOrganisations.includes(i.value));

        if (selectedQuickFilters.length > 0) {
            var selectedValues = selectedQuickFilters.map(x => x.value);
            result = result.filter(note => (selectedValues.includes(1) && note.noteCreatorIsLocalAuthority)
                                        || (selectedValues.includes(2) && !note.noteCreatorIsLocalAuthority));
        }

        return result;

    }, [selectedOrganisations, selectedNoteTypes, notes, noteTypeOptions]);


    const showSpinner = (!loaded);
    const showFailure = (loaded && !successful);

    if (showSpinner) {
        return <LoadingV1 />;
    } else if (showFailure) {
        return <h2>An error has occured!</h2>;
    }

    return (
        <div className="react-case-notes-page">

            <div className="stripedbox filters">
                <div className="query-options">
                    <MultiSelectList title="Quick Filter :" allText="All" options={quickFilters} values={selectedOrganisations} onChange={(v) => setSelectedOrganisations(v)} />
                    <MultiSelectList title="Filter by page :" allText="All Pages" options={groupedNoteTypes} values={selectedNoteTypes} onChange={(v) => setSelectedNoteTypes(v)} />
                </div>
            </div>

            <div className="stripedbox">
                <div className="header-with-button">
                    <h2>Notes</h2>
                    { !isClosed ? <button type="button" onClick={() => setShowAddModal(true)} className="rbtn primary small">Add Note</button> : null }
                </div>

                <div className="notes-container">
                { filteredNotes.map((item) => {
                    const formattedContent = item.content ? item.content.replace(/\n/g, '<br />') : item.content;

                    return (
                        <div className="notes-control fake-input" key={item.id}>
                            <div className="notes-text" dangerouslySetInnerHTML={{__html: formattedContent}}></div>
                            <div className="notes-widget-footer">
                                <span>
                                    <span className="purple">{item.noteCreator}</span>
                                    <span> on </span>
                                    <span className="darkGray">{item.dateCreated}</span>
                                </span>
                                <span className="purple float-right">
                                    <span>{ item.noteType }</span>
                                    { item.noteType === "Dependant" ? (
                                        <span> - {item.applicantName}</span>
                                    ) : null }
                                </span>
                            </div>
                        </div>
                    );
                }) }
                </div>
            </div>
            { addModal }
        </div>
    );
}
