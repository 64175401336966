import React from 'react';
import moment from "moment";

import ExpansionToggleButton from './expansion-toggle-button';

export default [
    {
      Header: 'Last Message Received',
      accessor: 'lastMessageDate',
      width: 124,
      minWidth: 0,
      maxWidth: 124,
      isSorted: true,
      isSortedDesc: true,
      Cell: ({ value }) => <span>{moment(value).format('DD/MM/YYYY')}</span>,
      disableFilters: true
    },
    {
      Header: 'Days Elapsed',
      accessor: 'daysElapsed',
      width: 75,
      minWidth: 0,
      maxWidth: 75,
      Cell: ({row, value}) => {
        return (<div className={`${row.original.daysElapsedColour} bold`}>{value === 0 ? "N/A" : value}</div>)
      },
      disableFilters: true
    },
    {
      Header: <>Type<br/>&nbsp;</>,
      accessor: 'noOfPigeons',
      width: 66,
      minWidth: 0,
      maxWidth: 66,
      disableSortBy: true,
      Cell: ({ value }) => <div className={`type-img query-${value > 1 ? 'received' : 'sent'}`}></div>,
      disableFilters: true
    },
    {
      Header: 'Last Message Sent By',
      accessor: 'lastMessageBy',
      width: 130,
      minWidth: 0,
      maxWidth: 130,
      Cell: ({value}) => <span>{`${value}`}</span>,
      disableFilters: true
    },
    {
      Header: 'Principal Applicant',
      accessor: 'principalApplicant',
      width: 115,
      minWidth: 0,
      maxWidth: 115,
      Cell: ({value}) => <span>{`${value}`}</span>,
      disableFilters: true
    },
    {
      Header: <>Query Title<br/>&nbsp;</>,
      accessor: 'queryTitle',
      width: 120,
      minWidth: 0,
      maxWidth: 120,
      Cell: ({value}) => <span>{`${value}`}</span>,
      disableFilters: true
    },
    {
      Header: 'Raised By - Organisation',
      accessor: 'raisedByOrganisation',
      width: 130,
      minWidth: 0,
      maxWidth: 130,
      Cell: ({value}) => <span>{`${value}`}</span>,
      disableFilters: true
    },
    {
      Header: 'Raised By - Name',
      accessor: 'raisedByName',
      width: 125,
      minWidth: 0,
      maxWidth: 125,
      Cell: ({value}) => <span>{`${value}`}</span>,
      disableFilters: true
    },
    {
      id: 'isExpanded',
      Header: <>&nbsp;<br/>&nbsp;</>,
      width: 50,
      minWidth: 0,
      maxWidth: 50,
      disableSortBy: true,
      Cell: ({row}) => <div className="center"><ExpansionToggleButton caseId={row.original.caseId} queryId={row.original.queryId} /></div>,
      disableFilters: true
    }
  ]