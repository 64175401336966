import axios from 'axios';

export const FILE_SELECTED = 'ADMIN_FILE_UPLOADS_FILE_SELECTED';
export const SUBMIT_START = 'ADMIN_FILE_UPLOADS_SUBMIT_START';
export const SUBMIT_SUCCESS = 'ADMIN_FILE_UPLOADS_SUBMIT_SUCCESS';
export const SUBMIT_FAIL = 'ADMIN_FILE_UPLOADS_SUBMIT_FAIL';

export const urls = {
    upload: `/api/admin/file-upload`,
}

const action = (type, data = null) => ({
    type, data
});

export function fileSelected(file) {
    return (dispatch) => {
        dispatch(action(FILE_SELECTED, file));
    }
}


export function uploadFile(file) {
    return async (dispatch) => {

        dispatch(action(SUBMIT_START));

        try {
            const formData = new FormData();
            formData.append("file", file, file.name);
    
            const response = await axios.post(urls.upload, formData);

            dispatch(action(SUBMIT_SUCCESS, response.data || {}));
        }
        catch (err) {
            dispatch(action(SUBMIT_FAIL));
        }
    }
}
