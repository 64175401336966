import Store from './base';
import {combineReducers} from 'redux';
import debounce from 'lodash.debounce';

import filtersReducer from '../reducers/filters-reducer';
import homeReducer from '../reducers/home-reducer';
import searchReducer from '../reducers/search-reducer';
import searchByPersonalDetailsReducer from '../reducers/search-form/by-personal-details';
import queriesResultsReducer from '../reducers/queries-reducer';

import superuserUsersReducer from '../reducers/superuser/users-reducer';

import adminUsersReducer from '../reducers/admin/users-reducer';
import adminFileUploadReducer from '../reducers/admin/file-upload-reducer';

import reportsReducer from '../reducers/reports/reports-reducer';
import caseReducer from '../reducers/case/case-reducer';

import { reducer as formReducer } from 'redux-form'

const reducers = combineReducers({
    filters: filtersReducer,
    home: homeReducer,
    search: searchReducer,
    searchByPersonalDetails: searchByPersonalDetailsReducer,
    queriesResults: queriesResultsReducer,

    superuserUsers: superuserUsersReducer,

    adminUsers: adminUsersReducer,
    adminFileUpload: adminFileUploadReducer,
    
    reports: reportsReducer,
    
    case: caseReducer,

    form: formReducer
});

const previousStoreNames = [
    'store', 
    'nrpf-redux-store-1109', 
    'nrpf-redux-store-1111', 
    'nrpf-redux-store-1119', 
    'nrpf-redux-store-1121', 
    'nrpf-redux-store-1179',
    'nrpf-redux-store-1203'
];
const currentStoreName = 'nrpf-redux-store-1235';

const saveToLocalStorage = (state) => {
    try {
        let { filters, search, searchByPersonalDetails, queriesResults, superuserUsers, adminUsers } = state;
        const serializedState = JSON.stringify({ filters, search, searchByPersonalDetails, queriesResults, superuserUsers, adminUsers});
        const base64state = window.btoa(serializedState);
        localStorage.setItem(currentStoreName, base64state);
    } catch (e) {
        console.log(e);
    }
}

const parseDate = function (key, value) {
    var a;
    if (typeof value === 'string') {
        a = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/i.exec(value);
        if (a) {
            return new Date(value);
        }
    }
    return value;
}

const loadFromLocalStorage = () => {
    try {
        previousStoreNames.forEach(name => localStorage.removeItem(name));

        const base64state = localStorage.getItem(currentStoreName);
        if (base64state === null) return undefined;
        const serializedState = window.atob(base64state);
        return JSON.parse(serializedState, parseDate);
    } catch (e) {
        console.log(e);
        return undefined;
    }
}

const persistedState = loadFromLocalStorage();
const instance = Store(reducers, './index.js', persistedState);

instance.subscribe(debounce(() => saveToLocalStorage(instance.getState()), 1000));

export default instance;
