import axios from 'axios';

import emptyGuid from '../../extensions/guid.empty';

export const urls = {
    load: (userId) => `/api/admin/users/${userId}`,
    addSubmit: `/api/admin/users/add`,
    updateSubmit: `/api/admin/users/edit`,
    delete: "/api/admin/users/delete",
    resetPassword: "/api/admin/users/reset-password",
    unlock: `/api/admin/users/unlock`,
    lock: `/api/admin/users/lock`,
}

const defaultResult = {
    isSuccess: false,
    errors: {},
    message: "Something went wrong, please contact support if you require assistance."
};

export const onLoadAsync = async (userId) => {
    try {

        if (!userId) {
            userId = emptyGuid;
        }

        const response = await axios.get(urls.load(userId));
        const values = response.data || {};

        return {
            isLoaded: true,
            isSuccessful: true,
            initialValues: mappers.toReactForm(values)
        };
    }
    catch (err) {
        return {
            isLoaded: true,
            isSuccessful: false
        }
    }
}

const onFormSubmitAsync = async (url, model) => {
    
    const response = await axios.post(url, model);
    return response.data
        ? mappers.toFormResult(response.data)
        : defaultResult;
}


export const onAddSubmitAsync = async (formValues) => {
    const serverModel = mappers.toServerModel(formValues);
    return await onFormSubmitAsync(urls.addSubmit, serverModel);
}

export const onUpdateSubmitAsync = async (formValues) => {
    const serverModel = mappers.toServerModel(formValues);
    return await onFormSubmitAsync(urls.updateSubmit, serverModel);
}

export const onDeleteSubmitAsync = async (formValues) => {
    return await onFormSubmitAsync(urls.delete, formValues);
}

export const onResetPasswordSubmitAsync = async (formValues) => {
    return await onFormSubmitAsync(urls.resetPassword, formValues);
}

export const onLockAccountAsync = async (userId) => {

    if (!userId) {
        return false;
    }

    try {
        await axios.post(urls.lock, { userId });

        return true;
    }
    catch (err) {
        return false;
    }
}

export const onUnlockAccountAsync = async (userId) => {

    if (!userId) {
        return false;
    }

    try {
        await axios.post(urls.unlock, { userId });

        return true;
    }
    catch (err) {
        return false;
    }
}

const mappers = {
    toReactForm: (values) => ({
        ...values,
        localAuthorityId: values.localAuthorityId?.toString(),
        homeOfficeId: values.homeOfficeId?.toString(),
        homeOfficeTeamId: values.homeOfficeTeamId?.toString(),
        accessLevel: values.accessLevel?.toString(),
        accessLevelDescription: values.accessLevels ? values.accessLevels[values.accessLevel] : '',

        isPrimaryContact: values.isPrimaryContact.toString(),
        accountIsLocked: values.accountIsLocked.toString(),
        isShowingAliasDetails: values.isShowingAliasDetails.toString(),
        userIsHomeOffice: values.userIsHomeOffice.toString()
    }),
    toServerModel: (values) => {

        const userIsHomeOffice = JSON.parse(values.userIsHomeOffice);
        const isShowingAliasDetails = JSON.parse(values.isShowingAliasDetails);
        const isPrimaryContact = JSON.parse(values.isPrimaryContact);
        const accountIsLocked = JSON.parse(values.accountIsLocked);

        return {
            ...values,
            localAuthorityId: userIsHomeOffice ? null : parseInt(values.localAuthorityId),
            homeOfficeId: userIsHomeOffice ? parseInt(values.homeOfficeId) : null,
            homeOfficeTeamId: userIsHomeOffice ? parseInt(values.homeOfficeTeamId) : null,
            accessLevel: parseInt(values.accessLevel),

            isPrimaryContact,
            accountIsLocked,
            isShowingAliasDetails,
            userIsHomeOffice
        }
    },
    toFormResult: (responseData) => ({
        isSuccess: responseData.isSuccess,
        errors: responseData.validationErrors,
        message: responseData.errorMessage
    })
}

export default {
    onLoadAsync,
    onAddSubmitAsync,
    onUpdateSubmitAsync,
    onDeleteSubmitAsync,
    onLockAccountAsync,
    onUnlockAccountAsync
}