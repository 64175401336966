import React from "react";

import ErrorPage from './error-page';

export default class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { error: null, errorInfo: null };
      this.onHashChange = this.onHashChange.bind(this);

      window.addEventListener('hashchange', this.onHashChange, false);
    }
    
    componentDidCatch(error, errorInfo) {
      // Catch errors in any components below and re-render with error message
      this.setState({
        error: error,
        errorInfo: errorInfo
      })
      // You can also log error messages to an error reporting service here
    }

    onHashChange() {
      this.setState({
        error: null,
        errorInfo: null
      })
    }
    
    render() {

      if (this.state.errorInfo) {
        return <ErrorPage />;
      }
      
      return this.props.children;
    }  
  }