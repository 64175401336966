import React from 'react';
import {withClassNameSwitch} from '../../common'

const BaseRow = ({children, className='', row}) => (
  <div {...row.getRowProps()} className={`tr ${className}`} style={{flexDirection: "column"}}>
    <div className="result-row">
      {
        row.cells.map((cell, index) => {
          return (
            <span {...cell.getCellProps()}>
              {cell.render('Cell')}
            </span>
          );
        })
      }
      <div className="clearfix"></div>
    </div>
    { children }
  </div>
);

const Row = withClassNameSwitch(props => props.rowIndex % 2 === 0 ? 'odd' : 'even')(BaseRow);

export default Row;