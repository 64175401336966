import React from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from '../routes';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import { nationalities } from './dictionaries';
import dateParseForReactWidget from '../../extensions/date.parseForReactWidget'
import splitName from './split-name';

import NoResults from './no-results';
import DigitalRecordResult from './digital-record-result';


const Results = ({ result, onReset, lookupValues }) => {

    const history = useHistory();

    if (!result) {
        return null;
    }

    const nationality = nationalities.find(n => n.value === result.nationality);

    const hasResults = result.statuses.length > 0;
    const digitalRecords = hasResults
        ? result.statuses.map((status, index) => <DigitalRecordResult status={status} key={`lookup_result_${index}`} />)
        : <NoResults />

    const onCreateCase = () => {
        const { firstName, surname } = splitName(result.fullName);

        const {
            dateOfBirth,
            nationality,
            documentType,
            documentReference,
            nino
        } = lookupValues;

        history.push({
            pathname: routes.cases.create,
            state: {
                rtpfLogId: result.logId,
                forename: firstName,
                surname: surname,
                nationalityCode: result.nationality || nationality,
                dateOfBirth: dateParseForReactWidget(result.dateOfBirth || dateOfBirth),
                rtpfLookupStatus: hasResults ? result.statuses[0] : null,
                brpNumber: documentType === "BRP" ? documentReference : null,
                brcNumber: documentType === "BRC" ? documentReference : null,
                passportNumber: documentType === "PASSPORT" ? documentReference : null,
                euIdentityCardNumber: documentType === "NAT" ? documentReference : null,
                ninoNumber: nino
            }
        });
    }

    const createButton = result.isLocalAuthorityUser 
    ? (
        <button type="button" className="rbtn primary" onClick={onCreateCase}>
            <FontAwesomeIcon icon={faAddressCard} />
            <span>Create Case</span>
        </button>
    ) : null;

    return (
        <div className="rtpf-result-page">
            <div className="top-menu">
                <button type="button" className="rbtn secondary" onClick={onReset}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                    <span>Go Back</span>
                </button>
                { createButton }
            </div>
            <div className="home-office-record">
                <div className="top-section">
                    <div>
                        <h2>Person's details:</h2>
                        <ul className="details-list">
                            <li>
                                <label>Full Name</label>
                                <span>{result.fullName}</span>
                            </li>
                            <li>
                                <label>Nationality</label>
                                <span>{nationality?.label || 'N/A'}</span>
                            </li>
                            <li>
                                <label>Date Of Birth</label>
                                <span>{result.dateOfBirth}</span>
                            </li>
                        </ul>
                    </div>
                    <img alt="Home Office" src="/images/footer_image_4.jpg"></img>
                </div>

                <h2>Digital immigration records within the last 2 years:</h2>

                {digitalRecords}
            </div>
        </div>
    )
}

export default Results;
