import * as actions from '../actions/search-actions';

export const searchTypes = {
  byList: 'by-list',
  byPersonalDetails: 'by-personal-details',
  byReference: 'by-ref',
  byAlert: 'by-alert',
  byCaseload: 'by-caseload'
}

const searchStarted = (state, action) => {

  const { searchType, searchParams } = action.data;

  const resetToPageOne = (state.searchType !== searchType)
    || (JSON.stringify(state.searchParams) !== JSON.stringify(searchParams));

  return {
    ...state,
    searchType: searchType,
    searchParams: searchParams,
    results: {},
    pageIndex: resetToPageOne ? 0 : state.pageIndex,
    loading: true,
    searchTainted: true,
    isAutomatedSearch: searchType === searchTypes.byAlert || searchType === searchTypes.byCaseload,
    isOneThousandDaySearch: searchType === searchTypes.byCaseload && searchParams.CaseLoadCurrentActivityId === 5
  };
}

const updateCaseWorker = (state, action) => {
  if (typeof action.data === 'undefined' || action.data === null) return state;

  const caseId = action.data.caseId;
  const originalRow = state.results[caseId];

  const newRow = {
    ...originalRow,
    caseWorkersName: action.data.caseWorkersName,
    isThisMyCase: action.data.caseWorkerIsMe
  };

  const newResults = { ...state.results };
  newResults[caseId] = newRow;

  return {
    ...state,
    loading: false,
    results: newResults
  };
}


const initialState = {
  byListFormValues: {
    caseStatusIds: [],
    referrals: false,
    financiallySupported: false,
    closedReferrals: false,
    closedSupported: false,
    selectAll: false,
    buttonIsDisabled: true
  },
  byListFormOptions: {
    caseStatuses: [{
      "label": null,
      "options": [
        { value: "1", label: "Referrals", groupName: "" },
        { value: "2", label: "Financially supported", groupName: "" },
        { value: "3", label: "Closed - referrals", groupName: "" },
        { value: "4", label: "Closed - supported", groupName: "" },
      ]
    }]
  },
  byReferenceFormValues: {
    connectId: "",
    hoRef: "",
    hoPersonId: "",
    laRef: ""
  },
  searchType: "",
  searchParams: "",
  results: {},
  pageIndex: 0,
  pageSize: 0,
  sortBy: [{
    id: "daysCount",
    desc: true,
  }],
  filters: [],
  showDependants: false,
  loading: false,
  searchTainted: false,
  casesBeingViewed: {},
  userId: 0,
  isAutomatedSearch: false,
  isOneThousandDaySearch: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.SEARCH_START:
      return searchStarted(state, action);
    case actions.SEARCH_SUCCESS:
      return {
        ...state,
        results: action.data.summaries || {},
        loading: false
      };
    case actions.SEARCH_FAILED:
      return { ...state, loading: false };
    case actions.SEARCH_RESET:
      return initialState;
    case actions.CLAIM_CASE_START:
      return {
        ...state,
        loading: true
      }
    case actions.CLAIM_CASE_END:
      return updateCaseWorker(state, action);
    case actions.SET_FORM_VALUES:
      return {
        ...state,
        byListFormValues: {
          ...state.byListFormValues,
          ...action.data.byListFormValues
        },
        byReferenceFormValues: {
          ...state.byReferenceFormValues,
          ...action.data.byReferenceFormValues
        }
      }
    case actions.SET_PAGE:
      return {
        ...state,
        pageIndex: action.data.pageIndex,
        pageSize: action.data.pageSize
      };
    case actions.SET_SORT:
      return {
        ...state,
        sortBy: action.data
      };
    case actions.SET_FILTERS:
      return {
        ...state,
        filters: action.data
      };
    case actions.TOGGLE_DEPENDANTS:
      return {
        ...state,
        showDependants: !state.showDependants
      }
    case actions.UPDATE_ISBEINGVIEWED:
      return {
        ...state,
        casesBeingViewed: action.data
      }
    case actions.UPDATE_USERID:
      return {
        ...state,
        userId: action.data
      }
    default:
      return state;
  }
}

const isCaseBeingViewed = (state, caseId) => {
  const hasCaseId = state.search.casesBeingViewed.hasOwnProperty(caseId);

  if (!hasCaseId) return false;

  const myCase = state.search.casesBeingViewed[caseId];
  const myUserId = state.search.userId;

  const isBeingViewedByOthers = myCase.filter(x => x !== myUserId).length > 0;
  return isBeingViewedByOthers;
}


export const selector = {
  byListFormValues: (state) => state.search.byListFormValues,
  byReferenceFormValues: (state) => state.search.byReferenceFormValues,

  byListFormOptions: (state) => state.search.byListFormOptions,

  results: (state) => getValues(state.search.results),
  resultsDictionary: (state) => state.search.results,
  isCaseBeingViewed: (state, caseId) => isCaseBeingViewed(state, caseId),
  pageSize: (state) => state.search.pageSize,
  pageIndex: (state) => state.search.pageIndex,
  sortBy: (state) => state.search.sortBy,
  filters: (state) => state.search.filters,
  showDependants: (state) => state.search.showDependants,
  loading: (state) => state.search.loading,
  searchTainted: (state) => state.search.searchTainted,
  isAutomatedSearch: (state) => state.search.isAutomatedSearch,
  isOneThousandDaySearch: (state) => state.search.isOneThousandDaySearch
};

const getValues = (obj) => {
  return Object.keys(obj).map(function (e) {
    return obj[e]
  })
}