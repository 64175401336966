import React from 'react';
import { Switch, Route } from "react-router-dom";

import PerformanceMonitoring from './performance-monitoring';

export default () => {

    return (
        <Switch>
            <Route exact path="/Reports/PerformanceMonitoring">
                <PerformanceMonitoring />
            </Route>
            <Route>
                <h4>404</h4>
            </Route>
        </Switch>
    );
}