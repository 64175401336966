import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faExclamation, faSpinner, faCheck } from '@fortawesome/free-solid-svg-icons';

const Status = ({ loading, successful, invalid }) => {

    const resultIcon = successful
        ? faCheck
        : invalid
            ? faExclamation
            : loading
                ? faSpinner
                : faTimes

    const resultDescription = successful
        ? ""
        : invalid
            ? "Missing details"
            : loading
                ? "Searching..."
                : "No result found";

    const color = successful
    ? "#00703c"
    : invalid
        ? "#eebb17"
        : loading
            ? "#000"
            : "#a82612";

    return (
        <div style={{ color }}>
            <FontAwesomeIcon icon={resultIcon} className="result-icon" />
            {resultDescription}
        </div>
    )
}

export default Status;