import axios from 'axios';
import { initialize, SubmissionError, clearSubmitErrors } from 'redux-form';

import { selector } from '../../reducers/superuser/users-reducer';

export const SEARCH_START = 'SUPERUSER_USERS_LIST_SEARCH_START';
export const SEARCH_SUCCESS = 'SUPERUSER_USERS_LIST_SEARCH_SUCCESS';
export const SEARCH_FAILED = 'SUPERUSER_USERS_LIST_SEARCH_FAILED';

export const SET_PAGE = 'SUPERUSER_USERS_LIST_SET_PAGE';
export const SET_SORT = 'SUPERUSER_USERS_LIST_SET_SORT';
export const SET_FILTERS = 'SUPERUSER_USERS_LIST_SET_FILTERS';
export const SET_USER_STATUS = 'SUPERUSER_USERS_LIST_SET_USER_STATUS';

export const MODALS_USERDELETE_INITALISE = 'SUPERUSER_MODALS_USERDELETE_INITALISE';
export const MODALS_USERDELETE_START = 'SUPERUSER_MODALS_USERDELETE_START';
export const MODALS_USERDELETE_SUCCESS = 'SUPERUSER_MODALS_USERDELETE_SUCCESS';
export const MODALS_USERDELETE_FAILED = 'SUPERUSER_MODALS_USERDELETE_FAILED';

export const MODALS_USERRESETPASSWORD_INITALISE = 'SUPERUSER_MODALS_USERRESETPASSWORD_INITALISE';
export const MODALS_USERRESETPASSWORD_START = 'SUPERUSER_MODALS_USERRESETPASSWORD_START';
export const MODALS_USERRESETPASSWORD_SUCCESS = 'SUPERUSER_MODALS_USERRESETPASSWORD_SUCCESS';
export const MODALS_USERRESETPASSWORD_FAILED = 'SUPERUSER_MODALS_USERRESETPASSWORD_FAILED';

export const urls = {
    users: `/api/superuser/users`,
    unlock: `/api/superuser/users/unlock`,
    lock: `/api/superuser/users/lock`,
    delete: "/api/superuser/users/delete",
    resetPassword: "/api/superuser/users/reset-password"
}

const action = (type, data = null) => ({
    type, data
});

export function loadUsers() {
    return (dispatch) => {
        dispatch(action(SEARCH_START));

        return axios
            .post(urls.users)
            .then(response => {
                dispatch(action(SEARCH_SUCCESS, response.data || []));
            }).catch(err  => {
                dispatch(action(SEARCH_FAILED));
            });
    }
}

export function resetFiltersAndLoadUsers() {
    return (dispatch, getState) => {

        const pageSize = selector.pageSize(getState());

        dispatch(setPage(0, pageSize));
        dispatch(setFilters([]));
        dispatch(loadUsers());
    }
}

export function setPage(pageIndex, pageSize) {
    return (dispatch) => {
        dispatch(action(SET_PAGE, { pageIndex, pageSize }));
    }
}

export function setSort(sortBy) {
    return (dispatch) => {
        dispatch(action(SET_SORT, sortBy));
    }
}

export function setFilters(filters) {
    return (dispatch) => {
        dispatch(action(SET_FILTERS, filters));
    }
}

export function unlockAccount(userId) {
    return async (dispatch) => {
        try {
            await axios.post(urls.unlock, { userId });
            dispatch(action(SET_USER_STATUS, {userId, isLocked: false}));
        }
        catch (err) {
        }
    }
}

export function lockAccount(userId) {
    return async (dispatch) => {
        try {
            await axios.post(urls.lock, { userId });
            dispatch(action(SET_USER_STATUS, {userId, isLocked: true}));
        }
        catch (err) {
        }
    }
}

export function deleteUser(formValues) {
    return (dispatch) => {
        
        dispatch(action(MODALS_USERDELETE_START));
        dispatch(clearSubmitErrors('user-delete'));
  
        return axios.post(urls.delete, formValues).then(response => {
              if (Object.keys(response.data).length)
                  throw new SubmissionError(response.data);
  
              dispatch(action(MODALS_USERDELETE_SUCCESS));
              dispatch(loadUsers());
        }).catch(error => {
            dispatch(action(MODALS_USERDELETE_FAILED));
  
            if (error instanceof SubmissionError)
                throw error;
        });
    }
  }
  
  export function deleteUserInitialise(userId) {
      return (dispatch) => {
          let initialValues = {
              userId
          };
  
          dispatch(initialize('user-delete', initialValues, true));
          dispatch(action(MODALS_USERDELETE_INITALISE));
      }
  }
  
  
  export function resetUserPassword(formValues) {
      return (dispatch) => {
          
          dispatch(action(MODALS_USERRESETPASSWORD_START));
          dispatch(clearSubmitErrors('user-reset-password'));
    
          return axios.post(urls.resetPassword, formValues).then(response => {
              if (Object.keys(response.data).length)
                  throw new SubmissionError(response.data);
    
              dispatch(action(MODALS_USERRESETPASSWORD_SUCCESS));
          }).catch(error => {
              dispatch(action(MODALS_USERRESETPASSWORD_FAILED));
    
              if (error instanceof SubmissionError)
                  throw error;
          });
      }
    }
    
    export function resetUserPasswordInitialise(userId) {
        return (dispatch) => {
            let initialValues = {
                userId
            };
    
            dispatch(initialize('user-reset-password', initialValues, true));
            dispatch(action(MODALS_USERRESETPASSWORD_INITALISE));
        }
    }