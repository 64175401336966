import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '../../../state/actions/search-actions';

export default function MyCaseCheckbox (props) {
    const { row, value } = props;
    const caseId = row.original.caseId;
    const dispatch = useDispatch();

    const onCheckboxChange = useCallback(
        (e) => {
            if (caseId) {
                if (e.target.checked) {
                    dispatch(actions.claimCase(caseId));
                } else {
                    dispatch(actions.unclaimCase(caseId));
                }
            }
        },
        [dispatch, caseId]
    ) 

    return (<input 
        type="checkbox"
        checked={value}
        disabled={row.original.isItAllocatable}
        onChange={onCheckboxChange}
        
    />);
}