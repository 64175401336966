import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { caseArea, caseId } from '../server-values';
import { onLoadAsync, onUpdateSubmitAsync } from '../../../api-endpoint-calls/case/referrals';

import Form from './form';
import EditReferralOrganisationModal from './referral-organisation/edit';
import DeleteReferralOrganisationModal from './referral-organisation/delete';

import useListState from '../use-list-state';
import useNoteState from '../notes/use-note-state';

import { LoadingV1 } from '../../common/loading';

const Referral = () => {

    const [loaded, setLoaded] = useState(false);
    const [successful, setSuccessful] = useState(false);
    const [initialValues, setInitialValues] = useState({});

    const {
        items: referralOrganisations,
        setItems: setReferralOrganisations,
        onEditModalRequest: onEditReferralOrganisationModalRequest,
        editModal: editReferralOrganisationModal,
        deleteModal: deleteReferralOrganisationModal
    } = useListState(
        0,
        (id, onClose, onDeleteRequest) => <EditReferralOrganisationModal onClose={onClose} referralOrganisationId={id} disabled={false} onDeleteModalRequest={onDeleteRequest} />,
        (id, onClose) => <DeleteReferralOrganisationModal onClose={onClose} referralOrganisationId={id} disabled={false} />
    )

    const {
        item: referralNote,
        setItem: setReferralNote,
        onAddModalRequest: onAddReferralNoteModalRequest,
        onHistoryModalRequest: onReferralNoteHistoryModalRequest,
        addModal: addReferralNoteModal,
        historyModal: ReferralNoteHistoryModal
    } = useNoteState()
    

    useEffect(() => {
        const source = axios.CancelToken.source();

        const onPageLoad = async () => {
            const result = await onLoadAsync(caseId, source);
            
            setLoaded(result.isLoaded);
            setSuccessful(result.isSuccessful);

            if (result.initialValues) {
                setInitialValues(result.initialValues);
                setReferralOrganisations(result.initialValues.options.referralOrganisations);
                setReferralNote(result.initialValues.note);
            }
        }

        onPageLoad();

        return () => {
            source.cancel();
        }

    }, [setInitialValues, setLoaded, setSuccessful, setReferralOrganisations, setReferralNote]);


    const showSpinner = (!loaded);
    const showFailure = (loaded && !successful);

    if (showSpinner) {
        return <LoadingV1 />;
    } else if (showFailure) {
        return <h2>An error has occured!</h2>;
    }


    const onFormSubmit = async (formValues) => {

        const result = await onUpdateSubmitAsync(formValues);

        if (result.isSuccess) {
            window.dispatcher.dispatchEvent('change-tracker-reset');

            if (formValues.goToImmigration) {
                window.location = `/${caseArea}/Immigration/Create?iCaseID=${caseId}`;
            } else {
                window.location = `/${caseArea}/Summary/Display?iCaseID=${caseId}`;
            }
        }

        return result.errors;
    }

    return (
        <div className="case-referral-edit-page">
            <Form
                onSubmit={onFormSubmit}
                initialValues={initialValues}
                referralOrganisations={referralOrganisations}
                referralNote={referralNote}
                onEditReferralOrganisationModalRequest={onEditReferralOrganisationModalRequest}
                onAddReferralNoteModalRequest={onAddReferralNoteModalRequest}
                onReferralNoteHistoryModalRequest={onReferralNoteHistoryModalRequest}
            />
            { editReferralOrganisationModal }
            { deleteReferralOrganisationModal }
            { addReferralNoteModal }
            { ReferralNoteHistoryModal }
        </div>
    );
}

export default Referral;
