import React from "react";
import { useHistory } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { routes } from '../routes';

import { HomePageCountLoadingIcon } from '../common/loading';
import { renderDropdownList, renderInput, renderDatePicker } from '../form-inputs/final-form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIdCard, faSearch, faUserTag, faUndo, faAddressCard } from '@fortawesome/free-solid-svg-icons';

import { documentTypes, nationalities } from './dictionaries';
import onValidate from "./on-validate";

const RtpfLookupForm = ({ onSubmit, onReset, initialValues, result, lookupPerformed }) => {

    const history = useHistory();

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            validate={onValidate}
            render={({ handleSubmit, submitting, form, values }) => {

                const onLookupByDocumentClick = (e) => {
                    e.preventDefault();

                    form.change('lookupType', 'document');
                }

                const onLookupByNinoClick = (e) => {
                    e.preventDefault();

                    form.change('lookupType', 'nino');
                }

                const onApplicantCreate = () => {
                    history.push({
                        pathname: routes.cases.create,
                        state: {
                            forename: values.firstName,
                            surname: values.surname,
                            nationalityCode: values.nationality,
                            dateOfBirth: values.dateOfBirth,
                            ninoNumber: values.nino,
                            passportNumber: values.documentType === "PASSPORT" ? values.documentReference : null,
                            brpNumber: values.documentType === "BRP" ? values.documentReference : null,
                            brcNumber: values.documentType === "BRC" ? values.documentReference : null,
                            euIdentityCardNumber: values.documentType === "NAT" ? values.documentReference : null
                        }
                    });
                }

                const onResetClick = (e) => {
                    e.preventDefault();
                    form.reset();
                    onReset();
                }


                const submitButton = (
                    <>
                        <hr />
                        <div className="form-buttons">
                            { lookupPerformed &&
                            <div>
                                <button type="button" className="rbtn primary" disabled={submitting} onClick={onApplicantCreate}>
                                    <FontAwesomeIcon icon={faAddressCard} />
                                    <span>Create Applicant</span>
                                </button>
                                <div style={{textAlign: "center", marginTop: "4px"}}>(Manual Status Check)</div>
                            </div>
                            }
                            <span style={{width: "430px"}}></span>
                            <button type="button" className="rbtn secondary" disabled={submitting} onClick={onResetClick}>
                                <FontAwesomeIcon icon={faUndo} />
                                <span>Reset</span>
                            </button>
                            <button type="submit" className="rbtn primary" disabled={submitting}>
                                { submitting  
                                    ? <HomePageCountLoadingIcon /> 
                                    : (
                                        <>
                                            <FontAwesomeIcon icon={faSearch} />
                                            <span>Lookup</span>
                                        </>
                                    )
                                }
                            </button>
                        </div>
                    </>
                )

                const dobFields = (
                    <div className="input-wrapper">
                        <label>Date of Birth</label>
                        <Field name="dateOfBirth" render={renderDatePicker} disabled={submitting} />
                    </div>
                )

                const lookupByDocumentFields = (values.lookupType === "document")
                ? (
                    <>
                        <hr/>
                        <div className="two-by-two-grid">
                            <div className="input-wrapper">
                                <label>Select Document Type:</label>
                                <Field name="documentType" render={renderDropdownList} options={documentTypes} disabled={submitting} />
                            </div>
                            <div className="input-wrapper">
                                <label>Select nationality</label>
                                <Field name="nationality" render={renderDropdownList} options={nationalities} disabled={submitting} />
                            </div>
                            <div className="input-wrapper">
                                <label>Enter document reference</label>
                                <Field name="documentReference" type="text" render={renderInput} disabled={submitting} />
                            </div>
                            { dobFields }
                        </div>
                        { submitButton }
                    </>
                )
                : null;

                const lookupByNinoFields = (values.lookupType === "nino")
                ? (
                    <>
                        <hr/>
                        <div className="two-by-two-grid">
                            <div className="input-wrapper">
                                <label>First Name</label>
                                <Field name="firstName" type="text" render={renderInput} disabled={submitting} />
                            </div>
                            <div className="input-wrapper">
                                <label>National Insurance Number:</label>
                                <Field name="nino" type="text" render={renderInput} disabled={submitting} />
                            </div>
                            <div className="input-wrapper">
                                <label>Second Name</label>
                                <Field name="surname" type="text" render={renderInput} disabled={submitting} />
                            </div>
                            { dobFields }
                        </div>
                        { submitButton }
                    </>
                )
                : null;

                const hideForm = (result != null);

                return (
                    <form onSubmit={handleSubmit} className={hideForm ? "hidden" : ""}>
                        <div className="rtpf-form-container">

                            <h2>Home Office Recourse to Public Funds Checker</h2>
                            
                            <div className="input-wrapper">
                                <label>For retrieval of a person's digital immigration status and confirmation of whether recourse to public funds has been granted.</label>
                            
                                <div className="lookup-choice">
                                    <button type="button" className={`rbtn primary ${values.lookupType === "document" ? "selected" : ""}`} disabled={submitting} onClick={onLookupByDocumentClick}>
                                        <FontAwesomeIcon icon={faIdCard} />
                                        <span>Lookup by Identity Document</span>
                                    </button>
                                    <button type="button" className={`rbtn primary ${values.lookupType === "nino" ? "selected" : ""}`} disabled={submitting} onClick={onLookupByNinoClick}>
                                        <FontAwesomeIcon icon={faUserTag} />
                                        <span>Lookup by National Insurance Number</span>
                                    </button>
                                </div>
                            </div>

                            { lookupByDocumentFields }

                            { lookupByNinoFields }

                        </div>
                    </form>
                )
            }}
        />
    )
}


export default RtpfLookupForm;
