const validateForm = (values) => {
    var errors = {};

    if (!values.forename) {
        errors.forename = "Forename is required.";
    }

    if (!values.surname) {
        errors.surname = "Surname is required.";
    }

    if (!values.gender) {
        errors.gender = "Gender is required.";
    }

    if (!values.nationalityCode) {
        errors.nationalityCode = "Nationality is required.";
    }

    if (!values.dateOfBirth) {
        errors.dateOfBirth = "Date of Birth is required.";
    }

    return errors;
}

export default validateForm;