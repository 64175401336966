import React, { useState, useEffect, useRef } from 'react'
import { Form } from 'react-final-form'

import ReopenFormFields from "../reopen/form-fields";
import ReferralFormFields from "../../referral/form-fields";

import { toIntOptions, toSortedOptions, toGroupedIntOptions } from '../../../form-inputs/final-form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlagCheckered } from '@fortawesome/free-solid-svg-icons';


const defaultOptions = {
    reopenReasons: [],
    reasonsForApplying: [],
    legislations: [],
    services: []
}

const ReopenWithReferralForm = ({ onSubmit, initialValues,
    referralNote, onAddReferralNoteModalRequest, onReferralNoteHistoryModalRequest,
    referralOrganisations, onEditReferralOrganisationModalRequest }) => {

    const formSubmitButton = useRef();

    useEffect(() => {
        var { unsubscribe } = window.dispatcher.subscribe("change-tracker-modal-submit-form", () => formSubmitButton.current.click());

        return () => {
            unsubscribe();
        }
    }, []);

    const [showReopenForm, setShowReopenForm] = useState(true);
    const [showReferralForm, setShowReferralForm] = useState(false);

    const onFormSubmit = (values) => {
        if (showReferralForm) {
            return onSubmit(values);
        } else {
            var errors = {};
            if (!values.reasonId) {
                errors.reasonId = "Reason is required.";
            }
            if (!values.reopenNote) {
                errors.reopenNote = "Further details are required.";
            }

            if (Object.entries(errors).length > 0) {
                return errors;
            } else {
                setShowReopenForm(false);
                setShowReferralForm(true);
            }
        }
    };

    const onPreviousClick = () => {
        setShowReopenForm(true);
        setShowReferralForm(false);
    };


    return (
        <Form
            onSubmit={onFormSubmit}
            initialValues={initialValues}
            render={({ handleSubmit, submitting, values, form }) => {

                const { options, referralDate, startDateOfService, previousEndDate, selectedServiceIds, referralOrganisationId, reopenReasons } = values || {};
                const { reasonsForApplying, legislations, services } = options || defaultOptions;

                const reopenReasonsOptions = toIntOptions(reopenReasons || defaultOptions.reopenReasons);
                const reasonsForApplyingOptions = toIntOptions(reasonsForApplying);
                const legislationOptions = toGroupedIntOptions(legislations);
                const referralOrganisationOptions = toSortedOptions(referralOrganisations);

                const disabled = (submitting);

                const previousButton = showReferralForm
                    ? (
                        <button type="button" className="rbtn secondary" onClick={onPreviousClick} disabled={disabled}>
                            <span>« Previous</span>
                        </button>
                    )
                    : null;

                const reopenForm = showReopenForm
                    ? (
                        <ReopenFormFields
                            referralDate={referralDate}
                            startDateOfService={startDateOfService}
                            previousEndDate={previousEndDate}
                            reopenReasonsOptions={reopenReasonsOptions}
                            disabled={disabled}
                        />
                    )
                    : null;

                const referralForm = showReferralForm
                    ? (
                        <ReferralFormFields
                            form={form}
                            selectedServiceIds={selectedServiceIds}
                            services={services}
                            referralOrganisationId={referralOrganisationId}
                            reasonsForApplyingOptions={reasonsForApplyingOptions}
                            legislationOptions={legislationOptions}
                            referralOrganisationOptions={referralOrganisationOptions}
                            disabled={disabled}
                            referralNote={referralNote}
                            onEditReferralOrganisationModalRequest={onEditReferralOrganisationModalRequest}
                            onAddReferralNoteModalRequest={onAddReferralNoteModalRequest}
                            onReferralNoteHistoryModalRequest={onReferralNoteHistoryModalRequest}
                        />
                    )
                    : null;

                return (
                    <form onSubmit={handleSubmit} rel="change-tracked-modal">
                        <div className="mandatory"><span>*</span> Denotes a mandatory field </div>

                        <div className="modal-edit-form">

                            {reopenForm}
                            {referralForm}

                            <hr />

                            <div className="form-buttons edges">
                                {previousButton}
                                <span></span>
                                <button type="submit" className="rbtn primary" disabled={disabled} ref={formSubmitButton}>
                                    <FontAwesomeIcon icon={faFlagCheckered} />
                                    {showReferralForm ? "Submit" : "Next"}
                                </button>
                            </div>
                        </div>
                    </form>
                )

            }}
        />
    );
}

export default ReopenWithReferralForm;
