import React from "react";
import { useSelector } from "react-redux";
import { selector } from '../../state/reducers/filters-reducer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';

const Reports = () => {

    const { isHomeOfficeUser } = useSelector(state => selector.userDetails(state));

    return isHomeOfficeUser
        ? null
        : (
        <div className="notification-grid">
            <div className="divider-above">My data is accurate, what are the key stats?</div>
            <div className="divider-above"></div>
            
            <div className="notification-item">
                <div className="white-box">
                    <span className="notification-text">Performance Monitoring Report</span>
                </div>
                <a href="/Reports/PerformanceMonitoring" className="rbtn primary">
                    <FontAwesomeIcon icon={faChartLine} /><div>View</div>
                </a>
            </div>
        </div>
    )
};

export default Reports;
