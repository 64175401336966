import React, { useEffect, useRef } from "react";
import { Field, Form } from 'react-final-form';

import { renderDropdownList, renderDatePicker, toIntOptions } from '../../form-inputs/final-form';

import NoteWidget from "../notes/widget";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlagCheckered, faHistory, faUndo } from '@fortawesome/free-solid-svg-icons';

const defaultOptions = {
    closureReasons: [],
    referralRoutes: [],
    clientFollowedReferralRoute: []
}

const AddForm = ({ onSubmit, initialValues, closureNote, onAddClosureNoteModalRequest, onClosureNoteHistoryModalRequest, onReopenModalRequest, onHistoryModalRequest }) => {

    const formSubmitButton = useRef();

    useEffect(() => {
        var { unsubscribe } = window.dispatcher.subscribe("change-tracker-submit-form", () => formSubmitButton.current.click());

        return () => {
            unsubscribe();
        }
    }, []);


    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            render={({ handleSubmit, submitting, values }) => {

                const { caseIsClosed, options } = values;
                const { closureReasons, referralRoutes, clientFollowedReferralRoute } = options || defaultOptions;

                const closureReasonsOptions = toIntOptions(closureReasons);
                const referralRoutesOptions = toIntOptions(referralRoutes);
                const clientFollowedReferralRouteOptions = toIntOptions(clientFollowedReferralRoute);
                const disabled = (caseIsClosed || submitting);

                const submitButton = caseIsClosed
                    ? (
                        <button type="button" onClick={onReopenModalRequest} className="rbtn primary" ref={formSubmitButton}>
                            <FontAwesomeIcon icon={faUndo} />
                            <span>Reopen</span>
                        </button>
                    )
                    : (
                        <button type="submit" className="rbtn primary" disabled={disabled} ref={formSubmitButton}>
                            <FontAwesomeIcon icon={faFlagCheckered} />
                            <span>Save</span>
                        </button>
                    );

                return (
                    <form onSubmit={handleSubmit} rel="change-tracked">

                        <div className="mandatory"><span>*</span> Denotes a mandatory field </div>

                        <div className="closure-edit-form">
                            <div className="input-wrapper">
                                <label>Reason for closure <span className="mandatory-stars">*</span></label>
                                <Field name="reasonForClosureId" render={renderDropdownList} options={closureReasonsOptions} disabled={disabled} />
                            </div>

                            <div className="input-wrapper">
                                <label>Referral Made <span className="mandatory-stars">*</span></label>
                                <Field name="referralMadeId" render={renderDropdownList} options={referralRoutesOptions} disabled={disabled} />
                            </div>

                            <div className="input-wrapper">
                                <label>Client followed referral route?</label>
                                <Field name="clientFollowedRefferalRouteId" render={renderDropdownList} options={clientFollowedReferralRouteOptions} disabled={disabled} />
                            </div>

                            <div className="input-wrapper">
                                <label>Notes</label>
                                <NoteWidget
                                    note={closureNote}
                                    disabled={disabled}
                                    onAddModalRequest={onAddClosureNoteModalRequest}
                                    onHistoryModalRequest={onClosureNoteHistoryModalRequest} />
                            </div>

                            <div className="input-wrapper">
                                <Field name="closureDate" type="text" render={renderDatePicker} label="Closure Date" isMandatory={true} placeholder="" disabled={disabled} />
                            </div>

                        </div>

                        <hr />

                        <div className="referral-edit-form">
                            <div className="full-row align-center">
                                <div className="form-buttons">
                                    { submitButton }
                                    <button type="button" className="rbtn primary" onClick={onHistoryModalRequest}>
                                        <FontAwesomeIcon icon={faHistory} />
                                        <span>History</span>
                                    </button>
                                </div>
                            </div>
                        </div>

                    </form>
                )
            }}
        />
    )
}


export default AddForm;
