import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';

import { caseId } from '../server-values';
import { onLoadAsync, onAddSubmitAsync } from '../../../api-endpoint-calls/case/closures';

import Form from './form';
import ReopenModal from './reopen';
import ReopenWithReferralModal from './reopen-with-referral';
import HistoryModal from "./history";

import useNoteState from '../notes/use-note-state';
import { LoadingV1, successNotification } from '../../common';

const CaseClosureEdit = () => {

    const [loaded, setLoaded] = useState(false);
    const [successful, setSuccessful] = useState(false);
    const [initialValues, setInitialValues] = useState({});

    const [saveMessage, setSaveMessage] = useState(null);

    const [showReopenModal, setShowReopenModal] = useState(false);
    const onReopenModalRequest = () => {
        setShowReopenModal(true);
    }
    const onReopenModalClose = async (successMessage) => {
        setShowReopenModal(false);
        if (successMessage) {
            setSaveMessage(successMessage);

            await onPageReload();
        }
    }

    const [showHistoryModal, setShowHistoryModal] = useState(false);
    const onHistoryModalRequest = () => {
        setShowHistoryModal(true);
    }
    const onHistoryModalClose = () => {
        setShowHistoryModal(false);
    }

    const historyModal = showHistoryModal
        ? <HistoryModal onClose={onHistoryModalClose} caseId={caseId} />
        : null;
    
    const reopenModal = showReopenModal
        ? initialValues.isClosureExpired
            ? <ReopenWithReferralModal onClose={onReopenModalClose} caseId={caseId} />
            : <ReopenModal onClose={onReopenModalClose} caseId={caseId} />
        : null;


    const {
        item: closureNote,
        setItem: setClosureNote,
        onAddModalRequest: onAddClosureNoteModalRequest,
        onHistoryModalRequest: onClosureNoteHistoryModalRequest,
        addModal: addClosureNoteModal,
        historyModal: closureNoteHistoryModal
    } = useNoteState()


    const onPageLoadAsync = useCallback(async (source) => {
        const result = await onLoadAsync(caseId, source);
        
        setLoaded(result.isLoaded);
        setSuccessful(result.isSuccessful);

        if (result.initialValues) {
            setInitialValues({ ...result.initialValues });
            setClosureNote(result.initialValues.note);
        }
    }, [setInitialValues, setLoaded, setSuccessful, setClosureNote])


    const onPageReload = async () => {
        const source = axios.CancelToken.source();
        await onPageLoadAsync(source);
    }


    useEffect(() => {
        const source = axios.CancelToken.source();
        onPageLoadAsync(source);

        return () => {
            source.cancel();
        }

    }, [onPageLoadAsync]);


    const showSpinner = (!loaded);
    const showFailure = (loaded && !successful);

    if (showSpinner) {
        return <LoadingV1 />;
    } else if (showFailure) {
        return <h2>An error has occured!</h2>;
    }

    const successAlert = successNotification(saveMessage);

    const onFormSubmit = async (formValues) => {

        const result = await onAddSubmitAsync(formValues);

        if (result.isSuccess) {
            window.dispatcher.dispatchEvent('change-tracker-reset');
            setSaveMessage("All Data Saved Successfully!");
            onPageReload();
        }

        return result.errors;
    }

    return (
        <div className="case-closure-edit-page">
            <div className="stripedbox">
                {successAlert}
                <Form
                    onSubmit={onFormSubmit}
                    initialValues={initialValues}
                    closureNote={closureNote}
                    onAddClosureNoteModalRequest={onAddClosureNoteModalRequest}
                    onClosureNoteHistoryModalRequest={onClosureNoteHistoryModalRequest}
                    onReopenModalRequest={onReopenModalRequest}
                    onHistoryModalRequest={onHistoryModalRequest}
                />
            </div>
            { addClosureNoteModal }
            { closureNoteHistoryModal }
            { reopenModal }
            { historyModal }
        </div>
    );
}

export default CaseClosureEdit;
