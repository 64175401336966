import React, { useCallback, useState, useRef } from "react";

import DropdownList from "react-widgets/DropdownList";

import { onPostcodeLookup } from '../../../../api-endpoint-calls/case/address';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

 
const usePostcodeLookupState = (onAddressFound) => {

    const [postcode, setPostcode] = useState('');
    const [nameOrNumber, setNameOrNumber] = useState('');
    const [addresses, setAddresses] = useState([]);
    const [showNoAddresesFound, setShowNoAddresesFound] = useState(false);
    const [showMultipleAddressOptions, setShowMultipleAddressOptions] = useState(false);


    const onNoResults = () => {
        setShowNoAddresesFound(true);
        setShowMultipleAddressOptions(false);
        setAddresses([]);
    }


    const onSingleResult = (results) => {
        setShowNoAddresesFound(false);
        setShowMultipleAddressOptions(false);
        setAddresses(results);
    }


    const onMultipleResults = (results) => {
        setShowNoAddresesFound(false);
        setShowMultipleAddressOptions(true);
        setAddresses(results);
    }


    const setFormAddress = useCallback((arrayIndex, addressArray) => {

        let address = (addressArray || addresses)[arrayIndex];

        onAddressFound({
            isSuccessful: address
                ? true
                : false,

            address: address
                ? {
                    fullAddress: address.display,
                    houseNameNumber: address.nameOrNumber,
                    street: address.street,
                    area: address.area,
                    townCity: address.townOrCity,
                    county: address.county,
                    postcode: address.postcode
                }  
                : null
        })

    }, [onAddressFound, addresses])


    const onPerformLookup = useCallback(async () => {

        var results = await onPostcodeLookup(postcode, nameOrNumber);

        if (results.length === 0) {

            onNoResults();

        } else if (results.length === 1) {

            onSingleResult(results);
            setFormAddress("0", results);
            
        } else {

            onMultipleResults(results);

        }

    }, [postcode, nameOrNumber, setFormAddress]);


    const onSelectingAddress = useCallback((value) => {
        setFormAddress(value);
    }, [setFormAddress]);


    return {
        postcode, setPostcode,
        nameOrNumber, setNameOrNumber,
        addresses, showNoAddresesFound, showMultipleAddressOptions,
        onPerformLookup, onSelectingAddress
    }
};


const PostcodeLookup = ({onAddressFound, onSwitchToManualEntry, errorMessage}) => {
    
    const lookupButton = useRef();

    const {
        postcode, nameOrNumber, addresses, showNoAddresesFound, showMultipleAddressOptions,
        setPostcode, setNameOrNumber, onPerformLookup, onSelectingAddress
    } = usePostcodeLookupState(
        onAddressFound
    );

    const onSwitchingToManualEntry = (e) => {
        e.preventDefault();
        onSwitchToManualEntry();
    }

    const onKeyDownManipulateEnterKey = (ev) => {
        if (ev.key === 'Enter') {
            ev.preventDefault();
            ev.stopPropagation();
            lookupButton.current.click();
        }
    }

    const noAddressesFound = showNoAddresesFound
    ? (
        <div className="address-edit-form">
            <div className="input-wrapper">
                <span style={{width:"40%"}}></span>
                <span className="RedText">No addresses found</span>
                <button type="button" onClick={onSwitchingToManualEntry} className="rbtn link">[ Switch To Manual Entry ]</button>
            </div>
        </div>
    )
    : null;

    const multipleAddressOptions = showMultipleAddressOptions 
    ? (
        <div className="address-edit-form">
            <div className="input-wrapper">
                <label>Select Address</label>
                <DropdownList
                    dataKey="id"
                    textField="text"
                    defaultValue=" -- Please Select --"
                    tabIndex={9}
                    data={addresses.map((value, index) => ({ id: index, text: value.display}))}
                    onChange={(nextValue) => onSelectingAddress(nextValue.id)}
                    className="border-orange"
                />
            </div>
        </div>
    )
    : null;

    return (
        
        <>
            <div className="address-edit-form">
                <div className="input-wrapper">
                    <label>House number or name (optional)</label>
                    <input type="text" value={nameOrNumber} onKeyDown={onKeyDownManipulateEnterKey} onChange={(e) => setNameOrNumber(e.target.value)} tabIndex={4} />
                </div>
                <div className="input-wrapper">
                    <label>Postcode <span className="mandatory-stars">*</span></label>
                    <div className="input-with-error-message">
                        <div className="input-with-button">
                            <input type="text" value={postcode} onKeyDown={onKeyDownManipulateEnterKey} onChange={(e) => setPostcode(e.target.value)} tabIndex={6} className={errorMessage ? "input-validation-error" : null} />
                            <button type="button" onClick={() => onPerformLookup()} className="rbtn primary" tabIndex={7} ref={lookupButton}>
                                <FontAwesomeIcon icon={faSearch} />
                                <span>Search</span>
                            </button>
                        </div>
                        { errorMessage && <span className="field-validation-error">{errorMessage}</span> }
                    </div>
                </div>
                
            </div>

            { noAddressesFound }

            { multipleAddressOptions }
        </>
                            
    )
}


export default PostcodeLookup;