export const INITALISE = 'FILTERS::INITALISE';
export const SET_FILTERS_OPTIONS = 'FILTERS::SET_OPTIONS';
export const SET_FILTERS = 'FILTERS::SET';
export const RESET = 'FILTERS::RESET';

const action = (type, data = null) => ({
    type, data
});

export function applyFilter(data) {
    return (dispatch) => {
        dispatch(action(SET_FILTERS, {...data}));
    }
}

export function applyAlertTypeFilter(id) {
    return (dispatch) => {
        var values = {
            alertTypeId: id,
            caseLoadCurrentActivityId: 0
        }

        dispatch(applyFilter(values));
    }
}

export function applyCaseLoadFilter(id) {
    return (dispatch) => {
        var values = {
            alertTypeId: 0,
            caseLoadCurrentActivityId: id
        }

        dispatch(applyFilter(values));
    }
}

export function applyServerFilters(serverValues) {
    return (dispatch) => {

        const userDetails = {
            isHomeOfficeUser: serverValues.isHomeOfficeUser,
            organisation: serverValues.organisation,
            usersFullName: serverValues.usersFullName
        }

        dispatch(action(INITALISE, userDetails));

        const filterOptions = {
            localAuthorities: serverValues.localAuthorities,
            homeOfficeCaseWorkers: serverValues.homeOfficeCaseWorkers,
            legislations: serverValues.legislations,
            localAuthorityCaseWorkers: serverValues.localAuthorityCaseWorkers
        }

        dispatch(action(SET_FILTERS_OPTIONS, filterOptions));
    }
}
