import React from "react";

import { userIsHomeOffice } from '../../server-values';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';

export default ({summary}) => {
    
    const caseCosts = userIsHomeOffice
        ? (
            <div className="costs-grid">
                <div>
                    <span>Weekly Cost</span>
                </div>
                <div>
                    <span>Annual Cost</span>
                </div>
                <div className="fake-input with-money-value">
                    <span>£</span>
                    <span>{summary.weeklyCost}</span>
                </div>
                <div className="fake-input with-money-value">
                    <span>£</span>
                    <span>{summary.annualCost}</span>
                </div>
            </div>
        )
        : null;

    const endDateIsRed = (summary.endDateOfServices !== "N/A");

    return (
        <div>
            <div className="summary-title">
                <span className="title">
                    <FontAwesomeIcon icon={faFolderOpen} />
                    <span>Case Information</span>
                </span>
            </div>
        
            <div className="case-details">
                <div className="stripedbox case-dates">
                    <div>
                        <div>
                            Legislation
                        </div>
                        <div className="fake-input legislation-input">
                            {summary.legislationName}
                        </div>
                    </div>
                    <div className="date-grid">
                        <div>
                            <span className="question-mark-label">Referral date</span>
                            <span className="hover-tooltip question-mark" data-tooltip="Date of first contact with LA, as recorded on referral page."></span>
                        </div>
                        <div>
                            <span className="question-mark-label">Start date for service</span>
                            <span className="hover-tooltip question-mark" data-tooltip="Date accomodation or financial support started, as recorded on finance page."></span>
                        </div>
                        <div>
                            <span className="question-mark-label">Closure date</span>
                            <span className="hover-tooltip question-mark" data-tooltip="End date of involvement, as recorded on closure page"></span>
                        </div>
                        <div className="fake-input">
                            {summary.referralDate}
                        </div>
                        <div className="fake-input">
                            {summary.startDateOfServices}
                        </div>
                        <div className={`fake-input ${endDateIsRed ? "bold-red" : ""}`}>
                            {summary.endDateOfServices}
                        </div>
                    </div>
                    { caseCosts }
                </div>
            </div>
        </div>
    )
}