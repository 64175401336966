import { onLoad, onAllocate, onDeallocate, onCloseAlert } from '../../../api-endpoint-calls/case/summary';

export const UPDATE = "CASE_SUMMARY::UPDATE";
export const UPDATE_LACW = "CASE_SUMMARY::UPDATE_LACW";
export const UPDATE_HOCW = "CASE_SUMMARY::UPDATE_HOCW";
export const UPDATE_ALERTS = "CASE_SUMMARY::UPDATE_ALERTS";
export const UPDATE_PHOTO = "CASE_SUMMARY::UPDATE_PHOTO";
export const TOGGLE_RTPF_MODAL = "CASE_SUMMARY::TOGGLE_RTPF_MODAL";


export async function loadSummary(dispatch, caseId, source) {
            
    const result = await onLoad(caseId, source);

    if (result) {
        dispatch({
            type: UPDATE,
            data: result
        });
    }

    return result;
}

export async function allocateCaseWorker(dispatch, caseId, userIsLocalAuthority) {
    
    var result = await onAllocate(caseId);

    dispatch({
        type: userIsLocalAuthority ? UPDATE_LACW : UPDATE_HOCW,
        data: result
    })
}

export async function deallocateCaseWorker(dispatch, caseId, userIsLocalAuthority) {
    
    var result = await onDeallocate(caseId);

    dispatch({
        type: userIsLocalAuthority ? UPDATE_LACW : UPDATE_HOCW,
        data: result
    })
}

export async function closeAlert(dispatch, caseId, alertTypeId) {

    var result = await onCloseAlert(caseId, alertTypeId);

    dispatch({
        type: UPDATE_ALERTS,
        data: result
    })
}

export async function updatePhoto(dispatch, photoFileName) {
    
    const photoHash = Date.now();

    dispatch({
        type: UPDATE_PHOTO,
        data: `${photoFileName}?${photoHash}`
    })
}

export async function toggleRtpfModal(dispatch) {
    dispatch({
        type: TOGGLE_RTPF_MODAL,
    })
}