import React, { useState } from 'react';

import * as actions from '../../../../../state/actions/case/summary-actions';

import Modal from './modal';

export default ({isChangeable, caseIsClosed, photoFileName, principalApplicantName, caseId, applicantId, dispatch}) => {

    const [showModal, setShowModal] = useState(false);

    const onImageClick = () => {
        if (!caseIsClosed) {
            setShowModal(true);
        }
    }

    const onModalClose = (newPhotoFileName) => {
        setShowModal(false);

        if (newPhotoFileName) {
            actions.updatePhoto(dispatch, newPhotoFileName);
        }
    }

    const ChangeImageModal = showModal
        ? <Modal onClose={onModalClose} caseId={caseId} applicantId={applicantId} /> 
        : null;


    const imageOverlay = isChangeable
        ? (
            <>
                <div className="img-overlay" onClick={onImageClick}></div>
                <div className="img-overlay-text" onClick={onImageClick}>Update Photo</div>
            </>
        )
        : null

    return (
        <>
            <div className={`photo-image ${isChangeable ? "changeable" : ""} ${caseIsClosed ? "closed" : ""}`}>
                <img src={`/Images/Applicant/${photoFileName}`} alt={principalApplicantName} title={principalApplicantName} />
                { imageOverlay }
                
            </div>
            { ChangeImageModal }
        </>
    );
}
