import React, { useEffect } from "react";

import SearchForms from './forms';
import SearchResults from './results';

export default () => {

    useEffect(() => {
        document.getElementById("pageInfoHeader").innerText = `Search Page`;
    }, [])

    return (
        <div className="search-page">
            <SearchForms />
            <SearchResults />
        </div>
    )
};
