import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { onLoadAsync, onDuplicateCheckAsync, onAddSubmitAsync } from '../../../api-endpoint-calls/case/applicants-with-referral';

import CreateCaseForm from './form';
import EditReferralOrganisationModal from '../referral/referral-organisation/edit';
import DeleteReferralOrganisationModal from '../referral/referral-organisation/delete';

import useListState from '../use-list-state';

import { LoadingV1 } from '../../common/loading';

export default ({ location }) => {

    const [loaded, setLoaded] = useState(false);
    const [successful, setSuccessful] = useState(false);
    const [initialValues, setInitialValues] = useState({});
    const [submitButtonIsdisabled, setSubmitButtonIsdisabled] = useState(false);

    const {
        items: referralOrganisations,
        setItems: setReferralOrganisations,
        onEditModalRequest: onEditReferralOrganisationModalRequest,
        editModal: editReferralOrganisationModal,
        deleteModal: deleteReferralOrganisationModal
    } = useListState(
        0,
        (id, onClose, onDeleteRequest) => <EditReferralOrganisationModal onClose={onClose} referralOrganisationId={id} disabled={false} onDeleteModalRequest={onDeleteRequest} />,
        (id, onClose) => <DeleteReferralOrganisationModal onClose={onClose} referralOrganisationId={id} disabled={false} />
    )


    useEffect(() => {
        const source = axios.CancelToken.source();

        const onPageLoadAsync = async () => {
            const result = await onLoadAsync(source);

            setLoaded(result.isLoaded);
            setSuccessful(result.isSuccessful);

            if (result.initialValues) {

                if (location.state) {
                    const iv = {
                        ...result.initialValues,
                        ...location.state
                    }

                    setInitialValues(iv);
                } else {
                    setInitialValues(result.initialValues);
                }

                setReferralOrganisations(result.initialValues.options.referralOrganisations);
            }
        }

        onPageLoadAsync();
        document.getElementById("pageInfoHeader").innerText = `Case create sequence`;

        return () => {
            source.cancel();
        }

    }, [location, setInitialValues, setLoaded, setSuccessful, setReferralOrganisations]);


    const showSpinner = (!loaded);
    const showFailure = (loaded && !successful);

    if (showSpinner) {
        return <LoadingV1 />;
    } else if (showFailure) {
        return <h2>An error has occured!</h2>;
    }


    const onFormSubmit = async (formValues) => {

        const result = await onAddSubmitAsync(formValues);

        if (result.isSuccess) {
            setSubmitButtonIsdisabled(true);
            window.dispatcher.dispatchEvent('change-tracker-reset');
            window.location = `/CaseOwner/Summary/Display?iCaseId=${result.value.caseId}`;
        }

        return result.errors;
    }

    return (
        <div className="create-case-wrapper">
            <CreateCaseForm
                onSubmit={onFormSubmit}
                initialValues={initialValues}
                submitButtonIsdisabled={submitButtonIsdisabled}
                onDuplicateCheckAsync={onDuplicateCheckAsync}
                referralOrganisations={referralOrganisations}
                onEditReferralOrganisationModalRequest={onEditReferralOrganisationModalRequest}
            />
            { editReferralOrganisationModal }
            { deleteReferralOrganisationModal }
        </div>
    );
}