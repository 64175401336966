import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selector } from '../../../../../../state/reducers/superuser/users-reducer';
import * as actions from '../../../../../../state/actions/superuser/users-list-actions';

import Confirmation from './confirmation';
import Form from './form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faTimes } from '@fortawesome/free-solid-svg-icons';

export default ({onClose, userId, replacementUsers}) => {

    const [visible, setVisible] = useState(false);

    let {completed, successful} = useSelector(state => selector.userResetPassword(state));
    
    const dispatch = useDispatch();
    useEffect(() => {
        if (visible === false) {
            setTimeout(() =>  setVisible(true), 200);
            dispatch(actions.resetUserPasswordInitialise(userId));
        }
    }, [visible, setVisible, dispatch, userId]);

    const onSubmit = (formValues) => {
        return dispatch(actions.resetUserPassword(formValues));
    }
    
    let showConfirmation = completed && successful;

    let style = showConfirmation ? { width: '400px' } : { width: '500px' };
    let page = showConfirmation
        ? <Confirmation onClose={onClose} />
        : <Form onSubmit={onSubmit} onClose={onClose} replacementUsers={replacementUsers} />;

    return (
        <>
            <div className={ `react-modal-anchor ${(visible ? 'show' : '')}`}>
                <div className={ `react-modal reset-password ${visible ? 'show' : ''}` } style={ style }>
                    <button onClick={onClose} className="close-button"><FontAwesomeIcon icon={faTimes} /></button>
                    <h1>
                        <FontAwesomeIcon icon={faKey} />
                        Reset User Password
                    </h1>

                    { page }
                </div>
            </div>
            <div className={ `react-modal-backdrop ${visible ? 'show' : ''}` }></div>
        </>
    );
    
}