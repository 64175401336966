import React, { useState } from 'react';
import { Field } from "react-final-form";

import { renderInput } from '../../../../form-inputs/final-form';
import dateParseForReactWidget from '../../../../../extensions/date.parseForReactWidget';

import RtpfLookupModal from '../../../../rtpf-lookup/modal';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

export default ({ form, values, disabled, userIsLocalAuthority }) => {

    const [showRtpfModal, setShowRtpfModal] = useState(false);

    const { 
        caseId,
        hasSuccessfulRtpfLookup, 
        rtpfLookupDetails 
    } = values;

    if (hasSuccessfulRtpfLookup === false)
        return null;

    
    const onRtpfLookUpClick = () => setShowRtpfModal(true);

    const onRtpfLookUpClose = (rtpfResult) => {
        if (rtpfResult) {
            const {isExpired, status, description, immigrationStatusId, startDate, endDate} = rtpfResult;
            form.change('rtpfStatus', status);
            form.change('rtpfDescription', description);
            form.change('immigrationStatusId', immigrationStatusId);
            form.change('dateLeaveGranted', dateParseForReactWidget(startDate));
            form.change('expiryDateOfLeave', dateParseForReactWidget(endDate));

            if (isExpired) {
                form.change('hasSuccessfulRtpfLookup', false);
            }
        }

        setShowRtpfModal(false);
    }


    const {
        firstName,
        surname,
        biometricResidencyPermit,
        biometricResidencyCard,
        europeanNationalIdentityCardNumber,
        nationalInsuranceNumber,
        passportNumber,
        dateOfBirth,
        nationalityCode
    } = rtpfLookupDetails;

    const modalValues = {
        caseId,
        forename: firstName,
        surname: surname,
        brpNumber: biometricResidencyPermit,
        brcNumber: biometricResidencyCard,
        euIdentityCardNumber: europeanNationalIdentityCardNumber,
        ninoNumber: nationalInsuranceNumber,
        passportNumber: passportNumber,
        dateOfBirth: dateParseForReactWidget(dateOfBirth),
        nationalityCode: nationalityCode
    }

    const rtpfLookupModal = showRtpfModal 
        ? <RtpfLookupModal onClose={onRtpfLookUpClose} values={modalValues} /> 
        : null

    const rtpfButtonTooltip = userIsLocalAuthority
        ? "Use the ‘Recourse to Public Funds checker’ to look-up digital immigration status information. If no valid credentials or no digital immigration status found, please use existing case create / query functions."
        : "The ‘Recourse to Public Funds checker’ accesses a person’s UK Visa and Immigration account to confirm any digital immigration status held by the applicant."


    return (
        <>
            <label>Recourse to Public Funds Checker - record of digital status</label>

            <div className="input-wrapper-full-width">
                <Field name="rtpfStatus" type="text" render={renderInput} disabled={true} style={{width: "auto"}} />
            </div>

            <div className="input-wrapper-full-width">
                <Field name="rtpfDescription" type="text" render={renderInput} disabled={true} style={{width: "auto"}} />
            </div>

            <div className="rtpf-lookup-button">
                <button
                    type="button"
                    onClick={onRtpfLookUpClick}
                    className="rbtn green hover-tooltip"
                    disabled={disabled}
                    data-tooltip={rtpfButtonTooltip}
                >
                    <FontAwesomeIcon icon={faSearch} />
                    <span>RTPF Look-up</span>
                </button>
            </div>

            {rtpfLookupModal}
        </>
    )
}
