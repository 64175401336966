export const routes = {
    home: "/",
    search: [
        "/search",
        "/Search"
    ],
    queries: [
        "/queries",
        "/Queries"
    ],
    account: {
        login:"/Account/Login"
    },
    reports: {
        performanceMonitoring: "/Reports/PerformanceMonitoring"
    },
    admin: [
        "/admin",
        "/Admin"
    ],
    rtpfLookup: "/rtpf-lookup/:lookupType?",
    su: {
        users: {
            add: "/SuperUser/Users/Add",
            edit: "/SuperUser/Users/Edit",
            root: "/SuperUser/Users"
        },
        rtpfDescriptions: "/SuperUser/RtpfDescriptions"
    },
    cases: {
        create: "/case/create",
        summary: [
            "/CaseOwner/Summary/Display",
            "/LIT/Summary/Display",
            "/NonCaseOwner/Summary/Display"
        ],
        applicant: {
            edit: [
                "/CaseOwner/Applicant/Edit",
                "/LIT/Applicant/Edit",
                "/NonCaseOwner/Applicant/Edit"
            ]
        },
        dependants: [
            "/CaseOwner/Dependant",
            "/LIT/Dependant",
            "/NonCaseOwner/Dependant",
        ],
        address: {
            add: "/CaseOwner/Address/Create",
            edit: "/CaseOwner/Address/Update",
            list: [
                "/CaseOwner/Address/History",
                "/LIT/Address/History"
            ]
        },
        notes: [
            "/CaseOwner/CaseNotes",
            "/LIT/CaseNotes"
        ],
        referral: "/CaseOwner/Referral",
        immigration: [
            "/CaseOwner/Immigration",
            "/LIT/Immigration",
            "/NonCaseOwner/Immigration"
        ],
        closure: [
            "/CaseOwner/Closure",
            "/LIT/Closure"
        ],
    }
};