import React, { useEffect, useRef } from "react";
import { Form } from 'react-final-form';

import ReopenFormFields from "./form-fields";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlagCheckered } from '@fortawesome/free-solid-svg-icons';

import { toIntOptions } from '../../../form-inputs/final-form';

const defaultOptions = {
    reopenReasons: []
}

const ReopenForm = ({ onSubmit, initialValues }) => {

    const formSubmitButton = useRef();

    useEffect(() => {
        var { unsubscribe } = window.dispatcher.subscribe("change-tracker-modal-submit-form", () => formSubmitButton.current.click());

        return () => {
            unsubscribe();
        }
    }, []);

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            render={({ handleSubmit, submitting, values }) => {

                const { referralDate, startDateOfService, previousEndDate, reopenReasons } = values;
                const reopenReasonsOptions = toIntOptions(reopenReasons || defaultOptions.reopenReasons);

                const disabled = (submitting);

                return (
                    <form onSubmit={handleSubmit} rel="change-tracked-modal">

                        <div className="mandatory"><span>*</span> Denotes a mandatory field </div>

                        <div className="modal-edit-form">

                            <ReopenFormFields
                                referralDate={referralDate}
                                startDateOfService={startDateOfService}
                                previousEndDate={previousEndDate}
                                reopenReasonsOptions={reopenReasonsOptions}
                                disabled={disabled}
                            />

                            <hr />

                            <div className="form-buttons edges">
                                <button type="submit" className="rbtn primary" disabled={disabled} ref={formSubmitButton}>
                                    <FontAwesomeIcon icon={faFlagCheckered} />
                                    <span>Save</span>
                                </button>
                            </div>

                        </div>
                    </form>
                )
            }}
        />
    )
}


export default ReopenForm;