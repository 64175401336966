import React from 'react';

const NoResults = () => (
    <div className="react-table address-history mt-20">
        <div className="table">
            <div className="thead">
                <div className="tr" style={{display: "flex", flex: "1 0 auto", minWidth: "0px"}}>
                    <span style={{boxSizing: "border-box", flex: "100 0 auto", minWidth: "0px", width: "100px"}}>
                        <div title="Toggle SortBy" className="th sorting_desc" >Move In</div>
                        <div className="table-filter"></div>
                    </span>
                    <span style={{boxSizing: "border-box", flex: "100 0 auto", minWidth: "0px", width: "100px"}}>
                        <div title="Toggle SortBy" className="th">Move Out</div>
                        <div className="table-filter"></div>
                    </span>
                    <span style={{boxSizing: "border-box", flex: "400 0 auto", minWidth: "0px", width: "400px"}}>
                        <div title="Toggle SortBy" className="th">Address</div>
                        <div className="table-filter"></div>
                    </span>
                    <span style={{boxSizing: "border-box", flex: "200 0 auto", minWidth: "0px", width: "200px"}}>
                        <div className="th disabled"> </div>
                        <div className="table-filter"></div>
                    </span>
                </div>
            </div>
            <div className="tbody">
                <div role="row" className="tr odd">
                    <div className="result-row" style={{display:"flex"}}>
                        <span role="cell" style={{flex: "1 1 100%", textAlign: "center"}}>
                            No addresses have been recorded
                        </span>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default NoResults;