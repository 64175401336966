import axios from 'axios';

import dateFormatForServer from '../extensions/date.formatForServer';

export const onLookupByNino = async (formValues, source) => {

    try {
        var model = mappers.toServerModel(formValues);
        const response = await axios.post('/api/rtpf-lookup/nino', model, { cancelToken: source.token });
        const results = response.data || {};

        return {
            result: results
        };
    }
    catch (err) {
        return {
            error: err.response.data
        };
    }
}

export const onLookupByDocument = async (formValues, source) => {

    try {
        var model = mappers.toServerModel(formValues);
        const response = await axios.post('/api/rtpf-lookup/document', model, { cancelToken: source.token });
        const results = response.data || {};

        return {
            result: results
        };
    }
    catch (err) {
        return {
            error: err.response.data
        };
    }
}

const mappers = {
    toServerModel: (values) => {
        return {
            ...values,
            dateOfBirth: dateFormatForServer(values.dateOfBirth),
            dateStart: dateFormatForServer(values.dateStart),
            dateEnd: dateFormatForServer(values.dateEnd),
        }
    }
}

export default {
    onLookupByNino,
    onLookupByDocument
}