import React from "react";
import { useTable, useFlexLayout, usePagination, useSortBy, useFilters } from 'react-table';
import moment from 'moment';

import Pagination from './pagination';
import Table from './table';
import { DefaultColumnFilter, fuzzyTextFilterFn, getDatesForSelectFilter } from "./filters";

const TableWrapper = ({columns, data, itemsPerPage, pageNumber, sortBy, filters = [], Row, ExpandableDetails, isHomeOfficeUser, useMiddleware, rowDescription, hidePagination}) => {

  const dataMemo = React.useMemo(() => data, [data]);
  const columnsMemo = React.useMemo(() => columns, [columns]);

  const defaultColumn = React.useMemo(
      () => ({
          // Let's set up our default Filter UI
          Filter: DefaultColumnFilter,
      }),
      []
  );

  const filterTypes = React.useMemo(
      () => ({
          // Add a new fuzzyTextFilterFn filter type.
          fuzzyText: fuzzyTextFilterFn,
          // Or, override the default text filter to use
          // "startWith"
          text: (rows, id, filterValue) => {
              return rows.filter(row => {
                  const rowValue = row.values[id]
                  return rowValue !== undefined
                      ? String(rowValue)
                          .toLowerCase()
                          .startsWith(String(filterValue).toLowerCase())
                      : true
              })
          },

          date: (rows, id, filterValue) => {
              if (filterValue === 'n') {
                return rows.filter(val => val.original[id] === null);
              }

              var dates = getDatesForSelectFilter(filterValue);
              let start = moment(dates.from).subtract(1, 'day')
              let end = moment(dates.to).add(1, 'day')

              return rows.filter(val => {
                return moment(val.original[id]).isBetween(start, end);
              });
          }
      }),
      []
  );

  const tableOptions = { 
    columns: columnsMemo, 
    data: dataMemo, 
    defaultColumn,
    filterTypes,
    initialState: { 
      sortBy: sortBy,
      pageSize: itemsPerPage,
      pageIndex: pageNumber,
      filters: filters
    },
    useControlledState: useMiddleware,
  };

  const tableInstance = useTable(tableOptions, useFlexLayout, useFilters, useSortBy, usePagination)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,

    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },

  } = tableInstance

  const tableProps = {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    Row,
    ExpandableDetails
  };

  const paginationProps = {
    gotoPage,
    canPreviousPage,
    canNextPage,
    rowCount: rows.length || 0,
    rowDescription: rowDescription || "results",
    pageCount,
    pageIndex,
    pageOptions,
    pageSize,
    setPageSize,
    isHomeOfficeUser
  };

  const pagination = hidePagination ? null : <Pagination {...paginationProps} />;
  return (
    <>
      {pagination}
      <Table {...tableProps} />
    </>
  );
};

export default TableWrapper;
