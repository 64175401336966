import React, { useCallback, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '../../../state/actions/queries-actions';
import * as filtersActions from '../../../state/actions/filters-actions';

const FiltersTabs = ({filters: { queriesTab }}) => {

    const dispatch = useDispatch();

    const handleTabChange = useCallback((selectedTab) => {
        if (queriesTab !== selectedTab) {
            dispatch(filtersActions.applyFilter({queriesTab: selectedTab}));
            dispatch(actions.loadQueries());
        }
    }, [dispatch, queriesTab]);

    const tabs = React.useMemo(() => [
        {
            value: 'R',
            label: 'Received Queries',
            isSelected: (queriesTab === "R")
        },
        {
            value: 'S',
            label: 'Sent Queries',
            isSelected: (queriesTab === "S")
        },
        {
            value: 'C',
            label: 'Completed Queries',
            isSelected: (queriesTab === "C")
        },
    ],
    [queriesTab]);

    return (
        <div className="inner-tabs">
            <ul>
                { tabs.map((t, i) => 
                    <Fragment key={t.value}>
                        <li>
                            <button className={t.isSelected ? "selected" : ""} onClick={() => handleTabChange(t.value)}>{t.label}</button>
                        </li>
                        { i >= (tabs.length - 1) ? null : <li className="queries-spacer"></li> }
                    </Fragment>
                )}
            </ul>
            <div className="clearfix"></div>
        </div>
    );
}

export default FiltersTabs;