import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../state/actions/admin/users-list-actions';
import { selector } from '../../../../state/reducers/admin/users-reducer';
import { Link } from "react-router-dom";

import { userId as myUserId } from './../../server-values';
import DeleteUserModal from './modals/delete-user';
import ResetUserPasswordModal from './modals/reset-password';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faEdit, faUserSlash, faLockOpen } from '@fortawesome/free-solid-svg-icons';

export default function ActionButtons (props) {
    const { row } = props;
    const userId = row.original.id;
    const { isLocalAuthorityUser, isCurrentUser, showActionButtons } = row.original;
    const dispatch = useDispatch();

    const [showUserDelete, setShowUserDelete] = useState(false);
    const [showUserResetPassword, setShowUserResetPassword] = useState(false);

    const [replacementUsers, setReplacementUsers] = useState([]);
    
    const users = useSelector(state => selector.replacementUsers(userId)(state));

    const onDeleteClick = useCallback(
        (e) => {
            e.preventDefault(); 
            setShowUserDelete(true);
            setReplacementUsers(isLocalAuthorityUser ? users : []);
        },
        [setShowUserDelete, isLocalAuthorityUser, users, setReplacementUsers]
    );

    const onDeleteSuccess = () => {
        setShowUserDelete(false);
        dispatch(actions.loadUsers());
    }

    const userDeleteModal = showUserDelete 
        ? <DeleteUserModal
            onSuccess={onDeleteSuccess}
            onClose={() => setShowUserDelete(false)} 
            userId={userId}
            replacementUsers={replacementUsers} /> 
        : null;



    const onRefreshPasswordClick = useCallback(
        (e) => {
            e.preventDefault(); 
            setShowUserResetPassword(true);
        },
        [setShowUserResetPassword]
    );

    const onResetPasswordClose = () => {
        setShowUserResetPassword(false);
        dispatch(actions.loadUsers());
    }

    const userResetPasswordModal = showUserResetPassword 
        ? <ResetUserPasswordModal
            onClose={onResetPasswordClose}
            userId={userId}
            isCurrentUser={isCurrentUser} /> 
        : null;

        

    const onUnlockClick = useCallback(() => {
        dispatch(actions.unlockAccount(userId));
    }, [dispatch, userId]);


    return showActionButtons
    ? (
        <>
            <button onClick={onUnlockClick} title="Unlock" className="link" disabled={!row.original.isLocked}><FontAwesomeIcon icon={faLockOpen} /></button>
            <button onClick={onRefreshPasswordClick} className="link" title="Reset User's Password"><FontAwesomeIcon icon={faKey} /></button>
            <Link to={`/admin/users/edit/${userId}`} className="link" title="Edit User"><FontAwesomeIcon icon={faEdit} /></Link>
            <button onClick={onDeleteClick} className="link" title="Delete User" disabled={userId === myUserId}><FontAwesomeIcon icon={faUserSlash} /></button>
            { userResetPasswordModal }
            { userDeleteModal }
        </>
    )
    : null;
}