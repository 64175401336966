import DomConfigRepository from '../../repositories/domConfigRepository';
const sv = DomConfigRepository.getJsonConfigById('serverValues') || {};

export const caseId = sv.caseId;
export const isClosed = sv.isClosed;
export const isMyCase = sv.isMyCase;
export const localAuthorityId = sv.localAuthorityId;

export const userId = sv.userId;
export const userIsLocalAuthority = sv.userIsLocalAuthority;
export const userIsHomeOffice = !userIsLocalAuthority;
export const userIsCaseOwner = sv.userIsCaseOwner;
export const caseArea = !sv.userIsLocalAuthority
    ? "LIT"
    : sv.userIsCaseOwner
        ? "CaseOwner"
        : "NonCaseOwner";

export const principalApplicantId = sv.principalApplicantId;
export const principalApplicantName = sv.principalApplicantName;
export const principalApplicantAddress = sv.principalApplicantAddress;
export const homeOfficeReference = sv.homeOfficeReference;

export const hasAddresses = sv.hasAddresses;

export const addressId = sv.addressId;



export default {
    caseId,
    isClosed,
    localAuthorityId,

    userId,
    userIsLocalAuthority,
    userIsCaseOwner,
    caseArea,

    principalApplicantId,
    principalApplicantName,
    principalApplicantAddress,
    homeOfficeReference,

    hasAddresses,

    addressId
};