import React, { Fragment, useState, useEffect, useRef } from "react";
import { Field, Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';

import { onGetAll } from "../../../../api-endpoint-calls/case/housing-provider";
import stringIsNullOrEmpty from "../../../../extensions/string.isNullOrEmpty";

import PostcodeLookup from "./postcode-lookup";
import { renderDropdownList, renderRadioOption, renderDatePicker, renderInput, toSortedOptions } from '../../../form-inputs/final-form';

import HousingProvidersModal from '../modals/housing-providers';
import AddFinanceModal from '../modals/add-finance';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faFlagCheckered } from '@fortawesome/free-solid-svg-icons';



const AddForm = ({onSubmit, initialValues}) => {
    
    const history = useHistory();
    const [ lookupPerformed, setLookupPerformed ] = useState();
    const [ lookupErrorMessage, setLookupErrorMessage ] = useState('');
    const [ showAddHousingProvidelModal, setShowAddHousingProviderModal ] = useState(false);
    const [ showEditHousingProvidelModal, setShowEditHousingProviderModal ] = useState(false);
    const [ showAddFinanceModal, setShowAddFinanceModal ] = useState(false);

    const formSubmitButton = useRef();

    
    const onHousingProviderEditClick = (e) => {
        e.preventDefault(); 
        setShowEditHousingProviderModal(true);
    }
    
    const onHousingProviderAddClick = (e) => {
        e.preventDefault(); 
        setShowAddHousingProviderModal(true);
    }


    useEffect(() => {
        var { unsubscribe } = window.dispatcher.subscribe("change-tracker-submit-form", () => formSubmitButton.current.click());

        return () => {
            unsubscribe();
        }
    }, []);


    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            validate={values => {

                const errors = {};

                if (!values.dateMovedIn) {
                  errors.dateMovedIn = 'Date moved in is required';
                }

                if (values.isSwitchedToManualEntry) {

                    if (stringIsNullOrEmpty(values.houseNameNumber)) {
                        errors.houseNameNumber = "House Name/Number is required";
                    }

                    if (stringIsNullOrEmpty(values.street)) {
                        errors.street = "Street is required";
                    }

                    if (stringIsNullOrEmpty(values.townCity)) {
                        errors.townCity = "Town/City is required";
                    }

                    if (stringIsNullOrEmpty(values.postcode)) {
                        errors.postcode = "Post Code is required";
                    }
                }

                return errors;
            }}
            render={({ handleSubmit, submitting, values, form, valid }) => {

                const { accommodationTypes, propertyDescriptions, housingProviders, fullAddress, isSwitchedToManualEntry, otherAddressExist, housingProviderId } = values;

                const accommodationTypesOptions = accommodationTypes || [];
                const propertyDescriptionsOptions = propertyDescriptions || [];
                const housingProvidersOptions = toSortedOptions(housingProviders || []);

                
                const reloadHousingProviders = async () => {
                    var results = await onGetAll();
                    form.change('housingProviders', results);
                }

                const onHousingProviderAddModalClose = () => {
                    setShowAddHousingProviderModal(false);
                    reloadHousingProviders();
                }
                const onHousingProviderEditModalClose = () => {
                    setShowEditHousingProviderModal(false);
                    reloadHousingProviders();
                }

                const onAddressFound = (result) => {
                    
                    const { isSuccessful, address } = result;

                    if (isSuccessful)
                    {
                        setLookupPerformed(true);
                        
                        form.batch(() => {
                            form.change('fullAddress', address.fullAddress);
                            form.change('houseNameNumber', address.houseNameNumber);
                            form.change('street', address.street);
                            form.change('area', address.area);
                            form.change('townCity', address.townCity);
                            form.change('county', address.county);
                            form.change('postcode', address.postcode);

                            var manualEntryRequired = !(address.houseNameNumber && address.street && address.townCity && address.postcode);
                            form.change('isSwitchedToManualEntry', manualEntryRequired);
                        });
                    }
                }

                const onSwitchToManualEntry = () => {
                    setLookupPerformed(true);
                    form.change('isSwitchedToManualEntry', true);
                }

                const onFormSubmit = (e) => {
                    e.preventDefault();

                    if (lookupPerformed) {
                        if (valid) {
                            setShowAddFinanceModal(true);
                        } else {
                            handleSubmit();
                        }
                    } else {
                        setLookupErrorMessage('Address is required.');
                    }
                }


                const onAddFinanceModalSelection = (addFinance) => {
                    form.change('isAddingFinanceAfterAddingAddress', addFinance);
                    setShowAddFinanceModal(false);
                    handleSubmit();
                }


                const addressFields = isSwitchedToManualEntry
                ? (
                    <div className="address-edit-form">
                        <div className="input-wrapper">
                            <Field name="houseNameNumber" type="text" render={renderInput} label="Address Line 1 / House Name - Number" isMandatory={true} tabIndex={6}></Field>
                        </div>
                        <div className="input-wrapper">
                            <Field name="street" type="text" render={renderInput} label="Address Line 2 / Street" isMandatory={true} tabIndex={6}></Field>
                        </div>
                        <div className="input-wrapper">
                            <Field name="area" type="text" render={renderInput} label="Address Line 3 / Area - Locality" tabIndex={6}></Field>
                        </div>
                        <div className="input-wrapper">
                            <Field name="townCity" type="text" render={renderInput} label="Address Line 4 / Town - City" isMandatory={true} tabIndex={6}></Field>
                        </div>
                        <div className="input-wrapper">
                            <Field name="county" type="text" render={renderInput} label="Address Line 5 / County" tabIndex={6}></Field>
                        </div>
                        <div className="input-wrapper">
                            <Field name="postcode" type="text" render={renderInput} label="Postcode" isMandatory={true} tabIndex={6}></Field>
                        </div>
                        
                    </div>
                ) 
                : lookupPerformed
                    ? (
                        <div className="address-edit-form">
                            <div className="input-wrapper">
                                <label>Address</label>
                                <div className="address-display fake-input">
                                    <div className="full-address">{fullAddress.split(',').map(value => <Fragment key={value}><span>{value}</span><br/></Fragment>)}</div>
                                    <button type="button" onClick={() => onSwitchToManualEntry()} className="rbtn link">[ Edit ]</button>
                                </div>
                            </div>
                        </div>
                    )
                    : (
                        <PostcodeLookup onAddressFound={onAddressFound} onSwitchToManualEntry={onSwitchToManualEntry} errorMessage={lookupErrorMessage} />
                    );


                const backButton = otherAddressExist 
                ? (
                    <button type="button" onClick={() => history.go(-1)} className="rbtn secondary" tabIndex={11} disabled={submitting}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                        <span>Back</span>
                    </button>
                ) 
                : null;
                

                const housingProviderModal = showAddHousingProvidelModal 
                    ? <HousingProvidersModal onClose={onHousingProviderAddModalClose} /> 
                    : showEditHousingProvidelModal
                        ? <HousingProvidersModal onClose={onHousingProviderEditModalClose} housingProviderId={housingProviderId} /> 
                        : null ;

                const addFinanceModal = showAddFinanceModal
                    ? <AddFinanceModal onClose={() => setShowAddFinanceModal(false)} onSelection={onAddFinanceModalSelection} />
                    : null;

                return (
                    <>
                        <form onSubmit={handleSubmit} rel="change-tracked">
                            <div className="stripedbox">

                                <div className="mandatory"><span>*</span> Denotes a mandatory field </div>

                                <h2 style={{margin: "15px 0px 15px 50px"}}>New Address</h2>

                                <div className="address-edit-form">
                                    <div className="input-wrapper">
                                        <Field name="dateMovedIn" type="text" render={renderDatePicker} label="Date Moved In" isMandatory={true} placeholder="" tabIndex={1} />
                                    </div>
                                    <div className="input-wrapper">
                                        <Field name="dateMovedOut" type="text" render={renderDatePicker} label="Date Moved Out" tabIndex={2} placeholder="" minDate={values.dateMovedIn} />
                                    </div>
                                    <div className="input-wrapper">
                                        <label>Share address with the Home Office?</label>
                                        <div className="radio-options">
                                            <Field name="isSharedWithHomeOffice" type="radio" render={renderRadioOption} value="true" label="Yes" tabIndex={3} />
                                            <Field name="isSharedWithHomeOffice" type="radio" render={renderRadioOption} value="false" label="No" tabIndex={3} />
                                        </div>
                                    </div>
                                </div>

                                <hr />

                                {addressFields}
                                
                                <hr />

                                <h2 style={{margin: "30px 0px 15px 50px"}}>Additional Information (Optional)</h2>

                                <div className="address-edit-form">
                                    <div className="input-wrapper">
                                        <label>Property Type</label>
                                        <Field name="accommodationTypeId" render={renderDropdownList} tabIndex={6} options={accommodationTypesOptions} />
                                    </div>
                                    <div className="input-wrapper">
                                        <label>Property Description</label>
                                        <Field name="propertyDescription" render={renderDropdownList} tabIndex={7} options={propertyDescriptionsOptions} />
                                    </div>
                                    <div className="input-wrapper">
                                        <label>Staying with friends / family</label>
                                        <div className="radio-options">
                                            <Field name="isStayingWithFriendFamily" type="radio" render={renderRadioOption} value="true" label="Yes" tabIndex={8} />
                                            <Field name="isStayingWithFriendFamily" type="radio" render={renderRadioOption} value="false" label="No" tabIndex={8} />
                                        </div>
                                    </div>

                                    <div className="input-wrapper">
                                        <label>Name of property provider</label>
                                        <div className="select-with-options">
                                            <Field name="housingProviderId" render={renderDropdownList} tabIndex={9} options={housingProvidersOptions} />
                                            <div className="select-with-options-buttons">
                                                <button type="button" className="rbtn primary small" onClick={onHousingProviderAddClick}>Add New Provider</button>
                                                { housingProviderId ? <button type="button" className="rbtn primary small" onClick={onHousingProviderEditClick}>View / Manage</button> : null }
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>

                                <hr />

                                <div className="address-edit-form">
                                    <div className="full-row align-center">
                                        <div className="form-buttons">
                                            { backButton }
                                            <button type="button" onClick={onFormSubmit} className="rbtn primary" tabIndex={10} disabled={submitting} ref={formSubmitButton}>
                                                <FontAwesomeIcon icon={faFlagCheckered} />
                                                <span>Save</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </form>
                        { housingProviderModal }
                        { addFinanceModal }
                    </>
                )
            }}
        />
    )
}


export default AddForm;
