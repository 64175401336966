import axios from 'axios';
import { selector } from '../../reducers/reports/performance-report-reducer';
import { toViewModel, toReducerFormValues, toReducerOptions } from './mappers';

export const LOAD_START = 'REPORTS_PERFORMANCE_MONITORING_LOAD_START';
export const LOAD_SUCCESS = 'REPORTS_PERFORMANCE_MONITORING_LOAD_SUCCESS';
export const LOAD_FAIL = 'REPORTS_PERFORMANCE_MONITORING_LOAD_FAIL';

export const SUBMIT_START = 'REPORTS_PERFORMANCE_MONITORING_SUBMIT_START';
export const SUBMIT_SUCCESS = 'REPORTS_PERFORMANCE_MONITORING_SUBMIT_SUCCESS';
export const SUBMIT_FAIL = 'REPORTS_PERFORMANCE_MONITORING_SUBMIT_FAIL';

export const UPDATE_FORM_VALUES = 'REPORTS_PERFORMANCE_MONITORING_UPDATE_FORM_VALUES';

export const RESET_RESULTS = 'REPORTS_PERFORMANCE_MONITORING_RESET_RESULTS';


export const urls = {
    performanceMonitoring: `/api/reports/performance-monitoring`,
}

const action = (type, data = null) => ({
    type, data
});

let initialValues = {}

export function initialise() {
    return async (dispatch) => {

        dispatch(action(LOAD_START));

        try {
            const response = await axios.get(urls.performanceMonitoring);
            const values = response.data || {};

            let options = toReducerOptions(values);
            initialValues = toReducerFormValues(values);
    
            dispatch(action(LOAD_SUCCESS, { options, initialValues }));
        }
        catch (err) {
            dispatch(action(LOAD_FAIL));
        }
    }
}

export function resetForm() {
    return async (dispatch, getState) => {

        const state = getState();
        const formValues = selector.formValues(state);

        const newFormValues = {
            ...formValues,
            ...initialValues
        }

        dispatch(action(UPDATE_FORM_VALUES, newFormValues));
        dispatch(action(RESET_RESULTS));
    }
}

export function submitForm() {
    return async (dispatch, getState) => {

        const state = getState();
        const formValues = selector.formValues(state);

        dispatch(action(SUBMIT_START));

        const viewModel = toViewModel(formValues);

        try {
            var response = await axios.post(urls.performanceMonitoring, viewModel);
            const results = response.data || {};

            dispatch(action(SUBMIT_SUCCESS, results));
        }
        catch (err) {
            dispatch(action(SUBMIT_FAIL));
        }
    }
}

export function updateFormValue(field, value) {
    return (dispatch, getState) => {

        const state = getState();
        const formValues = selector.formValues(state);

        const newFormValues = {
            ...formValues,
            [field]: value
        }

        dispatch(action(UPDATE_FORM_VALUES, newFormValues));
    }
}