import React from "react";
import { Field, Form } from 'react-final-form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faFlagCheckered } from '@fortawesome/free-solid-svg-icons'

import { renderInput, renderDropdownList, renderRadioOption, toOptionsWithDefault, toSortedOptionsWithDefault } from '../../../form-inputs/final-form';


const HomeOfficeOptions = ({isShowingAliasDetails, homeOfficeOptions, homeOfficeTeamOptions}) => {

    const aliasOptions = isShowingAliasDetails
    ? (
        <>
            <label className="full-row">
                Please enter the preferred details to be shown:
            </label>

            <div className="input-wrapper">
                <Field name="aliasEmailAddress" type="text" render={renderInput} label="Email Address" tabIndex={12} />
            </div>
            <div className="input-wrapper">
                <Field name="aliasTelephoneNumber" type="text" render={renderInput} label="Telephone Number" tabIndex={13} />
            </div>
        </>
    ) : null;

    return (
        <>
            <div className="input-wrapper">
                <label>Home Office <span className="mandatory-stars">*</span></label>
                <Field name="homeOfficeId" render={renderDropdownList} options={homeOfficeOptions} tabIndex={9} />
            </div>
            <div className="input-wrapper">
                <label>Home Office Team <span className="mandatory-stars">*</span></label>
                <Field name="homeOfficeTeamId" render={renderDropdownList} options={homeOfficeTeamOptions} tabIndex={10} />
            </div>

            <div className="full-row input-wrapper">
                <label>When case is allocated to this user, should we display their personal contact details?</label>
                
                <Field name="isShowingAliasDetails" type="radio" render={renderRadioOption} value="false" label="Yes" tabIndex={11} />
                <Field name="isShowingAliasDetails" type="radio" render={renderRadioOption} value="true" label="No" tabIndex={11} />
            </div>

            { aliasOptions }
        </>
    );
}


const LocalAuthorityOptions = ({localAuthorityOptions}) => {

    return (
        <div className="full-row input-wrapper">
            <label style={{minWidth:"168px"}}>Local Authority <span className="mandatory-stars">*</span></label>
            <div style={{minWidth:"240px"}}>
                <Field name="localAuthorityId" render={renderDropdownList} options={localAuthorityOptions} tabIndex={9} />
            </div>
        </div>
    );
}

const AddForm = ({ onSubmit, initialValues }) => {
    
    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            render={({ handleSubmit, submitting, values }) => {
            
                let { userIsHomeOffice, isShowingAliasDetails } = values;

                userIsHomeOffice = (userIsHomeOffice && JSON.parse(userIsHomeOffice)) || false;
                isShowingAliasDetails = (isShowingAliasDetails && JSON.parse(isShowingAliasDetails)) || false;

                const accessLevelOptions = values.accessLevels || [];
                const localAuthorityOptions = toSortedOptionsWithDefault(values.localAuthorities || []);
                const homeOfficeOptions = toOptionsWithDefault(values.homeOfficeTeams || []);
                const homeOfficeTeamOptions = toOptionsWithDefault(values.litOffices || []);

                var organisationOptions = userIsHomeOffice 
                    ? <HomeOfficeOptions isShowingAliasDetails={isShowingAliasDetails} homeOfficeOptions={homeOfficeOptions} homeOfficeTeamOptions={homeOfficeTeamOptions}  />
                    : <LocalAuthorityOptions localAuthorityOptions={localAuthorityOptions} />

                return (
                    <form onSubmit={handleSubmit}>
                        <div className="stripedbox">

                            <div className="mandatory"><span>*</span> Denotes a mandatory field </div>

                            <div className="user-edit-form">
                                <div className="input-wrapper">
                                    <Field name="userName" type="text" render={renderInput} label="Username" isMandatory={true} tabIndex={1} />
                                </div>
                                <div className="input-wrapper">
                                    <Field name="telNumber" type="text" render={renderInput} label="Telephone Number" isMandatory={true} tabIndex={4} />
                                </div>

                                <div className="input-wrapper">
                                    <Field name="fullName" type="text" render={renderInput} label="Full Name" isMandatory={true} tabIndex={2} />
                                </div>
                                <div className="input-wrapper">
                                    <Field name="email" type="text" render={renderInput} label="Email Address" isMandatory={true} tabIndex={5} />
                                </div>

                                <div className="input-wrapper">
                                    <label>Access Level <span className="mandatory-stars">*</span></label>
                                    <Field name="accessLevel" render={renderDropdownList} options={accessLevelOptions} tabIndex={3} />
                                </div>
                            </div>

                            <hr />

                            <div className="user-edit-form">
                                <div className="input-wrapper">
                                    <Field name="password" type="password" render={renderInput} label="New Password" isMandatory={true} tabIndex={6} />
                                </div>
                                <div className="input-wrapper">
                                    <Field name="confirmPassword" type="password" render={renderInput} label="Confirm Password" isMandatory={true} tabIndex={7} />
                                </div>
                            </div>

                            <hr />

                            <div className="user-edit-form">

                                <div className="full-row input-wrapper">
                                    <label>Who does this user work for?</label>
                                    
                                    <Field name="userIsHomeOffice" type="radio" render={renderRadioOption} value="false" label="Local Authority" tabIndex={8} />
                                    <Field name="userIsHomeOffice" type="radio" render={renderRadioOption} value="true" label="Home Office" tabIndex={8} />
                                </div>

                                { organisationOptions }
                            </div>
                            <hr />
                            <div className="user-edit-form">
                                <div className="full-row align-center">
                                    <div className="form-buttons">
                                        <a href="/SuperUser/Users" className="rbtn secondary" tabIndex={13} disabled={submitting}>
                                            <FontAwesomeIcon icon={faArrowLeft} /><div>Back</div>
                                        </a>
                                        <button type="submit" className="rbtn primary" tabIndex={12} disabled={submitting}>
                                            <FontAwesomeIcon icon={faFlagCheckered} /><div>Save</div>
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </form>
                );
            }}
        />
    );
}



export default AddForm;