import axios from 'axios';
import { selector, searchTypes } from '../reducers/search-reducer';
import { selector as filtersSelector } from '../reducers/filters-reducer';
import { actions as byPersonalDetailsActions } from './search-form/by-personal-details';
import * as filtersActions from './filters-actions';

export const SEARCH_START = 'SEARCH_SEARCH_START';
export const SEARCH_SUCCESS = 'SEARCH_SEARCH_SUCCESS';
export const SEARCH_FAILED = 'SEARCH_SEARCH_FAILED';
export const SEARCH_RESET = 'SEARCH_SEARCH_RESET';

export const CLAIM_CASE_START = 'SEARCH_CLAIM_CASE_START';
export const CLAIM_CASE_END = 'SEARCH_CLAIM_CASE_END';

export const SET_FORM_VALUES = 'SEARCH_SET_FORM_VALUES';
export const SET_PAGE = 'SEARCH_SET_PAGE';
export const SET_SORT = 'SEARCH_SET_SORT';
export const SET_FILTERS = 'SEARCH_SET_FILTERS';
export const TOGGLE_DEPENDANTS = 'SEARCH_TOGGLE_DEPENDANTS';
export const UPDATE_ISBEINGVIEWED = 'SEARCH_UPDATE_ISBEINGVIEWED';
export const UPDATE_USERID = 'SEARCH_UPDATE_USERID';

const action = (type, data = null) => ({
    type, data
});

export function onSearchByListChange(changes) {
    return (dispatch, getState) => {

        const formValues = selector.byListFormValues(getState());

        const byListFormValues = {
            ...formValues,
            ...changes
        };

        byListFormValues.referrals = byListFormValues.caseStatusIds.includes(1);
        byListFormValues.financiallySupported = byListFormValues.caseStatusIds.includes(2);
        byListFormValues.closedReferrals = byListFormValues.caseStatusIds.includes(3);
        byListFormValues.closedSupported = byListFormValues.caseStatusIds.includes(4);

        byListFormValues.selectAll = byListFormValues.referrals && byListFormValues.financiallySupported && byListFormValues.closedReferrals && byListFormValues.closedSupported;
        byListFormValues.buttonIsDisabled = !byListFormValues.referrals && !byListFormValues.financiallySupported && !byListFormValues.closedReferrals && !byListFormValues.closedSupported;

        dispatch(action(SET_FORM_VALUES, {byListFormValues}));
    }
}

export function searchByList() {
    return async (dispatch, getState) => {

        const byListFormValues = selector.byListFormValues(getState());
        const filters = filtersSelector.values(getState());

        const data = {
            ...byListFormValues,
            legislationIds: filters.legislationIds,
            caseWorkerIds: filters.caseWorkerIds,
            localAuthorityIds: filters.localAuthorityIds,
            hoCaseWorkerIds: filters.hoCaseWorkerIds,
        }

        dispatch(action(SEARCH_START, { searchType: searchTypes.byList, searchParams: data }));

        try {
            const response = await axios
                .post('/api/search/by-list', data);
            dispatch(action(SEARCH_SUCCESS, response.data || {}));
        } catch (err) {
            dispatch(action(SEARCH_FAILED));
        }
    }
}

export function onSearchByReferenceChange(changes) {
    return (dispatch, getState) => {

        const formValues = selector.byReferenceFormValues(getState());
        const byReferenceFormValues = {
            ...formValues,
            ...changes
        };

        dispatch(action(SET_FORM_VALUES, {byReferenceFormValues}));
    }
}

export function searchByRef() {
    return async (dispatch, getState) => {

        const data = selector.byReferenceFormValues(getState());

        dispatch(action(SEARCH_START, { searchType: searchTypes.byReference, searchParams: data }));

        try {
            const response = await axios.post('/api/search/by-ref', data);
            dispatch(action(SEARCH_SUCCESS, response.data || {}));
        } catch (err) {
            dispatch(action(SEARCH_FAILED));
        }
    }
}

export function searchByAlert() {
    return async (dispatch, getState) => {

        const state = getState();
        const filters = filtersSelector.values(state);

        const data = {
            AlertTypeId: filters.alertTypeId,
            LegislationIds: filters.legislationIds,
            CaseWorkerIds: filters.caseWorkerIds,
            LocalAuthorityIds: filters.localAuthorityIds,
            HoCaseWorkerIds: filters.hoCaseWorkerIds,
        }

        dispatch(action(SEARCH_START, { searchType: searchTypes.byAlert, searchParams: data }));

        try {
            const response = await axios.post('/api/search/by-alert', data);
            dispatch(action(SEARCH_SUCCESS, response.data || {}));
        } catch (err) {
            dispatch(action(SEARCH_FAILED));
        }
    }
}

export function searchByCaseload() {
    return async (dispatch, getState) => {

        const state = getState();
        const filters = filtersSelector.values(state);

        const data = {
            CaseLoadCurrentActivityId: filters.caseLoadCurrentActivityId,
            LegislationIds: filters.legislationIds,
            CaseWorkerIds: filters.caseWorkerIds,
            LocalAuthorityIds: filters.localAuthorityIds,
            HoCaseWorkerIds: filters.hoCaseWorkerIds,
        }

        dispatch(action(SEARCH_START, { searchType: searchTypes.byCaseload, searchParams: data }));

        try {
            const response = await axios.post('/api/search/by-caseload', data);
            dispatch(action(SEARCH_SUCCESS, response.data || {}));
        } catch (err) {
            dispatch(action(SEARCH_FAILED));
        }
    }
}

export function claimCase(caseId) {
    return async (dispatch) => {

        dispatch(action(CLAIM_CASE_START));

        try {
            const response = await axios.post('/api/case/claim', { caseId });
            dispatch(action(CLAIM_CASE_END, { caseId, caseWorkersName: response.data.name || '', caseWorkerIsMe: true }));
        } catch (err) {
            dispatch(action(CLAIM_CASE_END));
        }
    }
}

export function unclaimCase(caseId) {
    return async (dispatch) => {

        dispatch(action(CLAIM_CASE_START));

        try {
            const response = await axios.post('/api/case/unclaim', { caseId });
            dispatch(action(CLAIM_CASE_END, { caseId, caseWorkersName: response.data.name || '', caseWorkerIsMe: false }));
        } catch (err) {
            dispatch(action(CLAIM_CASE_END, "N/A"));
        }
    }
}

export function setPage(pageIndex, pageSize) {
    return (dispatch, getState) => {
        const currentPageSize = selector.pageSize(getState());
        if (pageSize !== currentPageSize) {
            axios.post('/api/search/set-preferences', { pageSize });
        }

        dispatch(action(SET_PAGE, { pageIndex, pageSize }));
    }
}

export function setSort(sortBy) {
    return (dispatch) => {
        dispatch(action(SET_SORT, sortBy));
    }
}

export function setFilters(filters) {
    return (dispatch) => {
        dispatch(action(SET_FILTERS, filters));
    }
}

export function toggleDependants() {
    return action(TOGGLE_DEPENDANTS);
}

export function resetSearch() {
    return (dispatch) => {
        dispatch(action(SEARCH_RESET));
        dispatch(action(byPersonalDetailsActions.reset));
        dispatch(action(filtersActions.RESET));
    }
}
