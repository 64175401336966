import React from 'react';
import { Field } from "react-final-form";
import { renderDropdownList, renderDatePicker } from "../../../../form-inputs/final-form";

export default ({ values, highlights, disabled, vrsOutcomesOptions }) => {

    if (!values.voluntaryReturnsServiceTypeId)
        return null;

    highlights = highlights || {};

    return (
        <>
            <div className="input-wrapper">
                <Field
                    name="dateVrsAvrAppliedFor"
                    type="text"
                    render={renderDatePicker}
                    label="Date VRS / AVR applied for"
                    placeholder=""
                    isHighlighted={highlights.dateVrsAvrAppliedFor}
                    disabled={disabled}
                />
            </div>

            <div className="input-wrapper">
                <label>Outcome</label>
                <Field
                    name="voluntaryReturnsServiceOutcomeId"
                    type="text"
                    render={renderDropdownList}
                    options={vrsOutcomesOptions}
                    isHighlighted={highlights.voluntaryReturnsServiceOutcomeId}
                    disabled={disabled}
                />
            </div>

            <div className="input-wrapper">
                <Field
                    name="dateOfVrsOutcome"
                    type="text"
                    render={renderDatePicker}
                    label="Date of outcome"
                    placeholder=""
                    isHighlighted={highlights.dateOfVrsOutcome}
                    disabled={disabled}
                />
            </div>
        </>
    )
}