import React, { useState, useEffect } from 'react';

import { userId } from '../../server-values';
import { onLoad, onUpdateSubmit } from '../../../../api-endpoint-calls/superuser/users';

import Form from './form';

import { LoadingV1 } from '../../../common/loading';


const SuperuserUsersEdit = () => {

    const [initialValues, setInitialValues] = useState({});

    const [loaded, setLoaded] = useState(false);
    const [successful, setSuccessful] = useState(false);

    useEffect(() => {
        const onPageLoad = async () => {
            const result = await onLoad(userId);
            
            setLoaded(result.isLoaded);
            setSuccessful(result.isSuccessful);

            if (result.initialValues) {
                setInitialValues(result.initialValues);
            }
        }

        onPageLoad();
        
    }, [setInitialValues, setLoaded, setSuccessful]);

    
    const showSpinner = (!loaded);
    const showFailure = (loaded && !successful);

    if (showSpinner) {
        return <LoadingV1 />;
    } else if (showFailure) {
        return <h2>An error has occured!</h2>;
    }


    const onFormSubmit = async (formValues) => {

        const result = await onUpdateSubmit(formValues);

        if (result.isSuccess) {
            window.dispatcher.dispatchEvent('change-tracker-reset');
            window.location.replace('/SuperUser/Users');
        }

        return result.errors;
    }

    return (
        <Form onSubmit={onFormSubmit} initialValues={initialValues} />
    );
}

export default SuperuserUsersEdit;