import React from 'react';

export default ({options: {column}}) => {
    
    let className = column.canSort
      ? column.isSorted
          ? column.isSortedDesc 
              ? 'sorting_desc' 
              : 'sorting_asc'
          : ''
      : 'disabled';
  
    return (
        <span {...column.getHeaderProps()}>
          <div {...column.getSortByToggleProps()} className={`th ${className}`}>
            {column.render('Header')}
          </div>
          <div className="table-filter">
            {column.canFilter ? column.render('Filter') : null}
          </div>
        </span>
    )
}