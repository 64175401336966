import React from 'react';
import moment from 'moment';

const ApplicantNameWithLink = ({userIsHomeOffice, summary}) => {
	let area = userIsHomeOffice ? 'LIT' : 'CaseOwner';

	return (
		<a href={`/${area}/Summary/Display?iCaseID=${summary.caseId}`} className="case-link">
			{ summary.principalApplicant }
		</a>
	)
}

export default ({summary, userIsHomeOffice, fromCase}) => (
    <div className="details">
		<div className="post-count">
			<div className="post-count-number">{summary.postCount}</div>
			<div>POSTS</div>
		</div>
		<div className="topic-name">
			<div className="topic-name-title">{summary.title}</div>
			<div>
				{
					fromCase 
					? <span>{ summary.principalApplicant }</span> 
					: <ApplicantNameWithLink summary={summary} userIsHomeOffice={userIsHomeOffice} />
				}
			</div>
		</div>
		<div className="references">
			<div><b>Connect ID:</b> {summary.caseId}</div>
			<div><b>Home Office Ref:</b> {summary.homeOfficeReference}</div>
		</div>
		<div className="creation-details">
			<div><b>Raised By:</b> {summary.originatingAuthority} - {summary.raisedBy}</div>
			<div><b>Date Query Opened:</b> {moment(summary.startDate).format("DD/MM/YYYY @ HH:mm")}</div>
		</div>
	</div>
);