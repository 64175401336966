import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../state/actions/search-actions';
import { selector } from '../../../state/reducers/filters-reducer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const alertName = (alertTypeId, caseLoadCurrentActivityId) => {

    switch (alertTypeId) {
        case 1: return "New Case Alert";
        case 2: return "LA Update Alert";
        case 3: return "Case Closed Alert";
        case 4: return "Immigration Update Alert";
        case 6: return "Closure Required Alert";
        case 8: return "Address Change Alert";
        default: ;
    }

    switch (caseLoadCurrentActivityId) {
        case 1: return "Number of new households added";
        case 2: return "Number of new financially supported";
        case 3: return "Open - Not financially supported";
        case 4: return "Open - Financially supported";
        case 5: return "OPEN - 1000 day 'priority' cases";
        default: ;
    }

    return "";
}

const getList = (label, list, selectedValues) => {

    selectedValues = selectedValues || [];

    if (selectedValues.length === 0)
        return null;

    const filterableOptions = list.reduce((acc, val) => acc.concat(val.options), []);
    const selectedOptions = filterableOptions.filter(item => selectedValues.includes(parseInt(item.value)));

    return (
        <>
            <label>{label}:</label>
            <span className="filter-list">
                {selectedOptions.map(x => <span className="selected-option">{x.label}</span>)}
            </span>
        </>
    )

}

const useDisplayFilters = () => {

    const {
        legislationIds, caseWorkerIds, localAuthorityIds, hoCaseWorkerIds
    } = useSelector(state => selector.values(state));

    const {
        localAuthorities, homeOfficeCaseWorkers, legislations, localAuthorityCaseWorkers
    } = useSelector(state => selector.filterOptions(state));

    const filters = [
        getList("Legislations selected", legislations, legislationIds),
        getList("Caseworkers selected", localAuthorityCaseWorkers, caseWorkerIds),
        getList("Local authorities selected", localAuthorities, localAuthorityIds),
        getList("Caseworkers / teams selected", homeOfficeCaseWorkers, hoCaseWorkerIds)
    ].filter(x => x != null);

    if (filters.length === 0)
        return null;

    return (
        <ul className="alerts-viewed-filters" style={{ marginTop: "6px" }}>
            {filters.map(x => <li key={x}>{x}</li>)}
        </ul>
    )
}


const ByAlert = () => {
    const dispatch = useDispatch();

    const { alertTypeId, caseLoadCurrentActivityId } = useSelector(state => selector.values(state));

    useEffect(() => {
        if (alertTypeId > 0) {
            dispatch(actions.searchByAlert())
        }
        else if (caseLoadCurrentActivityId > 0) {
            dispatch(actions.searchByCaseload())
        }
    }, [dispatch, alertTypeId, caseLoadCurrentActivityId])

    const alertTypeName = alertName(alertTypeId, caseLoadCurrentActivityId);

    const filters = useDisplayFilters();
    const hasFilters = (filters != null);

    const onReset = () => {
        return dispatch(actions.resetSearch());
    }

    return alertTypeId > 0 || caseLoadCurrentActivityId > 0
        ? (
            <div className="stripedbox search-footer">
                <div>
                    <p>
                        <span className={`alerts-viewed-text ${hasFilters ? 'with-filters' : ''}`}>
                            <label>VIEWING:</label>
                            <span className="text-orange">{alertTypeName}</span>
                        </span>
                        {filters}
                    </p>
                </div>
                <div className="search-buttons">
                    <button className="rbtn large primary" onClick={onReset} tabIndex={6} style={{ maxWidth: "140px", maxHeight: "35px" }}>
                        <FontAwesomeIcon icon={faSearch} style={{ maxWidth: "20%" }} /><div>New Search</div>
                    </button>
                </div>
            </div>
        )
        : null;
};

export default ByAlert;
