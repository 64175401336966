import React from "react";

import { onDownloadAsync } from '../../../../api-endpoint-calls/case/documents';
import emptyGuid from '../../../../extensions/guid.empty';
import downloadFile from "./download-file";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faDownload, faTrash } from '@fortawesome/free-solid-svg-icons';

const Documents = ({applicantId, documents, disabled, onEditDocumentModalRequest, onDeleteDocumentModalRequest}) => {
    
    const onEditClick = (documentId) => {
        onEditDocumentModalRequest(documentId);
    }

    const onDeleteClick = (documentId) => {
        onDeleteDocumentModalRequest(documentId);
    }

    const onDownloadClick = async (documentId) => {
        const download = await onDownloadAsync(applicantId, documentId);
        
        if (download.isSuccessful) {
            const { fileName, contents } = download.values;
            downloadFile(fileName, contents);
        }
    };

    const addButton = !disabled
        ? <button type="button" onClick={() => onEditClick(emptyGuid)} className="rbtn primary small">Add Document</button>
        : null;

    return (
        <>
            <div className="header-with-button">
                <h2>Documents</h2>
                { addButton }
            </div>

            <div className="documents">
                {
                    documents?.records.map((item) => {
                        
                        const deleteButton = !disabled
                            ?
                                <button type="button" className="rbtn light-grey" onClick={() => onDeleteClick(item.id)}>
                                    <span><FontAwesomeIcon icon={faTrash} /></span>
                                </button>
                            : null;

                        return (

                            <div  className={`document ${item.isHighlightedForAlert ? "is-highlighted" : ""}`} key={item.id}>
                                <div className="header">
                                    <span className="document-description">
                                        {item.documentCited}  ({item.documentLocation})
                                    </span>
                                    <div className="document-buttons">
                                        <button type="button" className="rbtn light-grey" onClick={() => onDownloadClick(item.id)}>
                                            <span><FontAwesomeIcon icon={faDownload} /></span>
                                        </button>
                                        <button type="button" className="rbtn light-grey" onClick={() => onEditClick(item.id)}>
                                            <span><FontAwesomeIcon icon={faEdit} /></span>
                                        </button>
                                        { deleteButton }
                                    </div>
                                </div>
                                <span className="document-user-details">
                                    {item.uploadedDetails}
                                </span>
                                
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}


export default Documents;
