import React, { Fragment } from "react";
import * as actions from '../../../../state/actions/case/summary-actions';

import { RecordCheckedByHomeOffice, ImmigrationUpdateAlert, ClosureRequiredAlert, OpenReferralAlert } from './local-authority-alerts';
import { AddressChangedAlert, CaseClosedAlert, StatusCheckAlert } from './home-office-alerts';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const NoActiveAlerts = () => (
    <div className="alert-row">
         <span className="header">
            <span className="title">
                No Active Alerts Found
            </span>
        </span>
    </div>
)

const AlertTypes = {
    OpenReferral: 7,
    ImmigrationUpdate: 4,
    ClosureRequired: 6,

    NewCase: 1,
    LocalAuthorityUpdate: 2,
    CaseClosed: 3,
    AddressChanged: 8
}

export default ({caseAlerts, dispatch}) => {

    const recordCheckedByHomeOffice = caseAlerts.isShowingToLocalAuthorityUser
        ? <RecordCheckedByHomeOffice hasHomeOfficeCheckedTheRecord={caseAlerts.hasHomeOfficeCheckedTheRecord} />
        : null;

    const noActiveAlerts = caseAlerts.hasActiveAlerts === false
        ? <NoActiveAlerts />
        : null;


    const RenderAlert = ({alert}) => {

        const onCloseAlertClick = (e) => {
            e.preventDefault();

            actions.closeAlert(dispatch, alert.caseId, alert.alertTypeId);
        }


        if (caseAlerts.isShowingToLocalAuthorityUser) {

            switch (alert.alertTypeId) {
                case AlertTypes.ImmigrationUpdate:
                    return <ImmigrationUpdateAlert alert={alert} />
                case AlertTypes.ClosureRequired:
                    return <ClosureRequiredAlert alert={alert} applicantIsChildOrCareLeaver={caseAlerts.applicantIsChildOrCareLeaver} ukbaOutcome={caseAlerts.ukbaOutcome} />
                case AlertTypes.OpenReferral:
                    return <OpenReferralAlert alert={alert} />
                default:
                    return null
            }

        } else {

            switch (alert.alertTypeId) {
                case AlertTypes.AddressChanged:
                    return <AddressChangedAlert alert={alert} /> 
                case AlertTypes.CaseClosed:
                    return <CaseClosedAlert alert={alert} endDate={caseAlerts.endDate} closureReason={caseAlerts.closureReason} onCloseAlertClick={onCloseAlertClick} />
                case AlertTypes.NewCase:
                case AlertTypes.LocalAuthorityUpdate:
                    return <StatusCheckAlert alert={alert} nextDependantLink={caseAlerts.nextDependantLink} />
                default:
                    return null
            }
        }
    }

    return (
        <div>
        
            <div className="summary-title">
                <span className="title">
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                    <span>Alerts</span>
                </span>
                <span className="small">Please review the case alerts below</span>
            </div>

            <div className={`stripedbox ${caseAlerts.hasActiveAlerts ? "alerts-wrapper" : ""}`}>
                <div className="case-alerts">

                    {recordCheckedByHomeOffice}

                    {noActiveAlerts}    

                    {
                        caseAlerts.activeAlerts.map(item => (
                            <Fragment key={item.alertTypeId}>
                                <RenderAlert alert={item} />
                            </Fragment>
                        ))
                    }


                </div>
            </div>

        </div>
    )
}