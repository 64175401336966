import React, { useEffect, useRef, useState } from "react";
import { Field, Form } from 'react-final-form';

import { renderDropdownList, renderRadioOption, toIntOptions } from '../../../../form-inputs/final-form';
import emptyGuid from "../../../../../extensions/guid.empty";

import FileInformation from './file-information';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlagCheckered } from '@fortawesome/free-solid-svg-icons';


const defaultOptions = {
    documentCited: [],
    documentLocations: []
}


const DocumentForm = ({onSubmit, initialValues, disabled}) => {
    
    const formSubmitButton = useRef();

    const [selectedFile, setSelectedFile] = useState(null);

    useEffect(() => {
        var { unsubscribe } = window.dispatcher.subscribe("change-tracker-modal-submit-form", () => formSubmitButton.current.click());

        return () => {
            unsubscribe();
        }
    }, []);

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            render={({ handleSubmit, submitting, values }) => {

                const { options } = values;
                const { documentCited, documentLocations } = options || defaultOptions;
                const isDisabled = disabled || submitting;

                const documentCitedOptions = toIntOptions(documentCited);
                const documentLocationsOptions = toIntOptions(documentLocations);

                const whenSubmittingTheForm = async (event) => {
                    values.file = selectedFile;
                    await handleSubmit(event);
                }

                const fileHandler = values.documentId === emptyGuid
                    ? (
                        <div className="input-wrapper">
                            <label>Selected Document</label>
                            <Field name="file" type="file">
                                {({input}) => (
                                    <>
                                        <input type="file" className="form-control-file" onChange={(e) => setSelectedFile(e.target.files[0])} />
                                    </>
                                )}
                            </Field>
                        </div>
                    )
                    : <FileInformation
                        applicantId={values.applicantId}
                        documentId={values.documentId}
                        fileName={values.documentFileName}
                        fileSize={values.documentFileSize}
                        uploaded={values.dateCreated} />

                const submitButton = !disabled
                    ? (
                        <>
                            <hr />
                        
                            <div className="form-buttons">
                                <button type="submit" className="rbtn primary" disabled={submitting} ref={formSubmitButton}>
                                    <FontAwesomeIcon icon={faFlagCheckered} />
                                    <span>Save</span>
                                </button>
                            </div>
                        </>
                    )
                    : null;

                return (
                    <form autoComplete="off" onSubmit={whenSubmittingTheForm} rel="change-tracked-modal">
    
                        <div className="mandatory"><span>*</span> Denotes a mandatory field </div>

                        <div className="modal-edit-form">
                            <div className="input-wrapper">
                                <label>Document Type</label>
                                <Field name="documentCited" type="text" render={renderDropdownList} options={documentCitedOptions} disabled={isDisabled} />
                            </div>
                            <div className="input-wrapper">
                                <label>Was it an original document?</label>
                                <span className="radio-options">
                                    <Field name="isOriginal" type="radio" render={renderRadioOption} value="true" label="Yes" />
                                    <Field name="isOriginal" type="radio" render={renderRadioOption} value="false" label="No" />
                                </span>
                            </div>
                            <div className="input-wrapper">
                                <label>Document Location</label>
                                <Field name="documentLocation" type="text" render={renderDropdownList} options={documentLocationsOptions} disabled={isDisabled} />
                            </div>

                            { fileHandler }
                        
                            { submitButton }
                        </div>
                        
                    </form>
                )
            }}
        />
    )
}


export default DocumentForm;
