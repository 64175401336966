import React from 'react';

export default ({row}) => {

	let aliases = row.original.aliases.length > 0
		? row.original.aliases.map((alias, index) => (
			<div key={`a${index}`}>
				<span style={{flex: "1 1 5%"}}></span>
				<span style={{flex: "1 1 12%"}}>ALIAS</span>
				<span style={{flex: "2 2 30%"}}><b>Forename: </b>{alias.forename}</span>
				<span style={{flex: "2 2 18%"}}><b>Surname: </b>{alias.surname}</span>
				<span style={{flex: "1 1 35%"}}></span>
			</div>
		))
		: null;

	let dependants = row.original.dependants.length > 0
		? row.original.dependants.map((dependant, index) => (
			<div key={`d${index}`}>
				<span style={{flex: "1 1 5%"}}></span>
				<span style={{flex: "1 1 12%"}}>DEPENDANT</span>
				<span style={{flex: "2 2 30%"}}><b>Forename: </b>{dependant.forename}</span>
				<span style={{flex: "2 2 18%"}}><b>Surname: </b>{dependant.surname}</span>
				<span style={{flex: "1 1 35%"}}></span>
			</div>
		))
		: null;
		

	let noRecords = (aliases === null) && (dependants === null)
		? (
			<div>
				<span style={{flex: "1 1 5%"}}></span>
				<span style={{flex: "3 3 60%"}}>This applicant has no Dependants or Aliases</span>
				<span style={{flex: "1 1 35%"}}></span>
			</div>
		) : null;

	return (
		<div style={{flex: "1 1 100%"}} className="result-subrow">
			{ aliases }
			{ dependants }
			{ noRecords }
		</div>
	);
}