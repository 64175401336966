import React from 'react';

const ErrorMessage = ({text}) => (
    <div style={{color: "#f00", whiteSpace: "initial"}}>{text}</div>
)

export const renderInput = ({input, tabIndex, type, meta: { touched, error }}) => (
    <>
        <input
            {...input}
            type={type}
            tabIndex={tabIndex}
            className={
                "input-block-level" +
                (touched && error ? " invalid-search-input" : "")
            }
        />
        {touched && error ? <ErrorMessage text={error} /> : null}
    </>
);

export const renderHiddenInput = ({input}) => 
    <input {...input} type="hidden" />;

export const renderSelectList = ({input, tabIndex, children, meta: { touched, error }}) => (
    <>
        <select
            {...input}
            tabIndex={tabIndex}
            className={
                "input-block-level" +
                (touched && error ? " invalid-search-input" : "")
            }>
            { children }
        </select>
        {touched && error ? <ErrorMessage text={error} /> : null}
    </>
);