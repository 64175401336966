import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { onGetAllAsync, onLoadAsync, onUpdateSubmitAsync } from '../../../../../api-endpoint-calls/case/educations';

import Form from './form';

import { errorNotification, LoadingV1 } from '../../../../common';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faTimes } from '@fortawesome/free-solid-svg-icons';


export default ({onClose, caseId, educationId, disabled, onDeleteEducationModalRequest}) => {

    const defaultLoadingState = (!educationId || educationId === 0);

    const [visible, setVisible] = useState(false);
    const [loaded, setLoaded] = useState(defaultLoadingState);
    const [successful, setSuccessful] = useState(defaultLoadingState);
    const [errorMessage, setErrorMessage] = useState(null);

    const [initialValues, setInitialValues] = useState({
        caseId,
        educationId 
    });

    useEffect(() => {
        const source = axios.CancelToken.source();

        const onPageLoadAsync = async () => {
            const result = await onLoadAsync(caseId, educationId, source);
            
            setLoaded(result.isLoaded);
            setSuccessful(result.isSuccessful);

            if (result.initialValues) {
                setInitialValues({ ...result.initialValues, caseId });
            }
        }

        if (educationId > 0) {
            onPageLoadAsync();
        }

        return () => {
            source.cancel();
        }

    }, [caseId, educationId, setLoaded, setSuccessful, setInitialValues]);

    useEffect(() => {
        if (visible === false) {
            setTimeout(() =>  setVisible(true), 200);
        }
    }, [visible, setVisible]);

    useEffect(() => {
        var { unsubscribe } = window.dispatcher.subscribe("change-tracker-modal-close", () => onClose());

        return () => {
            unsubscribe();
        }
    }, [onClose]);

    
    const onCloseClick = () => {
        window.dispatcher.dispatchEvent("change-tracker-modal-closing");
    }

    const onSubmit = async (formValues) => {
        const result = await onUpdateSubmitAsync(formValues);
        
        if (result.isSuccess) {
            setErrorMessage(null);
            window.dispatcher.dispatchEvent("change-tracker-modal-reset");

            const educations = await onGetAllAsync(caseId);
            onClose(educations);
        } else {
            setErrorMessage(result.message);
        }

        return result.errors;
    };


    const errorAlert = errorNotification(errorMessage);

    const showSpinner = (!loaded);
    const showFailure = (loaded && !successful);

    if (showSpinner) {
        return <LoadingV1 />;
    } else if (showFailure) {
        return <h2>An error has occured!</h2>;
    }


    return (
        <>
            <div className={ `react-modal-anchor ${(visible ? 'show' : '')}`}>
                <div className={ `react-modal educations ${visible ? 'show' : ''}` }>
                    <button onClick={onCloseClick} className="close-button"><FontAwesomeIcon icon={faTimes} /></button>
                    <h1>
                        <FontAwesomeIcon icon={faHome} />
                        {educationId > 0 ? "Edit" : "Add"} Education
                    </h1>

                    <div className="stripedbox">
                        {errorAlert}
                        <Form onSubmit={onSubmit} initialValues={initialValues} disabled={disabled} onDeleteEducationModalRequest={onDeleteEducationModalRequest} />
                    </div>
                </div>
            </div>
            <div className={ `react-modal-backdrop ${visible ? 'show' : ''}` }></div>
        </>
    );
    
}