import React from "react";
import { Field } from 'react-final-form';


import { renderDropdownList, renderGroupedDropdownList, renderDatePicker, renderTextArea, renderErrorMessage } from '../../form-inputs/final-form';

import ReferralServices from '../referral/services';

const ReferralFormFields = ({ form, selectedServiceIds, services, referralOrganisationId,
    reasonsForApplyingOptions, legislationOptions, referralOrganisationOptions, disabled,
    onEditReferralOrganisationModalRequest }) => {

    const referralOrganisationButtons = !disabled
        ? (
            <div className="select-with-options-buttons">
                <button type="button" className="rbtn primary small" onClick={() => onEditReferralOrganisationModalRequest(0)}>Add Referral Organisation</button>
                {referralOrganisationId ? <button type="button" className="rbtn primary small" onClick={() => onEditReferralOrganisationModalRequest(referralOrganisationId)}>View / Manage</button> : null}
            </div>
        )
        : null;

    return (
        <div className="case-referral-edit-page">
            <div className="stripedbox page-header">
                <h2>2 / 2 : Add Referral information</h2>
                <div className="mandatory-text"><span>*</span> Denotes a mandatory field </div>
            </div>
            <div className="stripedbox">
                <div className="referral-edit-form">
                    <div className="input-wrapper">
                        <Field name="dateOfReferral" type="text" render={renderDatePicker} label="Date Of Referral" isMandatory={true} placeholder="" maxDate={new Date()} disabled={disabled} />
                    </div>

                    <div className="input-wrapper">
                        <label>Referral Organisation</label>
                        <div className="select-with-options">
                            <Field name="referralOrganisationId" render={renderDropdownList} options={referralOrganisationOptions} disabled={disabled} />
                            {referralOrganisationButtons}
                        </div>

                    </div>

                    <div className="input-wrapper">
                        <label>Primary reason for referral <span className="mandatory-stars">*</span></label>
                        <Field name="applicationReasonId" render={renderDropdownList} options={reasonsForApplyingOptions} disabled={disabled} />
                    </div>


                    <div className="input-wrapper">
                        <label>Services Requested <span className="mandatory-stars">*</span></label>
                        <Field name="selectedServiceIds" render={renderErrorMessage}>
                            <ReferralServices form={form} selectedServiceIds={selectedServiceIds} services={services} disabled={disabled} />
                        </Field>
                    </div>

                    <div className="input-wrapper">
                        <label>Legislation <span className="mandatory-stars">*</span></label>
                        <Field name="legislationId" render={renderGroupedDropdownList} options={legislationOptions} disabled={disabled} />
                    </div>

                    <div className="input-wrapper">
                        <label>Notes</label>
                        <Field name="referralNote" type="text" render={renderTextArea} placeholder="" disabled={disabled} />
                    </div>

                </div>
            </div>
        </div>


    )
}


export default ReferralFormFields;
