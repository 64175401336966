import React from 'react';
import { Field } from "react-final-form";
import { renderDatePicker } from "../../../../form-inputs/final-form";

export default ({ values, highlights, disabled }) => {

    if (!values.familyReturnsUnitInvolvementId)
        return null;

    highlights = highlights || {};

    return (
        <>
            <div className="input-wrapper">
                <Field
                    name="familyReturnsUnitDateOfAction"
                    type="text"
                    render={renderDatePicker}
                    label="Date of action"
                    placeholder=""
                    isHighlighted={highlights.familyReturnsUnitDateOfAction}
                    disabled={disabled}
                />
            </div>
        </>
    )
}