import React from 'react';

import TableHead from './table-head';
import TableBody from './table-body';

export default ({getTableProps, getTableBodyProps, headerGroups, page, prepareRow, Row, ExpandableDetails}) => (
    <div {...getTableProps()} className="table">
      <TableHead options={{headerGroups}}/>
      <TableBody options={{page, prepareRow, getTableBodyProps, Row, ExpandableDetails}}/>
    </div>
)