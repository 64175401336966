import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import { onLookupByNino, onLookupByDocument } from '../../api-endpoint-calls/rtpf-lookup';

import RtpfLookupForm from './form';
import Results from './results';
import Error from './error';

//import defaultResult from './test-data';

const ImmigrationStatus = () => {

    const { lookupType } = useParams();

    const [result, setResult] = useState(null);
    const [error, setError] = useState(null);
    const [usedFormValues, setUsedFormValues] = useState({});
    const [lookupPerformed, setLookupPerformed] = useState(false);


    const initialValues = {
        lookupType: lookupType || ''
    };

    const onSubmit = async (formValues) => {

        setResult(null);
        setError(null);

        const source = axios.CancelToken.source();

        var lookup = (formValues.lookupType === 'document')
            ? await onLookupByDocument(formValues, source)
            : await onLookupByNino(formValues, source);

        setResult(lookup.result);
        setUsedFormValues(formValues);
        setError(lookup.error || '');
        setLookupPerformed(true);

        return {};
    }

    const onReset = () => {
        setResult(null);
        setError(null);
    }

    return (
        <div className="stripedbox react-rtpf-lookup-page">
            <RtpfLookupForm onSubmit={onSubmit} onReset={onReset} initialValues={initialValues} result={result} lookupPerformed={lookupPerformed} />
            <Error message={error} />
            <Results result={result} onReset={onReset} lookupValues={usedFormValues} />
        </div>
    );
}

export default ImmigrationStatus;
