import React from 'react';
import moment from 'moment';

const Message = ({details}) => (
	<div className={`message-row ${details.isReceived ? 'received' : 'sent'}`}>
		<div className="message">
			<span className="name">{details.name} :</span> {details.message}
			<span className="name team-name">{details.teamName}</span>
			<span className="creation-date font12">{moment(details.creationDate).format('DD/MM/YYYY HH:mm')}</span>
		</div>
	</div>
)

export default ({messages, userIsHomeOffice}) => (
	<div className={`rounded-box queries ${userIsHomeOffice ? 'ukba' : ''}`}>
		{ messages.map((item) => <Message key={item.creationDate} details={item} />) }
	</div>
)