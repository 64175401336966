import React from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

const Aliases = ({aliases, disabled, onEditAliasModalRequest, onDeleteAliasModalRequest, applicantName, applicantDateOfBirth}) => {
    
    const onEditClick = (aliasId) => {
        onEditAliasModalRequest(aliasId, applicantName, applicantDateOfBirth);
    }

    const onDeleteClick = (aliasId) => {
        onDeleteAliasModalRequest(aliasId);
    }

    const addButton = !disabled
        ? <button type="button" onClick={() => onEditClick(0)} className="rbtn primary small">Add Alias</button>
        : null;

    return (
        <>
            <div className="header-with-button">
                <h2>Aliases</h2>
                { addButton }
            </div>

            <div className="aliases">
                {
                    aliases?.records.map(item => {

                        const deleteButton = !disabled
                            ?
                                <button type="button" className="rbtn light-grey" onClick={() => onDeleteClick(item.id)}>
                                    <span><FontAwesomeIcon icon={faTrash} /></span>
                                </button>
                            : null

                        return (
                            <div className={`alias ${item.isHighlightedForAlert ? "is-highlighted" : ""}`} key={item.id}>
                                <div className="header">
                                    <span className="alias-description">
                                        {item.name}
                                    </span>
                                    <div className="alias-buttons">
                                        <button type="button" className="rbtn light-grey" onClick={() => onEditClick(item.id)}>
                                            <span><FontAwesomeIcon icon={faEdit} /></span>
                                        </button>
                                        { deleteButton }
                                    </div>
                                </div>
                                <span className="alias-user-details">
                                    {item.uploadedDetails}
                                </span>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}


export default Aliases;
