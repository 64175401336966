import React, { useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import * as actions from '../../../state/actions/case/address-list-actions';
import { selector } from '../../../state/reducers/case/address-reducer';

import TableResults from '../../react-table/table-results';
import NoResults from './list/no-results';
import { caseId, isClosed, userIsLocalAuthority, caseArea, hasAddresses } from '../server-values';
import { switchComponent, LoadingV1 } from "../../common";

import {localAuthorityColumns, homeOfficeColumns} from './list/table-columns';
import Row from './list/table-row';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'

const Results = () => {

    const results = useSelector(state => selector.resultValues(state));
    const pageSize = useSelector(state => selector.pageSize(state));
    const pageIndex = useSelector(state => selector.pageIndex(state));
    const sortBy = useSelector(state => selector.sortBy(state));
    const filters = useSelector(state => selector.filters(state));

    return (
        <div className="react-table address-history">
            <TableResults 
                columns={userIsLocalAuthority ? localAuthorityColumns : homeOfficeColumns} 
                data={results} 
                itemsPerPage={pageSize} 
                pageNumber={pageIndex} 
                sortBy={sortBy}
                filters={filters}
                Row={Row} 
                hidePagination={true} />
        </div>
    )
}

const TableWrapperSwitch = (props) => switchComponent(props)(Results, NoResults, () => props.hasResults);
const TableWrapperWithLoading = (props) => switchComponent(props)(LoadingV1, TableWrapperSwitch, () => props.loading);

export default () => {

    const dispatch = useDispatch();
    const history = useHistory();

    if (userIsLocalAuthority && !hasAddresses) {
        history.push(`/${caseArea}/Address/Create?CaseId=${caseId}`);
        return null;
    }

    const hasResults = useSelector(state => selector.hasResults(state));
    const loading = useSelector(state => selector.loading(state));

    useEffect(() => {
        dispatch(actions.loadAddresses(caseId));
    }, [dispatch])


    const addAddressButton = userIsLocalAuthority && !isClosed
        ? (
            /*
            <Link to={`/${caseArea}/Address/Create?CaseId=${caseId}`} className="rbtn primary right">
                <FontAwesomeIcon icon={faPlusCircle} />
                <span>Add New Address</span>
            </Link>
            */

            <a href={`/${caseArea}/Address/Create?CaseId=${caseId}`} title="add new address" className="rbtn primary right">
                <FontAwesomeIcon icon={faPlusCircle} />
                <span>Add New Address</span>
            </a>
        )
        : null;

    return (
        <>
            <div className="stripedbox">
                { addAddressButton }
                <h2>Saved Addresses</h2>

                <TableWrapperWithLoading hasResults={hasResults} loading={loading} />
            </div>
        </>
    )
};
