import React from 'react';

export const SimpleLoadingGif = () => {
    return (
        <div className="simple-loading-gif-container">
            <img alt="searching" src="/images/simple-loading.gif" />
        </div>
    );
};

export const SearchingGif = ({className = '', message="Loading . . ."}) => {
    return (
        <div className={`searching-loader-1 ${className}`}>
            <span>{message}</span>
            <div className="img-container">
                <img alt="searching" src="/images/searching.gif" />
            </div>
        </div>
    );
};

export const LoadingV1 = ({...rest}) => {
    return (
        <SearchingGif {...rest}/>
    );
};

export const HomePageCountLoadingIcon = () => (
    <svg style={{margin: "auto", background: "none", display: "block", shapeRendering: "auto"}} width="20px" height="26px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <circle cx="50" cy="50" r="36" strokeWidth="8" stroke="#fff" strokeDasharray="50 1000" fill="none" strokeLinecap="round">
            <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1.5s" keyTimes="0;1" values="0 50 50;360 50 50"></animateTransform>
        </circle>
    </svg>
)