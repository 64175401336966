import React, { useState, useCallback, useMemo } from 'react';

import AddNote from './add-from-widget';
import NoteHistory from './history';

export default () => {

    const [item, setItem] = useState(null);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showHistoryModal, setShowHistoryModal] = useState(false);

    const onAddModalRequest = useCallback(() => {
        setShowAddModal(true);
    }, [setShowAddModal]);

    const onAddModalClose = useCallback((item) => {
        setShowAddModal(false);
        if (item) {
            setItem(item);
        }
    }, [setItem, setShowAddModal]);

    const onHistoryModalRequest = useCallback(() => {
        setShowHistoryModal(true);
    }, [setShowHistoryModal]);

    const onHistoryModalClose = useCallback(() => {
        setShowHistoryModal(false);
    }, [setShowHistoryModal]);

    const addModal = useMemo(() => 
        showAddModal 
            ? <AddNote note={item} onClose={onAddModalClose} /> 
            : null
    , [item, showAddModal, onAddModalClose]);

    const historyModal = useMemo(() => 
        showHistoryModal 
            ? <NoteHistory note={item} onClose={onHistoryModalClose} /> 
            : null
    , [item, showHistoryModal, onHistoryModalClose]);

    return {
        item,
        setItem,
        onAddModalRequest,
        onHistoryModalRequest,
        addModal,
        historyModal,
    }
}