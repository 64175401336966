import React from 'react';

export default ({statuses}) => {

    return (
        <div className="performing-lookup-modal">
            <h2>Performing look up....</h2>
            <ul>
                {statuses.map((item, index) => (
                    <li key={`lookup_${index}`} className="lookup-result-row">
                        {item}
                    </li>
                ))}
            </ul>
        </div>
    )
}