import React from "react";

const ReferralServices = ({form, selectedServiceIds, services, disabled}) => {
    
    selectedServiceIds = selectedServiceIds || [];

    const toggleSelectedServiceCheckbox = (e) => {

        const currentOptions = [ ...selectedServiceIds ];
        const thisValue = parseInt(e.target.value);

        const exists = currentOptions.includes(thisValue);

        if (exists) {
            currentOptions.splice(currentOptions.indexOf(thisValue), 1);
        } else {
            currentOptions.push(thisValue);
        }

        form.change('selectedServiceIds', currentOptions);
    }

    return (
        <ul className="referral-services">
            {
                services.map(item => {

                    const ig_id = `referral-service-${item.value}`;
                    const ig_value = parseInt(item.value);
                    const ig_isChecked = selectedServiceIds.includes(ig_value);

                    return (
                        <li key={ig_id}>
                            <div className="input-wrapper">
                                <input id={ig_id} type="checkbox" checked={ig_isChecked} disabled={disabled} className="regular-checkbox" onChange={toggleSelectedServiceCheckbox} value={ig_value} />
                                <label htmlFor={ig_id} className="regular-checkbox-box"></label>
                                <label htmlFor={ig_id} className="regular-checkbox-label">{item.label}</label>
                            </div>
                        </li>
                    )
                })

            }
        </ul>
    )
}


export default ReferralServices;
