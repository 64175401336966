import * as actions from '../../actions/case/summary-actions';


export const initialState = {
    caseAlerts: {
        activeAlerts: [],
        hasActiveAlerts: false,
        isShowingToLocalAuthorityUser: true,
        applicantIsChildOrCareLeaver: false,
        ukbaOutcome: "",
        endDate: "",
        closureReason: ""
    },
    dependants: [],
    localAuthorityCaseWorker: {},
    homeOfficeCaseWorker: {},
    isMyCase: false,
    queries: {
        rows: []
    },
    isLoaded: false,
    rtpfLookupDetails: {},
    showRtpfModal: false
}

const summaryReducer = (state, action) => {
    switch (action.type) {
        case actions.UPDATE:
            return {
                ...state,
                ...action.data,
                isLoaded: true
            };
        case actions.UPDATE_LACW:
            return {
                ...state,
                localAuthorityCaseWorker: action.data,
                isMyCase: action.data.isMe || state.homeOfficeCaseWorker.isMe,
            };
        case actions.UPDATE_HOCW:
            return {
                ...state,
                homeOfficeCaseWorker: action.data,
                isMyCase: action.data.isMe || state.localAuthorityCaseWorker.isMe,
            };
        case actions.UPDATE_ALERTS:
            return {
                ...state,
                caseAlerts: action.data
            }
        case actions.UPDATE_PHOTO:
            return {
                ...state,
                photoFileName: action.data
            }
        case actions.TOGGLE_RTPF_MODAL:
            return {
                ...state,
                showRtpfModal: !state.showRtpfModal
            }
        default:
            return state;
    }
}

export default summaryReducer;