import { onFormSubmitAsync } from '../form-submit';

import fileToBase64 from '../../extensions/string.fileToBase64';

const baseUrl = '/api/case/applicant/image';

export const onUpdateSubmitAsync = async (formValues) => {
    const serverModel = await mappers.toServerModel(formValues);
    return await onFormSubmitAsync(baseUrl, serverModel);
}

const mappers = {
    toReduxForm: (values) => ({
        ...values
    }),
    toServerModel: async (values) => {
        
        if (values.file) {
            values.fileName = values.file.name;
            values.fileContents = await fileToBase64(values.file);

            delete values.file;
        }

        return {
            ...values
        }
    }
}


export default {
    onUpdateSubmitAsync
}