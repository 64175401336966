import * as actions from '../actions/home-actions';

const initialState = {
  activityCaseload: {
    newCasesSupported: 0,
    openFinanciallySupported: 0,
    openNotFinanciallySupported: 0,
    openThousandDayCases: 0,
    referrals: 0
  },
  queryCaseload: {
    caseClosedAlert: 0,
    closureRequiredAlert: 0,
    hoReplies: 0,
    imigrationUpdateAlert: 0,
    laReplies: 0,
    laUpdateAlert: 0,
    newCaseAlert: 0,
    newHOQueries: 0,
    newLAQueries: 0,
    addressChangedAlert: 0
  },
  loading: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.SEARCH_START:
      return {
        ...state,
        loading: true
      };
    case actions.SEARCH_SUCCESS:
      return { 
        ...state, 
        activityCaseload: { ...state.activityCaseload, ...action.data.activityCaseload},
        queryCaseload: { ...state.queryCaseload, ...action.data.queryCaseload},
        loading: false 
      };
    case actions.SEARCH_FAILED:
      return { 
        ...state, 
        loading: false 
      };
    default:
        return state;
  }
}


export const selector = {
  loading: (state) => state.home.loading,
  queryCaseload: (state) => state.home.queryCaseload,
  activityCaseload: (state) => state.home.activityCaseload,
};
