import React from 'react';
import { Form } from 'react-final-form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

const DeleteForm = ({onSubmit, onClose, initialValues}) => {
    
    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            render={({ handleSubmit, submitting }) => {

                return (
                    <form onSubmit={handleSubmit}>
                        <div className="stripedbox">
                            <p>Are you sure you want to delete this address and any associated finance?</p>

                            <div className="button-row p-15">
                                <button onClick={onClose} className="rbtn secondary" disabled={submitting}><FontAwesomeIcon icon={faTimesCircle} /><span>Cancel</span></button>
                                <button type="submit" className="rbtn primary" disabled={submitting}><FontAwesomeIcon icon={faTrashAlt} /><span>Delete</span></button>
                            </div>
                        </div>
                    </form>
                );

            }}
        />
    );
}

export default DeleteForm;
