import React from 'react';
import { Field, Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';

import { renderInput } from '../../../form-inputs/final-form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faArrowLeft } from '@fortawesome/free-solid-svg-icons';


const ResetPasswordForm = ({onSubmit, initialValues}) => {

    const history = useHistory();

    return (
        
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            render={({handleSubmit, submitting, form}) => (

                <form
                    autoComplete="off"
                    onSubmit={(event) => {
                        handleSubmit(event).then(() => form.reset());
                    }}>

                    <div className="mandatory"><span>*</span> Denotes a mandatory field </div>

                    <div className="user-edit-form">
                        
                        <div className="input-wrapper">
                            <Field name="password" type="password" render={renderInput} label="New Password" isMandatory={true} tabIndex={1} style={{marginLeft:'initial'}} />
                        </div>
                        <div className="input-wrapper">
                            <Field name="confirmPassword" type="password" render={renderInput} label="Confirm Password" isMandatory={true} tabIndex={2} style={{marginLeft:'initial'}} />
                        </div>

                    </div>

                    <hr />
                    
                    <div className="user-edit-form">
                        <div className="input-wrapper">
                            <label>Current Password <span className="mandatory-stars">*</span></label>
                            <Field name="currentPassword" type="password" render={renderInput} isMandatory={true} tabIndex={3} />
                        </div>
                    </div>

                    <hr />

                    <div className="advise-box">
                        <p>Passwords must meet the following minimum requirements:</p>
                        <ul>
                            <li>Be at least twelve characters in length</li>
                            <li>Not be the same as the last ten passwords used</li>
                            <li>
                                Contain characters from three of the following four categories:
                                <ul>
                                    <li>English uppercase characters (A through Z)</li>
                                    <li>English lowercase characters (a through z)</li>
                                    <li>Base 10 digits (0 through 9)</li>
                                    <li>Non-alphabetic characters (for example, !, $, #, %)</li>
                                </ul>
                            </li>
                        </ul>
                    </div>

                    <hr />

                    <div className="user-edit-form">
                        <div className="full-row align-center">
                            <div className="form-buttons">                                
                                <button type="button" onClick={() => history.go(-1)} className="rbtn secondary" tabIndex={5} disabled={submitting}>
                                    <FontAwesomeIcon icon={faArrowLeft} /><div>Back</div>
                                </button>
                                <button type="submit" className="rbtn primary" style={{minWidth:"190px"}} tabIndex={4} disabled={submitting}>
                                    <FontAwesomeIcon icon={faKey} style={{maxWidth:"20%"}} /><div>Change Password</div>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            )}
        />
    );
}

export default ResetPasswordForm;
