import React from "react";

const ImpairmentGroups = ({form, selectedHealthConditionIds, impairmentGroups, disabled}) => {
    
    selectedHealthConditionIds = selectedHealthConditionIds || [];

    const toggleSelectedHealthConditionCheckbox = (e) => {

        const currentOptions = [ ...selectedHealthConditionIds ];
        const thisValue = parseInt(e.target.value);

        const exists = currentOptions.includes(thisValue);

        if (exists) {
            currentOptions.splice(currentOptions.indexOf(thisValue), 1);
        } else {
            currentOptions.push(thisValue);
        }

        form.change('selectedHealthConditionIds', currentOptions);
    }

    return (
        <>
            <h2>Which impairment group(s) does the person belong to?</h2>

            <ul className="impairment-groups">
                {
                    impairmentGroups.map(item => {

                        const ig_id = `impairment-group-${item.value}`;
                        const ig_value = parseInt(item.value);
                        const ig_isChecked = selectedHealthConditionIds.includes(ig_value);

                        return (
                            <li key={ig_id}>
                                <div className="input-wrapper">
                                    <input id={ig_id} type="checkbox" checked={ig_isChecked} disabled={disabled} className="regular-checkbox" onChange={toggleSelectedHealthConditionCheckbox} value={ig_value} />
                                    <label htmlFor={ig_id} className="regular-checkbox-box"></label>
                                    <label htmlFor={ig_id} className="regular-checkbox-label">{item.label}</label>
                                </div>
                            </li>
                        )
                    })

                }
            </ul>
        </>
    )
}


export default ImpairmentGroups;
