import React from 'react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

export default ({ summaries }) => {

    const onViewCase = (caseId) => {
        window.location = `/CaseOwner/Summary/Display?iCaseID=${caseId}`;
    }

    return (
        <div className="duplicate-case-step">
            <div className="stripedbox page-header">
                <h2>1 / 2 : Add Principal Applicant details (lead adult)</h2>
                <div className="mandatory-text"><span>*</span> Denotes a mandatory field </div>
            </div>
            <div className="stripedbox">
                <h2 className="page-description">Does this case already exist?</h2>
                <p style={{padding: "0 10px"}}>Potential duplicate cases displayed below: select <b>next</b> to create case or <b>use this record</b> to end sequence.</p>

                {summaries.map((summary) =>
                    <div className="duplicate-case-list-item" key={`duplicate_summary_${summary.caseId}`}>
                        <div className="case-details-left">
                            <div className="case-details-flex-row">
                                <label>
                                    Principal Applicant
                                </label>
                                <div className="fake-input">
                                    {summary.name}
                                </div>
                            </div>

                            <div className="case-details-grid-row">
                                <label>
                                    Date of birth
                                </label>
                                <div className="fake-input">
                                    {summary.dateOfBirth}
                                </div>

                                <label>
                                    Nationality
                                </label>
                                <div className="fake-input">
                                    {summary.nationality}
                                </div>

                                <label>
                                    Connect Case Id
                                </label>
                                <div className="fake-input">
                                    {summary.caseId}
                                </div>

                                <label>
                                    Case Worker
                                </label>
                                <div className="fake-input">
                                    {summary.localAuthorityCaseWorker}
                                </div>
                            </div>

                            <div className="case-details-flex-row">
                                <label>
                                    Aliases
                                </label>
                                <div className="fake-input">
                                    {summary.aliases}
                                </div>
                            </div>

                            <div className="case-details-flex-row">
                                <label>
                                    Dependants
                                </label>
                                <div className="fake-input">
                                    {summary.dependants}
                                </div>
                            </div>

                            <div className="case-details-flex-row">
                                <label>
                                    Address
                                </label>
                                <div className="fake-input">
                                    {summary.address}
                                </div>
                            </div>
                        </div>
                        <div className="case-details-right">
                            <div className="photo-image">
                                <img src={`/Images/Applicant/${summary.photoFileName}`} alt={summary.name} title={summary.name} />
                            </div>
                            <button type="button" className="rbtn primary" onClick={() => onViewCase(summary.caseId)}>
                                <FontAwesomeIcon icon={faArrowRight} />
                                <span>Use this record</span>
                            </button>
                        </div>

                    </div>
                )}
            </div>
        </div>
    )
}