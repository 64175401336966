import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selector } from '../../../../../../state/reducers/superuser/users-reducer';
import * as actions from '../../../../../../state/actions/superuser/users-list-actions';

import Form from './form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserSlash, faTimes } from '@fortawesome/free-solid-svg-icons';

export default ({onClose, userId, organisation}) => {

    const [visible, setVisible] = useState(false);

    const dispatch = useDispatch();
    useEffect(() => {
        if (visible === false) {
            setTimeout(() =>  setVisible(true), 200);
            dispatch(actions.deleteUserInitialise(userId));
        }
    }, [visible, setVisible, dispatch, userId]);

    const onSubmit = (formValues) => {
        return dispatch(actions.deleteUser(formValues));
    }

    let {completed, successful} = useSelector(state => selector.userDelete(state));
    const replacementUsers = useSelector(state => selector.organisationUsers(organisation, userId)(state));

    if (completed && successful)
        return null;

    return (
        <>
            <div className={ `react-modal-anchor ${(visible ? 'show' : '')}`}>
                <div className={ `react-modal user-delete ${visible ? 'show' : ''}` } style={{ width: '500px' }}>
                    <button onClick={onClose} className="close-button"><FontAwesomeIcon icon={faTimes} /></button>
                    <h1>
                        <FontAwesomeIcon icon={faUserSlash} />
                        Delete User?
                    </h1>

                    <Form onSubmit={onSubmit} onClose={onClose} replacementUsers={replacementUsers} />
                </div>
            </div>
            <div className={ `react-modal-backdrop ${visible ? 'show' : ''}` }></div>
        </>
    );
    
}