import React from 'react';
import { useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIdCard, faUserTag } from '@fortawesome/free-solid-svg-icons';

const Form = () => {

    const history = useHistory()

    const onLookupByDocumentClick = () => {
        history.push('/rtpf-lookup/document');
    }
    const onLookupByNinoClick = () => {
        history.push('/rtpf-lookup/nino');
    }

    return (
        <div>
            <p className="form-description">Digital Immigration status only</p>

            <div className="lookup-choice">
                <button type="button" className="rbtn primary" onClick={onLookupByDocumentClick}>
                    <FontAwesomeIcon icon={faIdCard} />
                    <span>Lookup by Identity Document</span>
                </button>
                <button type="button" className="rbtn primary" onClick={onLookupByNinoClick}>
                    <FontAwesomeIcon icon={faUserTag} />
                    <span>Lookup by National Insurance Number</span>
                </button>
            </div>
        </div>
    );
}


export default Form;
