import React from 'react';

const NoResults = () => (
    <div className="react-table mt-20">
        <div className="stripedbox">
            <div className="pagination">
                <div className="total-records">
                    <span>Displaying </span> <span className="bold">0 results</span>
                </div>
            </div>
            <div className="table" style={{minWidth: "939px"}}>
                <div className="thead">
                    <div className="tr" style={{display: "flex", flex: "1 1 100%"}}>
                        <span className="th" style={{flex: "1 1 100%", padding: "0px"}}></span>
                    </div>
                </div>
                <div className="tbody">
                    <div role="row" className="tr odd">
                        <div className="result-row with-status" style={{display:"flex"}}>
                            <span role="cell" style={{flex: "1 1 100%", textAlign: "center"}}>
                                No results have been found
                            </span>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default NoResults;