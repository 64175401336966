import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faAddressCard } from '@fortawesome/free-solid-svg-icons';

import useSequentialLookUps from './use-sequential-look-ups';

import LookUpNinoStatus from './look-up-nino-status';
import LookUpDocumentStatus from './look-up-document-status';
import PerformingLookup from './performing-lookup';
import Result from './result'

//var defaultResult = {"fullName":"John Smith","dateOfBirth":"15/10/1971","nationality":"ESP","statuses":[]};
//var defaultResult = { "fullName": "John Smith", "dateOfBirth": "15/10/1971", "nationality": "ESP", "statuses": [{ "statusStartDate": "08/10/2021", "statusEndDate": null, "productType": "EUS", "immigrationStatus": "POST_GRACE_PERIOD_COA_GRANT", "noRecourseToPublicFunds": false }] };
//var defaultResult = { "fullName": "John Smith", "dateOfBirth": "15/10/1971", "nationality": "ESP", "statuses": [{ "statusStartDate": "08/10/2021", "statusEndDate": null, "productType": "EUS", "immigrationStatus": "POST_GRACE_PERIOD_COA_GRANT", "noRecourseToPublicFunds": true }] };
//var defaultResult = { "fullName": "John Smith", "dateOfBirth": "15/10/1971", "nationality": "ESP", "statuses": [{ "statusStartDate": "08/10/2021", "statusEndDate": null, "productType": "EUS", "immigrationStatus": "POST_GRACE_PERIOD_COA_GRANT", "noRecourseToPublicFunds": false }, { "statusStartDate": "08/10/2021", "statusEndDate": "01/08/2023", "productType": "EUS", "immigrationStatus": "POST_GRACE_PERIOD_COA_GRANT", "noRecourseToPublicFunds": false, "isExpired": true }] };
//var defaultResult = { "fullName": "John Smith", "dateOfBirth": "15/10/1971", "nationality": "ESP", "statuses": [{ "statusStartDate": "08/10/2021", "statusEndDate": null, "productType": "EUS", "immigrationStatus": "POST_GRACE_PERIOD_COA_GRANT", "noRecourseToPublicFunds": true }, { "statusStartDate": "08/10/2021", "statusEndDate": "01/08/2023", "productType": "EUS", "immigrationStatus": "POST_GRACE_PERIOD_COA_GRANT", "noRecourseToPublicFunds": false, "isExpired": true }] };
//var defaultError = "Can not find the person associated with the provided details";

//const result = defaultResult

export default ({ onClose, values }) => {

    const { caseId, forename, surname, dateOfBirth, nationalityCode, brpNumber, brcNumber, passportNumber, euIdentityCardNumber, ninoNumber, autoUpdate = false } = values
    const [visible, setVisible] = useState(false);
    const {
        lookUpStatuses,
        result
    } = useSequentialLookUps([
        (id, onSuccess, onFailure) => <LookUpNinoStatus id={id} label="By Nino" onNoResultFound={onFailure} onResultFound={onSuccess} caseId={caseId} ninoNumber={ninoNumber} forename={forename} surname={surname} dateOfBirth={dateOfBirth} autoUpdate={autoUpdate} />,
        (id, onSuccess, onFailure) => <LookUpDocumentStatus id={id} label="By Passport" onNoResultFound={onFailure} onResultFound={onSuccess} caseId={caseId} nationality={nationalityCode} documentType="PASSPORT" documentReference={passportNumber} dateOfBirth={dateOfBirth} autoUpdate={autoUpdate} />,
        (id, onSuccess, onFailure) => <LookUpDocumentStatus id={id} label="By BRP" onNoResultFound={onFailure} onResultFound={onSuccess} caseId={caseId} nationality={nationalityCode} documentType="BRP" documentReference={brpNumber} dateOfBirth={dateOfBirth} autoUpdate={autoUpdate} />,
        (id, onSuccess, onFailure) => <LookUpDocumentStatus id={id} label="By BRC" onNoResultFound={onFailure} onResultFound={onSuccess} caseId={caseId} nationality={nationalityCode} documentType="BRC" documentReference={brcNumber} dateOfBirth={dateOfBirth} autoUpdate={autoUpdate} />,
        (id, onSuccess, onFailure) => <LookUpDocumentStatus id={id} label="By European national identity card" onNoResultFound={onFailure} onResultFound={onSuccess} caseId={caseId} nationality={nationalityCode} documentType="NAT" documentReference={euIdentityCardNumber} dateOfBirth={dateOfBirth} autoUpdate={autoUpdate} />,
    ])

    useEffect(() => {
        if (visible === false) {
            setTimeout(() => setVisible(true), 200);
        }
    }, [visible, setVisible]);


    const onCloseModal = () => {

        if (result && result.statuses.length > 0) {
            const latest = result.statuses[0];

            const rtpfResult = {
                isExpired: latest.isExpired,
                status: latest.isExpired ? '' : latest.status,
                description: latest.isExpired ? '' : latest.description,
                immigrationStatusId: latest.immigrationStatusId,
                statusStartDate: latest.isExpired ? '' : latest.statusStartDate,
                statusEndDate: latest.isExpired ? '' : latest.statusEndDate,
            };

            onClose(rtpfResult);

        } else {
            onClose();
        }
    }


    const currentPage = result 
        ? <Result result={result} /> 
        : <PerformingLookup statuses={lookUpStatuses} />

    const modalWidth = result
        ? "900px"
        : "600px";

    return (
        <>
            <div className={`react-modal-anchor ${(visible ? 'show' : '')}`}>
                <div className={`react-modal reset-password ${visible ? 'show' : ''}`} style={{ width: modalWidth }}>
                    <button onClick={onCloseModal} className="close-button"><FontAwesomeIcon icon={faTimes} /></button>
                    <h1>
                        <FontAwesomeIcon icon={faAddressCard} />
                        Recourse To Public Funds Checker
                    </h1>

                    <div className="react-rtpf-lookup-modal stripedbox">
                        {currentPage}
                    </div>
                </div>
            </div>
            <div className={`react-modal-backdrop ${visible ? 'show' : ''}`}></div>
        </>
    );

}