import axios from 'axios';
import moment from 'moment';

import { onFormSubmitAsync } from '../form-submit';

import dateFormatForServer from '../../extensions/date.formatForServer';

const baseUrl = '/api/case/applicant';
export const urls = {
    addSubmit: `${baseUrl}/add`,
    updateSubmit: `${baseUrl}/edit`,
    delete: `${baseUrl}/delete`
}


export const onLoadAsync = async (caseId, source) => {
    try {

        const response = await axios.get(`${baseUrl}/${caseId}`, { cancelToken: source.token });
        const values = response.data || {};

        return {
            isLoaded: true,
            isSuccessful: true,
            initialValues: mappers.toReduxForm(values)
        };
    }
    catch (err) {
        return {
            isLoaded: true,
            isSuccessful: false
        }
    }
}


export const onUpdateSubmitAsync = async (formValues) => {
    const serverModel = mappers.toServerModel(formValues);
    return await onFormSubmitAsync(urls.updateSubmit, serverModel);
}


const mappers = {
    toReduxForm: (values) => ({
        ...values,
        dateOfBirth: values.dateOfBirth ? moment(values.dateOfBirth)._d : null,
        dateOfDeath: values.dateOfDeath ? moment(values.dateOfDeath)._d : null,
    }),
    toServerModel: (values) => ({
        ...values,
        dateOfBirth: dateFormatForServer(values.dateOfBirth),
        dateOfDeath: dateFormatForServer(values.dateOfDeath)
    })
}


export default {
    onLoadAsync,
    onUpdateSubmitAsync
}