import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faTimes } from '@fortawesome/free-solid-svg-icons';

export default ({onClose, name, telephone, email}) => {

    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (visible === false) {
            setTimeout(() =>  setVisible(true), 200);
        }
    }, [visible, setVisible]);

    return (
        <>
            <div className={ `react-modal-anchor ${(visible ? 'show' : '')}`}>
                <div className={ `react-modal reset-password ${visible ? 'show' : ''}` } style={{ width: "450px" }}>
                    <button onClick={onClose} className="close-button"><FontAwesomeIcon icon={faTimes} /></button>
                    <h1>
                        <FontAwesomeIcon icon={faUser} />
                        Caseworker Details
                    </h1>

                    <div className="stripedbox caseworker-details-modal">
                        <div className="label">
                            Full Name:
                        </div>
                        <div className="nontextbox">
                            { name }
                        </div>
                        <div className="label">
                            Telephone:
                        </div>
                        <div className="nontextbox">
                            { telephone }
                        </div>
                        <div className="label">
                            Email:
                        </div>
                        <div className="nontextbox">
                            { email }
                        </div>
                    </div>
                </div>
            </div>
            <div className={ `react-modal-backdrop ${visible ? 'show' : ''}` }></div>
        </>
    );
    
}