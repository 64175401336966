import { useState, useEffect } from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';
import {HttpTransportType} from '@microsoft/signalr';
import store from '../../../state/stores/index';
import * as searchActions from '../../../state/actions/search-actions';
import * as queriesActions from '../../../state/actions/queries-actions';

const CasesHub = () => {
    const [ connection, setConnection ] = useState(null);

    useEffect(() => {
        const newConnection = new HubConnectionBuilder()
            .withUrl('/hubs/cases', {
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets
            })
            .withAutomaticReconnect()
            .build();
        
        setConnection(newConnection);
    }, []);

    useEffect(() => {
        if (connection) {
            connection.start()
                .then(_ => {
                    connection.on('ReceiveCasesBeingViewed', message => {
                        store.dispatch({type: searchActions.UPDATE_ISBEINGVIEWED, data: message.data });
                        store.dispatch({type: queriesActions.UPDATE_ISBEINGVIEWED, data: message.data });
                    });

                    connection.on('ReceiveUserId', message => {
                        store.dispatch({type: searchActions.UPDATE_USERID, data: message.data });
                        store.dispatch({type: queriesActions.UPDATE_USERID, data: message.data });
                    })

                    connection.send("Connect");
                })
                .catch(e => console.log('Connection failed: ', e));
        }
    }, [connection]);

    return null;
};

export default CasesHub;