import React, { useEffect, useRef } from "react";
import { Field, Form } from "react-final-form";

import {
    renderDropdownList,
    renderDatePicker,
    renderRadioOption,
    toIntOptions
} from "../../../form-inputs/final-form";

import RtpfLookup from "./sections/rtpf-lookup";
import ClaimedAsylum from "./sections/claimed-asylum";
import ImmigrationApplication from "./sections/immigration-application";
import AsylumApplication from "./sections/asylum-application";
import VoluntaryReturnsService from "./sections/voluntary-returns-service";
import FamilyReturnsUnit from "./sections/family-returns-unit";

import NoteWidget from "../../notes/widget";
import QueriesWidget from "../../../queries/widget";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faFlagCheckered, faHistory } from "@fortawesome/free-solid-svg-icons";



const defaultOptions = {
    applicationOutcomes: [],
    applicationTypes: [],
    asylumEndedReasons: [],
    asylumOutcomes: [],
    asylumStatuses: [],
    asylumTypes: [],
    barrierToReturnReasons: [],
    fruInvolvements: [],
    immigrationStatuses: [],
    vrsTypes: [],
    vrsOutcomes: [],
    assistedReturns: [],
    ensuredReturns: [],
    requiredReturns: [],
    scheduled3Statuses: [],
    barrierToEnforceReturns: [],
    caseConclusions: [],
    solicitors: [],
    homeOfficeCaseWorkers: [],
    litOffices: [],
    fileOwners: []
};

const CaseOwnerForm = ({
    onSubmit,
    initialValues,
    isClosed,
    highlights,
    solicitors,
    fileOwners,
    localAuthorityNote,
    homeOfficeNote,
    queries,
    onEditSolicitorModalRequest,
    onAddLocalAuthorityNoteModalRequest,
    onLocalAuthorityNoteHistoryModalRequest,
    onAddHomeOfficeNoteModalRequest,
    onHomeOfficeNoteHistoryModalRequest,
    onAddQueryModalRequest,
    onViewQueryModalRequest,
    onHistoryModalRequest
}) => {

    const formSubmitButton = useRef();

    useEffect(() => {
        var { unsubscribe } = window.dispatcher.subscribe(
            "change-tracker-submit-form",
            () => formSubmitButton.current.click()
        );

        return () => {
            unsubscribe();
        };
    }, []);


    const {
        applicationOutcomes,
        applicationTypes,
        asylumEndedReasons,
        asylumOutcomes,
        asylumStatuses,
        asylumTypes,
        barrierToReturnReasons,
        fruInvolvements,
        immigrationStatuses,
        vrsTypes,
        vrsOutcomes,
        homeOfficeCaseWorkers,
        litOffices
    } = initialValues.options || defaultOptions;

    const applicationOutcomesOptions = toIntOptions(applicationOutcomes);
    const applicationTypesOptions = toIntOptions(applicationTypes);
    const asylumEndedReasonsOptions = toIntOptions(asylumEndedReasons);
    const asylumOutcomesOptions = toIntOptions(asylumOutcomes);
    const asylumStatusesOptions = toIntOptions(asylumStatuses);
    const asylumTypesOptions = toIntOptions(asylumTypes);
    const barrierToReturnReasonsOptions = toIntOptions(barrierToReturnReasons);
    const fruInvolvementsOptions = toIntOptions(fruInvolvements);
    const immigrationStatusesOptions = toIntOptions(immigrationStatuses);
    const vrsTypesOptions = toIntOptions(vrsTypes);
    const vrsOutcomesOptions = toIntOptions(vrsOutcomes);

    const homeOfficeCaseWorkersOptions = toIntOptions(homeOfficeCaseWorkers);
    const litOfficesOptions = toIntOptions(litOffices);
    const fileOwnersOptions = toIntOptions(fileOwners);
    const solicitorsOptions = toIntOptions(solicitors);

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            render={({ handleSubmit, submitting, form, values }) => {

                const disabled = submitting || isClosed;

                const pageHeader = values.isActiveImmigrationAlertId
                    ? (
                        <div className="stripedbox page-header alert-header is-highlighted">
                            <div>
                                <h2>Immigration Alert</h2>
                                <p>The Home Office has made a change to the immigration record below. Please confirm the changes by clicking accept.</p>
                            </div>
                            <button type="submit" className="rbtn primary" disabled={disabled}>
                                <FontAwesomeIcon icon={faCheck} />
                                <span>Accept</span>
                            </button>
                        </div>
                    )
                    : null

                return (
                    <form onSubmit={handleSubmit} rel="change-tracked">
                        <div className="immigration-edit-wrapper">

                            {pageHeader}

                            <div className="immigration-grid-wrapper">
                                <div className="stripedbox left-column">
                                    <div className="immigration-edit-form">

                                        <div className="header-with-flex">
                                            <h2>Immigration Status</h2>
                                            <div className="mandatory-text">
                                                <span>*</span> Denotes a mandatory field
                                            </div>
                                        </div>

                                        <div className="input-wrapper">
                                            <Field
                                                name="dateOfStatusCheck"
                                                type="text"
                                                render={renderDatePicker}
                                                isMandatory={true}
                                                label="Date of status check"
                                                placeholder=""
                                                isHighlighted={highlights.dateOfStatusCheck}
                                                disabled={disabled}
                                            />
                                        </div>
                                        <div className="input-wrapper-full-width">
                                            <label>Current Status <span className="mandatory-stars">*</span></label>
                                            <Field
                                                name="immigrationStatusId"
                                                type="text"
                                                render={renderDropdownList}
                                                options={immigrationStatusesOptions}
                                                isHighlighted={highlights.immigrationStatusId}
                                                disabled={disabled}
                                            />
                                        </div>

                                        <RtpfLookup
                                            form={form}
                                            values={values}
                                            disabled={disabled}
                                            userIsLocalAuthority={true}
                                        />

                                        <div className="input-wrapper">
                                            <Field
                                                name="dateEnteredUK"
                                                type="text"
                                                render={renderDatePicker}
                                                label="Date entered the UK"
                                                placeholder=""
                                                isHighlighted={highlights.dateEnteredUK}
                                                disabled={disabled}
                                            />
                                        </div>
                                        <div className="input-wrapper">
                                            <Field
                                                name="dateLeaveGranted"
                                                type="text"
                                                render={renderDatePicker}
                                                label="Date leave granted"
                                                placeholder=""
                                                isHighlighted={highlights.dateLeaveGranted}
                                                disabled={disabled}
                                            />
                                        </div>
                                        <div className="input-wrapper">
                                            <Field
                                                name="expiryDateOfLeave"
                                                type="text"
                                                render={renderDatePicker}
                                                label="Expiry date of leave"
                                                placeholder=""
                                                isHighlighted={highlights.expiryDateOfLeave}
                                                disabled={disabled}
                                            />
                                        </div>

                                        <div className="input-wrapper">
                                            <label>Foreign National Offender?</label>
                                            <div className={`radio-options ${highlights.isForeignNationalOffender ? 'is-highlighted' : ''}`}>
                                                <Field name="isForeignNationalOffender" type="radio" render={renderRadioOption} value="true" label="Yes" disabled={disabled} />
                                                <Field name="isForeignNationalOffender" type="radio" render={renderRadioOption} value="false" label="No" disabled={disabled} />
                                            </div>
                                        </div>


                                        <hr />

                                        <div className="input-wrapper section-header">
                                            <label>Claimed Asylum?</label>
                                            <div className={`radio-options ${highlights.isClaimedAsylum ? 'is-highlighted' : ''}`}>
                                                <Field name="isClaimedAsylum" type="radio" render={renderRadioOption} value="true" label="Yes" disabled={disabled} />
                                                <Field name="isClaimedAsylum" type="radio" render={renderRadioOption} value="false" label="No" disabled={disabled} />
                                            </div>
                                        </div>

                                        <ClaimedAsylum
                                            values={values}
                                            highlights={highlights}
                                            disabled={disabled}
                                            asylumOutcomesOptions={asylumOutcomesOptions}
                                        />

                                        <hr />


                                        <div className="input-wrapper section-header">
                                            <label>Immigration application?</label>
                                            <div className={`radio-options ${highlights.isImmigrationOrEeaApplication ? 'is-highlighted' : ''}`}>
                                                <Field name="isImmigrationOrEeaApplication" type="radio" render={renderRadioOption} value="true" label="Yes" disabled={disabled} />
                                                <Field name="isImmigrationOrEeaApplication" type="radio" render={renderRadioOption} value="false" label="No" disabled={disabled} />
                                            </div>
                                        </div>


                                        <ImmigrationApplication
                                            values={values}
                                            highlights={highlights}
                                            disabled={disabled}
                                            applicationTypesOptions={applicationTypesOptions}
                                            applicationOutcomesOptions={applicationOutcomesOptions}
                                        />


                                        <hr />


                                        <div className="input-wrapper section-header">
                                            <label>Appeal ongoing?</label>
                                            <div className={`radio-options ${highlights.isAppealOngoing ? 'is-highlighted' : ''}`}>
                                                <Field name="isAppealOngoing" type="radio" render={renderRadioOption} value="true" label="Yes" disabled={disabled} />
                                                <Field name="isAppealOngoing" type="radio" render={renderRadioOption} value="false" label="No" disabled={disabled} />
                                                <Field name="isAppealOngoing" type="radio" render={renderRadioOption} value="unknown" label="N/A" disabled={disabled} />
                                            </div>
                                        </div>

                                        <div className="input-wrapper">
                                            <Field
                                                name="dateOfAppealHearing"
                                                type="text"
                                                render={renderDatePicker}
                                                label="Date of appeal hearing"
                                                placeholder=""
                                                isHighlighted={highlights.dateOfAppealHearing}
                                                disabled={disabled}
                                            />
                                        </div>

                                        <div className="input-wrapper">
                                            <Field
                                                name="dateOfAppealRightsExhausted"
                                                type="text"
                                                render={renderDatePicker}
                                                label="Appeal Rights Exhausted date"
                                                placeholder=""
                                                isHighlighted={highlights.dateOfAppealRightsExhausted}
                                                disabled={disabled}
                                            />
                                        </div>


                                        <hr />


                                        <div className="header-with-button">
                                            <h2>Immigration Represenative Instructed</h2>
                                            <button
                                                type="button"
                                                onClick={() => onEditSolicitorModalRequest(0)}
                                                className="rbtn primary small"
                                            >
                                                Add Solicitor
                                            </button>
                                        </div>

                                        <div className="input-wrapper-with-options">
                                            <Field
                                                name="solicitorId"
                                                render={renderDropdownList}
                                                options={solicitorsOptions}
                                                isHighlighted={highlights.solicitorId}
                                            />
                                            {values.solicitorId > 0 ? (
                                                <div className="dropdown-options">
                                                    <button
                                                        type="button"
                                                        onClick={() =>
                                                            onEditSolicitorModalRequest(values.solicitorId)
                                                        }
                                                        className="rbtn primary small"
                                                    >
                                                        View / Edit
                                                    </button>
                                                </div>
                                            ) : null}
                                        </div>


                                        <hr />


                                        <h2>Local Authority Notes</h2>

                                        <NoteWidget
                                            note={localAuthorityNote}
                                            disabled={disabled}
                                            onAddModalRequest={onAddLocalAuthorityNoteModalRequest}
                                            onHistoryModalRequest={onLocalAuthorityNoteHistoryModalRequest}
                                        />
                                    </div>
                                </div>
                                <div className="right-column">
                                    <div className="stripedbox dark">
                                        <div className="immigration-edit-form">

                                            <h2>Home Office Involvements</h2>

                                            <div className="input-wrapper">
                                                <label>Home Office Team</label>
                                                <Field
                                                    name="litOfficeId"
                                                    type="text"
                                                    render={renderDropdownList}
                                                    options={litOfficesOptions}
                                                    isHighlighted={highlights.litOfficeId}
                                                    disabled={true}
                                                />
                                            </div>

                                            <div className="input-wrapper">
                                                <label>Home Office Allocated User</label>
                                                <Field
                                                    name="litCaseWorkerId"
                                                    type="text"
                                                    render={renderDropdownList}
                                                    options={homeOfficeCaseWorkersOptions}
                                                    isHighlighted={highlights.litCaseWorkerId}
                                                    disabled={true}
                                                />
                                            </div>

                                            <div className="input-wrapper">
                                                <label>Home Office File Ownership</label>
                                                <Field
                                                    name="fileOwnershipId"
                                                    type="text"
                                                    render={renderDropdownList}
                                                    options={fileOwnersOptions}
                                                    isHighlighted={highlights.fileOwnershipId}
                                                    disabled={true}
                                                />
                                            </div>


                                            <hr />


                                            <h2>Asylum Support</h2>

                                            <div className="input-wrapper-full-width">
                                                <label>Type of asylum support (last provided)</label>
                                                <Field
                                                    name="typeOfAsylumSupportId"
                                                    type="text"
                                                    render={renderDropdownList}
                                                    options={asylumTypesOptions}
                                                    isHighlighted={highlights.typeOfAsylumSupportId}
                                                    disabled={true}
                                                />
                                            </div>

                                            <AsylumApplication
                                                values={values}
                                                highlights={highlights}
                                                disabled={true}
                                                asylumStatusesOptions={asylumStatusesOptions}
                                                asylumEndedReasonsOptions={asylumEndedReasonsOptions}
                                            />


                                            <hr />


                                            <h2>Enforcement Action</h2>

                                            <div className="input-wrapper-full-width">
                                                <label>Barrier to return</label>
                                                <Field
                                                    name="enforcementBarrierToReturnId"
                                                    type="text"
                                                    render={renderDropdownList}
                                                    options={barrierToReturnReasonsOptions}
                                                    isHighlighted={highlights.enforcementBarrierToReturnId}
                                                    disabled={true}
                                                />
                                            </div>

                                            <div className="input-wrapper-long-question">
                                                <label>Has a removal decision been made?</label>
                                                <div className={`radio-options ${highlights.isRemovalDecisionMade ? 'is-highlighted' : ''}`}>
                                                    <Field name="isRemovalDecisionMade" type="radio" render={renderRadioOption} value="true" label="Yes" disabled={true} />
                                                    <Field name="isRemovalDecisionMade" type="radio" render={renderRadioOption} value="false" label="No" disabled={true} />
                                                    <Field name="isRemovalDecisionMade" type="radio" render={renderRadioOption} value="unknown" label="N/A" disabled={true} />
                                                </div>
                                            </div>

                                            <div className="input-wrapper">
                                                <Field
                                                    name="dateOfReturn"
                                                    type="text"
                                                    render={renderDatePicker}
                                                    label="Date of return"
                                                    placeholder=""
                                                    isHighlighted={highlights.dateOfReturn}
                                                    disabled={true}
                                                />
                                            </div>


                                            <hr />


                                            <h2>Voluntary Returns Service</h2>

                                            <div className="input-wrapper">
                                                <label>VRS applied for</label>
                                                <Field
                                                    name="voluntaryReturnsServiceTypeId"
                                                    type="text"
                                                    render={renderDropdownList}
                                                    options={vrsTypesOptions}
                                                    isHighlighted={highlights.voluntaryReturnsServiceTypeId}
                                                    disabled={true}
                                                />
                                            </div>

                                            <VoluntaryReturnsService
                                                values={values}
                                                highlights={highlights}
                                                disabled={true}
                                                vrsOutcomesOptions={vrsOutcomesOptions}
                                            />


                                            <hr />


                                            <h2>Family Returns Unit</h2>

                                            <div className="input-wrapper">
                                                <label>Involvement</label>
                                                <Field
                                                    name="familyReturnsUnitInvolvementId"
                                                    type="text"
                                                    render={renderDropdownList}
                                                    options={fruInvolvementsOptions}
                                                    isHighlighted={highlights.familyReturnsUnitInvolvementId}
                                                    disabled={true}
                                                />
                                            </div>

                                            <FamilyReturnsUnit
                                                values={values}
                                                highlights={highlights}
                                                disabled={true}
                                            />


                                            <hr />


                                            <h2>Home Office Notes</h2>

                                            <NoteWidget
                                                note={homeOfficeNote}
                                                disabled={true}
                                                onAddModalRequest={onAddHomeOfficeNoteModalRequest}
                                                onHistoryModalRequest={onHomeOfficeNoteHistoryModalRequest}
                                            />
                                        </div>
                                    </div>

                                    <div className="stripedbox">
                                        <div className="immigration-edit-form">
                                            <QueriesWidget
                                                queries={queries}
                                                disabled={disabled}
                                                onAddQueryModalRequest={onAddQueryModalRequest}
                                                onViewQueryModalRequest={onViewQueryModalRequest}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="stripedbox">
                                <div className="form-buttons">
                                    <button
                                        type="submit"
                                        className="rbtn primary"
                                        disabled={disabled}
                                        ref={formSubmitButton}
                                    >
                                        <FontAwesomeIcon icon={faFlagCheckered} />
                                        <span>Save</span>
                                    </button>
                                    <button type="button" className="rbtn primary" onClick={onHistoryModalRequest}>
                                        <FontAwesomeIcon icon={faHistory} />
                                        <span>History</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                );
            }}
        />
    );
};

export default CaseOwnerForm;
