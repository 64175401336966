import React from 'react';
import { Link, useRouteMatch } from "react-router-dom";

const Tab = ({ label, to, activeOnlyWhenExact }) => {
    let match = useRouteMatch({
      path: to,
      exact: activeOnlyWhenExact
    });
  
    return (
        <Link className={match ? "selected" : ""} to={to}>{label}</Link>
    );
}

const getOnDemandTab = (path, myUserId) => {

    const isUserAdd = (path.toLowerCase().indexOf('users/add') > -1);
    const isUserEdit = () => (path.toLowerCase().indexOf('users/edit') > -1);
    const isAnotherUser = () => (path.toLowerCase().indexOf(myUserId) === -1);

    if (isUserAdd || (isUserEdit() && isAnotherUser())) {
        const label = isUserAdd ? "Add User" : "Edit User";
        return (
            <>
                <li className="queries-spacer">
                </li>
                <li>
                    <Tab to={path} activeOnlyWhenExact={true} label={label} />
                </li>
            </>
        )
    }

    return null;
}

export default ({myUserId, userIsAdmin}) => {

    const onDemandTab = getOnDemandTab(window.location.hash.substring(1), myUserId);

    const adminTabs = userIsAdmin
    ? (
        <>
            <li className="queries-spacer">
            </li>
            <li>
                <Tab to="/admin/users" activeOnlyWhenExact={true} label="Add / Edit / Delete user accounts" />
            </li>
            { onDemandTab }
            <li className="queries-spacer">
            </li>
            <li>
                <Tab to="/admin/file-upload" activeOnlyWhenExact={true} label="File Upload" />
            </li>
        </>
    ) : null
    
    return (
        <div className="inner-tabs">
            <ul>
                <li>
                    <Tab to="/admin/" activeOnlyWhenExact={true} label="Admin" />
                </li>
                <li className="queries-spacer">
                </li>
                <li>
                    <Tab to={`/admin/users/edit/${myUserId}`} label="My Details" />
                </li>
                <li className="queries-spacer">
                </li>
                <li>
                    <Tab to={`/admin/users/reset-password/${myUserId}`} label="My Password" />
                </li>
                
                { adminTabs }
            </ul>
            <div className="clearfix"></div>
        </div>
    )
}