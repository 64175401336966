import React, { useEffect, useRef } from "react";
import { Field, Form } from 'react-final-form';

import { renderDropdownList, renderTextArea } from '../../../form-inputs/final-form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlagCheckered } from '@fortawesome/free-solid-svg-icons';


const NoteAddForm = ({onSubmit, initialValues, dependants, noteTypes, disabled}) => {
    
    const formSubmitButton = useRef();

    useEffect(() => {
        var { unsubscribe } = window.dispatcher.subscribe("change-tracker-modal-submit-form", () => formSubmitButton.current.click());

        return () => {
            unsubscribe();
        }
    }, []);

    const dependantNoteTypes = [2,3];

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            render={({ handleSubmit, submitting, values }) => {

                const isDisabled = disabled || submitting;

                return (
                    <form autoComplete="off" onSubmit={handleSubmit} rel="change-tracked-modal">
    
                        <div className="modal-edit-form">
                            <div className="input-wrapper">
                                <Field name="noteTypeId" type="text" render={renderDropdownList} options={noteTypes} isClearable={false} />

                                {
                                    dependantNoteTypes.includes(values.noteTypeId)
                                        ? <Field name="dependantId" type="text" render={renderDropdownList} options={dependants} isClearable={false} />
                                        : null
                                }
                            </div>
                            <div className="input-wrapper">
                                <Field name="content" type="text" render={renderTextArea} isMandatory={true} disabled={isDisabled} isFullWidth={true} />
                            </div>

                            <div className="form-buttons">
                                <button type="submit" className="rbtn primary" disabled={isDisabled} ref={formSubmitButton}>
                                    <FontAwesomeIcon icon={faFlagCheckered} />
                                    <span>Save</span>
                                </button>
                            </div>
                        </div>
                        
                    </form>
                )
            }}
        />
    )
}


export default NoteAddForm;
