const splitName = (fullName) => {
    if (fullName.indexOf(' ') >= 0) {
        const result = fullName.split(/\s(.*)/s);
        return {
            firstName: result[0],
            surname: result[1]
        };
    }

    return {
        firstName: fullName,
        surname: ''
    };
}

export default splitName;