import React, { useState, useEffect } from 'react';

import { onDeleteSubmit } from '../../../../../api-endpoint-calls/case/address';

import Form from './form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faTimes } from '@fortawesome/free-solid-svg-icons';

export default ({onClose, caseId, addressId}) => {

    const [ visible, setVisible ] = useState(false);

    const initialValues = { 
        caseId, 
        addressId 
    };

    useEffect(() => {
        if (visible === false) {
            setTimeout(() =>  setVisible(true), 200);
        }
    }, [visible, setVisible]);

    const onSubmit = async (formValues) => {
        await onDeleteSubmit(formValues);
        onClose();
    }

    return (
        <>
            <div className={ `react-modal-anchor ${(visible ? 'show' : '')}`}>
                <div className={ `react-modal user-delete ${visible ? 'show' : ''}` } style={{ width: '500px' }}>
                    <button onClick={onClose} className="close-button"><FontAwesomeIcon icon={faTimes} /></button>
                    <h1>
                        <FontAwesomeIcon icon={faHome} />
                        Delete Address?
                    </h1>

                    <Form onSubmit={onSubmit} onClose={onClose} initialValues={initialValues} />
                </div>
            </div>
            <div className={ `react-modal-backdrop ${visible ? 'show' : ''}` }></div>
        </>
    );
    
}